import axios from "axios";
import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { Form, Icon, Button, Checkbox, notification, message } from "antd";
import {
  COOKIE,
  createCookie,
  deleteCookie,
  getCookie
} from "../../../services/cookie";
import { fetchApi } from "../../../services/api";

import Input from "../../components/Custom/Input";
import Select from "../../components/Custom/Select";
import * as JWT from "jwt-decode";
//Styling Import
import "./index.less";

const { Option } = Select;
let _this;

message.config({
  maxCount: 1
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      farmList: []
    };
    _this = this;
  }

  componentDidMount() {
    this.fetchFarmData();
    let {
      form: { setFieldsValue }
    } = this.props;

    // Redirect if logged in
    var token = getCookie(COOKIE.ID_TOKEN);
    var decoded = token ? JWT(token) : "";
    if (decoded && decoded.roles[0] == "ROLE_REPORTS") {
      _this.props.history.push("/");
    } else if (decoded && decoded.roles[0] == ("ROLE_ADMIN" || "ROLE_FARM" || "ROLE_VALUATION")) {
      _this.props.history.push("/");
    }

    if (localStorage.usrname) {
      setFieldsValue({
        username: localStorage.usrname,
        password: localStorage.pass,
        location: localStorage.location
      });
      var token = getCookie(COOKIE.ID_TOKEN);
      var decoded = token ? JWT(token) : "";
      if (decoded && decoded.roles[0] == "ROLE_REPORTS") {
        _this.props.history.push("/");
      } else if (decoded && decoded.roles[0] == ("ROLE_ADMIN" || "ROLE_FARM" || "ROLE_VALUATION")) {
        _this.props.history.push("/");
      }
      // if (getCookie(COOKIE.ROLE) == "ROLE_REPORTS") {
      //   //_this.props.history.push("/input-formats/120-days-count");
      //   _this.props.history.push("/");
      // } else if (getCookie(COOKIE.ROLE) == ("ROLE_ADMIN" || "ROLE_FARM")) {
      //   _this.props.history.push("/");
      // }
    }
  }

  fetchFarmData = () => {
    // fetchApi("/farms", "get").then(data =>
    //   this.setState({
    //     data: data
    //   }, () => {
    //     console.log(this.state.data)

    //     let farm = [];
    //     this.state.data.map(({ name, code }, index) => (
    //       farm.push(name)
    //     ));
    //     this.setState({
    //       farmList: farm
    //     }, () => console.log(this.state.farmList)
    //     )

    //   })
    // );

    axios({
      method: "GET",
      url: `${
        process.env.NODE_ENV == "development"
          ? //"http://localhost:8000/farms"
          //"https://api.cdms.deejayfarms.com/farms"
          "https://api.cdms.opspldev.in/farms"
          //: "https://api.cdms.opspldev.in/farms"          //Dev
          : "https://api.cdms.deejayfarms.com/farms"    //Live
        //"http://api.cdms.ho.opspl.com:8090/farms"
        }`,
      headers: {
        accept: "application/json"
      }
    })
      .then(res => {
        this.setState(
          {
            data: res.data
          },
          () => {
            let farm = [];
            this.state.data.map(({ name, code }, index) => farm.push(name));
            this.setState({
              farmList: farm
            });
          }
        );
      })
      .catch(err => {
        let { status } = err.response;
        if (status === 401) {
          deleteCookie(COOKIE.ID_TOKEN);
          deleteCookie(COOKIE.NAME);
          deleteCookie(COOKIE.FARM);
          deleteCookie(COOKIE.CODE);
          deleteCookie(COOKIE.ROLE);
          deleteCookie(COOKIE.UUID);

          window.location.assign("/");
        } else if (status === 400) {
          if (err.response.data.violations) {
            return err.response.data;
          } else {
            return false;
          }
        }
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      let code = null;
      if (!err) {
        this.setState({ loading: true });

        for (let i = 0; i < this.state.data.length; i++) {
          if (this.state.data[i]["name"] == values.location) {
            code = this.state.data[i]["code"];
            break;
          }
        }

        let requestBody = {
          username: values.username,
          password: values.password,
          //farm: values.location.toLowerCase(),
          farm: values.location,
          code: code
          //farm: values.location
        };

        fetch(
          `${
          process.env.NODE_ENV == "development"
            ? //"http://127.0.0.1:8000/login_check"
            //"https://api.cdms.deejayfarms.com/login_check"
            "https://api.cdms.opspldev.in/login_check"
            //: "https://api.cdms.opspldev.in/login_check"          //Dev
            : "https://api.cdms.deejayfarms.com/login_check"    //Live
          //"http://api.cdms.ho.opspl.com:8090/login_check"
          }`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
          }
        ).then(response => {
          response.json().then(response => {
            if (response.code != 401) {
              createCookie(COOKIE.ID_TOKEN, response.token, null, "/");
              createCookie(COOKIE.NAME, response.name, null, "/");
              createCookie(COOKIE.FARM, response.farm, null, "/");
              createCookie(COOKIE.CODE, response.code, null, "/");
              createCookie(COOKIE.ROLE, response.roles[0], null, "/");
              createCookie(COOKIE.UUID, response.uuid, null, "/");
              if (localStorage) {
                if (values.remember) {
                  localStorage.usrname = values.username;
                  localStorage.pass = values.password;
                  localStorage.location = values.location;
                  localStorage.code = code;
                } else {
                  localStorage.clear();
                }
              }
              var token = getCookie(COOKIE.ID_TOKEN);
              var decoded = token ? JWT(token) : "";
              var role = decoded ? decoded.roles[0] : "";
              if (role == "ROLE_REPORTS") {
                //_this.props.history.push("/input-formats/120-days-count");
                localStorage.menuselect = "33";
                _this.props.history.push("/");
              } else {
                _this.props.history.push("/");
              }
            } else {
              if(response.message === "Invalid credentials.")
                message.error("Invalid username or password", 5);
              else 
                message.error(response.message, 5);
              // message.error("Invalid username or password", 5);
            }
          });
          this.setState({ loading: false });
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <DocumentTitle title="Coconut Data Management System">
        <div className="login-container">
          <div className="login-form-container">
            <div className="login-header">
              <p className="login-header-title">Deejay Groups</p>
              <p className="login-header-sub-title">
                Coconut Data Management System
              </p>
              <div className="login-header-icon">
                <Icon type="lock" theme="filled" />
              </div>
            </div>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [{ required: true, message: "This field is required" }]
                })(<Input label="Username" required={true} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [{ required: true, message: "This field is required" }]
                })(<Input type="password" label="Password" required={true} />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("location", {
                  rules: [
                    { required: true, message: "This field is required" }
                  ],
                  initialValue: this.state.data.length>0? "Madurai":null
                })(
                  <Select
                    label="Location"
                    required={true}
                    selectItems={this.state.farmList}
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("remember", {
                  valuePropName: "checked",
                  initialValue: false
                })(
                  <Checkbox className="login-form-remember-me">
                    Remember me
                  </Checkbox>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default Form.create({ name: "login_form" })(Login);
