import React from "react";

import { DwarfSeedling, TallSeedling } from "./SeedlingGrowth";

import { DwarfTree, TallTree } from "./TreeHarvest";

import { DwarfCoconut, TallCoconut } from "./CoconutAnalysis";

import { DwarfMale, TallMale } from "./PollenCollection";

import { DwarfTreeEmasculation, DwarfTree120Dayscount } from "./Others";

export default [
  {
    label: "Seedling Growth",
    key: "1",
    render: null,
    link: [
      "/transaction-files/seedling-growth/dwarf-seedling",
      "/transaction-files/seedling-growth/tall-seedling"
    ],
    children: [
      {
        label: "Dwarf Seedling",
        key: "1",
        link: "/transaction-files/seedling-growth/dwarf-seedling",
        render: () => <DwarfSeedling />
      },
      {
        label: "Tall Seedlings",
        key: "2",
        link: "/transaction-files/seedling-growth/tall-seedling",
        render: () => <TallSeedling />
      }
    ]
  },
  {
    label: "Dwarf Tree Emasculation",
    key: "2",
    link: "/transaction-files/dwarf-tree-emasculation",
    render: () => <DwarfTreeEmasculation />
  },
  {
    label: "Dwarf Tree 120 Days count",
    key: "3",
    link: "/transaction-files/dwarf-tree-120-days",
    render: () => <DwarfTree120Dayscount />
  },
  {
    label: "Tree Harvest",
    key: "4",
    render: null,
    link: [
      "/transaction-files/tree-harvest/dwarf-tree",
      "/transaction-files/tree-harvest/tall-tree"
    ],
    children: [
      {
        label: "Dwarf Tree",
        key: "1",
        link: "/transaction-files/tree-harvest/dwarf-tree",
        render: () => <DwarfTree />
      },
      {
        label: "Tall Tree",
        key: "2",
        link: "/transaction-files/tree-harvest/tall-tree",
        render: () => <TallTree />
      }
    ]
  },
  {
    label: "Coconut Analysis",
    key: "5",
    render: null,
    link: [
      "/transaction-files/coconut-analysis/dwarf-coconut",
      "/transaction-files/coconut-analysis/tall-coconut"
    ],
    children: [
      {
        label: "Dwarf Coconut",
        key: "1",
        link: "/transaction-files/coconut-analysis/dwarf-coconut",
        render: () => <DwarfCoconut />
      },
      {
        label: "Tall Coconut",
        key: "2",
        link: "/transaction-files/coconut-analysis/tall-coconut",
        render: () => <TallCoconut />
      }
    ]
  },
  {
    label: "Pollen Collection",
    key: "6",
    render: null,
    link: [
      "/transaction-files/pollen-collection/dwarf-male",
      "/transaction-files/pollen-collection/tall-male"
    ],
    children: [
      {
        label: "Dwarf Male",
        key: "1",
        link: "/transaction-files/pollen-collection/dwarf-male",
        render: () => <DwarfMale />
      },
      {
        label: "Tall Male",
        key: "2",
        link: "/transaction-files/pollen-collection/tall-male",
        render: () => <TallMale />
      }
    ]
  }
  // {
  //     label: 'Others',
  //     key: '7',
  //     children: [
  //         {
  //             label: "Dwarf Tree Emasculation",
  //             key: '11',
  //             link: '/transaction-files/others/dwarf-tree-emasculation',
  //             render: () => <DwarfTreeEmasculation />
  //         }, {
  //             label: "Dwarf Tree 120 Days count",
  //             key: '22',
  //             link: '/transaction-files/others/dwarf-tree-120-days',
  //             render: () => <DwarfTree120Dayscount />
  //         }]
  // }
];
