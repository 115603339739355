import React, { Component } from "react";

//Component
import { message, Spin, Table } from "antd";
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import FarmTotal from "./FarmTotal";
import Empty from "../../components/Custom/Empty";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";
import { sortFunction, sortFunction2 } from "../../../utility/sorter";
import { formatDate } from "../../../utility/date";
import { COOKIE, getCookie } from "../../../services/cookie";

class DwarfTallTreesWithMAXMINHarvestedNuts extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    treeTypeList: [],
    treeType: null,
    treeCode: null,
    harvestedNutsFrom: null,
    harvestedNutsTo: null,
    type: null,
    equality: null,
    harvestedNuts: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchDwarfTallTreeWithHarvestedNuts = () => {
    let {
      blockNumber,
      treeType,
      harvestedNutsFrom,
      type,
      equality,
      harvestedNuts,
      harvestedNutsTo
    } = this.state;
    this.setState({data: []})
    if (
      harvestedNutsFrom &&
      harvestedNutsTo &&
      type &&
      equality &&
      harvestedNuts
    ) {
      let url = "/query-module/max-min-harvested-nuts";
      url += `?type=${type === "Dwarf" ? 1 : 2}`;
      url += `&fromDate=${harvestedNutsFrom}&toDate=${harvestedNutsTo}`;
      url += `&is_max=${
        equality === "Top" ? "true" : "false"
      }&limit=${harvestedNuts}`;

      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      this.setState({ loading: true });

      fetchApi(url, "get").then(res => {
        if (res) {
          let { data } = res;
          this.setState({
            data: data != null ? sortFunction(sortFunction(data, "tree_no_full"), "block_no_full") : []
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else
      this.setState({
        data: []
      });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchType = it =>
    it
      ? fetchApi(`/tree_types?type=${it === "Dwarf" ? 1 : 2}`, "get").then(
          data =>
            this.setState(
              {
                treeTypeList: sortFunction(data, "code"),
                treeCode: null,
                treeType: null,
                type: it
              },
              () => this.fetchDwarfTallTreeWithHarvestedNuts()
            )
        )
      : this.setState(
          { treeTypeList: [], treeType: null, treeCode: null, type: null },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        this.setState(
          {
            blockNumber: item
              ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
              : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "tree":
        this.setState(
          {
            treeType: item
              ? cloneDeep(this.state.treeTypeList).filter(
                  each => each.code === item
                )[0].uuid
              : null,
            treeCode: item
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "equality":
        this.setState(
          {
            equality: item ? item : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      case "harvestedNuts":
        this.setState(
          {
            harvestedNuts: item ? item : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
        break;
      default:
        this.setState(
          {
            harvestedNutsFrom:
              item.length > 0 ? item[0].format("YYYY-MM-DD") : null,
            harvestedNutsTo:
              item.length > 0 ? item[1].format("YYYY-MM-DD") : null
          },
          () => this.fetchDwarfTallTreeWithHarvestedNuts()
        );
    }
  };

  generateExcel = () => {
    let {
      blockNumber,
      treeType,
      harvestedNutsFrom,
      type,
      equality,
      harvestedNuts,
      harvestedNutsTo
    } = this.state;

    if (
      harvestedNutsFrom &&
      harvestedNutsTo &&
      type &&
      equality &&
      harvestedNuts
    ) {
      let url = "/query-module/max-min-harvested-nuts";
      url += `?type=${type === "Dwarf" ? 1 : 2}`;
      url += `&fromDate=${harvestedNutsFrom}&toDate=${harvestedNutsTo}`;
      url += `&is_max=${
        equality === "Top" ? "true" : "false"
      }&limit=${harvestedNuts}`;

      if (blockNumber) url += `&block.uuid=${blockNumber}`;
      if (treeType) url += `&tree_type.uuid=${treeType}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      treeTypeList,
      treeCode,
      blockNoList,
      loading,
      harvestedNutsFrom,
      harvestedNutsTo,
      type,
      equality,
      harvestedNuts
    } = this.state;
    const columns =
      this.state.type === "Dwarf"
        ? [
            {
              title: "Block No.",
              dataIndex: "block_no_full",
              key: "block_no_full"
            },
            {
              title: "Tree No.",
              dataIndex: "tree_no_full",
              key: "tree_no_full"
            },
            {
              title: "Date of Planting",
              dataIndex: "dt_planted",
              key: "dt_planted",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Type",
              dataIndex: "code",
              key: "code"
            },
            {
              title: "Spathes",
              dataIndex: "no_of_spathes",
              key: "no_of_spathes"
            },
            {
              title: "Buttons",
              dataIndex: "no_of_buttons",
              key: "no_of_buttons"
            },
            {
              title: "120 Days Nuts",
              dataIndex: "count_120_days_nuts",
              key: "count_120_days_nuts"
            },
            {
              title: "Harvested Nuts",
              dataIndex: "harvested_nuts",
              key: "harvested_nuts"
            },

            {
              title: "Spathes/Nuts",
              dataIndex: "nuts_spathe",
              key: "nuts_spathe"
            }
          ]
        : [
            {
              title: "Block No.",
              dataIndex: "block_no_full",
              key: "block_no_full"
            },
            {
              title: "Tree No.",
              dataIndex: "tree_no_full",
              key: "tree_no_full"
            },
            {
              title: "Date of Planting",
              dataIndex: "dt_planted",
              key: "dt_planted",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "Type",
              dataIndex: "code",
              key: "code"
            },
            {
              title: "No. of Bunches",
              dataIndex: "no_of_bunches",
              key: "no_of_bunches"
            },
            {
              title: "Harvested Nuts",
              dataIndex: "harvested_nuts",
              key: "harvested_nuts"
            },
            {
              title: "Nuts/Bunches",
              dataIndex: "nuts_bunches",
              key: "nuts_bunches"
            }
          ];
    let JSX =
      data && data.length > 0 ? (
        <div>
        <h3>{equality + " "+ harvestedNuts +" " + type +" "} Trees with {" "+ (equality === "Top" ?( "Maximum" ):("Minimum"))+" "}Harvested Nuts &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
        : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
        <h3>Harvested Nuts From {moment(this.state.harvestedNutsFrom).format("DD/MM/YYYY")} to {moment(this.state.harvestedNutsTo).format("DD/MM/YYYY")}</h3>
        <br/>
        <Table dataSource={data} columns={columns} pagination={false}/>
        </div>
      ) : harvestedNutsFrom &&
        harvestedNutsTo &&
        type &&
        equality &&
        harvestedNuts ? (
        <Empty />
      ) : (
        <Empty
          description={
            "Select the Tree Type, Top/Low Harvests, Number of Harvested Nuts and Period of Harvested Nuts"
          }
        />
      );
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;

    return (
      <ReportLayout
        title="Dwarf/Tall Trees with MAX-Harvested/MIN-Harvested Nuts"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it),
            className: "width-bock-no"
          },
          {
            label: "Tree",
            listItems: ["Dwarf", "Tall"],
            changeHandler: this.fetchType,
            className: "width-tree-type"
          },
          {
            label: "Type",
            disabled: !treeTypeList.length > 0,
            listItems: cloneDeep(treeTypeList).map(it => it.code),
            value: treeCode ? treeCode : undefined,
            changeHandler: it => this.onDropDownChangeHandler("tree", it),
            className: "width-type"
          },
          {
            label: "Type Of Harvest",
            listItems: ["Top", "Low"],
            changeHandler: it => this.onDropDownChangeHandler("equality", it),
            className: "width-type-harvest"
          },
          {
            label: "Harvested Nuts",
            type: "number",
            changeHandler: it =>
              this.onDropDownChangeHandler("harvestedNuts", it),
            className: "width-harvested-nuts"
          },
          {
            label: "Trees Harvested Nuts From:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) =>
              this.onDropDownChangeHandler("harvestDate", date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTallTreesWithMAXMINHarvestedNuts;
