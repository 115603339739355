import React from "react";
import { Table } from 'antd'

export default ({ data ,columns}) => {
  return (
    <div className="pagebreak">
      <br />
      <h3>Farm Total</h3>
      <hr/>
      <Table
        dataSource={data}
        pagination={false}
        columns={columns}
      />
    </div>
  );
};
