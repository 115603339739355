import React, { Component, Fragment } from "react";

import { Table, Statistic, message, Spin } from "antd";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { parseData } from "./parseData";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import { sortFunction } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

//Component import
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../components/Custom/Empty";

const { Column, ColumnGroup } = Table;

class DwarfTreeHarvestData extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    harvest_dt: null
  };

  componentDidMount() {
    this.setState({ loading: false });
    //this.fetchData();
    this.fetchBlockNo();
  }

  fetchData = () => {
    let { area_uuid, block_uuid, harvest_dt } = this.state,
      url = `/input-format/dwarf-harvest?month=${harvest_dt}`;
    if (harvest_dt) {
      this.setState({ loading: true });

      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      // }
      fetchApi(url, "get").then(data => {
        if (data) {
          let temp = data.map(({ dtEmasculation, ...rest }) => ({
            dtEmasculation: moment(dtEmasculation).format("DD/MM/YYYY"),
            goodNuts: "-",
            rejectedNuts: "-",
            ...rest
          }));
          this.setState({
            data: temp,
            dynamicData: parseData(temp)
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: [],
        dynamicData: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList, harvest_dt } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => (harvest_dt ? this.fetchData() : null)
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber, harvest_dt } = this.state;
    if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState({ rowNoList: sortFunction(res, "rowNoFull") });
      });
    }
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => (harvest_dt ? this.fetchData() : null)
    );
  };

  harvestDateHandler = it => {
    this.setState(
      {
        harvest_dt: it ? moment(it).format("YYYY-MM") : null
      },
      () => this.fetchData()
    );
  };

  generateTables = tableData =>
    tableData.map(({ blockNo, areaNo, value }, index) => (
      <div className="printblock" key={index}>
        <div className="multi-table-header">
          <Statistic title="Block No." value={blockNo} />
          <Statistic title="Area No." value={areaNo} />
        </div>
        <hr />
        <Table
          rowKey="uuid"
          className="multi-table"
          blockNo={blockNo}
          areaNo={areaNo}
          columns={[
            {
              title: "Tree No.",
              dataIndex: "treeNo",
              key: "treeNo"
            },
            {
              title: "Date of Emasculation",
              dataIndex: "dtEmasculation",
              key: "dtEmasculation"
            },
            {
              title: "No. of Buttons",
              children: [
                {
                  title: "Emasculation",
                  dataIndex: "buttons",
                  key: "emasculation"
                },
                {
                  title: "120 Days",
                  dataIndex: "actualNuts",
                  key: "120days"
                }
              ]
            },
            {
              title: "Nuts Quantity",
              children: [
                {
                  title: "Good Nuts",
                  dataIndex: "goodNuts",
                  key: "goodNuts"
                },
                {
                  title: "Rejected Nuts",
                  dataIndex: "rejectedNuts",
                  key: "rejectedNuts"
                }
              ]
            }
          ]}
          dataSource={value}
          pagination={false}
        />
      </div>
    ));

  generateExcel = () => {
    console.log("Fetch Api");
    let { area_uuid, block_uuid, harvest_dt } = this.state,
      url = `/input-format/dwarf-harvest?month=${harvest_dt}`;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      dynamicData,
      loading,
      blockNoList,
      areaNoList,
      blockNumber,
      areaNumber,
      harvest_dt
    } = this.state;
    // let JSX = harvest_dt ? (
    //   dynamicData.length > 0 ? (
    //     <Fragment>
    //       <div className="multi-table-header">
    //         <Statistic
    //           title="Farm."
    //           value={
    //             getCookie(COOKIE.FARM)
    //               .charAt(0)
    //               .toUpperCase() + getCookie(COOKIE.FARM).slice(1)
    //           }
    //         />
    //       </div>
    //       <br></br>
    //       {this.generateTables(dynamicData)}
    //     </Fragment>
    let JSX = harvest_dt ? (
      dynamicData.length > 0 ? (
        <Fragment>
          <h3>
            Dwarf Tree Harvest Data Input Format
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <h3>Harvest Due In : {" "+moment(this.state.harvest_dt).format("MMMM YYYY")}</h3>
          {this.generateTables(dynamicData)}
        </Fragment>
      ) : (
        <Empty></Empty>
      )
    ) : (
      <Empty description={"Select Harvest Due In "} />
    );
    const buttonList =
      dynamicData.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Dwarf Tree Harvest Data"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Harvest Due In",
            type: "month",
            changeHandler: this.harvestDateHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreeHarvestData;
