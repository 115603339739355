import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class RowNumber extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    dynamicTableData: [],
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNumber: null,
    areaNoList: [],
    area_uuid: null,
    btnLoad: false,
    pagination: true,
    editData: null,
    isEdit: false,
    rowData: [],
    blockUuid: null,
    areaUuid: null,
    rowUuid: null,
    tableLoader: false,
    rowNum: null,
    rowNumSuffix: null,
    addPopulateData: null,
    isAdded: false
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchRow();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Row Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Row-Number");
  }

  fetchBlockNo = () => {
    let { blockNumber } = this.state,
      url = "/blocksuuid";
    if (blockNumber) url += `?blockNo=${blockNumber}`;
    fetchApi(url, "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchRow = (reload = true) => {
    if (reload) {
      this.setState({ tableLoader: true, data: [], pagination: false });
      let { area_uuid, block_uuid, rowNum } = this.state,
        url = "/rows";
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;

      fetchApi(url, "get").then(data =>
        this.setState({
          tableLoader: false,
          data: sortFunction(sortFunction(sortFunction(data, "rowNoFull"), "areaNo"), "blockNo"),
          pagination: true
        })
      );
    }
  };

  addModalToggle = () => {
    let {
      blockNumber,
      block_uuid,
      areaNumber,
      addModal,
      isEdit,
      area_uuid,
      isAdded
    } = this.state;

    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          blockNumber: isEdit || isAdded ? blockNumber : null,
          block_uuid: isEdit || isAdded ? block_uuid : null,
          areaNumber: isEdit || isAdded ? areaNumber : null,
          area_uuid: isEdit || isAdded ? area_uuid : null,
          // addPopulateData: isAdded
          //   ? {
          //       blockNo: blockNumber,
          //       areaNo: areaNumber
          //     }
          //   : null,
          isEdit: false,
          //addPopulateData: null,
          isAdded: false
        }
        //() => this.fetchRow()
      );
    } else {
      if (!blockNumber || !areaNumber) {
        message.info("Select Block No. and Area No.");
        // } else if (!areaNumber) {
        //   message.info("Please Select an Area Number");
      } else {
        // this.setState({
        //   addModal: blockNumber
        //     ? areaNumber
        //       ? !this.state.addModal
        //       : false
        //     : false
        // });
        this.setState({
          addModal: blockNumber ? !this.state.addModal : false
        });
      }
    }
  };

  clearFields = () => {
    this.setState({
      // areaNumber: null,
      // area_uuid: null,
      addPopulateData: {
        startTree: null,
        endTree: null,
        //areaNo: null,
        rowNo: null
      }
    });
  };

  addFormHandler = ({
    rowNo,
    rowNoSuffix,
    startTree,
    startTreeSuffix,
    endTree,
    endTreeSuffix,
    ...val
  }) => {
    //to remove the previous instances of
    notification.destroy();

    let {
      block_uuid,
      area_uuid,
      isEdit,
      blockUuid,
      areaUuid,
      rowUuid,
      rowNum,
      rowNumSuffix
    } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/rows", "post", {
        rowNo: parseInt(rowNo),
        rowSuffix: rowNoSuffix == "--All--" ? null : rowNoSuffix,
        startTree: parseInt(startTree),
        startTreeSuffix: startTreeSuffix == "--All--" ? null : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix: endTreeSuffix == "--All--" ? null : endTreeSuffix,
        block: "/blocks/" + block_uuid,
        area: "/areas/" + area_uuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added a Row"
            });
            this.setState({ isAdded: true });
            this.clearFields();
            this.fetchRow();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add a Row"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/rows/${rowUuid}`, "put", {
        rowNo: parseInt(rowNum),
        rowSuffix:
          rowNumSuffix == "--All--" || rowNumSuffix == "" ? null : rowNumSuffix,
        startTree: parseInt(startTree),
        startTreeSuffix:
          startTreeSuffix == "--All--" || startTreeSuffix == ""
            ? null
            : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix:
          endTreeSuffix == "--All--" || endTreeSuffix == ""
            ? null
            : endTreeSuffix,
        block: "/blocks/" + blockUuid,
        area: "/areas/" + areaUuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully updated a Row"
            });
            this.fetchRow();
            this.addModalToggle()
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update a Row"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    // this.setState({ data: [], pagination: false }, () =>
    //   this.setState({ pagination: true })
    // );
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res, areaNumber: null }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        addPopulateData: {
          blockNo: it,
          areaNo: null
        }
      },
      () => this.fetchRow()
    );
  };

  onAreaChangeHandler = (it, reload = true) => {
    let { areaNoList, blockNumber } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null,
        addPopulateData: {
          blockNo: blockNumber,
          areaNo: it ? it : null
        }
      },
      () => this.fetchRow(reload)
    );
  };

  onEditClickHandler = item => {
    this.setState(
      {
        isEdit: true,
        rowData: item
      },
      () => {
        this.setState({
          blockUuid: item.blockUuid,
          areaUuid: item.areaUuid,
          rowUuid: item.uuid,
          rowNum: item.rowNo,
          rowNumSuffix: item.rowSuffix,
          addModal: true,
          editData: {
            blockNo: item.blockNo,
            areaNo: item.areaNo,
            rowNo: item.rowNoFull,
            startTree: item.startTree,
            startTreeSuffix: item.startTreeSuffix
              ? item.startTreeSuffix
              : "--All--",
            endTree: item.endTree,
            endTreeSuffix: item.endTreeSuffix ? item.endTreeSuffix : "--All--"
          }
        });
      }
    );
  };

  generateExcel = () => {
    let { area_uuid, block_uuid, rowNum } = this.state,
      url = "/rows";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      addModal,
      data,
      blockNoList,
      blockNumber,
      areaNoList,
      editData,
      pagination,
      tableLoader,
      btnLoad,
      isEdit,
      areaNumber,
      addPopulateData
    } = this.state;

    const columns = [
      {
        title: "Block No.",
        dataIndex: "blockNo"
      },
      {
        title: "Area No.",
        dataIndex: "areaNo"
      },
      {
        title: "Row No.",
        dataIndex: "rowNoFull"
      },
      {
        title: "Start Tree No.",
        dataIndex: "startTreeNoFull"
      },
      {
        title: "End Tree No.",
        dataIndex: "endTreeNoFull"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "startTree",
          placeholder: "Start Tree No.",
          type: "number",
          postfix: true
        },
        {
          name: "endTree",
          placeholder: "End Tree No.",
          type: "number",
          postfix: true
        }
      ];
    const _formItems = isEdit
      ? [
        {
          name: "blockNo",
          placeholder: "Block No.",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number",
          disabled: true
        },
        {
          name: "rowNo",
          placeholder: "Row No.",
          disabled: true
        }
      ].concat(formItems)
      : [
        {
          name: "blockNo",
          placeholder: "Block No.",
          type: "number",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number",
          //required: true,
          disabled: true
          //listData: cloneDeep(areaNoList).map(it => it.areaNo),
          //changeHandler: it => this.onAreaChangeHandler(it, false)
        },
        {
          name: "rowNo",
          placeholder: "Row No.",
          type: "number",
          postfix: true,
          disabled: areaNumber ? false : true
        }
      ].concat(formItems);

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Row Number Master"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        addPopulateData={addPopulateData}
        editData={editData ? editData : null}
        btnLoad={btnLoad}
        formItems={_formItems}
        isEdit={isEdit}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Row Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm :{" "}
            {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default RowNumber;
