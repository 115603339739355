import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../DataEntryStatusReport120Days/Multitable";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";
import { parseData } from "./parseData";

//mock
//import mock from "./mocData";

class DataEntryStatusReportTallMalePollenCollection extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null,
    ActivityMonth: null
  };

  componentDidMount() {
    //this.fetchDataEntryTall();
    this.fetchBlockNo();
  }

  fetchDataEntryTall = () => {
    let { block_uuid, ActivityMonth } = this.state,
      today = moment().format("YYYY-MM");
    if (ActivityMonth) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/data-entry-tall-male-pollen?month=${ActivityMonth}&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res) {
          this.setState({
            data: parseData(res)
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }

    // this.setState({
    //   data: mock
    // });
    // fetchApi(
    //     `/regular-reports/farm-production-activities-all-blocks?period=${
    //     DateOfSummary ? DateOfSummary : today
    //     }&block.uuid=${block_uuid}`,
    //     "get"
    // ).then(res => {
    //     if (res)
    //         this.setState({
    //             data: parserFunction(res.blocks).flat(1)
    //         })
    //     else message.error("Internal Server Error!");
    //     this.setState({ loading: false });
    // });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    // if (!this.state.ActivityMonth) {
    //   message.info("Select Activity Month");
    //   this.setState({
    //     blockNumber: null,
    //     block_uuid: null
    //   });
    // } else {
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null
      },
      () => this.fetchDataEntryTall()
    );
  };

  activityMonthHandler = date => {
    if (date) {
      this.setState(
        {
          ActivityMonth: date.format("YYYY-MM")
        },
        () => this.fetchDataEntryTall()
      );
    } else {
      this.setState({
        ActivityMonth: null,
        blockNumber: null,
        block_uuid: null,
        TreeType: null,
        data: null
      });
    }
  };

  generateExcel = () => {
    let { block_uuid, ActivityMonth } = this.state,
      today = moment().format("YYYY-MM");
    fetchExcelApi(
      `/regular-reports/data-entry-tall-male-pollen?month=${ActivityMonth}&block.uuid=${block_uuid}`,
      "get"
    ).then();
  };

  render() {
    let { data, loading, farmTotal, blockNoList, ActivityMonth } = this.state;
    const columns = [
      {
        title: "Date Of Pollen Collected",
        //dataIndex: "dt_pollen_collected",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_pollen_collected
            ? it.dt_pollen_collected.length > 10
              ? {
                  children: it.dt_pollen_collected,

                  props: {
                    colSpan: 6
                  }
                }
              : it.dt_pollen_collected == "Total"
              ? it.dt_pollen_collected
              : formatDate(it.dt_pollen_collected)
            : "-"
      },
      {
        title: "Total No. Of Trees",
        //dataIndex: "count"
        render: it =>
          it.dt_pollen_collected.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.count
            ? it.count
            : "-"
      },
      {
        title: "Dates Of Starting",
        //dataIndex: "start_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_pollen_collected.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_pollen_collected == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.start_date
            ? formatDate(it.start_date)
            : "-"
      },
      {
        title: "Date Of Ending",
        //dataIndex: "end_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_pollen_collected.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_pollen_collected == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.end_date
            ? formatDate(it.end_date)
            : "-"
      },
      {
        title: "Last Update Date",
        //dataIndex: "update_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_pollen_collected.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_pollen_collected == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.update_date
            ? formatDate(it.update_date)
            : "-"
      }
    ];
    let JSX =
      data && data.blocks ? (
        <MultiTable columns={columns} dataSource={data} multitable={true} type={"Tall Male Pollen Collection"}/>
      ) : ActivityMonth ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Activity Month"} />
      );
    const buttonList =
      data && data.blocks
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Data Entry Status Report Tall Male Pollen Collection"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Activity Month",
            type: "month",
            hasInitial: false,
            changeHandler: this.activityMonthHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DataEntryStatusReportTallMalePollenCollection;
