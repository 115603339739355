import { sortFunction } from "../../../utility/sorter";

export const parseData = arr => {
  let resultArray = [];
  var Areas = [];
  var Data = [];
  arr &&
    arr.map(({ block_no, areas, block_codes, block_total }, i) => {
      var Area_no = [];
      var Area_count = [];
      var Code = [];
      var Tree_nos = [];
      var Total_count = [];

      var Blocks = [];
      areas &&
        areas.map(({ area_no, trees, area_count }, j) => {
          var prev_area = area_no;

          trees &&
            trees.map(({ code, tree_nos, total_count }, k) => {
              var curr_area = prev_area;

              Blocks.push({
                block_no: block_no,
                area_no: area_no,
                code: code,
                tree_nos: tree_nos,
                total_count: total_count
              });

              // Code.push(code);
              // Tree_nos.push(tree_nos);
              // Total_count.push(total_count);
            });

          if (trees != null) {
            Areas.push({ block_data: Blocks, area_count: area_count });
          }
          // console.log("Area count is zero for area no", area_no);

          //Areas.push({ block_data: Blocks, area_count: area_count });

          Blocks = [];
        });

      var Block_codes = [];
      block_codes &&
        block_codes.map(({ code, area_count }, l) => {
          Block_codes.push({ Code: code, Area_count: area_count });
        });

      // if (block_total > 0) {
      //   Data.push({
      //     Areas: Areas,
      //     //Blocks: Blocks,
      //     block_total: block_total,
      //     block_no: block_no,
      //     Block_codes: Block_codes
      //   });
      // }
      Data.push({
        Areas: Areas,
        //Blocks: Blocks,
        block_total: block_total ? block_total : null,
        block_no: block_no,
        Block_codes: Block_codes.length > 0 ? Block_codes : null
      });
      // Data.push({
      //   Areas: Areas,
      //   //Blocks: Blocks,
      //   block_total: block_total,
      //   block_no: block_no,
      //   Block_codes: Block_codes
      // });

      Blocks = [];
      Areas = [];
    });
  console.log("hiii", Data);
  if (Data.length == 1) {
    if (Data[0].Areas.length == 0) {
      return null;
    } else return sortFunction(Data, "block_no");
  }

  if (Data.length == 0) {
    return null;
  }
  return sortFunction(Data, "block_no");
};
