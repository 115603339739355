import React from "react";
//Utility
import { formatDate } from "../../../../utility/date";

const columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full",
    key: "tree_no_full"
  },
  {
    title: "Date of Planting",
    dataIndex: "dt_planted",
    key: "dt_planted",
    render: it => (it ? formatDate(it) : "-")
  },
  /*{
    title: "Date Died",
    dataIndex: "dt_died",
    key: "dt_died"
  },*/
  {
    title: "Age",
    children: [
      {
        title: "Years",
        dataIndex: "years",
        key: "years"
      },
      {
        title: "Months",
        dataIndex: "months",
        key: "months"
      }
    ]
  }
];

export default columns;
