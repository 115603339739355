import React from "react";

import { BlockNumber, AreaNumber, RowNumber, PitsGaps } from "./Location";

import {
  TreeTypes as DwarfTreeTypes,
  Seedlings as DwarfSeedlings,
  Plants as DwarfPlants,
  Trees as DwarfTrees,
  ValuationChart as DwarfValuationChart,
  ValuationAmount as ValuationAmount 
} from "./Dwarf";

import {
  TreeTypes as TallTreeTypes,
  Seedlings as TallSeedlings,
  Plants as TallPlants,
  Trees as TallTrees,
  ValuationChart as TallValuationChart
} from "./Tall";

import {
  DwarfMaster,
  TallMaster,
  DwarfTree as DwarfTreeResearch,
  TallTree as TallTreeResearch
} from "./Research";

export const buttonList = [
  {
    label: "Location",
    key: "1",
    children: [
      {
        label: "Block Number",
        key: "11",
        link: "/master-files/location/block-number",
        render: () => <BlockNumber />,
        span: 6
      },
      {
        label: "Area Number",
        key: "22",
        link: "/master-files/location/area-number",
        render: () => <AreaNumber />,
        span: 6
      },
      {
        label: "Row Number",
        key: "33",
        link: "/master-files/location/row-number",
        render: () => <RowNumber />,
        span: 6
      },
      {
        label: "Pits / Gaps",
        key: "44",
        link: "/master-files/location/pits-gaps",
        render: () => <PitsGaps />,
        span: 6
      }
    ]
  },
  {
    label: "Dwarf",
    key: "2",
    children: [
      {
        label: "Dwarf Tree Types",
        key: "11",
        link: "/master-files/dwarf/dwarf-tree-types",
        render: () => <DwarfTreeTypes />,
        span: 5
      },
      {
        label: "Dwarf Seedlings",
        key: "22",
        link: "/master-files/dwarf/dwarf-seedlings",
        render: () => <DwarfSeedlings />,
        span: 5
      },
      {
        label: "Dwarf Plants",
        key: "33",
        link: "/master-files/dwarf/dwarf-plants",
        render: () => <DwarfPlants />,
        span: 4
      },
      {
        label: "Dwarf Trees",
        key: "44",
        link: "/master-files/dwarf/dwarf-trees",
        render: () => <DwarfTrees />,
        span: 5
      },
      // {
      //   label: "Dwarf Valuation Chart",
      //   key: "55",
      //   link: "/master-files/dwarf/dwarf-valuation-chart",
      //   render: () => <DwarfValuationChart />,
      //   span: 5
      // }
      {
        label: "Valuation Amount",
        key: "55",
        link: "/master-files/dwarf/valuation-amount",
        render: () => <ValuationAmount />,
        span: 5
      }
    ]
  },
  {
    label: "Tall",
    key: "3",
    children: [
      {
        label: "Tall Tree Types",
        key: "11",
        link: "/master-files/tall/tall-tree-types",
        render: () => <TallTreeTypes />,
        span: 6
      },
      {
        label: "Tall Seedlings",
        key: "22",
        link: "/master-files/tall/tall-seedlings",
        render: () => <TallSeedlings />,
        span: 6
      },
      {
        label: "Tall Plants",
        key: "33",
        link: "/master-files/tall/tall-plants",
        render: () => <TallPlants />,
        span: 6
      },
      {
        label: "Tall Trees",
        key: "44",
        link: "/master-files/tall/tall-trees",
        render: () => <TallTrees />,
        span: 6
      }
      //,
      // {
      //   label: "Tall Valuation Chart",
      //   key: "55",
      //   link: "/master-files/tall/tall-valuation-chart",
      //   render: () => <TallValuationChart />,
      //   span: 5
      // }
    ]
  },
  {
    label: "Research",
    key: "4",
    children: [
      {
        label: "Dwarf Research Master",
        key: "11",
        link: "/master-files/research/dwarf-research-master",
        render: () => <DwarfMaster />,
        span: 6
      },
      {
        label: "Tall Research Master",
        key: "22",
        link: "/master-files/research/tall-research-master",
        render: () => <TallMaster />,
        span: 6
      },
      {
        label: "Dwarf Tree Research",
        key: "33",
        link: "/master-files/research/dwarf-tree-research",
        render: () => <DwarfTreeResearch />,
        span: 6
      },
      {
        label: "Tall Tree Research",
        key: "44",
        link: "/master-files/research/tall-tree-research",
        render: () => <TallTreeResearch />,
        span: 6
      }
    ]
  }
];

export const buttonListFarms = [
  {
    label: "Location",
    key: "1",
    children: [
      {
        label: "Block Number",
        key: "11",
        link: "/master-files/location/block-number",
        render: () => <BlockNumber />,
        span: 6
      },
      {
        label: "Area Number",
        key: "22",
        link: "/master-files/location/area-number",
        render: () => <AreaNumber />,
        span: 6
      },
      {
        label: "Row Number",
        key: "33",
        link: "/master-files/location/row-number",
        render: () => <RowNumber />,
        span: 6
      },
      {
        label: "Pits / Gaps",
        key: "44",
        link: "/master-files/location/pits-gaps",
        render: () => <PitsGaps />,
        span: 6
      }
    ]
  },
  {
    label: "Dwarf",
    key: "2",
    children: [
      {
        label: "Dwarf Tree Types",
        key: "11",
        link: "/master-files/dwarf/dwarf-tree-types",
        render: () => <DwarfTreeTypes />,
        span: 6
      },
      {
        label: "Dwarf Seedlings",
        key: "22",
        link: "/master-files/dwarf/dwarf-seedlings",
        render: () => <DwarfSeedlings />,
        span: 6
      },
      {
        label: "Dwarf Plants",
        key: "33",
        link: "/master-files/dwarf/dwarf-plants",
        render: () => <DwarfPlants />,
        span: 6
      },
      {
        label: "Dwarf Trees",
        key: "44",
        link: "/master-files/dwarf/dwarf-trees",
        render: () => <DwarfTrees />,
        span: 6
      }
    ]
  },
  {
    label: "Tall",
    key: "3",
    children: [
      {
        label: "Tall Tree Types",
        key: "11",
        link: "/master-files/tall/tall-tree-types",
        render: () => <TallTreeTypes />,
        span: 6
      },
      {
        label: "Tall Seedlings",
        key: "22",
        link: "/master-files/tall/tall-seedlings",
        render: () => <TallSeedlings />,
        span: 6
      },
      {
        label: "Tall Plants",
        key: "33",
        link: "/master-files/tall/tall-plants",
        render: () => <TallPlants />,
        span: 6
      },
      {
        label: "Tall Trees",
        key: "44",
        link: "/master-files/tall/tall-trees",
        render: () => <TallTrees />,
        span: 6
      }
      //,
      // {
      //   label: "Tall Valuation Chart",
      //   key: "55",
      //   link: "/master-files/tall/tall-valuation-chart",
      //   render: () => <TallValuationChart />,
      //   span: 5
      // }
    ]
  },
  {
    label: "Research",
    key: "4",
    children: [
      {
        label: "Dwarf Research Master",
        key: "11",
        link: "/master-files/research/dwarf-research-master",
        render: () => <DwarfMaster />,
        span: 6
      },
      {
        label: "Tall Research Master",
        key: "22",
        link: "/master-files/research/tall-research-master",
        render: () => <TallMaster />,
        span: 6
      },
      {
        label: "Dwarf Tree Research",
        key: "33",
        link: "/master-files/research/dwarf-tree-research",
        render: () => <DwarfTreeResearch />,
        span: 6
      },
      {
        label: "Tall Tree Research",
        key: "44",
        link: "/master-files/research/tall-tree-research",
        render: () => <TallTreeResearch />,
        span: 6
      }
    ]
  }
];