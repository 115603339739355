import React, { Component, Fragment } from "react";

//Component
import { message, Spin, Descriptions } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";

import dummyData from "./Deletion.json";

//class PerformanceComparisonReport extends Component {
class DwarfTallTypeDeletionReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,

    blockNoList: [],
    blockNumber: null,
    block_uuid: null,

    areaNumber: null,
    area_uuid: null,
    areaNoList: [],

    isopen: false,
    period: [],
    periodVS: null,
    formType: null,
    title: "Age Of Flowering",

    to_date: null,
    from_date: null,
    treeType: "Dwarf",
    treeTypeCode: 1
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchDeletionReport();
  }

  fetchDeletionReport = () => {
    let { block_uuid, treeTypeCode, to_date, from_date } = this.state;

    if (to_date && from_date && treeTypeCode) {
      let url = `/regular-reports/deletion-list`;

      if (to_date) url += `?toDate=${to_date}`;
      if (from_date) url += `&fromDate=${from_date}`;
      if (treeTypeCode) url += `&type=${treeTypeCode}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;

      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res.blocks,
            farm_total: res.farm_total
            //title: res.title
          });
        } else {
          message.error("Internal Server Error!");
          this.setState({
            data: null
          });
        }
        this.setState({ loading: false });
      });
    }

    //this.setState({ data: dummyData.blocks });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null
        //areaNoList: null
      },
      () => this.fetchDeletionReport()
    );
  };

  // onAreaChangeHandler = it => {
  //   let { areaNoList } = this.state;
  //   this.setState(
  //     {
  //       areaNumber: it ? it : null,
  //       area_uuid: it
  //         ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
  //         : null
  //     },
  //     () => this.fetchDeletionReport()
  //   );
  // };

  //generateTables = tableData => <MultiTable dataSource={tableData} />;

  // fromDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         from_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchDeletionReport()
  //     );
  //   } else {
  //     this.setState(
  //       {
  //         from_date: null
  //       },
  //       () => this.fetchDeletionReport()
  //     );
  //   }
  // };

  // toDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         to_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchDeletionReport()
  //     );
  //   } else {
  //     this.setState(
  //       {
  //         to_date: null
  //       },
  //       () => this.fetchDeletionReport()
  //     );
  //   }
  // };

  onTreeTypeChangeHandler = it => {
    if (it) {
      if (it == "Dwarf") {
        this.setState({ treeTypeCode: 1, treeType: it }, () =>
          this.fetchDeletionReport()
        );
      } else {
        this.setState({ treeTypeCode: 2, treeType: it }, () =>
          this.fetchDeletionReport()
        );
      }
    } else {
      this.setState({ treeType: null }, () => this.fetchDeletionReport());
    }
  };

  onRangeChangeHandler = date => {
    if (date.length > 0) {
      console.log("Date is", moment(date[0]));
      this.setState(
        {
          from_date: date[0].format("YYYY-MM-DD"),
          to_date: date[1].format("YYYY-MM-DD")
        },
        () => this.fetchDeletionReport()
      );
    } else {
      console.log("Select a date");
      this.setState(
        {
          to_date: null,
          from_date: null
        },
        () => this.fetchDeletionReport()
      );
    }
  };

  generateExcel = () => {
    let { block_uuid, treeTypeCode, to_date, from_date } = this.state;

    if (to_date && from_date && treeTypeCode) {
      let url = `/regular-reports/deletion-list`;

      if (to_date) url += `?toDate=${to_date}`;
      if (from_date) url += `&fromDate=${from_date}`;
      if (treeTypeCode) url += `&type=${treeTypeCode}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      areaNoList,
      areaNumber,
      treeType,
      from_date,
      to_date,
      farm_total
    } = this.state;

    let JSX = <MultiTable dataSource={data} />;

    const buttonList = data
      ? data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null
      : null;

    return (
      <ReportLayout
        title={"Dwarf/Tall Type Deletion Report"}
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          ,
          // {
          //   label: "Area",
          //   listItems: cloneDeep(areaNoList).map(it => it.areaNo),
          //   disabled: blockNumber ? false : true,
          //   value: areaNumber ? areaNumber : undefined,
          //   changeHandler: it => this.onAreaChangeHandler(it)
          // }
          // {
          //   label: "From",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.fromDateHandler
          // },
          // {
          //   label: "To",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.toDateHandler
          // },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            //disabled: blockNumber ? false : true,
            //value: treeType ? treeType : undefined,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Deleted:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) => this.onRangeChangeHandler(date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? (
              data.length > 0 ? (
                <Fragment>
                  <h3>
                    {treeType + " "} Type Deletion Report for the Period of
                    {" " + moment(from_date).format("DD MMM YYYY") + " "}
                    to
                    {" " + moment(to_date).format("DD MMM YYYY")}
                  </h3>

                  <div> {JSX}
                  {
                    farm_total?
                      <Descriptions bordered>
                        <Descriptions.Item label="Farm Total Trees" span={3}>
                          {farm_total}
                        </Descriptions.Item>
                      </Descriptions>
                      :null
                  }
                  </div>
                </Fragment>
              ) : (
                <Empty
                  description={"Select Deleted Date, and Tree Type"}
                ></Empty>
              )
            ) : (
              <Empty></Empty>
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

//export default PerformanceComparisonReport;
export default DwarfTallTypeDeletionReport;
