import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  Icon,
  message,
  notification,
  Popconfirm,
  Tooltip,
  Spin
} from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction, sortFunctionDesc } from "../../../../utility/sorter";
import { cloneDeep, filter } from "lodash";
import { fetchApi } from "../../../../services/api";
import moment from "moment";

class TallCoconut extends Component {
  componentRef = React.createRef();
  state = {
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    data: [],
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    treeNoList: [],
    treeNumber: null,
    tree_uuid: null,
    pagination: true,
    dateOfAnalyses: null,
    addModal: false,
    editData: null,
    btnLoad: false,
    loading: false,
    addPopulateData: null,
    isEdit: false,
    isDelete: false,
    isAdded: false
  };

  componentDidMount() {
    this.tallAnalyses();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Tall Coconut Analysis</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Coconut-Analysis");
  }

  tallAnalyses = (reload = true) => {
    if (reload) {
      this.setState({ loading: true, data: [], pagination: false });
      let {
        block_uuid,
        area_uuid,
        tree_uuid,
        dateOfAnalyses,
        treeNoList
      } = this.state,
        url = "/tall_analyses";
      if (block_uuid) url += `?tree.block.uuid=${block_uuid}`;
      if (area_uuid) url += `&tree.area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;

      if (dateOfAnalyses)
        if (block_uuid) {
          url += `&dtAnalysis%5Bbefore%5D=${moment(dateOfAnalyses).format(
            "YYYY/MM/DD"
          )}`;
          url += `&dtAnalysis%5Bafter%5D=${moment(dateOfAnalyses).format(
            "YYYY/MM/DD"
          )}`;
        } else {
          url += `?dtAnalysis%5Bbefore%5D=${moment(dateOfAnalyses).format(
            "YYYY/MM/DD"
          )}`;
          url += `&dtAnalysis%5Bafter%5D=${moment(dateOfAnalyses).format(
            "YYYY/MM/DD"
          )}`;
        }
      if (block_uuid && treeNoList.length < 1) {
        // console.log("inside the loop");
        this.fetchFormTreeList();
      }

      fetchApi(url, "get")
        .then(data =>
          data
            ? this.setState({
              data: sortFunctionDesc(
                sortFunction(data, "treeNo"),
                "dtAnalysis"
              ),
              pagination: true
              // data: data.map(({ dtAnalysis, ...it }) => {
              //   return {
              //     dtAnalysis: moment(dtAnalysis).format("DD/MM/YYYY"),
              //     date: dtAnalysis,
              //     ...it
              //   };
              // })
            })
            : message.error("Internal Server Error!")
        )
        .then(() => this.setState({ loading: false }));
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchFormTreeList = () => {
    let { blockNoList, blockNumber, block_uuid, area_uuid } = this.state;
    let url = `/talls?stageCode=3&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    fetchApi(url, "get").then(res =>
      this.setState({
        treeNoList: sortFunction(res, "treeNoFull"),
        treeNumber: null,
        tree_uuid: null
      })
    );
  };

  clear = () => {
    this.setState(
      {
        blockNumber: null,
        block_uuid: null,
        data: [],
        blockNumber: null,
        areaNoList: [],
        areaNumber: null,
        area_uuid: null,
        treeNoList: [],
        treeNumber: null,
        tree_uuid: null,
        pagination: true,
        dateOfAnalyses: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        isEdit: false,
        isDelete: false,
        loading: false
      },
      () => this.tallAnalyses()
    );
  };

  addModalToggle = () => {
    message.destroy();
    let {
      dateOfAnalyses,
      treeNumber,
      tree_uuid,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      addModal,
      isEdit,
      isAdded,
      isDelete
    } = this.state;
    if (addModal) {
      this.setState(
        {
          // addPopulateData: isAdded
          //   ? { blockNo: blockNumber, tree: treeNumber }
          //   : null,
          addPopulateData: {
            blockNo: !isEdit || !isAdded || !isDelete ? blockNumber : null
          },
          addModal: false,
          editData: null,
          blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
          block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
          areaNumber: isEdit || isAdded || isDelete ? areaNumber : null,
          area_uuid: isEdit || isAdded || isDelete ? area_uuid : null,
          treeNumber: isEdit || isAdded || isDelete ? treeNumber : null,
          tree_uuid: isEdit || isAdded || isDelete ? tree_uuid : null,
          dateOfAnalyses: isEdit || isDelete ? dateOfAnalyses : null,
          //dateOfAnalyses: null,
          isEdit: false,
          isDelete: false,
          isAdded: false
          //addPopulateData: null,
        },
        () => {
          this.setState({ isAdded: false });
        }
        //() => this.tallAnalyses()
      );
    } else {
      if (!blockNumber) {
        message.info("Select Block No.");
      } else {
        // if (dateOfAnalyses || treeNumber) {
        this.setState(
          {
            editData: Object.assign(
              {},
              dateOfAnalyses ? { dtAnalysis: dateOfAnalyses } : {},
              //treeNumber ? { tree: treeNumber } : {},
              cloneDeep(treeNumber) ? { tree: cloneDeep(treeNumber) } : {},
              cloneDeep(this.state.editData)
            )
          },
          () => this.setState({ addModal: !this.state.addModal })
        );
        // }
      }
    }
  };

  clearFields = () => {
    let { blockNumber } = this.state;
    this.setState({
      areaNumber: null,
      treeNumber: null,
      addPopulateData: {
        //areaNo: null,
        //blockNo: null,
        blockNo: blockNumber ? blockNumber : null,
        tree: null,
        //dtAnalysis: null,
        huskWeight: null,
        shellWeight: null,
        kernelWeight: null,
        kernelThickness: null,
        nutWeight: null,
        oilContent: null
      }
    });
  };

  addFormHandler = ({
    tree,
    huskWeight,
    kernelThickness,
    kernelWeight,
    nutWeight,
    shellWeight,
    oilContent,
    ...values
  }) => {
    notification.destroy();
    let { treeNoList, isDelete, isEdit, editData } = this.state;
    this.setState({ btnLoad: true });
    if (isDelete) {
      fetchApi(`/tall_analyses/${editData.uuid}`, "put", {
        dtDeleted: moment().format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully Deleted Tall Analysis"
          });
          this.tallAnalyses();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Delete Tall Analysis"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else if (isEdit) {
      fetchApi(`/tall_analyses/${editData.uuid}`, "put", {
        huskWeight: parseFloat(huskWeight),
        kernelThickness: parseFloat(kernelThickness),
        kernelWeight: parseFloat(kernelWeight),
        nutWeight: parseFloat(nutWeight),
        shellWeight: parseFloat(shellWeight),
        oilContent: parseFloat(oilContent),
        tree: editData.treeUuid,
        dtAnalysis: moment(values.dtAnalysis).format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully Updated Tall Tree Analysis"
          });
          this.tallAnalyses();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Update Tall Tree Analysis"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      fetchApi("/tall_analyses", "post", {
        tree: "/talls/" + filter(treeNoList, { treeNoFull: tree })[0].uuid,
        huskWeight: parseFloat(huskWeight),
        kernelThickness: parseFloat(kernelThickness),
        kernelWeight: parseFloat(kernelWeight),
        nutWeight: parseFloat(nutWeight),
        shellWeight: parseFloat(shellWeight),
        oilContent: parseFloat(oilContent),
        ...values,
        dtAnalysis: moment(values.dtAnalysis).format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Tall Analysis"
            });
            this.setState({ isAdded: true, dateOfAnalyses: null }, () =>
              this.tallAnalyses()
            );
            this.clearFields();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Tall Analysis"
          });
        }
        this.setState({
          btnLoad: false,
          addPopulateData: null
        });
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNumber: null,
            treeNoList: [],
            addPopulateData: {
              blockNo: it ? it : null
            }
          },
          () => this.tallAnalyses()
        )
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null,
          addPopulateData: { blockNo: it }
        },
        () => this.tallAnalyses()
      );
    }
  };

  onAreaChangeHandler = (it, reload = true) => {
    let { areaNoList, blockNumber, blockNoList, dateOfAnalyses } = this.state;
    if (it) {
      //&properties[]=uuid&properties[]=treeNoFull`
      fetchApi(
        `/talls?stageCode=3&area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null
          },
          () => this.tallAnalyses(reload)
        );
      });
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null
        },
        () => this.tallAnalyses(reload)
      );
    }
  };

  onTreeChangeHandler = (it, reload = true) => {
    let { treeNoList } = this.state;
    if (it) {
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo
          // area_uuid: cloneDeep(treeNoList).filter(
          //   item => item.treeNoFull == it
          // )[0].areaUuid,
        },
        () => this.tallAnalyses(reload)
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
        },
        () => this.tallAnalyses(reload)
      );
    }
  };

  dateOfAnalysesHandler = (date, dateString) => {
    if (date) {
      this.setState({ dateOfAnalyses: date }, () => this.tallAnalyses());
    } else {
      this.setState({ dateOfAnalyses: null }, () => this.tallAnalyses());
    }
  };

  editHandler = ({ tree, dtAnalysis, date, treeNo, ...it }) => {
    this.setState(
      {
        isEdit: true,
        editData: {
          //dtAnalysis: moment(date, "YYYY-MM-DD"),
          dtAnalysis: moment(dtAnalysis, "YYYY-MM-DD"),
          tree: treeNo,
          treeUuid: tree,
          ...it
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );
  };

  deleteHandler = ({ tree, dtAnalysis, date, treeNo, ...it }) => {
    this.setState(
      {
        isDelete: true,
        editData: {
          //dtAnalysis: moment(date, "YYYY-MM-DD"),
          dtAnalysis: moment(dtAnalysis, "YYYY-MM-DD"),
          tree: treeNo,
          treeUuid: tree,
          ...it
        }
      },
      () => this.setState({ addModal: true })
    );
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      areaNumber,
      dateOfAnalyses,
      pagination,
      addModal,
      editData,
      btnLoad,
      isEdit,
      isDelete,
      treeNumber,
      loading,
      data,
      addPopulateData
    } = this.state;

    const columns = [
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Date Of Analysis",
        key: "dtAnalysis",
        dataIndex: "dtAnalysis",
        render: it => (it ? moment(it).format("DD/MM/YYYY") : null)
      },
      {
        title: "Husk Wt. (gms)",
        key: "huskWeight",
        dataIndex: "huskWeight"
      },
      {
        title: "Shell Wt. (gms)",
        key: "shellWeight",
        dataIndex: "shellWeight"
      },
      {
        title: "Kernel Wt. (gms)",
        key: "kernelWeight",
        dataIndex: "kernelWeight"
      },
      {
        title: "Kernal Thickness (mm)",
        key: "kernelThickness",
        dataIndex: "kernelThickness"
      },
      {
        title: "Seed Nut Wt. (gms)",
        key: "nutWeight",
        dataIndex: "nutWeight"
      },
      {
        title: "Oil Content (%age)",
        key: "oilContent",
        dataIndex: "oilContent"
      },
      {
        title: "Actions",
        key: "actions",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <Icon
                className="table-action-btn"
                onClick={() => this.editHandler(it)}
                type="edit"
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.deleteHandler(it)}
                type="delete"
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];

    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "blockNo",
            placeholder: "Block",
            listData: cloneDeep(blockNoList).map(it => it.blockNo),
            disabled: true
            //value: areaNumber ? areaNumber : undefined,
            //changeHandler: it => this.onAreaChangeHandler(it, false)
          },
          /*{
            name: "areaNo",
            placeholder: "Area",
            listData: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            //value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it, false)
          },*/
          {
            name: "tree",
            placeholder: "Tree No.",
            listData: cloneDeep(treeNoList).map(it => it.treeNoFull),
            //disabled: areaNumber ? false : true,
            //disabled: treeNoList.length > 0 ? false : true,
            readonly: treeNoList.length > 0 ? false : true,
            //value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it, false)
          },
          {
            name: "dtAnalysis",
            placeholder: "Date Of Analysis",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of Analysis!"
          },
          {
            name: "huskWeight",
            placeholder: "Husk Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Husk Weight!"
          },
          {
            name: "shellWeight",
            placeholder: "Shell Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Shell Weight!"
          },
          {
            name: "kernelWeight",
            placeholder: "Kernel Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Kernel Weight!"
          },
          {
            name: "kernelThickness",
            placeholder: "Kernel Thickness (mm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Kernel Thickness!"
          },
          {
            name: "nutWeight",
            placeholder: "Seed Nut Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Seed Nut Weight!"
          },
          {
            name: "oilContent",
            placeholder: "Oil Content (%age)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Oil Content!"
          }
        ]
        : [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true
          },
          {
            name: "tree",
            placeholder: "Tree No.",
            disabled: true,
            message: "Input the tree no.!"
          },
          {
            name: "dtAnalysis",
            placeholder: "Date Of Analysis",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of Analysis!"
          },
          {
            name: "huskWeight",
            placeholder: "Husk Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Husk Weight!"
          },
          {
            name: "shellWeight",
            placeholder: "Shell Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Shell Weight!"
          },
          {
            name: "kernelWeight",
            placeholder: "Kernel Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Kernel Weight!"
          },
          {
            name: "kernelThickness",
            placeholder: "Kernel Thickness (mm)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Kernel Thickness!"
          },
          {
            name: "nutWeight",
            placeholder: "Seed Nut Weight (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Seed Nut Weight!"
          },
          {
            name: "oilContent",
            placeholder: "Oil Content (%age)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Oil Content!"
          }
        ];

    const buttonList =
      data.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfAnalyses
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfAnalyses
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Tall Coconut Analysis"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: blockNoList.map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Tree No.",
            listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
            disabled: areaNumber ? false : true,
            //disabled: blockNumber ? false : true,
            value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it)
          },
          {
            label: "Analysis Date",
            type: "date",
            value: dateOfAnalyses ? dateOfAnalyses : undefined,
            changeHandler: this.dateOfAnalysesHandler
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        addPopulateData={addPopulateData}
        editData={editData}
        btnLoad={btnLoad}
        isEdit={isEdit}
        isDelete={isDelete}
        formItems={formItems}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            <Table
              key="tallcoconut"
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={loading}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default TallCoconut;
