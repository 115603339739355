import React, { Component, Fragment } from "react";
import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import ScollBarView from "react-double-scrollbar";
import MultiTable from "./MultiTableTreewisePerformance";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
import {
  parserFunctionDwarf,
  dateParser,
  parserFunctionTall
} from "./parserFunctions";
import moment from "moment";
import { dwartfColoums, tallColoums } from "./columnsChecker";

class TreeWisePerformanceComparison3yearsPeriod extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    type: null,
    typeList: [],
    type_uuid: null,
    isopen: false,
    treeType: 1,
    period: [],
    years: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchType();
  }

  fetchFarmPerformanceReportPeriodGroup = () => {
    let { block_uuid, area_uuid, type_uuid, treeType, period } = this.state;
    if (period.length > 0 && block_uuid) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/tree-wise-performance-comparison?fromYear=${period[0]}&toYear=${period[1]}&block.uuid=${block_uuid}&type=${treeType}&area.uuid=${area_uuid}&tree_type.uuid=${type_uuid}`,
        "get"
      ).then(res => {
        if (res) {
          let tmp = res.blocks;
          res.blocks =
            tmp &&
            tmp.map(({ areas, ...rest }) => ({
              areas: areas.map(({ trees, ...rest }) => ({
                trees: trees.map(({ dt_planted, ...rest }) => ({
                  dt_planted: moment(dt_planted).format("DD/MM/YYYY"),
                  ...rest
                })),
                ...rest
              })),
              ...rest
            }));
          this.setState({
            data:
              treeType == 1
                ? parserFunctionDwarf(res.blocks)
                : parserFunctionTall(res.blocks),
            years: res.years
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  fetchType = () => {
    let { treeType } = this.state;
    fetchApi(`/tree_types?type=${treeType}`, "get").then(data =>
      this.setState({ typeList: sortFunction(data, "code") })
    );
  };

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  onDropDownChangeHandler = (type, item) => {
    let { blockNoList, areaNoList, typeList } = this.state;
    switch (type) {
      case "block":
        if (item) {
          fetchApi(
            `/areasuuid?block.uuid=${
            cloneDeep(blockNoList).filter(it => it.blockNoFull == item)[0]
              .uuid
            }`,
            "get"
          ).then(res => this.setState({ areaNoList: res }));
          this.setState(
            {
              blockNumber: item ? item : null,
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                  each => each.blockNoFull === item
                )[0].uuid
                : null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null,
              areaNoList: [],
              areaNumber: null,
              area_uuid: null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
        break;

      case "treeType":
        console.log("treeType case", item)
        if (item) {
          if (item == "Dwarf") {
            this.setState(
              {
                treeType: 1,
                typeList: [],
                type: null,
                type_uuid: null
              },
              () => {
                this.fetchFarmPerformanceReportPeriodGroup();
                this.fetchType();
              }
            );
          } else if (item == "Tall") {
            this.setState(
              {
                treeType: 2,
                typeList: [],
                type: null,
                type_uuid: null
              },
              () => {
                this.fetchFarmPerformanceReportPeriodGroup();
                this.fetchType();
              }
            );
          }
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null,
              areaNoList: [],
              areaNumber: null,
              area_uuid: null
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
        break;

      case "area":
        this.setState(
          {
            areaNumber: item ? item : null,
            area_uuid: item
              ? cloneDeep(areaNoList).filter(it => it.areaNo == item)[0].uuid
              : null
          },
          () => this.fetchFarmPerformanceReportPeriodGroup()
        );
        break;

      case "type":
        this.setState(
          {
            type: item ? item : null,
            type_uuid: item
              ? cloneDeep(typeList).filter(it => it.code == item)[0].uuid
              : null
          },
          () => this.fetchFarmPerformanceReportPeriodGroup()
        );
        break;

      default:
        if (item.length > 0) {
          let diff =
            moment(item[1]).format("YYYY") - moment(item[0]).format("YYYY");
          if (diff >= 2) {
            this.setState(
              {
                value: item,
                period: [
                  moment(item[0]).format("YYYY"),
                  moment(item[1]).format("YYYY")
                ],
                isopen: false
              },
              () => this.fetchFarmPerformanceReportPeriodGroup()
            );
          } else {
            message.info("Enter a minimum year difference of 3 years");
            this.setState(
              {
                value: null,
                period: [],
                isopen: false,
                data: []
              },
              () => this.fetchFarmPerformanceReportPeriodGroup()
            );
          }
        } else {
          this.setState(
            {
              value: null,
              period: [],
              isopen: false,
              data: []
            },
            () => this.fetchFarmPerformanceReportPeriodGroup()
          );
        }
    }
  };

  generateExcel = () => {
    let { block_uuid, area_uuid, type_uuid, treeType, period } = this.state;
    if (period.length > 0 && block_uuid) {
      fetchExcelApi(
        `/regular-reports/tree-wise-performance-comparison?fromYear=${period[0]}&toYear=${period[1]}&block.uuid=${block_uuid}&type=${treeType}&area.uuid=${area_uuid}&tree_type.uuid=${type_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      loading,
      value,
      isopen,
      blockNoList,
      areaNoList,
      typeList,
      blockNumber,
      areaNumber,
      type,
      years,
      treeType,
      block_uuid,
      period
    } = this.state;
    let JSX =
      data && data.length > 0 ? (
        <Fragment>
          <h3>
            {
              //TREEWISE FARM PERFORMANCE ACTIVITIES REPORT FOR THE PERIOD{" "}
            }
            Tree Wise Performance Comparison for the Period{" "}
            {years.split("-")[1]} to {years.split("-")[0]}
          </h3>
          <MultiTable
            columns={
              treeType == 1
                ? dwartfColoums(dateParser(years))
                : tallColoums(dateParser(years))
            }
            dataSource={data}
          />
        </Fragment>
      ) : period.length > 0 && block_uuid ? (
        <Empty description="No Data Found" />
      ) : (
            <Empty description={"Select Block No. and Period"} />
          );
    const buttonList =
      data && data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            toolTip: "Suggestion: Print in Landscape",
            reference: this.componentRef
          }
        ]
        : null;
    return (
      <ReportLayout
        //title="Monthly Farm Production Activities Report - All Blocks"
        title="Tree Wise Performance Comparison Between any 3 Years & for a Selected Period"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("block", it),
            className: "width-bock-no-DTTWMMHN"
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("area", it),
            className: "width-area-no-DTTWMMHN"
          },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            changeHandler: it => this.onDropDownChangeHandler("treeType", it),
            className: "width-tree-type-DTTWMMHN"
          },

          {
            label: "Type",
            listItems: cloneDeep(typeList).map(it => it.code),
            value: type ? type : undefined,
            changeHandler: it => this.onDropDownChangeHandler("type", it),
            className: "width-type-DTTWMMHN"
          },
          {
            label: "Period",
            mode: ["year", "year"],
            value: value,
            type: "range",
            format: "YYYY",
            open: isopen,
            showTime: true,
            onOk: date => this.onDropDownChangeHandler("period", date),
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.setState({ value: val }),
            changeHandler: () => this.onDropDownChangeHandler("period", [])
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TreeWisePerformanceComparison3yearsPeriod;
