import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  Icon,
  message,
  notification,
  Popconfirm,
  Tooltip,
  Spin,
  Modal,
  Row, Col, Button
} from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter, values } from "lodash";
import { fetchApi } from "../../../../services/api";
import { formatDate } from "../../../../utility/date";
import moment from "moment";

class DwarfTree extends Component {
  componentRef = React.createRef();
  constructor(props) {
    super(props);
  this.state = {
    treeTypeData:[],
    blockNoList: [],
    block_uuid: null,
    data: [],
    dataWODate: [],
    dynamicTableData: [],
    blockNumber: null,
    area_uuid: null,
    areaNoList: [],
    areaNumber: null,
    tree_uuid: null,
    treeNoList: [],
    treeNumber: null,
    formTreeNoList: [],
    pagination: true,
    dateOfHarvest: null,
    addModal: false,
    isConfirm:false,
    editData: null,
    confirmedId:null,
    btnLoad: false,
    isEdit: false,
    isDelete: false,
    loading: false,
    addPopulateData: null,
    visibleConfirm: false,
    TreeTypeCode:null,
    showModal:false,
    storageId:null,
    storageCode:null,
    recordId:null,
    recordCode:null,
    TreeTypeId:null,
    TreeTypeUuid:null,
    rejectedNuts:null,
    goodNuts:null,
    blockNo:null,
    treeNo:null,
    uuid:null,
    harvestDt:null,
    showModalAdd:false,
    harvestDt:null,
  }
  }
  componentDidMount() {
    this.dwarfHarvests();
    this.dwarfHarvestswithoutDate();
    this.fetchBlockNo();
    this.fetchTreeType();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Dwarf Tree Harvest</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Dwarf-Tree-Harvest");
  }

  fetchTreeType = ()=>{
    let {treeTypeData} = this.state;
    fetchApi(
      "/tree_types?type=1","get").then((response) =>{
        this.setState({treeTypeData:response});
        console.log(this.state.treeTypeData);
        return treeTypeData;
       });
  }

  dwarfHarvests = () => {
    this.setState({ loading: true, dynamicTableData: [], pagination: false });
    let {
      block_uuid,
      area_uuid,
      tree_uuid,
      formTreeNoList,
      dateOfHarvest
    } = this.state,
      url = "/dwarf_harvests";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
    if (dateOfHarvest) {
      if (block_uuid) {
        url += `&harvestDt%5Bbefore%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
        url += `&harvestDt%5Bafter%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
      } else {
        url += `?harvestDt%5Bbefore%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
        url += `&harvestDt%5Bafter%5D=${moment(dateOfHarvest).format(
          "YYYY/MM/DD"
        )}`;
      }
    }
    if (block_uuid && formTreeNoList.length < 1) {
      // console.log("inside the loop");
      this.fetchFormTreeList();
    }
    fetchApi(url, "get")
      .then(data =>
            data
              ? this.setState(
                {
                  data: data.map(({ harvestDt, ...it }) => {
                  return {
                  harvestDt: moment(harvestDt).format("DD/MM/YYYY"),
                  date: harvestDt,
                  ...it
                 };
              }),
                pagination: true
               },
            () => this.tableFilterHandler()
             )
             : message.error("Internal Server Error!")
       // console.log(data)
      )
      .then(() => this.setState({ loading: false }));
  };

  dwarfHarvestswithoutDate = () => {
    this.setState({ loading: true });
    let {
      treeNumber,
      block_uuid,
      area_uuid,
      tree_uuid,
      formTreeNoList
    } = this.state,
      url = "/dwarf_harvests";
    if (block_uuid && area_uuid && tree_uuid) {
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
      fetchApi(url, "get")
        .then(data =>
          data
            ? this.setState(
              {
                dataWODate: data,
              }, () => {
                if (this.state.dataWODate.length == 0 && this.state.addModal == true && cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2)
                  this.setState({
                    visibleConfirm: true,
                    addModal: false,
                    btnLoad: false
                  })
                else {
                  this.setState({ btnLoad: false })
                }
              }
            )
            : message.error("Internal Server Error!")
        )
        .then(() => this.setState({ loading: false }));
    } else {
      this.setState(
        {
          btnLoad: false,
          dataWODate: [],
        }
      )
    }
  }

  fetchBlockNo = () => {
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchAreaRowUuid = () => {
    var { block_uuid, treeNumber } = this.state;
    fetchApi(
      `/dwarves?block.uuid=${block_uuid}&treeNo=${treeNumber}`,
      "get"
    ).then(res =>
      this.setState(
        {
          area_uuid: res[0].areaUuid,
          // row_uuid: res[0].rowUuid,
          tree_uuid: res[0].uuid,
          loading: false
        }
        //,
        //() => console.log("Dwarf Data", res)
      )
    );
  };

  fetchFormTreeList = () => {
    let { blockNoList, blockNumber, block_uuid } = this.state;
    //`/dwarves?block.uuid=${block_uuid}&properties[]=uuid&properties[]=treeNoFull&stageCode[]=2&stageCode[]=3`,
    fetchApi(
      // `/dwarves?block.uuid=${block_uuid}&stageCode[]=2&stageCode[]=3`,
      `/dwarves?block.uuid=${block_uuid}`,
      "get"
    ).then(res =>
      this.setState(
        {
          formTreeNoList: sortFunction(res, "treeNoFull"),
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        }
        // () => this.dwarfTreeEmasculation()
      )
    );
  };

  clear = () => {
    this.setState(
      {
        //block_uuid: null,
        data: [],
        dynamicTableData: [],
        //blockNumber: null,
        area_uuid: null,
        areaNoList: [],
        areaNumber: null,
        tree_uuid: null,
        treeNoList: [],
        treeNumber: null,
        formTreeNoList: [],
        pagination: true,
        dateOfHarvest: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        isEdit: false,
        isDelete: false,
        loading: false
      },
      () => {
        this.dwarfHarvests()
        this.dwarfHarvestswithoutDate()
      }
    );
  };

  addModalToggle = () => {
    message.destroy();
    let {
      loading,
      isAdded,
      dateOfHarvest,
      treeNumber,
      tree_uuid,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      addModal,
      isEdit,
      isDelete,
      dataWODate,
      TreeTypeCode,
      TreeTypeUuid,
      TreeTypeId,
      formTreeNoList
    } = this.state;
    if (!isEdit && !isAdded && !isDelete && loading) {
      message.info("Loading Data Please wait");
    } else {
      if (addModal) {
        this.setState(
          {
            addModal: false,
            editData: null,
            blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
            block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
            areaNumber: isEdit || isAdded || isDelete ? areaNumber : null,
            area_uuid: isEdit || isAdded || isDelete ? area_uuid : null,
            treeNumber: isEdit || isAdded || isDelete ? treeNumber : null,
            tree_uuid: isEdit || isAdded || isDelete ? tree_uuid : null,
            dateOfHarvest: isEdit || isDelete ? dateOfHarvest : null,
            TreeTypeCode:isEdit || isDelete ? TreeTypeCode: null,
            isEdit: false,
            isDelete: false,
            isAdded: false,
          },
          () => {
            this.setState({ isAdded: false });
          }
          //() => this.dwarfHarvests()
        );
      } else {
        if (!blockNumber || !dateOfHarvest) {
          message.info("Select Block and Harvest Date");
        } else {
          if (dateOfHarvest || treeNumber) {
            this.setState(
              {
                editData: Object.assign(
                  {},
                  dateOfHarvest ? { harvestDt: dateOfHarvest } : {},
                  //treeNumber ? { tree: treeNumber } : { tree: null },
                  cloneDeep(treeNumber) ? { tree: cloneDeep(treeNumber) } : {},
                  cloneDeep(this.state.editData)
                )
              },
              //() => this.setState({ addModal: !this.state.addModal })
              () => {
                this.state.addModal == true ?
                  this.setState({ addModal: false })
                  : dataWODate.length == 0 && cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2 ?
                    this.setState({ visibleConfirm: true })
                    : this.setState({ addModal: true })
              }
            );
          }
        }
      }
    }
  };

  yesConfirm = () => {
    if (this.state.addModal == false)
      this.setState({
        addModal: true,
        visibleConfirm: false,
        btnLoad: false
      })
    else
      this.setState({
        visibleConfirm: false,
        btnLoad: false
      })
  }
  closeConfirm = () => {
    let {
      isEdit,
      isAdded,
      isDelete,
      block_uuid,
      areaNumber,
      area_uuid,
      treeNumber,
      tree_uuid,
      dateOfHarvest,
      TreeTypeCode
    } = this.state;
    this.setState({
      visibleConfirm: false,
      btnLoad: false,
      addModal: false,
      editData: null,
      blockNumber: isEdit || isAdded || isDelete ? isDelete : null,
      block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
      areaNumber: isEdit || isAdded || isDelete ? areaNumber : null,
      area_uuid: isEdit || isAdded || isDelete ? area_uuid : null,
      treeNumber: isEdit || isAdded || isDelete ? treeNumber : null,
      tree_uuid: isEdit || isAdded || isDelete ? tree_uuid : null,
      dateOfHarvest: isEdit || isDelete ? dateOfHarvest : null,
      TreeTypeCode:isEdit || isDelete ? TreeTypeCode :null,
      isEdit: false,
      isDelete: false,
      isAdded: false
    })
  }

  clearFields = () => {
    let { blockNumber,TreeTypeCode } = this.state;
    this.setState({
      areaNumber: null,
      treeNumber: null,
      dateOfHarvest: null,
      addPopulateData: {
        blockNo: blockNumber ? blockNumber : null,
        //harvestDt: null,
        tree: null,
        goodNuts: null,
        rejectedNuts: null,
        TreeTypeCode:null
      }
    });
  };

  addFormHandler = ({harvestQty,treeNo,harvestDt,rejectedNuts,goodNuts,block,area,tree,treeUuid,blockUuid,dtPlanted,TreeTypeUuid,areaNo,areaUuid,stageCode,
    blockNo,...values }) =>{
    notification.destroy();
    message.destroy();
    let {
      loading,
      blockNoList,
      areaNoList,
      treeNoList,
      blockNumber,
      areaNumber,
      area_uuid,
      tree_uuid,
      isEdit,
      isDelete,
      recordCode,
      TreeTypeCode,
      addModal,
      TreeTypeId,
      confirmedId,
      recordId,
      storageId,
      storageCode,
      editData,
    } = this.state;
    if (loading) {
      message.info("Loading Data Please wait");
    } else {
      this.setState({ btnLoad: true });
      if (isDelete) {
        fetchApi(`/dwarf_harvests/${editData.uuid}`, "put", {
          dtDeleted: moment().format("YYYY-MM-DD")
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully Deleted Dwarf Harvest"
              });
              this.dwarfHarvests();
              this.dwarfHarvestswithoutDate();
              this.addModalToggle()
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Delete Dwarf Dwarf Harvest"
            });
          }
          this.setState({ btnLoad: false }
            //, () => this.addModalToggle()
          );
        });
      } else if (isEdit) {
        fetchApi(`/dwarf_harvests/${editData.uuid}`, "put", {
          harvestQty:(rejectedNuts ? parseInt(rejectedNuts) : 0) + parseInt(goodNuts),
          goodNuts: parseInt(goodNuts),
          rejectedNuts: rejectedNuts ? parseInt(rejectedNuts) : 0,
           block: "/blocks/" + editData.blockUuid,
           area: "/areas/" + editData.areaUuid,
           tree: "/dwarves/" + editData.treeUuid,
          harvestDt: moment(harvestDt).format("YYYY-MM-DD"),
          TreeTypeCode:editData.TreeTypeCode,
          areaNo,
          areaUuid,
          blockNo,
          blockUuid,
          dtPlanted,
          stageCode,
          treeNo,
          treeUuid,
          ...values
        }).then(res => {
          console.log(tree,area,block);
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              notification.success({
                message: "Success",
                description: "Successfully Updated Dwarf Tree Harvest"
              });
              this.dwarfHarvests();
              this.dwarfHarvestswithoutDate();
              this.addModalToggle()
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Update Dwarf Tree Harvest"
            });
          }
          this.setState({ btnLoad: false }
            //, () => this.addModalToggle()
          );
        });
      } else {
  
        fetchApi("/dwarf_harvests", "post", {
          harvestQty:
            (rejectedNuts ? parseInt(rejectedNuts) : 0) + parseInt(goodNuts),
          goodNuts: parseInt(goodNuts),
          rejectedNuts: rejectedNuts ? parseInt(rejectedNuts) : 0,
          block:
            "/blocks/" +
            filter(blockNoList, { blockNoFull: blockNumber })[0].uuid,
          area: "/areas/" + area_uuid,
          tree: "/dwarves/" + tree_uuid,
          TreeTypeCode:TreeTypeCode,
          treeTypeId: confirmedId && confirmedId == true ? Number(recordId):confirmedId == false ?Number(storageId):TreeTypeId,
          ...values,
          harvestDt: moment(harvestDt).format("YYYY-MM-DD")
        }).then(res => {
          if (res) {
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
              //this.setState({addModal: false});
            } else {
              notification.success({
                message: "Success",
                description: "Successfully Added a Dwarf Tree Harvest"
              });
              //this.setState({addModal: false});
              
              this.setState({ isAdded: true, dateOfHarvest: null }, () => {
                this.dwarfHarvests();
                this.dwarfHarvestswithoutDate();
              }
              );
              this.clearFields();
          
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Add Dwarf Tree Harvest"
            });
          }
          this.setState({
            btnLoad: false,
            addPopulateData: null,
            addPopulateData: {
              blockNo: blockNumber ? blockNumber : null,
            }
          },
          () =>  this.dwarfHarvests()
          );
        });
      }
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNumber: null,
            treeNoList: [],
            formTreeNoList: [],
            addPopulateData: {
              blockNo: it ? it : null
            }
          },
          () => {
            this.dwarfHarvests()
            this.dwarfHarvestswithoutDate()
          }
        )
      );
    } else {
      this.setState(
        {
          block_uuid: null,
          blockNumber: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          treeNumber: null,
          treeNoList: [],
          formTreeNoList: [],
          tree_uuid: null,
          addPopulateData: {
            blockNo: null
          }
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate()
        }
      );
    }
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNumber, blockNoList } = this.state;
    //&properties[]=uuid&properties[]=treeNoFull
    if (it) {
      fetchApi(
        // `/dwarves?stageCode[]=2&stageCode[]=3&area.uuid=${
        `/dwarves?area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        } `,
        "get"
      ).then(res => {
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null
          },
          () => {
            this.dwarfHarvests()
            this.dwarfHarvestswithoutDate()
          }
        );
      });
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate()
        }
      );
    }
  };

  onTreeChangeHandler = it => {
    let { treeNoList } = this.state;
    if (it) {
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate()
        }
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate()
        }
      );
    }
  };

  dwarfAddTreeTypeHandler = (it)=>{
    let {treeTypeData,changedtreeUid,isEdit,uuid,TreeTypeId,editData,TreeTypeCode,data,treeTypeUuid,
      isConfirm,
      storageId,
      storageCode,
      recordId,
      recordCode,
      showModalAdd,...values} = this.state;

      let previousCode = localStorage.getItem('oldValueCode');
      let previousId = localStorage.getItem('oldValueId');
      console.log(previousCode,previousId);

       this.setState({storageId:previousId,storageCode:previousCode},function(){console.log(this.state.storageId,this.state.storageCode)});
     
         if(it){
           console.log(it);
          //  this.setState({
          //   addPopulateData: null
          // })
          const filtered = treeTypeData.find(el => {return (el.code === it)});
          let treeId = filtered.id;
          let treeCode = filtered.code;
          console.log(treeCode);
          this.setState({recordId:treeId},function(){console.log(this.state.recordId)});
          this.setState({recordCode:treeCode},function(){console.log(this.state.recordCode)});
          if(treeCode === previousCode){
            this.setState({showModalAdd:false});
          }else if(treeCode !== previousCode){
            this.setState({showModalAdd:true,addPopulateData:{
              TreeTypeCode:it
            }});
          }
    }

  }

  handleOk = ()=>{
    this.setState({showModalAdd:false});
  }

  handleCancelAdd = ()=>{
    let {TreeTypeId,storageId,storageCode,recordId,confirmedId,recordCode,isConfirm,editData,uuid,tree_uuid,area_uuid,row_uuid, blockNoList,
    blockNumber,prematureSpathe,showModalAdd,...values}= this.state;
    this.setState({confirmedId:true});
    this.setState({showModalAdd:false,isConfirm:true});
    this.setState({TreeTypeId:recordId});
  }

  handleCancelCloseAdd = ()=>{
    let {TreeTypeId,storageId,storageCode,showModalAdd,blockNoList,confirmedId, blockNumber,recordId,recordCode,tree_uuid,area_uuid, row_uuid, dtEmasculation,isConfirm,uuid,editData,TreeTypeCode,treeTypeData}= this.state;
    this.setState({confirmedId:false});
    this.setState({showModalAdd:false,isConfirm:false});
    this.setState({TreeTypeId:storageId,TreeTypeCode:storageCode});
    
    console.log(this.state.storageCode);
        this.setState({addPopulateData: {
          TreeTypeCode: storageCode
        },
       })
  }

  dwarfTreeTypeHandler = (it)=>{
  let {treeTypeData,isEdit,uuid,editData,data,treeTypeUuid,storageId,storageCode,recordId,showModal,
      recordCode,TreeTypeId,blockNo,harvestDt,treeNo,TreeTypeCode,isConfirm}= this.state;
      let treetypeIdentifier = localStorage.getItem('treeID');
      let treetypeCodeIdentifier = localStorage.getItem('treeCo');
        this.setState({storageId:treetypeIdentifier},function(){console.log(this.state.storageId)});
        this.setState({storageCode:treetypeCodeIdentifier},function(){console.log(this.state.storageCode)});
        console.log(treetypeIdentifier,treetypeCodeIdentifier);
         if(it){
           console.log("change",it);
           
         this.setState({
          addPopulateData: null
        })
          const filtered = treeTypeData.find(el => {return (el.code === it)});
          let treeId = filtered.id;
          let treeCode =filtered.code;
          //console.log(treeId);
          console.log("treecode",treeCode);
          this.setState({recordId:treeId},function(){console.log(this.state.recordId)});
          this.setState({recordCode:treeCode},function(){console.log(this.state.recordCode)});
         
          if(treeCode === treetypeCodeIdentifier){
            this.setState({showModal:false});
          }else if(treeCode !== treetypeCodeIdentifier){
            this.setState({showModal:true});
          }
    }

   // this.setState({addPopulateData:{TreeTypeCode: TreeTypeCode === recordCode ? recordCode: recordCode}})
  }

  handleOk = ()=>{
    this.setState({showModal:false});
  }

  handleCancel = ()=>{
    let {storageId,storageCode,recordId,recordCode,isConfirm,visible,editData,TreeTypeId,TreeTypeCode,addPopulateData}= this.state;
    this.setState({showModal:false,isConfirm:true},function(){console.log(this.state.isConfirm)});
    this.setState({TreeTypeId:recordId,TreeTypeCode:recordCode},function(){console.log(this.state.TreeTypeId,this.state.recordCode)});
    fetchApi(`/dwarf_harvests/${editData.uuid}`,"put",{
      treeTypeId:Number(recordId),
         })
  }

  handleCancelClose = ()=>{
    let {storageId,storageCode,recordId,recordCode,isConfirm,visible,editData,TreeTypeCode,TreeTypeId,isEdit,btnLoad}= this.state;
    this.setState({showModal:false,isConfirm:false},()=>{console.log(this.state.isConfirm)});
      this.setState({TreeTypeId:storageId,TreeTypeCode:storageCode},function(){console.log(this.state.TreeTypeId,this.state.TreeTypeCode)});
      fetchApi(`/dwarf_harvests/${editData.uuid}`,"put",{
        treeTypeId:Number(storageId),
      })
              this.setState({
              addPopulateData: {
                 TreeTypeCode: storageCode,
               }
            })
    // this.setState({TreeTypeCode:storageCode,addPopulateData:{TreeTypeCode:storageCode}});
  }

  onFormTreeChangeHandler = it => {
    let { formTreeNoList,TreeTypeCode,TreeTypeId } = this.state;
    console.log("onFormTreeChangeHandler", it);
    console.log(this.state.formTreeNoList);

    if (it) {
      let selectedRecord = formTreeNoList.find(el => {return (el.treeNoFull === it)});
      console.log(selectedRecord);
      let selectedTreeType = selectedRecord.treeType;
      let selectedTreeId = selectedRecord.treeTypeId;
      console.log(selectedTreeType);
      console.log(selectedTreeId);


      this.setState(
        {
          btnLoad: true,
          treeNumber: it,
          TreeTypeCode:selectedTreeType,
          TreeTypeId:selectedTreeId,
          tree_uuid: cloneDeep(formTreeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0].areaNo,
          area_uuid: cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0].areaUuid,
          addPopulateData: {
            tree: it ? it : null,
            TreeTypeCode:selectedTreeType,
          }
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate();
          localStorage.setItem('oldValueCode',this.state.TreeTypeCode);
          let test1 = localStorage.getItem('oldValueCode');
          localStorage.setItem('oldValueId',this.state.TreeTypeId);
          let test2 = localStorage.getItem('oldValueId');
          console.log(test1,test2);
        }
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null,
          addPopulateData: {
            tree: it ? it : null,
          }
        },
        () => {
          this.dwarfHarvests()
          this.dwarfHarvestswithoutDate()
        }
      );
    }
    // let { formTreeNoList } = this.state;
    // if (it) {
    //   this.setState({ loading: true });
    //   //this.setState({ treeNumber: it }, () => this.dwarfTreeEmasculation());
    //   fetchApi(
    //     `/dwarves/${
    //     cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0]
    //       .uuid
    //     }?groups[]=getNextSpatheNo`,
    //     "get"
    //   ).then(res =>
    //     this.setState(
    //       {
    //         treeNumber: it,
    //         tree_uuid: cloneDeep(formTreeNoList).filter(
    //           item => item.treeNoFull == it
    //         )[0].uuid,
    //         spatheNo: res.nextSpatheNo,
    //         areaNumber: cloneDeep(formTreeNoList).filter(
    //           item => item.treeNoFull == it
    //         )[0].areaNo
    //         //loading: false
    //       },
    //       () => {
    //         this.fetchAreaRowUuid()
    //         this.dwarfHarvestswithoutDate()
    //       }

    //       //,
    //       // () => {
    //       //   this.setState(
    //       //     {
    //       //       // addPopulateData: {
    //       //       //   spatheNo: cloneDeep(this.state.spatheNo)
    //       //       // }
    //       //     },
    //       //     () => this.fetchAreaRowUuid()
    //       //   );
    //       // }
    //     )
    //   );
    // } else {
    //   this.setState(
    //     {
    //       treeNumber: null,
    //       tree_uuid: null,
    //       spatheNo: null,
    //       area_uuid: null,
    //       row_uuid: null
    //     }
    //     // () => {
    //     //   this.setState({
    //     //     // addPopulateData: {
    //     //     //   spatheNo: null
    //     //     // }
    //     //   });
    //     // }
    //   );
    // }
  };

  dateOfHarvestHandler = (date, dateString) => {
    let { blockNumber, treeNumber } = this.state;
    if (date) {
      this.setState(
        { dateOfHarvest: date },
        () => this.dwarfHarvests()
        //() => this.tableFilterHandler()
      );
    } else {
      this.setState(
        { dateOfHarvest: null },
        () => this.dwarfHarvests()
        //() => this.tableFilterHandler()
      );
    }
  };

  tableFilterHandler = () => {
    let {
      //dateOfHarvest,
      data
      // blockNumber,
      // areaNumber,
      // treeNumber
    } = this.state,
      filterJson = {};
    //if (dateOfHarvest)
    //  filterJson.harvestDt = dateOfHarvest.format("DD/MM/YYYY");
    // if (blockNumber) filterJson.blockNo = blockNumber;
    // if (areaNumber) filterJson.areaNo = areaNumber;
    // if (treeNumber) filterJson.treeNo = parseInt(treeNumber);
    //console.log(dateOfHarvest, filterJson);
    this.setState({
      //dynamicTableData: filter(data, filterJson)
      dynamicTableData: data
    });
  };

  editHandler = ({TreeTypeCode,TreeTypeId,date,tree,treeNo,areaNo,blockUuid,block,area,treeUuid,areaUuid,rejectedNuts,uuid,TreeTypeUuid,goodNuts,harvestDt,blockNo}) => {
    this.setState(
      {
        addPopulateData:null,
        isEdit: true,
        editData: {
         harvestDt:moment(date, "YYYY-MM-DD"),
          TreeTypeCode,
          TreeTypeId,
          TreeTypeUuid,
          uuid,
          rejectedNuts,
          goodNuts,
          blockNo,
          treeUuid,
          areaNo,
          areaUuid,
          blockUuid,
          tree:treeNo,
        }
      },

    () => {this.setState({ addModal: true})});

    //this.setState({TreeTypeCode:TreeTypeCode},()=>{console.log(this.state.TreeTypeCode)});
   // this.setState({TreeTypeId:TreeTypeId},()=>{console.log(this.state.TreeTypeId)})

    localStorage.setItem('treeID',TreeTypeId);
    localStorage.setItem('treeCo',TreeTypeCode);
  };

  deleteHandler = ({ harvestDt, date,tree, treeNo, ...it }) => {
   
    this.setState(
      {
        isDelete: true,
        editData: {
          harvestDt: moment(date, "YYYY-MM-DD"),
          tree:treeNo,
          ...it
        }
      },
      () =>{this.setState({ addModal: true })
      console.log(tree);
    
    }
    );
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      formTreeNoList,
      areaNumber,
      dateOfHarvest,
      dynamicTableData,
      pagination,
      addModal,
      editData,
      btnLoad,
      isEdit,
      isDelete,
      treeNumber,
      loading,
      data,
      dataWODate,
      addPopulateData,
      treeTypeData,
      TreeTypeCode,
    } = this.state;
    const columns = [
      {
        title: "Harvest Date",
        key: "harvestDt",
        dataIndex: "harvestDt"
      },
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Tree Types",
        key: "TreeTypeCode",
        dataIndex: "TreeTypeCode",
      },
      {
        title: "Harvest Nuts",
        key: "harvestQty",
        dataIndex: "harvestQty"
      },
      {
        title: "Actions",
        className: "action",
        key: "actions",
        render: (it) => (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <Icon
                className="table-action-btn"
                onClick={() =>{ this.editHandler(it);
                             console.log(it);
        }
                }
                type="edit"
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.deleteHandler(it)}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];
    const buttonList =
      dynamicTableData.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfHarvest
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfHarvest
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true
          },
          {
            name: "harvestDt",
            placeholder: "Harvest Date",
            type: "date",
            message: "Input the date of harvest!"
          },
          {
            name: "tree",
            placeholder: "Tree No.",
            type: "alphanumeric",
            listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
            readonly: treeNoList.length > 0 ? false : true,
            message: "Input the tree no.!",
            disabled: this.state.btnLoad,
            changeHandler: it => this.onFormTreeChangeHandler(it)
          },
          {
            name: "TreeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            required: true,
            value: this.state.TreeTypeCode,
            listData: cloneDeep(treeTypeData).map(it => it.code),
            changeHandler: it=> this.dwarfAddTreeTypeHandler(it)
          },
          {
            name: "goodNuts",
            placeholder: "Good Nuts",
            type: "number",
            message: "Input the number of Good Nuts!"
          },
          {
            name: "rejectedNuts",
            placeholder: "Rejected Nuts",
            type: "number",
            required: false,
            message: "Input the number of Rejected Nuts!"
          }
        ]
        : [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true,
            required: false
          },
          {
            name: "harvestDt",
            placeholder: "Harvest Date",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of harvest!"
          },
          {
            name:"tree",
            placeholder: "Tree No.",
            //type: "alphanumeric",
            type: "text",
            disabled: true,
            required: false
          },
          {
            name: "TreeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            required: true,
          //  changeHandler: this.onPrematureSpatheHandler
            value: this.state.TreeTypeCode,
            listData: cloneDeep(treeTypeData).map(it => it.code),
            changeHandler: it=> this.dwarfTreeTypeHandler(it)
          },
          {
            name: "goodNuts",
            placeholder: "Good Nuts",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Good Nuts!"
          },
          {
            name: "rejectedNuts",
            placeholder: "Rejected Nuts",
            type: "number",
            required: false,
            disabled: isDelete ? true : false,
            message: "Input the number of Rejected Nuts!"
          }
        ];
    return (
      <Fragment>
        <Modal
          className="page-builder-add-modal emasculation-modal"
          //className="admin-container-modal"
          width={400}
          footer={false}
          closable={false}
          destroyOnClose={true}
          title="Confirm"
          visible={this.state.visibleConfirm}
        //onOk={()=>this.yesConfirm()}
        //onCancel={()=>this.closeConfirm()}
        >
          {cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 ? <p className="emasculation-p">{"Date Of Planting: " + moment(cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].dtPlanted).format("DD/MM/YYYY")}</p> : null}
          {cloneDeep(formTreeNoList) && formTreeNoList.length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber).length > 0 && cloneDeep(formTreeNoList).filter(item => item.treeNoFull == treeNumber)[0].stageCode == 2 ? <p>Plant will be transferred to Tree</p> : null}
          <p>Do you want to Proceed further?</p>
          <Row type="flex" justify="end" gutter={5}>
            <Col>
              <Button
                onClick={() => this.closeConfirm()} //loading={btnLoad}
              >
                CANCEL
                  </Button>
            </Col>
            <Col>
              <Button
                onClick={() => this.yesConfirm()}
              >
                OK
                  </Button>
            </Col>
          </Row>
        </Modal>
        <LocationPageBuilder
          title="Dwarf Tree Harvest"
          buttonList={buttonList}
          dropDownList={[
            {
              label: "Block",
              listItems: blockNoList.map(it => it.blockNoFull),
              value: blockNumber ? blockNumber : undefined,
              changeHandler: it => this.onBlockChangeHandler(it)
            },
            {
              label: "Area",
              listItems: cloneDeep(areaNoList).map(it => it.areaNo),
              disabled: blockNumber ? false : true,
              value: areaNumber ? areaNumber : undefined,
              changeHandler: it => this.onAreaChangeHandler(it)
            },
            {
              label: "Tree No.",
              listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
              disabled: areaNumber ? false : true,
              value: treeNumber ? treeNumber : undefined,
              changeHandler: it => this.onTreeChangeHandler(it)
            },
            {
              label: "Harvest Date",
              type: "date",
              //disabled: treeNumber ? false : true,
              value: dateOfHarvest ? dateOfHarvest : undefined,
              changeHandler: this.dateOfHarvestHandler
            }
          ]}
          addModal={addModal}
          addModalToggle={this.addModalToggle}
          addFormHandler={this.addFormHandler}
          addPopulateData={addPopulateData}
          editData={editData}
          btnLoad={btnLoad}
          isEdit={isEdit}
          isDelete={isDelete}
          formItems={formItems}
        // formItems={[
        //   {
        //     name: "blockNo",
        //     placeholder: "Block",
        //     //listData: cloneDeep(blockNoList).map(it => it.blockNo),
        //     disabled: true
        //   },
        //   {
        //     name: "harvestDt",
        //     placeholder: "Harvest Date",
        //     type: "date",
        //     disabled: isDelete ? true : false,
        //     message: "Input the date of harvest!"
        //   },
        //   {
        //     name: "tree",
        //     placeholder: "Tree No.",
        //     //type: "alphanumeric",
        //     type: "text",
        //     listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
        //     disabled: isEdit || isDelete ? true : false,
        //     readonly: treeNoList.length > 0 ? false : true,
        //     message: "Input the tree no.!",
        //     changeHandler: it => this.onFormTreeChangeHandler(it)
        //   },
        //   {
        //     name: "goodNuts",
        //     placeholder: "Good Nuts",
        //     type: "number",
        //     disabled: isDelete ? true : false,
        //     message: "Input the number of Good Nuts!"
        //   },
        //   {
        //     name: "rejectedNuts",
        //     placeholder: "Rejected Nuts",
        //     type: "number",
        //     required: false,
        //     disabled: isDelete ? true : false,
        //     message: "Input the number of Rejected Nuts!"
        //   }
        // ]}
        >
          <div ref={this.componentRef}>
            <Spin spinning={loading} size="large">
              <Table
                key="tablekey"
                ref="table"
                rowKey="uuid"
                columns={columns}
                dataSource={dynamicTableData}
                pagination={!pagination ? pagination : { pageSize: 15 }}
                //loading={loading}
                locale={{
                  emptyText: <Empty />
                }}
              />
            </Spin>
          </div>
        </LocationPageBuilder>
        <Modal 
        title="Confirm"
        visible={this.state.showModal}
        className="confirmModal"
        onCancel={this.handleCancel}
        footer={[
          <Button key="submit" onClick={this.handleCancel}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelClose}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>

        <Modal 
        title="Confirm"
        visible={this.state.showModalAdd}
        className="confirmModal"
        onCancel={this.handleCancelAdd}
        footer={[
          <Button key="submit" onClick={this.handleCancelAdd}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelCloseAdd}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>
      </Fragment>
    );
  }
}

export default DwarfTree;
