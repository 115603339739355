import React from "react";

import DwarfPlantswithNilSpatheEmasculation from "./DPWithNilSpatheEmasculation";
import DwarfTreePlantWithNumberOfSpatheEmasculated from "./DTPWithNumberOfSpatheEmasculated";
import DwarfTreesPlantsWithPrematureSpathesEmasculated from "./DTPWithPMSpathesEmasculated";
import DwarfTreesPlantsWithNutsCountOneTwentyDays from "./DTPWithNutsCountOneTwentyDays";
import TallTreesWithNoMalePollen from "./TallTreesWithNoMalePollen";
import DwarfTallNilHarvestedNuts from "./DTNilHarvestedNuts";
import DwarfTallTreeWithHarvestedNuts from "./DTTWithHarvestedNuts";
import DwarfTallTreesWithMAXMINHarvestedNuts from "./DTTWithMAXMINHarvestedNuts";

export default [
  {
    label: "Dwarf Plants with Nil Spathe Emasculation from date of transfer",
    key: "1",
    link: "/query-module/dwarf-plants-with-nil-spathe-emasculation",
    render: () => <DwarfPlantswithNilSpatheEmasculation />
  },
  {
    label: "Dwarf Trees/Plants with No. of Spathe Emasculated",
    key: "2",
    link: "/query-module/dwarf-trees/plants-with-number-of-spathe-emasculated",
    render: () => <DwarfTreePlantWithNumberOfSpatheEmasculated />
  },
  {
    label: "Dwarf Trees/Plants with Premature Spathes Emasculated",
    key: "3",
    link: "/query-module/dwarf-trees/plants-with-premature-spathes-emasculated",
    render: () => <DwarfTreesPlantsWithPrematureSpathesEmasculated />
  },
  {
    label: "Dwarf Trees/Plants with Nuts Count 120 Days",
    key: "4",
    link: "/query-module/dwarf-trees/plants-with-nuts-count-120days",
    render: () => <DwarfTreesPlantsWithNutsCountOneTwentyDays />
  },
  {
    label: "Tall Trees with NO MALE POLLEN",
    key: "5",
    link: "/query-module/tall-trees-with-no-male-pollen",
    render: () => <TallTreesWithNoMalePollen />
  },
  {
    label: "Dwarf/Tall Trees Nil Harvested Nuts",
    key: "6",
    link: "/query-module/dwarf/tall-nil-harvested-nuts",
    render: () => <DwarfTallNilHarvestedNuts />
  },
  {
    label: "Dwarf/Tall Trees with Harvested Nuts",
    key: "7",
    link: "/query-module/dwarf/tall-trees-with-harvested-nuts",
    render: () => <DwarfTallTreeWithHarvestedNuts />
  },
  {
    label: "Dwarf/Tall Trees with MAX-Harvested/MIN-Harvested Nuts",
    key: "8",
    link:
      "/query-module/dwarf/tall-trees-with-max-harvested/min-harvested-nuts",
    render: () => <DwarfTallTreesWithMAXMINHarvestedNuts />
  }
];
