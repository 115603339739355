import React from "react";

export const columns = [
  //{
  //children: [
  {
    title: "Area",
    dataIndex: "area_no"
  },
  {
    title: "Tree",
    dataIndex: "trees"
  },
  //]
  //},
  {
    title: "WEEK-1",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekOneSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekOneButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-2",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekTwoSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekTwoButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-3",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekThreeSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekThreeButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-4",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekFourSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekFourButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "TOTAL",
    children: [
      {
        title: "Spathe",
        dataIndex: "spathes"
      },
      {
        title: "Button",
        dataIndex: "buttons"
      }
    ]
  }
];

export const columns_farm = [
  //{
  //children: [
  {
    title: "Farm",
    dataIndex: "area_no"
  },
  {
    title: "Tree",
    dataIndex: "trees"
  },
  //]
  //},
  {
    title: "WEEK-1",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekOneSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekOneButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-2",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekTwoSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekTwoButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-3",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekThreeSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekThreeButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "WEEK-4",
    children: [
      {
        title: "Spathe",
        dataIndex: "weekFourSpathes",
        render: it => (it ? it : "0")
      },
      {
        title: "Button",
        dataIndex: "weekFourButtons",
        render: it => (it ? it : "0")
      }
    ]
  },
  {
    title: "TOTAL",
    children: [
      {
        title: "Spathe",
        dataIndex: "spathes"
      },
      {
        title: "Button",
        dataIndex: "buttons"
      }
    ]
  }
];
//export default columns;
