export const parserFunction = arr => {
  let resultArray = [];
  console.log("Array", arr);
  Object.keys(arr).map((it, i) => {
    arr[it] &&
      it != "title" &&
      arr[it].forEach(
        ({
          block_no,
          data = null,
          block_monthly_data,
          block_total,
          farm_monthly_data,
          farm_total
        }) => {
          /*data &&
            data.forEach(
              ({
                tree_code = null,
                area_code = null,
                year_data = null,
                type_total
              }) => {
                //Push tree code
                tree_code &&
                  resultArray.push({ tree_code: `TYPE - ${tree_code}` });
                area_code &&
                  resultArray.push({ area_code: `AREA - ${area_code}` });
                //year data
                year_data &&
                  year_data.forEach(({ year, ...rest }, index) => {
                    resultArray.push({
                      year:
                        index == 0
                          ? year
                          : year_data[index - 1].year == year
                          ? "  "
                          : year,
                      ...rest
                    });
                  });
                //type total
                type_total &&
                  type_total.forEach((it, index) => {
                    if (tree_code)
                      resultArray.push({
                        total: "TYPE - TOTAL",
                        ...it
                      });
                    if (area_code)
                      resultArray.push({
                        total: "AREA - TOTAL",
                        ...it
                      });
                  });
              }
            );*/
          // for Area wise
          data &&
            data.forEach(
              ({ area_code, tree_code, year_data, type_total }, j) => {
                area_code &&
                  resultArray.push({ tree_code: `Area - ${area_code}` });
                tree_code &&
                  resultArray.push({ tree_code: `Type - ${tree_code}` });
                year_data &&
                  year_data.forEach(({ year, month, ...rest }, index) => {
                    resultArray.push({
                      year: index == 0 || month == "Jan" ? year : "  ",
                      month,
                      ...rest
                    });
                  });
                //type total
                type_total &&
                  type_total.forEach((it, index) => {
                    if (tree_code)
                      resultArray.push({
                        total: "TYPE - TOTAL",
                        ...it
                      });
                    if (area_code)
                      resultArray.push({
                        total: "AREA - TOTAL",
                        ...it
                      });
                  });
              }
            );

          block_monthly_data &&
            resultArray.push({ tree_code: `BLOCK ${block_no}` });
          //block monthly data
          block_monthly_data &&
            block_monthly_data.forEach(({ year, month, ...rest }, index) => {
              resultArray.push({
                year: index == 0 || month == "Jan" ? year : "  ",
                month,
                ...rest
              });
            });
          //block total
          block_total &&
            block_total.forEach((it, index) => {
              resultArray.push({
                total: "BLOCK - TOTAL",
                ...it
              });
            });

          farm_monthly_data && resultArray.push({ tree_code: "FARM" });
          //block monthly data
          farm_monthly_data &&
            farm_monthly_data.forEach(({ year, month, ...rest }, index) => {
              resultArray.push({
                year: index == 0 || month == "Jan" ? year : "  ",
                month,
                ...rest
              });
            });
          //block total
          farm_total &&
            farm_total.forEach((it, index) => {
              resultArray.push({
                total: "FARM - TOTAL",
                ...it
              });
            });
        }
      );
  });
  console.log("resultArray", resultArray);
  return resultArray;
};

// export const parserFunction = arr => {
//   let resultArray = []

//   arr && arr.forEach(({ block_no, data = null, block_monthly_data, block_total }) => {
//     data && data.forEach(({ tree_code = null, area_code = null, year_data = null, type_total }) => {
//       //Push tree code
//       resultArray.push({ tree_code: `TYPE - ${tree_code}` }) || resultArray.push({ area_code: `Area - ${area_code}` })
//       //year data
//       year_data && year_data.forEach(({ year, ...rest }, index) => {

//         resultArray.push({
//           year: index == 0 ? year : year_data[index - 1].year == year ? '  ' : year,
//           ...rest
//         })
//       })
//       //type total
//       type_total && type_total.forEach((it, index) => {
//         if (tree_code)
//           resultArray.push({
//             total: 'TYPE - TOTAL',
//             ...it
//           })
//         if (area_code)
//           resultArray.push({
//             total: 'AREA - TOTAL',
//             ...it
//           })
//       })
//     })

//     block_monthly_data && resultArray.push({ tree_code: `BLOCK ${block_no}` })
//     //block monthly data
//     block_monthly_data && block_monthly_data.forEach(({ year, ...rest }, index) => {
//       resultArray.push({
//         year: index == 0 ? year : '  ',
//         ...rest
//       })
//     })
//     //block total
//     block_total && block_total.forEach((it, index) => {
//       resultArray.push({
//         total: 'BLOCK - TOTAL',
//         ...it
//       })
//     })
//   })
//   return resultArray
// }
