import React, { Fragment } from "react";
import { Table, Statistic } from "antd";

export default ({ blockNo, list, summary, listColumns, summaryColumns, pagebreak }) => {
  return (
    <Fragment>
      <div className={pagebreak}>
        <div>
          <Statistic title="Block No." value={blockNo} />
        </div>
        <hr />
        <Table
          rowKey="uuid"
          className="multi-table"
          columns={listColumns}
          dataSource={list}
          pagination={false}
        />
      </div>
      <div>
        <div>
          <Statistic title="Summary For Block" value={blockNo} />
        </div>
        <hr />
        <Table
          rowKey="uuid"
          className="multi-table"
          columns={summaryColumns}
          dataSource={summary}
          pagination={false}
        />
      </div>
    </Fragment>
  );
};
