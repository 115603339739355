import React from "react";
import { Table, Col, Statistic, Descriptions, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";
import { formatDate } from "../../../../utility/date";
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};
const style = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center"
  //minWidth: "200px"
};
const stylebackground = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center",
  //minWidth: "200px",
  background: "#fafafa"
};

let columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full",
    key: "treeNo"
    //render: it => (it ? it : "-")
  },
  {
    title: "Spathe No.",
    dataIndex: "spathe_no",
    key: "spathNo"
    //render: it => (it ? it : "-")
  },
  {
    title: "P.M",
    children: [
      {
        title: "Y/N",
        key: "P.M",
        dataIndex: "premature_spathe",
        render: it => (it ? "Yes" : "No")
      }
    ]
  },
  {
    title: "Date Of Emasculation",
    dataIndex: "dt_emasculation",
    key: "dt_emasculation",
    render: it => moment(it).format("DD/MM/YYYY")
  },
  {
    title: "Date Of Appearance",
    dataIndex: "dt_appearance",
    key: "dt_appearance",
    render: it => moment(it).format("DD/MM/YYYY")
  },
  {
    title: "Buttons",
    dataIndex: "buttons",
    key: "buttons",
    render: it => (it ? it : "-")
  },
  {
    title: "Date of Pollination",
    children: [
      {
        title: "From",
        key: "pollination_from",
        dataIndex: "pollination_from",
        //render: it => moment(it).format("DD/MM/YYYY")
        render: it => (it ? formatDate(it) : "-")
      },
      {
        title: "To",
        key: "pollination_to",
        dataIndex: "pollination_to",
        //render: it => moment(it).format("DD/MM/YYYY")
        render: it => (it ? formatDate(it) : "-")
      }
    ]
  }
];

// let farmColumns=[
//   {
//     title: "Tree No.",
//     dataIndex: "tree_no_full"
//     //render: it => (it ? it : "-")
//   },
//   {
//     title: "Spathe No.",
//     dataIndex: "spathe_No"
//     //render: it => (it ? it : "-")
//   },
//   {
//     title: "Spathe No.",
//     dataIndex: "spathe_No"
//     //render: it => (it ? it : "-")
//   },
// ]

export default ({ dataSource, farmTotal, fromdate, todate }) => (
  <div>
    <h3>
      Dwarf Tree Emasculation - List &nbsp;&nbsp; {moment(fromdate).format("DD/MM/YYYY")} - {moment(todate).format("DD/MM/YYYY")}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    {dataSource.blocks.map(({ block_no_full, areas, block_total }, index) => (
      <div key={index}>
        {areas &&
          areas.map(({ area_no, area_total, trees }, j) => (
            <div key={j} className={index == 0 && j == 0 ? "" : "pagebreak"}>
              {trees && (
                <div>
                  <Col key={1} {...layout}>
                    <Statistic title="Block No." value={block_no_full} />
                  </Col>
                  <Col key={2} {...layout}>
                    <Statistic title="Area No." value={area_no} />
                  </Col>
                  <br></br>
                  <br></br>
                  <Table
                    //className="block-wise-summary-dwarf-table"
                    //className="bordered-table-head"
                    rowKey={
                      "" +
                      (record => record.tree_no_full) +
                      (record => record.spathe_no)
                    }
                    size="medium"
                    columns={columns}
                    bordered
                    dataSource={trees}
                    pagination={false}
                  />
                  <br></br>
                  {area_total && (
                    <div>
                      {
                        //<Row gutter={[32, 16]}>
                      }
                      <Row>
                        <Col span={10} style={stylebackground}>
                          <h3>{"Buttons In Area " + area_no}</h3>
                        </Col>
                        <Col span={7} style={stylebackground}>
                          <h3>Entries </h3>
                        </Col>
                        <Col span={7} style={stylebackground}>
                          <h3>Buttons</h3>
                        </Col>
                      </Row>

                      {area_total.map(
                        ({ buttons, code, entries }, i) => (
                          {
                            //<Row gutter={[32, 16]} key={i}>
                          },
                          (
                            <Row key={i}>
                              <Col span={10} style={stylebackground}>
                                {code ? (
                                  <h3>{"Type " + code}</h3>
                                ) : (
                                    <h3>{"Area Wise Total Buttons"}</h3>
                                  )}
                              </Col>
                              <Col span={7} style={style}>
                                <h3>{entries ? entries : "-"}</h3>
                              </Col>
                              <Col span={7} style={style}>
                                <h3>{buttons ? buttons : "-"}</h3>
                              </Col>
                            </Row>
                          )
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
              <br></br>
            </div>
          ))}

        {block_total && (
          <div>
            {
              //<Row gutter={[32, 16]}>
            }
            <Row>
              <Col span={10} style={stylebackground}>
                <h3>{"Buttons In Block " + block_no_full}</h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Entries </h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Buttons</h3>
              </Col>
            </Row>
            {block_total.map(
              ({ buttons, code, entries }, i) => (
                {
                  //<Row gutter={[32, 16]} key={i}>
                },
                (
                  <Row key={i}>
                    <Col span={10} style={stylebackground}>
                      {code ? (
                        <h3>{"Type " + code}</h3>
                      ) : (
                          <h3>{"Block Wise Total Buttons"}</h3>
                        )}
                    </Col>
                    <Col span={7} style={style}>
                      <h3>{entries ? entries : "-"}</h3>
                    </Col>
                    <Col span={7} style={style}>
                      <h3>{buttons ? buttons : "-"}</h3>
                    </Col>
                  </Row>
                )
              )
            )}
          </div>
        )}
        <br></br>
      </div>
    ))}
    {dataSource.farm_total && (
      <div>
        {
          //<Row gutter={[32, 16]}>
        }
        <Row>
          <Col span={10} style={stylebackground}>
            <h3>{"Buttons In Farm"}</h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Entries </h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Buttons</h3>
          </Col>
        </Row>
        {dataSource.farm_total.map(
          ({ buttons, code, entries }, i) => (
            {
              //<Row gutter={[32, 16]} key={i}>
            },
            (
              <Row key={i}>
                <Col span={10} style={stylebackground}>
                  {code ? (
                    <h3>{"Type " + code}</h3>
                  ) : (
                      <h3>{"Farm Wise Total Buttons"}</h3>
                    )}
                </Col>
                <Col span={7} style={style}>
                  <h3>{entries ? entries : "-"}</h3>
                </Col>
                <Col span={7} style={style}>
                  <h3>{buttons ? buttons : "-"}</h3>
                </Col>
              </Row>
            )
          )
        )}
        <br></br>
        <br></br>
      </div>
    )}
    <br></br>
    <br></br>
    <br></br>
  </div>
);
