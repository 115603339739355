import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Statistic,
  Descriptions,
  Col,
  Spin
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";

//mock
//import mock from "./mocData";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class DwarfTreeResearchAreaList extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    //farmTotal: [],

    blockNoList: [],
    block_uuid: null,
    blockNumber: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    farm_codes: null,
    farm_total: null
  };

  componentDidMount() {
    this.fetchAreaList();
    this.fetchBlockNo();
  }

  fetchAreaList = () => {
    this.setState({ loading: true });
    // let { block_uuid, ActivityMonth } = this.state,
    //   today = moment().format("YYYY-MM");

    let { area_uuid, block_uuid } = this.state,
      url = "/dwarf-research-area-wise-list";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchApi(url, "get").then(res => {
      if (res)
        this.setState({
          data: res.data,
          farm_codes: res.farm_codes,
          farm_total: res.farm_total
        });
      else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });

    // this.setState({
    //   data: mock
    // });
    // fetchApi(
    //     `/regular-reports/farm-production-activities-all-blocks?period=${
    //     DateOfSummary ? DateOfSummary : today
    //     }&block.uuid=${block_uuid}`,
    //     "get"
    // ).then(res => {
    //     if (res)
    //         this.setState({
    //             data: parserFunction(res.blocks).flat(1)
    //         })
    //     else message.error("Internal Server Error!");
    //     this.setState({ loading: false });
    // });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchAreaList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchAreaList()
    );
  };

  generateTables = tableData => (
    <div>
      <h3>
        Research Dwarf Tree Transaction - Area Wise List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      {tableData.map(({ areas, block_no, block_codes, block_total }, index) => (
        <div key={index}>
          {areas.map(({ area_codes, tree_nos, area_no, area_count }, j) => (
            <div key={j} className={index == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
              {tree_nos && (
                <div>
                  <Col key={1} {...layout}>
                    <Statistic title="Block No." value={block_no} />
                  </Col>
                  <Col key={2} {...layout}>
                    <Statistic title="Area No." value={area_no} />
                  </Col>
                </div>
              )}

              {tree_nos && (
                <Descriptions bordered className="p-0">
                  <Descriptions.Item label="Tree No." span={3}>
                    {tree_nos &&
                      tree_nos.map((it, i) => (
                        <Col>
                          <span
                            key={i}
                            style={{
                              margin: "0px 10px"
                              // padding: "5px",
                              // border: "1px solid #4caf50"
                            }}
                          >
                            {it}
                          </span>
                        </Col>
                      ))}
                  </Descriptions.Item>
                  <Descriptions.Item span={6}></Descriptions.Item>
                </Descriptions>
              )}

              {area_codes && (
                <Descriptions bordered>
                  <Descriptions.Item
                    label={"Total Dwarf Trees In Area"}
                    span={3}
                  >
                    {area_no}
                  </Descriptions.Item>
                  {area_codes &&
                    area_codes.map(({ code, total_count }, k) => (
                      <Descriptions.Item
                        key={k}
                        label={"Area Total Of " + code + " Type"}
                        span={3}
                      >
                        {total_count}
                      </Descriptions.Item>
                    ))}
                  <Descriptions.Item label={"Area Total Of All Types"} span={3}>
                    {area_count}
                  </Descriptions.Item>
                  <Descriptions.Item span={6}></Descriptions.Item>
                </Descriptions>
              )}
              {j == areas.length - 1 ? (
                <br />
              ) : (
                  <Fragment><br /><br /></Fragment>
                )}
            </div>
          ))}
          {block_codes && (
            <Descriptions bordered>
              <Descriptions.Item
                label={"Total Dwarf Trees in Block"}
                span={3}
              >
                {block_no}
              </Descriptions.Item>
              {block_codes &&
                block_codes.map(({ code, total_count }, i) => (
                  <Descriptions.Item
                    key={i}
                    label={"Block Total Of " + code + " Type"}
                    span={3}
                  >
                    {total_count}
                  </Descriptions.Item>
                ))}
              <Descriptions.Item label={"Block Total Of All Types "} span={3}>
                {block_total}
              </Descriptions.Item>
            </Descriptions>
          )}
          <br />
          <br />
        </div>
      ))}
      {this.state.farm_codes && (
        <Descriptions bordered>
          <Descriptions.Item
            label={"Total Dwarf Trees in Farm"}
            span={3}
          ></Descriptions.Item>
          {this.state.farm_codes &&
            this.state.farm_codes.map(({ code, total_count }, i) => (
              <Descriptions.Item
                key={i}
                label={"Farm Total Of " + code + " Type"}
                span={3}
              >
                {total_count}
              </Descriptions.Item>
            ))}
          <Descriptions.Item label={"Farm Total Of All Types"} span={3}>
            {this.state.farm_total}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );

  generateExcel = () => {
    let { area_uuid, block_uuid } = this.state,
      url = "/dwarf-research-area-wise-list";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNoList
    } = this.state;

    const buttonList = data
      ? [
        {
          label: "Excel",
          icon: "file-text",
          onClickHandler: () => this.generateExcel()
        },
        {
          label: "Print",
          icon: "diff",
          reference: this.componentRef
        }
      ]
      : null;

    let JSX = data ? this.generateTables(data) : null;

    const columns = [
      {
        title: "Date Of Pollen Collected",
        dataIndex: "dt_pollen_collected"
      },
      {
        title: "Total No. Of Trees",
        dataIndex: "count"
      },
      {
        title: "Dates Of Starting",
        dataIndex: "start_date"
      },
      {
        title: "Date Of Ending",
        dataIndex: "end_date"
      },
      {
        title: "Last Update Date",
        dataIndex: "update_date"
      }
    ];
    return (
      <ReportLayout
        title="Research Dwarf Tree Transaction - Area Wise List "
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? <div>{JSX}</div> : <Empty description={"No Data Found"} />}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreeResearchAreaList;
