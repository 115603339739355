import React from "react";
import { Table, Statistic, Col } from "antd";
import ScollBarView from "react-double-scrollbar";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 3
  },
  md: {
    span: 3
  }
};

const alignleft = {
  marginRight: "auto"
};
export default ({ columns = [], tableData = [] }) => {
  return tableData.map(({ block_no, area_no, trees }, index) => (
    <div className={index != 0 ? "pagebreak" : ""}>
      <ScollBarView>
        <div className="multi-table-header" style={alignleft}>
          <Statistic title="Block No." value={block_no} />
          <Statistic title="Area No." value={area_no} />
        </div>
        {/* <hr className="table-top-hr" /> */}
        <Table
          rowKey="uuid"
          //className="multi-table input-formats-tables"
          className="seedling-growth-data-for-tall-trees-multi-table input-formats-tables"
          columns={columns}
          dataSource={trees}
          pagination={false}
        />
      </ScollBarView>
    </div>
  ));
};
