
//Dwarf
export const parserFunctionDwarf = arr => arr && arr.map(({ block_no, areas }) => ({
  block_no,
  areas: areas.map(({ area_no, trees }) => ({
    area_no,
    tableData: trees.map(({ tree_no_full, code, dt_planted, years }) => {
      let temp = { tree_no_full, code, dt_planted }
      years.forEach(({ no_of_buttons, no_of_spathes, count_120_days, harvested_nuts, nuts_per_spathe }, i) => {
        temp[`no_of_buttons_${i}`] = no_of_buttons
        temp[`no_of_spathes_${i}`] = no_of_spathes
        temp[`count_120_days_${i}`] = count_120_days
        temp[`harvested_nuts_${i}`] = harvested_nuts
        temp[`nuts_per_spathe_${i}`] = nuts_per_spathe
      }
      )
      return temp
    }
    )
  }))
}))

//Tall
export const parserFunctionTall = arr => arr && arr.map(({ block_no, areas }) => ({
  block_no,
  areas: areas.map(({ area_no, trees }) => ({
    area_no,
    tableData: trees.map(({ tree_no_full, code, dt_planted, years }) => {
      let temp = { tree_no_full, code, dt_planted }
      years.forEach(({ bunches, harvested_nuts, nuts_per_bunch }, i) => {
        temp[`bunches_${i}`] = bunches
        temp[`harvested_nuts_${i}`] = harvested_nuts
        temp[`nuts_per_bunch_${i}`] = nuts_per_bunch
      }
      )
      return temp
    }
    )
  }))
}))


//Date Parser
export const dateParser = (date) => {
  let temp = date.split('-').map(it => parseInt(it))
  let splitString = []
  for (let i = temp[0]; i >= temp[1]; i--) {
    splitString.push(i)
  }
  return splitString
}
