import React from "react";

import SelectedListYieldingDwarfTrees from "./Yielding/Dwarf";
import SelectedListYieldingTallTrees from "./Yielding/Tall";
import ResearchGroupRelatedPerformanceReport from "./RelatedPerformance";
import PerformanceComparisonReport from "./PerformanceComparison";
import AgeOfFloweringReport from "./AgeOfFlowering";

export default [
  {
    label: "Selected List of LOW/HIGH YIELDING (Performance) DWARF TREES",
    key: "1",
    link: "/special-reports/selected-list-low/high-yielding-dwarf-trees",
    render: () => <SelectedListYieldingDwarfTrees />
  },
  {
    label: "Selected List of LOW/HIGH YIELDING (Performance) TALL TREES",
    key: "2",
    link: "/special-reports/selected-list-low/high-yielding-tall-trees",
    render: () => <SelectedListYieldingTallTrees />
  },
  {
    label:
      "Research Group Related Performance Report for a Selected Period & Selected Research Group",
    key: "3",
    link: "/special-reports/research-group-related-performance-report",
    render: () => <ResearchGroupRelatedPerformanceReport />
  },
  {
    label:
      "Performance Comparison Report between Selected Period & Selected Research Group",
    key: "4",
    link: "/special-reports/performance-comparison-report",
    render: () => <PerformanceComparisonReport />
  },
  {
    label: "Age Of Flowering Report",
    key: "5",
    link: "/special-reports/age-of-flowering-report",
    render: () => <AgeOfFloweringReport />
  }
];
