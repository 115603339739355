import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { COOKIE, getCookie } from "./../services/cookie";

import RouteWithLayout from "./routeHandler";

//Layout imports
import DashboardLayout from "../app/layouts/Dashboard";

//Auth Layouts
import LoginPage from "../app/pages/Login";

//List Of Pages
import MasterFiles from "../app/pages/MasterFiles";
import TransectionFiles from "../app/pages/TransectionFiles";
import InputFormat from "../app/pages/InputFormats";
import TreeAgewiseSummary from "../app/pages/TreeAgewiseSummary";
import QueryModule from "../app/pages/QueryModule";
import RegularReports from "../app/pages/RegularReports";
import SpecialReports from "../app/pages/SpecialReports";
import OtherReports from "../app/pages/OtherReports";

import Admin from "../app/pages/Admin";

// let path;
// if (getCookie(COOKIE.ROLE) == "ROLE_REPORTS") {
//     path = "/input-formats/120-days-count"
// } else {
//     path = "/master-files"
// }

export default ({ location, ...rest }) => {
  return (
    <Switch>
      //#region Authentication
      <Route
        path="/login"
        roles={["ROLE_ADMIN","ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        component={LoginPage}
      />
      {/* TBD
            <Route path="/register" component={LoginPage} />
            <Route path="/register" component={LoginPage} /> */}
      //#endregion //#region Master file
      <RouteWithLayout
        exact
        path="/master-files"
        roles={["ROLE_ADMIN","ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Master Files"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      //#region Location
      <RouteWithLayout
        exact
        path="/master-files/location/block-number"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Block Number"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/location/area-number"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Area Number"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/location/row-number"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Row Number"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/location/pits-gaps"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Pits/Gaps"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      //#endregion //#region Dwarf
      <RouteWithLayout
        exact
        path="/master-files/dwarf/dwarf-tree-types"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Tree Types"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/dwarf/dwarf-seedlings"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Seedlings"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/dwarf/dwarf-plants"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Plants"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/dwarf/dwarf-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Trees"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      {/* <RouteWithLayout
        exact
        path="/master-files/dwarf/dwarf-valuation-chart"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Valuation Chart"
        location={location}
        component={MasterFiles}
        {...rest}
      /> */}
      <RouteWithLayout
        exact
        path="/master-files/dwarf/valuation-amount"
        roles={["ROLE_ADMIN", "ROLE_VALUATION"]}
        layout={DashboardLayout}
        title="Valuation Amount"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      //#endregion //#region Tall
      <RouteWithLayout
        exact
        path="/master-files/tall/tall-tree-types"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Tree Types"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/tall/tall-seedlings"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Seedlings"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/tall/tall-plants"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Plants"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/tall/tall-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Trees"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      {/* <RouteWithLayout
        exact
        path="/master-files/tall/tall-valuation-chart"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Valuation Chart"
        location={location}
        component={MasterFiles}
        {...rest}
      /> */}
      //#endregion //#region Research
      <RouteWithLayout
        exact
        path="/master-files/research/dwarf-research-master"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Research Master"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/research/tall-research-master"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Research Master"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/research/dwarf-tree-research"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Tree Research"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/master-files/research/tall-tree-research"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Tree Research"
        location={location}
        component={MasterFiles}
        {...rest}
      />
      //#endregion //#endregion //#region Transaction files
      {/* <RouteWithLayout
        exact
        path="/transaction-files"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Transaction Files"
        location={location}
        component={TransectionFiles}
        {...rest}
      /> */}
      //#region Seedling Growth
      <RouteWithLayout
        exact
        path="/transaction-files/seedling-growth/dwarf-seedling"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Seedling"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/transaction-files/seedling-growth/tall-seedling"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Seedling"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      //#endregion //#region Tree Harvest
      <RouteWithLayout
        exact
        path="/transaction-files/tree-harvest/dwarf-tree"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Harvest"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/transaction-files/tree-harvest/tall-tree"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Harvest"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      //#endregion //#region Coconut analysis
      <RouteWithLayout
        exact
        path="/transaction-files/coconut-analysis/dwarf-coconut"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Coconut"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/transaction-files/coconut-analysis/tall-coconut"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Tall Coconut"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      //#endregion //#region Pollen Collection
      <RouteWithLayout
        exact
        path="/transaction-files/pollen-collection/dwarf-male"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Pollen Collection"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/transaction-files/pollen-collection/tall-male"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Pollen Collection"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      //#endregion //#region Others
      <RouteWithLayout
        exact
        //path="/transaction-files/others/dwarf-tree-emasculation"
        path="/transaction-files/dwarf-tree-emasculation"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Tree Emasculation"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      <RouteWithLayout
        exact
        //path="/transaction-files/others/dwarf-tree-120-days"
        path="/transaction-files/dwarf-tree-120-days"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM"]}
        layout={DashboardLayout}
        title="Dwarf Tree 120 Days Count"
        location={location}
        component={TransectionFiles}
        {...rest}
      />
      //#endregion //#endregion //#region Input Format
      <RouteWithLayout
        exact
        path="/input-formats/120-days-count"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="120 Days Count"
        location={location}
        component={InputFormat}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/input-formats/dwarf-tree-harvest-data"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Harvest Data"
        location={location}
        component={InputFormat}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/input-formats/tall-tree-harvest-data"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Harvest Data"
        location={location}
        component={InputFormat}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/input-formats/seedling-growth-data-for-dwarf-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Seedling Growth Data For Dwarf Trees"
        location={location}
        component={InputFormat}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/input-formats/seedling-growth-data-for-tall-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Seedling Growth Data For Tall Trees"
        location={location}
        component={InputFormat}
        {...rest}
      />
      //#endregion //#region Tree Age Wise Summary
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/row-wise-chart"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Row Wise Chart All Types Tree/Plant/Seedling/Pit/Gap"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/row-area-wise-summary"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Row Wise/Area Wise Summary All Types Tree/Plant/Seedling/Pit/Gap"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/area-block-wise-summary"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Area Wise/Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/block-wise-summary"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/dwarf-age-type-wise-summary"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Age Wise/Type Wise Summary Dwarf Tree/Plant/Seedling"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/dwarf-age-type-wise-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Type Wise/Age Wise List Dwarf Tree/Plant/Seedling"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/tall-age-type-wise-summary"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Age Wise/Type Wise Summary Tall Tree/Plant/Seedling"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/tree-agewise-summary/tall-age-type-wise-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Type Wise/Age Wise List Tall Tree/Plant/Seedling"
        location={location}
        component={TreeAgewiseSummary}
        {...rest}
      />
      //#region Query Module
      <RouteWithLayout
        exact
        path="/query-module/dwarf-plants-with-nil-spathe-emasculation"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Plants with Nil Spathe Emasculation"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf-trees/plants-with-number-of-spathe-emasculated"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Trees/Plants with No. of Spathe Emasculated"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf-trees/plants-with-premature-spathes-emasculated"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Trees/Plants with Premature Spathes Emasculated"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf-trees/plants-with-nuts-count-120days"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Trees/Plants with Nuts Count 120 Days"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/tall-trees-with-no-male-pollen"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Trees with NO MALE POLLEN"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf/tall-nil-harvested-nuts"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf/Tall Trees Nil Harvested Nuts"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf/tall-trees-with-harvested-nuts"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf/Tall Trees with Harvested Nuts"
        location={location}
        component={QueryModule}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/query-module/dwarf/tall-trees-with-max-harvested/min-harvested-nuts"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf/Tall Trees with MAX-Harvested/MIN-Harvested Nuts"
        location={location}
        component={QueryModule}
        {...rest}
      />
      //#endregion //#region Query Module
      <RouteWithLayout
        exact
        path="/regular-reports/monthly-farm-production-activities-report-all-blocks"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Monthly Farm Production Activities Report - All Blocks"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/monthwise-farm-production-activities-report-selected-period-group"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Monthwise Farm Production Activities Reports - Selected Period and Selected Group"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/monthwise-farm-performance-activities-report-selected-period-group"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Monthwise Farm Performance Activities Report - Selected Period and Selected Group"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/monthly-movement-statement-seedlings-plants-trees-pits-gaps"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Monthly Movement Statement of Seedlings/Plants/Trees/Pits & Gaps"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/weekly-spathe-emasculation-data-summary-all-blocks"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Weekly Spathe Emasculation Data Summary - All Blocks"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/dwarf-trees-population-monthly-average-harvested-nuts"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Trees population spread Typewise based on Monthly Average Harvested Nuts"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/tall-trees-population-monthly-average-harvested-nuts"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Trees population spread Typewise based on Monthly Average Harvested Nuts"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-120-days"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Data Entry Status Report-120 Days"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-emasculation"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Data Entry Status Report-Emasculation"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-growth-tall-dwarf"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Data Entry Status Report-Growth-Tall/Dwarf"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-harvest-tall-dwarf"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Data Entry Status Report-Harvest-Tall/Dwarf"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-tall-male-pollen-collection"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Data Entry Status Report-Tall Male Pollen Collection"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/tree-wise-performance-comparison-3-years-period"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tree Wise Performance Comparison between any 3 years & for a Selected Period"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        //path="/regular-reports/dwarf-tree-wise-deletion-report"
        path="/regular-reports/dwarf-tall-type-deletion-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tall Type Deletion Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/dwarf-tall-type-population-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf/Tall Type Population Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        //path="/regular-reports/seedling-wise-dwarf-tall-type-report"
        path="/regular-reports/new-addition-dwarf-tall-type-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        //title="Seedling Wise Dwarf/Tall Type Report"
        title="New Addition Dwarf/Tall Type Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/dwarf-tree-evaluation-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Evaluation Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/tall-tree-evaluation-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Evaluation Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/valuation-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Valuation Report"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/valuation-of-new-additions"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Valuation Of New Additions"
        location={location}
        component={RegularReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/regular-reports/value-of-deleted-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Value Of Deleted Trees"
        location={location}
        component={RegularReports}
        {...rest}
      />
      //#endregion //#region Special reports
      <RouteWithLayout
        exact
        path="/special-reports/selected-list-low/high-yielding-dwarf-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Selected List of LOW/HIGH YIELDING (Performance) DWARF TREES"
        location={location}
        component={SpecialReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/special-reports/selected-list-low/high-yielding-tall-trees"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Selected List of LOW/HIGH YIELDING (Performance) TALL TREES"
        location={location}
        component={SpecialReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/special-reports/research-group-related-performance-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Research Group Related Performance Report for a Selected Period and Selected Research Group"
        location={location}
        component={SpecialReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/special-reports/performance-comparison-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Performance Comparison Report between Selected Period and Selected Research Group"
        location={location}
        component={SpecialReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/special-reports/age-of-flowering-report"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Age OF Flowering Report"
        location={location}
        component={SpecialReports}
        {...rest}
      />
      //#region Other Reports
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-plant/dwarf-plant-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        //title="Other Reports - Dwarf Plant Type List"
        title="Dwarf Plant Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-plant/dwarf-plant-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Plant Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-plant/dwarf-plant-transfer"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Plant Transfer"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-plant/dwarf-plant-view"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Plant View"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-seedling/dwarf-seedling-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Seedling Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-seedling/dwarf-seedling-transfer"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Seedling Transfer"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-seedling/dwarf-seedling-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Seedling Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree/dwarf-tree-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree/dwarf-tree-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree/dwarf-tree-view"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree View"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree-research/dwarf-tree-research-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Research Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree-research/dwarf-tree-research-code-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Research Code List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/pits-and-gaps/pits-and-gaps-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Pits And Gaps List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/pits-and-gaps/pits-and-gaps-transfer"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Pits And Gaps Transfer"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-plant/tall-plant-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Plant Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-plant/tall-plant-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Plant Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-plant/tall-plant-transfer"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Plant Transfer"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-tree/tall-tree-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-tree/tall-tree-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-tree/tall-tree-view"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree View"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-tree-research/tall-tree-research-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Research Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-tree-research/tall-tree-research-code-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Research Code List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-seedling/tall-seedling-type-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Seedling Type List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-seedling/tall-seedling-area-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Seedling Area List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/tall-seedling/tall-seedling-transfer"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Seedling Transfer"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-tree-emasculation-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Emasculation List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/dwarf-trees-120-days-count-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Trees120 Days Count List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/harvests-list/tall-tree-harvest-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Tall Tree Harvest List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      <RouteWithLayout
        exact
        path="/other-reports/harvests-list/dwarf-tree-harvest-list"
        roles={["ROLE_ADMIN", "ROLE_VALUATION", "ROLE_FARM", "ROLE_REPORTS"]}
        layout={DashboardLayout}
        title="Dwarf Tree Harvest List"
        location={location}
        component={OtherReports}
        {...rest}
      />
      //#endregion //#region Admin
      <RouteWithLayout
        exact
        path="/administration"
        roles={["ROLE_ADMIN"]}
        layout={DashboardLayout}
        title="Administration"
        location={location}
        component={Admin}
        {...rest}
      />
      //#endregion
      <Redirect from="/*" to="/master-files" />
    </Switch>
  );
};
