import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic, Col, Row } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { sortFunction } from "../../../../utility/sorter";
//import { parserFunction } from "./parserFunctions";
import moment from "moment";
import columns from "./columns";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class DwarfTallTypePopulationReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    tempData: [],
    blockNoList: [],
    block_uuid: null,
    type: 1,
    treetype: "Dwarf",
    DateAsOn: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }
  fetchDwarfTallTypePopulationReport = () => {
    let { block_uuid, type, DateAsOn } = this.state;
    if (type && DateAsOn) {
      this.setState({ loading: true });
      let url = `/regular-reports/population-list?type=${type}&dt_as_on=${DateAsOn}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res.blocks
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(each => each.blockNoFull === it)[0]
              .uuid
          : null
      },
      () => this.fetchDwarfTallTypePopulationReport()
    );
  };

  onDateAsOnChangeHandler = it => {
    this.setState(
      {
        DateAsOn: it ? it.format("YYYY-MM-DD") : null
      },
      () => this.fetchDwarfTallTypePopulationReport()
    );
  };

  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        type: it == "Dwarf" ? 1 : it == "Tall" ? 2 : null,
        treetype: it
      },
      () => this.fetchDwarfTallTypePopulationReport()
    );
  };

  generateExcel = () => {
    let { block_uuid, type, DateAsOn } = this.state;
    if (type && DateAsOn) {
      let url = `/regular-reports/population-list?type=${type}&dt_as_on=${DateAsOn}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      fetchExcelApi(url, "get").then();
    }
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      blockNumber,
      type,
      treetype,
      DateAsOn
    } = this.state;
    const buttonList =
      data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    const JSX =
      data && data.length > 0 ? (
        data.map(({ block_no, seedlings, plants, trees }, index) => (
          <div key={index}>
            {seedlings && (
              <Fragment>
                <div className={index == 0 ? "" : "pagebreak"}>
                  <div
                  className="multi-table-header "
                  >
                    <Col span={12} key={1}>
                      <Statistic title="Block No." value={block_no} />
                    </Col>
                    <Col span={12} key={1}>
                      <Statistic title="Type" value={"Seedling"} />
                    </Col>
                  </div>
                  <Table
                    className="bordered-table-head"
                    bordered
                    rowKey={seedlings.tree_no_full}
                    columns={columns}
                    dataSource={seedlings}
                    bordered
                    size="small"
                    pagination={false}
                  />
                </div>
                <br />
                <br />
              </Fragment>
            )}
            {plants && (
              <Fragment>
                <div className="pagebreak">
                  <div className="multi-table-header">
                    <Col span={12} key={1}>
                      <Statistic title="Block No." value={block_no} />
                    </Col>
                    <Col span={12} key={1}>
                      <Statistic title="Type" value={"Plants"} />
                    </Col>
                  </div>
                  <Table
                    className="bordered-table-head"
                    bordered
                    rowKey={plants.tree_no_full}
                    columns={columns}
                    dataSource={plants}
                    bordered
                    size="small"
                    pagination={false}
                  />
                </div>
                <br />
                <br />
              </Fragment>
            )}
            {trees && (
              <Fragment>
                <div className="pagebreak">
                  <div className="multi-table-header">
                    <Col span={12} key={1}>
                      <Statistic title="Block No." value={block_no} />
                    </Col>
                    <Col span={12} key={1}>
                      <Statistic title="Type" value={"Trees"} />
                    </Col>
                  </div>
                  <Table
                    className="bordered-table-head"
                    bordered
                    rowKey={trees.tree_no_full}
                    columns={columns}
                    dataSource={trees}
                    bordered
                    size="small"
                    pagination={false}
                  />
                </div>
                <br />
                <br />
              </Fragment>
            )}
            <br />
            <br />
          </div>
        ))
      ) : type && DateAsOn ? (
        <Empty />
      ) : (
        <Empty description="Select Date as on" />
      );

    return (
      <ReportLayout
        title="Dwarf/Tall Type Population Report"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Date as on",
            type: "date",
            //value: DateAsOn ? DateAsOn : undefined,
            changeHandler: it => this.onDateAsOnChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data && data.length > 0 ? (
              <Fragment>
                <h3>
                  {treetype +
                    " Type Population Report for Date as on " +
                    moment(DateAsOn).format("DD/MM/YYYY")}
                </h3>
                {JSX}
              </Fragment>
            ) : type && DateAsOn ? (
              <Empty />
            ) : (
              <Empty description="Select Period" />
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}
export default DwarfTallTypePopulationReport;
