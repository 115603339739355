import React, { Fragment } from "react";
import { Table, Col, Statistic, Row } from "antd";
import { isEmpty } from "lodash";
import ScollBarView from "react-double-scrollbar";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

export default ({ dataSource, columns,title }) =>
  dataSource && (
    <div>
      <h3>
           {title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      {dataSource.map(({ block_no, areas }, i) => (
        <div key={i}>
          {areas &&
            areas.map(
              ({ tableData, ...rest }, index) =>
                tableData && (
                  <div key={index}>
                    <Row gutter={5}>
                      {Object.keys(rest).map((it, index) => (
                        <Fragment>
                          <Col key={block_no} {...layout}>
                            <Statistic title={"Block No."} value={block_no} />
                          </Col>
                          <Col key={block_no} {...layout}>
                            <Statistic
                              title={"Area No."}
                              value={rest.area_no}
                            />
                          </Col>
                        </Fragment>
                      ))}
                    </Row>
                    {/* <ScollBarView> */}
                    <Table
                      //className="block-wise-summary-dwarf-table"
                      //className="bordered-table-head"
                      bordered
                      columns={columns}
                      dataSource={tableData}
                      size="large"
                      pagination={false}
                    />
                    {/* </ScollBarView> */}
                    <br />
                    <br />
                    {areas.length > index +1? (
                      <div className="pagebreak" />
                    ) : null}
                  </div>
                )
            )}
        </div>
      ))}
    </div>
  );
