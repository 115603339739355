import React from "react";

import RowWiseChart from "./RowWiseChart";
import DwarfAgeTypeWiseList from "./DwarfAgeTypeWiseList";
import TallAgeTypeWiseList from "./TallAgeTypeWiseList";
import BlockWiseSummary from "./BlockWiseSummary";
import RowAreaWiseSummary from "./RowAreaWiseSummary";
import AreaWiseSummary from "./AreaBlockWiseSummary";
import DwarfAgeTypeWiseSummary from "./DwarfAgeTypeWiseSummary";
import TallAgeTypeWiseSummary from "./TallAgeTypeWiseSummary";

export default [
    {
        label: "Row Wise Chart All Types Tree/Plant/Seedling/Pit/Gap",
        key: "1",
        link: "/tree-agewise-summary/row-wise-chart",
        render: () => <RowWiseChart />
    },
    {
        label: "Row Wise/Area Wise Summary All Types Tree/Plant/Seedling/Pit/Gap",
        key: "2",
        link: "/tree-agewise-summary/row-area-wise-summary",
        render: () => <RowAreaWiseSummary />
    },
    {
        label: "Area Wise/Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap",
        key: "3",
        link: "/tree-agewise-summary/area-block-wise-summary",
        render: () => <AreaWiseSummary />
    },
    {
        label: "Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap",
        key: "4",
        link: "/tree-agewise-summary/block-wise-summary",
        render: () => <BlockWiseSummary />
    },
    {
        label: "Age Wise/Type Wise Summary Dwarf Tree/Plant/Seedling",
        key: "5",
        link: "/tree-agewise-summary/dwarf-age-type-wise-summary",
        render: () => <DwarfAgeTypeWiseSummary />
    },
    {
        label: "Type Wise/Age Wise List Dwarf Tree/Plant/Seedling",
        key: "6",
        link: "/tree-agewise-summary/dwarf-age-type-wise-list",
        render: () => <DwarfAgeTypeWiseList />
    },
    {
        label: "Age Wise/Type Wise Summary Tall Tree/Plant/Seedling",
        key: "7",
        link: "/tree-agewise-summary/tall-age-type-wise-summary",
        render: () => <TallAgeTypeWiseSummary />
    },
    {
        label: "Type Wise/Age Wise List Tall Tree/Plant/Seedling",
        key: "8",
        link: "/tree-agewise-summary/tall-age-type-wise-list",
        render: () => <TallAgeTypeWiseList />
    }
];