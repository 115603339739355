import React from "react";
import { Table, Col, Statistic, Row } from "antd";

export default ({
  dwarfs,
  pitGap,
  talls,
  columns,
  headerItems = [],
  pagebreak,
  lastIndex,
  key
}) => {
  const layout = {
    xs: {
      span: 6
    },
    sm: {
      span: 6
    },
    lg: {
      span: 3
    },
    md: {
      span: 3
    }
  };

  return (
    <div className={pagebreak}>
      <Row gutter={5}>
        {headerItems.map(({ title, value }, i) =>
          value ? (
            <Col {...layout}>
              <Statistic title={title} value={value} />
            </Col>
          ) : (
            <p className="block-wise-summary-farm-title">{title}</p>
          )
        )}
      </Row>
      <hr />
      <br />
      <div>
        <Row gutter={8}>
          {dwarfs ? 
          <Col span={9}>
            <Table
              className="block-wise-summary-dwarf-table bold-last-row width-last-row"
              columns={columns.concat({
                title: "Dwarf Total",
                dataIndex: "total",
                render: it => (it ? it : 0)
              })}
              dataSource={dwarfs}
              pagination={false}
            />
          </Col> : null}
          {talls ?
          <Col span={9}>
            <Table
              //className="block-wise-summary-tall-table"
              className="block-wise-summary-dwarf-table bold-last-row width-last-row"
              columns={columns.concat({
                title: "Tall Total",
                dataIndex: "total",
                render: it => (it ? it : 0)
              })}
              dataSource={talls}
              pagination={false}
            />
          </Col> : null }
          {pitGap ?
          <Col span={6}>
            <Table
              //className="block-wise-summary-pitgap-table"
              className="block-wise-summary-dwarf-table bold-last-row width-last-row"
              columns={[
                // { title: "", dataIndex: "total" },
                { title: "Pit", dataIndex: "pit", render: it => (it ? it : 0) },
                { title: "Gap", dataIndex: "gap", render: it => (it ? it : 0) },
                {
                  title: "Total",
                  dataIndex: "total",
                  render: it => (it ? it : 0)
                }
              ]}
              dataSource={pitGap}
              pagination={false}
            />
          </Col> : null}
        </Row>
      </div>
      {parseInt(key) !== parseInt(lastIndex) - 1 ? (
        <div>
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};
