import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, Tooltip, notification, Icon, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter, sortBy } from "lodash";

import { sortFunction } from "../../../../utility/sorter";

class BlockNumber extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    btnLoad: false,
    pagination: true,
    editData: null,
    isEdit: false,
    blockData: [],
    blockUuid: null,
    blockNum: null,
    blockNumSuffix: null,
    tableLoader: false,
    addPopulateData: null
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchBlock();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Block Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Block-Number");
  }

  fetchBlock = () =>
    fetchApi("/blocks", "get").then(data =>
      this.setState({
        tableLoader: false,
        data: sortFunction(data, "blockNoFull")
      })
    );

  addModalToggle = () => {
    let { editData, addModal } = this.state;
    if (addModal) {
      this.setState(
        {
          addModal: false,
          editData: null,
          isEdit: false
        }
        //() => this.fetchBlock()
      );
    } else {
      this.setState({ addModal: !this.state.addModal });
    }
  };

  clearFilds = () => {
    this.setState({
      addPopulateData: {
        blockNo: null,
        blockName: null,
        startTree: null,
        endTree: null
        // blockNumSuffix: null,
        // startTreeSuffix: null,
        // endTreeSuffix: null
      },
      blockNumSuffix: null,
      startTreeSuffix: null,
      endTreeSuffix: null
    });
  };

  addFormHandler = ({
    blockNoSuffix,
    blockNo,
    startTree,
    endTree,
    startTreeSuffix,
    endTreeSuffix,
    ...val
  }) => {
    notification.destroy();
    let {
      isEdit,
      blockNoList,
      blockUuid,
      blockNum,
      blockNumSuffix
    } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/blocks", "post", {
        blockSuffix: blockNoSuffix == "--All--" ? null : blockNoSuffix,
        blockNo: parseInt(blockNo),
        startTree: parseInt(startTree),
        startTreeSuffix: startTreeSuffix == "--All--" ? null : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix: endTreeSuffix == "--All--" ? null : endTreeSuffix,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added a Block"
            });
            this.fetchBlock();
            this.clearFilds();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add a Block"
          });
        }

        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/blocks/${blockUuid}`, "put", {
        blockNo: parseInt(blockNum),
        blockSuffix:
          blockNumSuffix == "--All--" || blockNumSuffix == ""
            ? null
            : blockNumSuffix,
        startTree: parseInt(startTree),
        startTreeSuffix:
          startTreeSuffix == "--All--" || startTreeSuffix == ""
            ? null
            : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix:
          endTreeSuffix == "--All--" || endTreeSuffix == ""
            ? null
            : endTreeSuffix,
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully updated a Block"
          });
          this.fetchBlock();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update a Block"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onEditClickHandler = item => {
    let { startTreeSuffix, endTreeSuffix, ...rest } = item;
    Promise.all([fetchApi(`/blocks?blockNo=${item.blockNoFull}`, "get")]).then(
      data => {
        this.setState(
          {
            isEdit: true,
            blockData: data[0]
          },
          () => {
            this.setState({
              blockUuid: this.state.blockData[0].uuid,
              blockNum: item.blockNo,
              blockNumSuffix: item.blockSuffix,
              addModal: true,
              editData: {
                startTreeSuffix: startTreeSuffix ? startTreeSuffix : "--All--",
                endTreeSuffix: endTreeSuffix ? endTreeSuffix : "--All--",
                ...rest
              }
            });
          }
        );
      }
    );
  };

  generateExcel = () => {
    let { block_uuid } = this.state;
    fetchExcelApi(`/blocks`, "get").then();
  };

  render() {
    let {
      addModal,
      data,
      btnLoad,
      editData,
      pagination,
      tableLoader,
      isEdit,
      addPopulateData
    } = this.state;

    const columns = [
      {
        title: "Block No.",
        dataIndex: "blockNoFull"
      },
      {
        title: "Supervisor Name",
        dataIndex: "blockName",
        render: it => (it ? it : "-")
      },
      {
        title: "Start Tree No.",
        dataIndex: "startTreeNoFull"
      },
      {
        title: "End Tree No.",
        dataIndex: "endTreeNoFull"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "blockName",
          placeholder: "Supervisor Name",
          type: "name",
          required: false
        },
        {
          name: "startTree",
          placeholder: "Start Tree No.",
          type: "number",
          postfix: true
        },
        {
          name: "endTree",
          placeholder: "End Tree No.",
          type: "number",
          postfix: true
        }
      ];

    const _formItems = isEdit
      ? [
        {
          name: "blockNoFull",
          placeholder: "Block No.",
          disabled: true
        }
      ].concat(formItems)
      : [
        {
          name: "blockNo",
          placeholder: "Block No.",
          type: "number",
          postfix: true
        }
      ].concat(formItems);

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Block Number Master"
        buttonList={buttonList}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        btnLoad={btnLoad}
        editData={editData ? editData : null}
        formItems={_formItems}
        isEdit={isEdit}
        addPopulateData={addPopulateData}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Block Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
            : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              rowClassName="pagebreak"
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default BlockNumber;
