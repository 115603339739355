import React, { Component, Fragment } from "react";
import { Table, Col, Statistic, Descriptions, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};
const style = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center"
  //minWidth: "200px"
};
const stylebackground = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center",
  //minWidth: "200px",
  background: "#fafafa"
};

let columns = [
  {
    title: "Tree No.",
    dataIndex: "tree_no_full",
    key: "treeNo"
    //render: it => (it ? it : "-")
  },
  {
    title: "Spathe No.",
    dataIndex: "spathe_no",
    key: "spathNo"
    //render: it => (it ? it : "-")
  },
  // {
  //   title: "P.M",
  //   children: [
  //     {
  //       title: "Y/N",
  //       key: "P.M",
  //       dataIndex: "premature_spathe",
  //       render: it => (it ? "Yes" : "No")
  //     }
  //   ]
  // },
  {
    title: "Date Of Emasculation",
    dataIndex: "dt_emasculation",
    key: "dt_emasculation",
    render: it => moment(it).format("DD/MM/YYYY")
  },
  // {
  //   title: "Date Of Appearance",
  //   dataIndex: "dt_appearance",
  //   key: "dt_appearance",
  //   render: it => moment(it).format("DD/MM/YYYY")
  // },
  {
    title: "No Of Buttons",
    dataIndex: "buttons",
    key: "buttons"
    //render: it => (it ? it : "-")
  },
  {
    title: "Actual Nuts Counts",
    children: [
      {
        title: "Due Date",
        key: "dt_due",
        dataIndex: "dt_due",
        render: it => moment(it).format("DD/MM/YYYY")
      },
      {
        title: "Quantity",
        key: "actual_nuts",
        dataIndex: "actual_nuts"
        //render: it => moment(it).format("DD/MM/YYYY")
      }
    ]
  }
];

// let farmColumns=[
//   {
//     title: "Tree No.",
//     dataIndex: "tree_no_full"
//     //render: it => (it ? it : "-")
//   },
//   {
//     title: "Spathe No.",
//     dataIndex: "spathe_No"
//     //render: it => (it ? it : "-")
//   },
//   {
//     title: "Spathe No.",
//     dataIndex: "spathe_No"
//     //render: it => (it ? it : "-")
//   },
// ]

export default ({ dataSource, farmTotal, fromdate, todate }) => (
  <div>
    <h3>
      Dwarf Tree 120 Days Count - List &nbsp;&nbsp;{moment(fromdate).format("DD/MM/YYYY")} - {moment(todate).format("DD/MM/YYYY")}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    {dataSource.map(({ block_no_full, areas, block_total }, index) => (
      <div key={index}>
        {areas &&
          areas.map(({ area_no, area_total, trees }, j) => (
            <div className={index == 0 && j == 0 ? "" : "pagebreak"}>
              {trees && (
                <div>
                  <Col key={1} {...layout}>
                    <Statistic title="Block No." value={block_no_full} />
                  </Col>
                  <Col key={2} {...layout}>
                    <Statistic title="Area No." value={area_no} />
                  </Col>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Table
                    //className="block-wise-summary-dwarf-table"
                    //rowKey={record => record.tree_no_full}
                    className="bordered-table-head"
                    bordered
                    rowKey={
                      "" +
                      (record => record.tree_no_full) +
                      (record => record.spathe_no)
                    }
                    key={"table" + j}
                    size="small"
                    columns={columns}
                    bordered
                    dataSource={trees}
                    pagination={false}
                  />
                  <br></br>
                  {area_total && (
                    <div>
                      <Row
                      //gutter={[32, 16]}
                      >
                        <Col span={10} style={stylebackground}>
                          <h3>{"120 Days Count in Area " + area_no}</h3>
                        </Col>
                        <Col span={7} style={stylebackground}>
                          <h3>Entries </h3>
                        </Col>
                        <Col span={7} style={stylebackground}>
                          <h3>Count</h3>
                        </Col>
                      </Row>

                      {area_total.map(({ actual_nuts, code, entries }, i) => (
                        <Row
                        //gutter={[32, 16]} key={i}
                        >
                          <Col span={10} style={stylebackground}>
                            {code ? (
                              <h3>{"Type " + code}</h3>
                            ) : (
                                <h3>{"Area Wise Total 120 Days Count"}</h3>
                              )}
                          </Col>
                          <Col span={7} style={style}>
                            <h3>{entries ? entries : "-"}</h3>
                          </Col>
                          <Col span={7} style={style}>
                            <h3>{actual_nuts ? actual_nuts : "-"}</h3>
                          </Col>
                        </Row>
                      ))}
                      {j == areas.length - 1 ? (
                        <br />
                      ) : (
                          <Fragment><br /><br /></Fragment>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}

        {block_total && (
          <div>
            <Row
            //gutter={[32, 16]}
            >
              <Col span={10} style={stylebackground}>
                <h3>{"120 Days Count in Block " + block_no_full}</h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Entries </h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Counts </h3>
              </Col>
            </Row>

            {block_total.map(({ actual_nuts, code, entries }, i) => (
              <Row
              //gutter={[32, 16]} key={i}
              >
                <Col span={10} style={stylebackground}>
                  {code ? (
                    <h3>{"Type " + code}</h3>
                  ) : (
                      <h3>{"Block Wise Total 120 Days Count"}</h3>
                    )}
                </Col>
                <Col span={7} style={style}>
                  <h3>{entries ? entries : "-"}</h3>
                </Col>
                <Col span={7} style={style}>
                  <h3>{actual_nuts ? actual_nuts : "-"}</h3>
                </Col>
              </Row>
            ))}
            {index == dataSource.length - 1 ? (
              <br />
            ) : (
                <Fragment><br /><br /></Fragment>
              )}
          </div>
        )}
      </div>
    ))}
    {farmTotal && (
      <div>
        <Row
        //gutter={[32, 16]}
        >
          <Col span={10} style={stylebackground}>
            <h3>{"120 Days Count in Farm"}</h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Entries </h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Counts</h3>
          </Col>
        </Row>

        {farmTotal.map(({ actual_nuts, code, entries }, i) => (
          <Row
          //gutter={[32, 16]} key={i}
          >
            <Col span={10} style={stylebackground}>
              {code ? (
                <h3>{"Type " + code}</h3>
              ) : (
                  <h3>{"Farm Wise Total 120 Days Count"}</h3>
                )}
            </Col>
            <Col span={7} style={style}>
              <h3>{entries ? entries : "-"}</h3>
            </Col>
            <Col span={7} style={style}>
              <h3>{actual_nuts ? actual_nuts : "-"}</h3>
            </Col>
          </Row>
        ))}
      </div>
    )}
    <br></br>
    <br></br>
    <br></br>
  </div>
);
