const resolveTree = (types, totals, isFarm, isBlock) =>
  types.concat({ type: isFarm? "Farm Total": isBlock? "Block Total" : "Area Total", data: totals }).map(each => {
    let tempVar = { type: each.type ? each.type : "Block Total" };
    if (each.data.length > 0) {
      each.data.forEach(({ stage_code, count }) => {
        if (stage_code == 1) tempVar.seedling = count;
        else if (stage_code == 2) tempVar.plant = count;
        else if (stage_code == 3) tempVar.tree = count;
        else tempVar.total = count;
      });
    }
    return tempVar;
  });

// export const parserFunction = arr =>
//   arr.map(({ dwarf, tall, gap, pit, ...it }) => {
//     let dwarfs = resolveTree(dwarf.types, dwarf.totals),
//       talls = resolveTree(tall.types, tall.totals);

//     return {
//       dwarfs,
//       talls,
//       pitGap: { total: "Total", gap: gap[0].count, pit: pit[0].count },
//       ...it
//     };
//   });

  export const parserFunction = (arr, isFarm = null, isBlock = null) =>
  arr.map(({ dwarf, tall, gap, pit, 
    //total, 
    totals, ...it }) => {
      if((pit[0].count > 0 || gap[0].count)|| dwarf.types.length>0 || tall.types.length > 0){
        let tempPitGap = [{ total: totals[0], gap: gap[0].count, pit: pit[0].count }];
        let dwarfLength=dwarf.types.length,
        tallLength=tall.types.length;
        if(tallLength>dwarfLength){
          let i=dwarfLength;
          while(i<tallLength){
            let data= [{"stage_code": 1, "count": null},{"stage_code": 2, "count": null},{"stage_code": 3, "count": null}, {"stage_code": null, "count": null}];
            let type= "-";
            dwarf.types.push({type,data});
            i++;
          }
          
          let j=0;
          while(j<tallLength-1){
            tempPitGap.push({ total: totals[j+1], gap: null, pit: null })
            j++;
          }
          tempPitGap.push({ total: totals[totals.length-1], gap: gap[0].count, pit: pit[0].count })
          console.log("dtempPitGap",tempPitGap)

        }else if(dwarfLength>tallLength){
          let i=tallLength;
          while(i<dwarfLength){
            let data= [{"stage_code": 1, "count": null},{"stage_code": 2, "count": null},{"stage_code": 3, "count": null}, {"stage_code": null, "count": null}];
            let type= "-";
            tall.types.push({type,data});
            i++;
          }
          let j=0;
          while(j<dwarfLength-1){
            tempPitGap.push({ total: totals[j+1], gap: null, pit: null })
            j++;
          }
          tempPitGap.push({ total: totals[totals.length-1], gap: gap[0].count, pit: pit[0].count })
          console.log("ttempPitGap",tempPitGap)
        }else if(dwarfLength==0 && tallLength==0){
        //do nothing
        }else{
          let j=1;
          while(j<dwarfLength){
            tempPitGap.push({ total: totals[j+1], gap: null, pit: null })
            j++;
          }
          tempPitGap.push({ total: totals[totals.length-1], gap: gap[0].count, pit: pit[0].count })
          console.log("ttempPitGap",tempPitGap)
        }
        let dwarfs = resolveTree(dwarf.types, dwarf.totals, isFarm, isBlock);
        let talls = resolveTree(tall.types, tall.totals, isFarm, isBlock);
      return {
        dwarfs,
        talls,
        pitGap: tempPitGap,
        //pitGap: { total: "Total", gap: gap[0].count, pit: pit[0].count },
        //total,
        ...it
      };
    }
    return []
  });

export const parseData = arr =>
  arr.map(({ block_no, areas, ...rest }) => ({
    block_no,
    areas: parserFunction(areas, null, null),
    total: rest.total ? parserFunction([rest.total],null,true) : []
  }));
