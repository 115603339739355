import React, { Component, Fragment } from "react";
import { Form, Button, Row, Col, Select as AntDSelect } from "antd";
import { typeList } from "./typeList";
import PropTypes from "prop-types";
import { keys, isEqual, isArray } from "lodash";
import moment from "moment";

import Input from "../../components/Custom/Input";
import Select from "../../components/Custom/Select";
import DatePicker from "../../components/Custom/DatePicker";
import NumericInput from "../../components/Custom/NumericInput";

import "./index.less";
import FormItem from "antd/lib/form/FormItem";

const { Option } = AntDSelect;

class AddForm extends Component {
  componentDidMount() {
    let { form, editData, addPopulateData } = this.props;
    if (editData)
      form.setFieldsValue({
        ...editData
      });
    if (addPopulateData) {
      form.setFieldsValue({
        ...addPopulateData
      });
    }
  }

  componentWillReceiveProps(nextProp) {
    let { addPopulateData, form } = nextProp;
    if (addPopulateData) {
      let temp = keys(addPopulateData),
        checkObject = {};
      temp.forEach(it => {
        checkObject[it] = form.getFieldsValue()[it];
      });
      if (!isEqual(checkObject, addPopulateData)) {
        form.setFieldsValue({
          ...addPopulateData
        });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let { onAdd, form } = this.props;
    this.props.form.validateFields((err, values) => {
      //console.log(values);

      if (!err) {
        let temp = null;
        if (onAdd) {
          temp = onAdd(values);
          //console.log("Temp", temp);
        }

        form.setFieldsValue({
          rowNoSuffix: "--All--",
          blockNoSuffix: "--All--",
          startTreeSuffix: "--All--",
          endTreeSuffix: "--All--",
          treeNoSuffix: "--All--"
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form,
      {
        onCancel,
        formItems,
        btnLoad,
        isEdit,
        isTransfer,
        isDelete
      } = this.props;

    // const postfixSelector = name =>
    //   getFieldDecorator(name + "Suffix", {
    //     initialValue: typeList[0]
    //   })(
    //     <Select>
    {
      typeList.map((it, i) => (
        <Option key={i} value={it}>
          {it}
        </Option>
      ));
    }
    //     </Select>
    //   );

    return (
      <Form
        onSubmit={this.handleSubmit}
        className="page-builder-add-form"
        noValidate
        autocomplete="off"
      >
        {formItems.map(
          (
            {
              name,
              placeholder,
              type = null,
              postfix = false,
              listData = [],
              changeHandler,
              onPanelChange,
              onOpenChange,
              disabled = false,
              required = true,
              readonly = null,
              value,
              ...rest
            },
            i
          ) =>
            postfix ? (
              <Fragment>
                <Row className="form-items-with-surfix" gutter={4}>
                  <Col span={18}>
                    <FormItem>
                      {getFieldDecorator(name, {
                        rules: [
                          {
                            required: required,
                            message: "This field is required"
                          },
                          {
                            pattern: /^[1-9]\d*$/,
                            message: `Enter valid ${placeholder}`
                          }
                        ]
                      })(
                        <NumericInput
                          disabled={disabled}
                          label={placeholder}
                          required={required}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem className="page-builder-add-form-surfix-container">
                      {/* {console.log("tree No", name + "Suffix")} */}
                      {getFieldDecorator(name + "Suffix", {
                        initialValue: typeList[0]
                      })(
                        <AntDSelect>
                          {typeList.map((it, i) => (
                            <Option key={i} value={it}>
                              {it}
                            </Option>
                          ))}
                        </AntDSelect>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Form.Item key={i} className={type === "tagNum" ? "tagNum" : ""}>
                {getFieldDecorator(
                  name,
                  listData.length > 0
                    ? {
                      rules: [
                        {
                          required: required,
                          message: "This field is required"
                        }
                      ]
                    }
                    : type === null
                      ? {
                        rules: !disabled
                          ? [
                            {
                              required: required,
                              message: "This field is required"
                            },
                            {
                              //pattern: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
                              pattern: /^(([A-Z]{1}[a-z]{1,30}[ ]{0,1}|[A-Z]{1}[\']{1}[A-Za-z]{1,1}[a-z]{1,30}[- ]{0,1}){1,5})+$/,
                              message: `Enter valid ${placeholder}`
                            }
                          ]
                          : []
                      }
                      : type === "number"
                        ? {
                          rules: !disabled
                            ? [
                              {
                                required: required,
                                message: "This field is required"
                              },
                              {
                                pattern: /^\d+(\.\d{1,2})?$/,
                                message: `Enter valid ${placeholder}`
                              }
                            ]
                            : []
                        }
                        : type === "alphanumeric"
                          ? {
                            rules: !disabled
                              ? [
                                {
                                  required: required,
                                  message: "This field is required"
                                },
                                {
                                  pattern: /^([a-zA-Z0-9-]+\s)*[a-zA-Z0-9-]+$/,
                                  message: `Enter a Valid ${placeholder}`
                                }
                              ]
                              : []
                          }
                          : type === "name"
                            ? {
                              rules: !disabled
                                ? [
                                  {
                                    required: required,
                                    message: "This field is required"
                                  },
                                  {
                                    pattern: /^[a-zA-Z ]{2,30}[']{0,1}[a-zA-Z ]{1,30}$/,
                                    message: `Enter a Valid ${placeholder}`
                                  }
                                ]
                                : []
                            }
                            : type === "tagNum"
                              ? {
                                rules: !disabled
                                  ? [
                                    {
                                      required: required,
                                      message: "This field is required"
                                    },
                                    { max: 20, message: 'Tag Number cannot exceed 20 characters.' }]
                                  : []
                              }
                              : {
                                rules: [
                                  {
                                    required: required,
                                    message: "This field is required"
                                  }
                                ]
                              }
                )(
                  listData.length > 0 ? (
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      onChange={changeHandler}
                      disabled={disabled}
                      selectItems={listData}
                      label={placeholder}
                      required={required}
                      {...rest}
                    />
                  ) : type == "date_yesterday" ? (
                    <DatePicker
                      getCalendarContainer={trigger => trigger.parentNode}
                      disabledDate={dt => {
                        return (
                          //dt < moment().subtract(2, "day") ||
                          dt <=
                          moment()
                            .subtract(1, "month")
                            .endOf("month") || dt > moment().valueOf()
                        );
                      }}
                      disabled={disabled}
                      format="DD/MM/YYYY"
                      onChange={changeHandler}
                      label={placeholder}
                      required={required}
                    />
                  ) : type == "date" ? (
                    <DatePicker
                      getCalendarContainer={trigger => trigger.parentNode}
                      disabledDate={dt => dt > moment().valueOf()}
                      disabled={disabled}
                      format="DD/MM/YYYY"
                      onChange={changeHandler}
                      label={placeholder}
                      required={required}
                    />
                  ) : type == "year" ? (
                    <DatePicker
                      getCalendarContainer={trigger => trigger.parentNode}
                      //disabledDate={dt => dt > moment().valueOf()}
                      disabled={disabled}
                      mode="year"
                      format="YYYY"
                      onPanelChange={onPanelChange}
                      onChange={onPanelChange}
                      label={placeholder}
                      required={required}
                      onOpenChange={onOpenChange}
                      {...rest}
                    />
                  ) : type == "number" ? (
                    <NumericInput
                      disabled={disabled}
                      label={placeholder}
                      required={required}
                      {...rest}
                    />
                  ) : type == "hidden" ? (
                    <></>
                  ) : (
                    <Input
                      disabled={disabled}
                      //type={type}
                      // addonAfter={postfix ? postfixSelector(name) : null}
                      label={placeholder}
                      required={required}
                      value={value}
                      readonly={readonly}
                    />
                  )
                )}
              </Form.Item>
            )
        )}
        <Form.Item>
          <Row type="flex" justify="end" gutter={5}>
            <Col>
              <Button onClick={onCancel}>Cancel</Button>
            </Col>
            <Col>
              <Button htmlType="submit" loading={btnLoad}>
                {isEdit
                  ? "Update"
                  : isDelete
                    ? "Delete"
                    : isTransfer
                      ? "Transfer"
                      : "Add"}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }
}

AddForm.defaultProps = {
  onCancel: () => { },
  onAdd: () => { },
  formItems: []
};

AddForm.propTypes = {
  onCancel: PropTypes.func,
  onAdd: PropTypes.func,
  formItems: PropTypes.array
};

export default Form.create({ name: "add block number form" })(AddForm);
