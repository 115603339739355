import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  Icon,
  message,
  notification,
  Popconfirm,
  Tooltip,
  Spin
} from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter } from "lodash";
import { fetchApi } from "../../../../services/api";
import { formatDate } from "../../../../utility/date";
import moment from "moment";

class DwarfMale extends Component {
  componentRef = React.createRef();
  state = {
    blockNoList: [],
    block_uuid: null,
    data: [],
    dynamicTableData: [],
    blockNumber: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    treeNoList: [],
    treeNumber: null,
    tree_uuid: null,
    pagination: true,
    dateOfPollenCollected: null,
    addModal: false,
    editData: null,
    btnLoad: false,
    isEdit: false,
    isDelete: false,
    addPopulateData: null,
    loading: false
  };

  componentDidMount() {
    this.dwarfMalePollens();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Dwarf Male Pollen Collection</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Pollen-Collection");
  }

  dwarfMalePollens = (reload = true) => {
    if (reload) {
      this.setState({ loading: true, dynamicTableData: [], pagination: false });
      let {
        block_uuid,
        area_uuid,
        tree_uuid,
        treeNoList,
        dateOfPollenCollected
      } = this.state,
        url = "/dwarf_male_pollens";
      if (block_uuid) url += `?tree.block.uuid=${block_uuid}`;
      if (area_uuid) url += `&tree.area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;

      if (dateOfPollenCollected)
        if (block_uuid) {
          url += `&dtPollenCollected%5Bbefore%5D=${moment(
            dateOfPollenCollected
          ).format("YYYY/MM/DD")}`;
          url += `&dtPollenCollected%5Bafter%5D=${moment(
            dateOfPollenCollected
          ).format("YYYY/MM/DD")}`;
        } else {
          url += `?dtPollenCollected%5Bbefore%5D=${moment(
            dateOfPollenCollected
          ).format("YYYY/MM/DD")}`;
          url += `&dtPollenCollected%5Bafter%5D=${moment(
            dateOfPollenCollected
          ).format("YYYY/MM/DD")}`;
        }
      if (block_uuid && treeNoList.length < 1) {
        // console.log("inside the loop");
        this.fetchFormTreeList();
      }
      fetchApi(url, "get")
        .then(data =>
          data
            ? this.setState(
              {
                data: data.map(({ dtPollenCollected, ...it }) => {
                  return {
                    dtPollenCollected: moment(dtPollenCollected).format(
                      "DD/MM/YYYY"
                    ),
                    date: dtPollenCollected,
                    ...it
                  };
                }),
                pagination: true
              },
              () => this.tableFilterHandler()
            )
            : message.error("Internal Server Error!")
        )
        .then(() => this.setState({ loading: false }));
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchFormTreeList = () => {
    let { blockNoList, blockNumber, block_uuid, area_uuid } = this.state;
    let url = `/dwarves?stageCode=3&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    fetchApi(url, "get").then(res =>
      this.setState({
        treeNoList: sortFunction(res, "treeNoFull"),
        treeNumber: null,
        tree_uuid: null
      })
    );
  };

  clear = () => {
    this.setState(
      {
        blockNumber: null,
        block_uuid: null,
        data: [],
        dynamicTableData: [],
        areaNoList: [],
        areaNumber: null,
        area_uuid: null,
        treeNoList: [],
        treeNumber: null,
        tree_uuid: null,
        pagination: true,
        dateOfPollenCollected: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        isEdit: false,
        isDelete: false,
        loading: false
      },
      () => this.dwarfMalePollens()
    );
  };

  addModalToggle = () => {
    message.destroy();
    let {
      isAdded,
      dateOfPollenCollected,
      treeNumber,
      tree_uuid,
      blockNumber,
      block_uuid,
      areaNumber,
      area_uuid,
      isEdit,
      isDelete,
      addModal
    } = this.state;
    if (addModal) {
      this.setState(
        {
          addPopulateData: isAdded
            ? { blockNo: blockNumber, tree: treeNumber }
            : null,
          addModal: false,
          editData: null,
          // areaNumber: isEdit ? areaNumber : null,
          // area_uuid: isEdit ? area_uuid : null,
          // treeNumber: isEdit ? treeNumber : null,
          // tree_uuid: isEdit ? tree_uuid : null,
          addPopulateData: {
            blockNo: !isEdit || !isAdded || !isDelete ? blockNumber : null
          },
          blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
          block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
          areaNumber: isEdit || isDelete ? areaNumber : null,
          area_uuid: isEdit || isDelete ? area_uuid : null,
          treeNumber: isEdit || isDelete ? treeNumber : null,
          tree_uuid: isEdit || isDelete ? tree_uuid : null,
          dateOfPollenCollected:
            isEdit || isDelete ? dateOfPollenCollected : null,
          isEdit: false,
          isDelete: false,
          isAdded: false
        },
        () => {
          this.setState({ isAdded: false });
        }
        //() => this.dwarfMalePollens()
      );
    } else {
      if (!blockNumber) {
        message.info("Select Block No.");
      } else {
        // if (dateOfPollenCollected || treeNumber) {
        this.setState(
          {
            editData: Object.assign(
              {},
              dateOfPollenCollected
                ? { dtPollenCollected: dateOfPollenCollected }
                : {},
              //treeNumber ? { tree: treeNumber } : {},
              cloneDeep(treeNumber) ? { tree: cloneDeep(treeNumber) } : {},
              cloneDeep(this.state.editData)
            )
          },
          () => this.setState({ addModal: !this.state.addModal })
        );
        //}
      }
    }
  };

  clearFields = () => {
    let { blockNumber } = this.state;
    this.setState({
      area_uuid: null,
      tree_uuid: null,
      areaNumber: null,
      treeNumber: null,
      dateOfPollenCollected: null,
      addPopulateData: {
        blockNo: blockNumber ? blockNumber : null,
        //areaNo: null,
        tree: null,
        //dtPollenCollected: null,
        spikesQty: null,
        flowerWt: null,
        pollenWt: null,
        viability: null
      }
    });
  };

  addFormHandler = ({
    tree,
    spikesQty,
    flowerWt,
    pollenWt,
    viability,
    ...values
  }) => {
    notification.destroy();
    let { treeNoList, isEdit, isDelete, editData } = this.state;
    this.setState({ btnLoad: true });
    if (isDelete) {
      fetchApi(`/dwarf_male_pollens/${editData.uuid}`, "put", {
        dtDeleted: moment().format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully Deleted Dwarf Male Pollen"
          });
          this.dwarfMalePollens();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Delete Dwarf Male Pollen"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else if (isEdit) {
      fetchApi(`/dwarf_male_pollens/${editData.uuid}`, "put", {
        tree: editData.treeUuid,
        spikesQty: parseFloat(spikesQty),
        flowerWt: parseFloat(flowerWt),
        pollenWt: parseFloat(pollenWt),
        viability: parseFloat(viability),
        dtPollenCollected: moment(values.dtPollenCollected._d).format(
          "YYYY-MM-DD"
        )
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully Updated Dwarf Male Pollen"
          });
          this.dwarfMalePollens();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Update Dwarf Male Pollen"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      fetchApi("/dwarf_male_pollens", "post", {
        tree: "/dwarves/" + filter(treeNoList, { treeNoFull: tree })[0].uuid,
        //area: "/areas/" + filter(areaNoList, { areaNo: areaNumber })[0].uuid,
        // block:
        //   "/blocks/" +
        //   filter(blockNoList, { blockNoFull: blockNumber })[0].uuid,
        spikesQty: parseFloat(spikesQty),
        flowerWt: parseFloat(flowerWt),
        pollenWt: parseFloat(pollenWt),
        viability: parseFloat(viability),
        ...values,
        dtPollenCollected: moment(values.dtPollenCollected).format("YYYY-MM-DD")
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Dwarf Male Pollen Collection"
            });
            this.setState({ isAdded: true }, () => this.clearFields());
            this.dwarfMalePollens();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Dwarf Male Pollen Collection"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNumber: null,
            treeNoList: [],
            addPopulateData: {
              blockNo: it
            }
          },
          () => this.dwarfMalePollens()
        )
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null,
          addPopulateData: { blockNo: null }
        },
        () => this.dwarfMalePollens()
      );
    }
  };

  onAreaChangeHandler = (it, reload = true) => {
    let { areaNoList, blockNumber, blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/dwarves?stageCode=3&area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }
        `,
        "get"
      ).then(res => {
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null
          },
          () => this.dwarfMalePollens(reload)
        );
      });
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          treeNumber: null,
          treeNoList: [],
          tree_uuid: null
        },
        () => this.dwarfMalePollens(reload)
      );
    }
  };

  onTreeChangeHandler = (it, reload = true) => {
    let { treeNoList } = this.state;
    if (it) {
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].uuid,
          areaNumber: cloneDeep(treeNoList).filter(
            item => item.treeNoFull == it
          )[0].areaNo
          // addPopulateData: {
          //   blockNo: blockNumber ? blockNumber : null,
          //   //areaNo: areaNumber ? areaNumber : null,
          //   tree: it
          //   //dtAnalysis: dateOfAnalyses ? dateOfAnalyses : null
          // }
        },
        () => this.dwarfMalePollens(reload)
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
          // addPopulateData: {
          //   blockNo: blockNumber ? blockNumber : null,
          //   //areaNo: areaNumber ? areaNumber : null,
          //   tree: null
          //   //dtAnalysis: dateOfAnalyses ? dateOfAnalyses : null
          // }
        },
        () => this.dwarfMalePollens(reload)
      );
    }
  };

  dateOfPollenCollectedHandler = (date, dateString) => {
    if (date) {
      this.setState({ dateOfPollenCollected: date }, () =>
        this.dwarfMalePollens()
      );
    } else {
      this.setState({ dateOfPollenCollected: null }, () =>
        this.dwarfMalePollens()
      );
    }
  };

  tableFilterHandler = () => {
    let { dateOfPollenCollected, data, treeNumber } = this.state,
      filterJson = {};

    //if (treeNumber) { filterJson.treeNo = parseInt(treeNumber); }
    if (treeNumber) { filterJson.treeNo = treeNumber; }
    if (dateOfPollenCollected)
      filterJson.dtPollenCollected = dateOfPollenCollected.format("DD/MM/YYYY");

    //console.log(filterJson, dateOfPollenCollected);
    this.setState({
      dynamicTableData: filter(data, filterJson)
    });
  };

  editHandler = ({ dtPollenCollected, date, treeNo, tree, ...it }) => {
    this.setState(
      {
        isEdit: true,
        editData: {
          dtPollenCollected: moment(date, "YYYY-MM-DD"),
          tree: treeNo,
          treeUuid: tree,
          ...it
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );
  };

  deleteHandler = ({ dtPollenCollected, date, treeNo, tree, ...it }) => {
    this.setState(
      {
        isDelete: true,
        editData: {
          dtPollenCollected: moment(date, "YYYY-MM-DD"),
          tree: treeNo,
          treeUuid: tree,
          ...it
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      areaNumber,
      dateOfPollenCollected,
      dynamicTableData,
      pagination,
      addModal,
      editData,
      btnLoad,
      isEdit,
      isDelete,
      treeNumber,
      loading,
      data,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Date Of Collection",
        key: "dtPollenCollected",
        dataIndex: "dtPollenCollected"
      },
      {
        title: "Spikes Qty.",
        key: "spikesQty",
        dataIndex: "spikesQty"
      },
      {
        title: "Flower Wt. (gms)",
        key: "flowerWt",
        dataIndex: "flowerWt"
      },
      {
        title: "Pollen Wt. (gms)",
        key: "pollenWt",
        dataIndex: "pollenWt"
      },
      {
        title: "Viability (%age)",
        key: "viability",
        dataIndex: "viability"
      },
      {
        title: "Actions",
        key: "actions",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <Icon
                className="table-action-btn"
                onClick={() => this.editHandler(it)}
                type="edit"
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.deleteHandler(it)}
                type="delete"
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];

    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "blockNo",
            placeholder: "Block",
            listData: cloneDeep(blockNoList).map(it => it.blockNo),
            disabled: true
          },
          // {
          //   name: "areaNo",
          //   placeholder: "Area",
          //   listData: cloneDeep(areaNoList).map(it => it.areaNo),
          //   disabled: blockNumber ? false : true,
          //   //value: areaNumber ? areaNumber : undefined,
          //   changeHandler: it => this.onAreaChangeHandler(it, false)
          // },
          {
            name: "tree",
            placeholder: "Tree No.",
            listData: cloneDeep(treeNoList).map(it => it.treeNoFull),
            //disabled: treeNoList.length > 0 ? false : true,
            readonly: treeNoList.length > 0 ? false : true,
            //value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it, false)
          },
          {
            name: "dtPollenCollected",
            placeholder: "Date Of Collection",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of Collection!"
          },
          {
            name: "spikesQty",
            placeholder: "Spikes Qty.",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Spikes Quantity!"
          },
          {
            name: "flowerWt",
            placeholder: "Flower Wt. (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Flower Weight!"
          },
          {
            name: "pollenWt",
            placeholder: "Pollen Wt. (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Pollen Weight!"
          },
          {
            name: "viability",
            placeholder: "Viability (%age)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of viability!"
          }
        ]
        : [
          {
            name: "blockNo",
            placeholder: "Block",
            disabled: true
          },
          {
            name: "tree",
            placeholder: "Tree No.",
            disabled: true,
            message: "Input the tree no.!"
          },
          {
            name: "dtPollenCollected",
            placeholder: "Date Of Collection",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of Collection!"
          },
          {
            name: "spikesQty",
            placeholder: "Spikes Qty.",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Spikes Quantity!"
          },
          {
            name: "flowerWt",
            placeholder: "Flower Wt. (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Flower Weight!"
          },
          {
            name: "pollenWt",
            placeholder: "Pollen Wt. (gms)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of Pollen Weight!"
          },
          {
            name: "viability",
            placeholder: "Viability (%age)",
            type: "number",
            disabled: isDelete ? true : false,
            message: "Input the number of viability!"
          }
        ];

    const buttonList =
      data.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfPollenCollected
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfPollenCollected
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    return (
      <LocationPageBuilder
        title="Dwarf Male Pollen Collection"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: blockNoList.map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Tree No.",
            listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
            disabled: areaNumber ? false : true,
            value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it)
          },
          {
            label: "Date Of Collection",
            type: "date",
            value: dateOfPollenCollected ? dateOfPollenCollected : undefined,
            changeHandler: this.dateOfPollenCollectedHandler
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        addPopulateData={addPopulateData}
        editData={editData}
        btnLoad={btnLoad}
        isEdit={isEdit}
        isDelete={isDelete}
        formItems={formItems}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={dynamicTableData}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={loading}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default DwarfMale;
