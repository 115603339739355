import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import MultiTable from "../MultiTable3";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { parseData, parserFunction } from "./parserFunctions";
import { COOKIE, getCookie } from "../../../../services/cookie";

const columns = [
  {
    title: "Type",
    dataIndex: "type"
  },
  {
    title: "Seedling",
    dataIndex: "seedling",
    render: it => (it ? it : 0)
  },
  {
    title: "Plant",
    dataIndex: "plant",
    render: it => (it ? it : 0)
  },
  {
    title: "Tree",
    dataIndex: "tree",
    render: it => (it ? it : 0)
  }
];

class AreaWiseSummary extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    loading: false,
    farmTotal: [],
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNumber: null,
    areaNoList: [],
    area_uuid: null
  };

  componentDidMount() {
    this.fetchAreaWiseSummary();
    this.fetchBlockNo();
  }

  fetchAreaWiseSummary = () => {
    this.setState({ loading: true });
    let { block_uuid, area_uuid } = this.state;
    let url = "/tree-age/area-wise-summary";
    if (block_uuid && !area_uuid) url += `?block.uuid=${block_uuid}`;
    else if (block_uuid && area_uuid)
      url += `?block.uuid=${block_uuid}&area.uuid=${area_uuid}`;
    fetchApi(url, "get").then(res => {
      if (res) {
        if (res.blocks)
          this.setState(
            {
              data: parseData(cloneDeep(res.blocks)),
              farmTotal: res.farm_total
                ? parserFunction(cloneDeep([res.farm_total]), 1)
                : []
            },
            () => console.log("data", this.state.data)
          );
        else
          this.setState({
            data: null,
            farmTotal: []
          });
      } else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  generateTables = tableData =>
    tableData.map(({ areas, block_no, ...rest }, i) => (
      <div key={i}>
        {areas.map(
          (
            { dwarfs, talls, pitGap, area_no, start_tree, end_tree },
            areaIndex
          ) =>
            pitGap ? (
              <MultiTable
                pagebreak={i == 0 && areaIndex == 0 ? "" : "pagebreak"}
                key={areaIndex}
                dwarfs={dwarfs}
                talls={talls}
                pitGap={pitGap}
                columns={columns}
                headerItems={[
                  { title: "Block No.", value: block_no },
                  { title: "Area No.", value: area_no },
                  { title: "Start Tree", value: start_tree },
                  { title: "End Tree", value: end_tree }
                ]}
              />
            ) : null
        )}
        {rest.total.map(({ dwarfs, talls, pitGap }, totalIndex) =>
          pitGap ? (
            <MultiTable
              pagebreak="pagebreak"
              key={totalIndex}
              dwarfs={dwarfs}
              talls={talls}
              pitGap={pitGap}
              columns={columns}
              headerItems={[{ title: "Block Total", value: block_no }]}
            />
          ) : null
        )}
      </div>
    ));

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(each => each.blockNoFull === it)[0]
              .uuid
          : null,
        areaNumber: null,
        areaNoList: []
      },
      () => this.fetchAreaWiseSummary()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(each => each.areaNo === it)[0].uuid
          : null
      },
      () => this.fetchAreaWiseSummary()
    );
  };

  generateExcel = () => {
    let { block_uuid, area_uuid } = this.state,
      url = "/tree-age/area-wise-summary";

    if (block_uuid && !area_uuid) url += `?block.uuid=${block_uuid}`;
    else if (block_uuid && area_uuid)
      url += `?block.uuid=${block_uuid}&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNumber,
      blockNoList,
      areaNumber,
      areaNoList
    } = this.state;
    let JSX =
      data && data.length > 0 ? (
        <Fragment>
          <h3>
            Area Wise/Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm :{" "}
            {getCookie(COOKIE.FARM)
              .charAt(0)
              .toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "}
          </h3>
          {this.generateTables(data)}
        </Fragment>
      ) : (
        <Empty description="No Data Found" />
      );

    const buttonList = data
      ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            //toolTip: "Suggestion: Print in Landscape",
            reference: this.componentRef
          }
        ]
      : null;
    return (
      <ReportLayout
        title="Area Wise/Block Wise Summary All Types Tree/Plant/Seedling/Pit/Gap"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
            {farmTotal.map(({ dwarfs, talls, pitGap }, index) => (
              <MultiTable
                pagebreak="pagebreak"
                key={index}
                dwarfs={dwarfs}
                talls={talls}
                pitGap={pitGap}
                columns={columns}
                headerItems={[{ title: "Farm total", value: null }]}
              />
            ))}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default AreaWiseSummary;
