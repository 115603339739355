import React, { Component } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";

import { Modal, Form, Row, Col, Button } from "antd";

import FormItem from "antd/lib/form/FormItem";
import { Typography } from "antd";
import moment from "moment";
//import { DatePicker } from "antd";

import { cloneDeep, filter } from "lodash";
import NumericInput from "../../components/Custom/NumericInput";
import DatePicker from "../../components/Custom/DatePicker";
import Input from "../../components/Custom/Input";

const { Title } = Typography;

var span = 3;
var style = {
  //margin: "5px"
  marginLeft: "5px",
  marginRight: "5px",
  marginTop: "0px",
  marginBottom: "0px"
};

var DataLength = 0;

class AddForm2 extends Component {
  state = {
    data: this.props.addData,

    error_flag: true
  };

  // check = () => {
  //   this.props.form.validateFields(err => {
  //     if (!err) {
  //       console.info("success");
  //       this.setState({ error_flag: false });
  //     } else {
  //       this.setState({ error_flag: true });
  //     }
  //   });
  // };

  componentDidMount() {
    let { form } = this.props;
    let { data } = this.state;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        form.setFieldsValue({
          ["" + i + "uuid"]: data[i].uuid,
          ["" + i + "treeNo"]: data[i].treeNo,
          ["" + i + "spatheNo"]: data[i].spatheNo,
          ["" + i + "dtEmasculation"]: moment(data[i].dtEmasculation).format(
            "DD/MM/YYYY"
          ),
          ["" + i + "dueDate"]: moment(data[i].dtEmasculation).add(4, "months"),
          ["" + i + "buttons"]: data[i].buttons

          //["" + i + "spatheNo"]: data[i].spatheNo,
        });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let { onOk } = this.props;
    let { data } = this.state;
    let length = 10;
    //console.log("Hello");
    this.props.form.validateFields((err, values) => {
      //console.log("values", values);

      if (!err) {
        if (data.length < 10) {
          length = data.length;
        }
        if (onOk) onOk(values, length);
        //console.log("data length", data.length);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { data } = this.state;
    //console.log("Data", data);
    let { onCancel } = this.props;
    return (
      <div>
        <Form
          onSubmit={this.handleSubmit}
          className="page-builder-add-form onetwenty-days-count-form"
          noValidate
          autocomplete="off"
        >
          <Row>
            <Col span={span} style={style}>
              Tree No.
            </Col>
            <Col span={span} style={style}>
              Spathe No.
            </Col>
            <Col span={5} style={style}>
              Date of Emasculation
            </Col>
            <Col span={span} style={style}>
              No. of Buttons
            </Col>
            <Col span={5} style={style}>
              Due Date
            </Col>
            <Col span={span} style={style}>
              Quantity
            </Col>
          </Row>

          {data &&
            data.map(({ treeNo, spatheNo, dtEmasculation, buttons }, index) => (
              <div>
                <Row className="noHeight">
                  <Col>
                    <FormItem>
                      {getFieldDecorator("" + index + "uuid", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        <Input
                          type="hidden"

                          //value={treeNo}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={span} style={style}>
                    <FormItem>
                      {getFieldDecorator("" + index + "treeNo", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        <Input
                          disabled={true}
                          //label={"Tree No"}

                          //value={treeNo}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={span} style={style}>
                    <FormItem className="">
                      {getFieldDecorator("" + index + "spatheNo", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        <Input
                          disabled={true}
                          //label={"Spath No"}

                          //value={spatheNo}
                          //style={{ margin: "5px" }}
                        ></Input>
                      )}
                    </FormItem>
                  </Col>

                  <Col span={5} style={style}>
                    <FormItem className="">
                      {getFieldDecorator("" + index + "dtEmasculation", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        <Input
                          disabled={true}
                          //label={"Spath No"}

                          //value={spatheNo}
                          //style={{ margin: "5px" }}
                        ></Input>
                      )}
                    </FormItem>
                  </Col>

                  <Col span={span} style={style}>
                    <FormItem className="">
                      {getFieldDecorator("" + index + "buttons", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        // <Input
                        //   disabled={true}
                        //   //label={"buttons"}

                        //   //value={spatheNo}
                        //   //style={{ margin: "5px" }}
                        // ></Input>
                        <Input
                          disabled={true}
                          //type={type}
                          // addonAfter={postfix ? postfixSelector(name) : null}
                          //label={placeholder}
                          required={false}
                          //value={value}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col span={5} style={style}>
                    <FormItem className="">
                      {getFieldDecorator("" + index + "dueDate", {
                        rules: [
                          {
                            required: true,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        <DatePicker
                          //placeholder=""
                          format="DD/MM/YYYY"
                          //value={state.value}
                          // onFocus={this.activateField}
                          // onBlur={this.disableField}
                          // allowClear
                          // {...rest}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col span={span} style={style}>
                    <FormItem className="">
                      {getFieldDecorator("" + index + "actualNuts", {
                        rules: [
                          {
                            required: false,
                            message: "This field is required"
                          }
                          // {
                          //   pattern: /^[1-9]\d*$/,
                          //   message: `Please enter valid ${placeholder}`
                          // }
                        ]
                      })(
                        // <Input
                        //   disabled={false}
                        //   //onBlur={() => this.check()}
                        //   //label={"buttons"}
                        //   //required={true}
                        //   //value={spatheNo}
                        //   //style={{ margin: "5px" }}
                        // ></Input>
                        <NumericInput
                          disabled={false}
                          //label={"Quantity"}
                          required={false}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </div>
            ))}
          <Form.Item>
            <Row
              type="flex"
              justify="end"
              gutter={5}
              className="tf120daysButton"
            >
              <Col>
                <Button onClick={onCancel}>Cancel</Button>
              </Col>
              <Col>
                <Button htmlType="submit">Add</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
      // <div>
      //   <input name="Tree"></input>
      //   <input name="Type"></input>
      // </div>
    );
  }
}

export default Form.create({ name: "add block number form" })(AddForm2);
