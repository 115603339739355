export const parserFunction = (types, totals) =>
  types.concat({ type: "Total", data: totals }).map(each => {
    let tempVar = { type: each.type ? each.type : "Total" };
    console.log(each);

    if (each.data.length > 0) {
      each.data.forEach(({ months, count }) => {
        if (months == "0-12") tempVar.zeroTwelve = count;
        else if (months == "13-24") tempVar.thirteenTwentyFour = count;
        else if (months == "25-30") tempVar.twentyFiveThirty = count;
        else if (months == "0-30") tempVar.zeroThirty = count;
        else if (months == "31-42") tempVar.thirtyOneFourtyTwo = count;
        else if (months == "43-60") tempVar.fourtyThreeSixty = count;
        else if (months == "61-96") tempVar.sixtyOneNinetySix = count;
        else if (months == "97-144") tempVar.ninetySevenOneFourtyFour = count;
        else if (months == "145-192") tempVar.oneFourtyFiveOneNinetyTwo = count;
        else if (months == "193-240") tempVar.OneNinetyThreeTwoFourty = count;
        else if (months == ">240") tempVar.greaterTwoFourty = count;
        else if (months == "43-240") tempVar.fourtyThreeTwoFourty = count;
        else if (months == "0-240") tempVar.zeroTwoFourty = count;
        else tempVar.total = count;
      });
    }
    return tempVar;
  });

export const parseData = arr =>
  arr.map(({ block_no, data }) => ({
    block_no,
    summaryData: parserFunction(data.types, data.totals)
  }));
