import { mapKeys, groupBy } from "lodash";
import { sortFunction } from "../../../../../utility/sorter";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
  arr.blocks &&
    arr.blocks.map(({ total_trees, data }, index) => {
      var total = {
        dt_pollen_collected: "Total No. of trees in the Block : " + total_trees
      };
      data.unshift(total);
      data.map(
        (it, i) => {
          if (i == data.length - 1) {
            var obj = {};
            obj["dt_pollen_collected"] = "Total";
            obj["count"] = total_trees;
            obj["start_date"] = null;
            obj["end_date"] = null;
            obj["update_date"] = null;
            data.push(obj);
          }
        }
        //var tree_total = { total_trees: total_trees };
      );
    });
  console.log("array", arr);
  return arr;
};
