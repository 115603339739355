import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../DataEntryStatusReport120Days/Multitable";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";
import { parseData } from "./parseData";

class DataEntryStatusReportGrowthTallDwarf extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,

    blockNumber: null,
    blockNoList: [],
    block_uuid: null,

    TreeType: null,

    ActivityMonth: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchDataEntrySeedlingGrowth = () => {
    let { block_uuid, ActivityMonth, TreeType } = this.state;
    if (ActivityMonth) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/data-entry-seedling-growth?month=${ActivityMonth}&type=${
          TreeType ? TreeType : 1
        }&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState({
            // data: cloneDeep(this.state.ActivityMonth)
            //   ? res.blocks
            //     ? res
            //     : null
            //   : null
            data: parseData(res)
          });
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    // if (!this.state.ActivityMonth) {
    //   //message.info("Please Select a Activity Month");
    //   this.setState({
    //     blockNumber: null,
    //     block_uuid: null
    //   });
    // } else {
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
          : null
      },
      () => this.fetchDataEntrySeedlingGrowth()
    );
  };

  onTreeTypeChangeHandler = it => {
    // if (!this.state.ActivityMonth) {
    //   //message.info("Please Select a Activity Month");
    //   this.setState({
    //     blockNumber: null,
    //     block_uuid: null,
    //     TreeType: null
    //   });
    // } else {
      this.setState(
        {
          TreeType: it == "Dwarf" ? 1 : it == "Tall" ? 2 : null
        },
        () => this.fetchDataEntrySeedlingGrowth()
      );
    //}
  };

  activityMonthHandler = date => {
    if (date) {
      this.setState(
        {
          ActivityMonth: date.format("YYYY-MM")
        },
        () => this.fetchDataEntrySeedlingGrowth()
      );
    } else {
      this.setState({
        ActivityMonth: null,
        blockNumber: null,
        block_uuid: null,
        TreeType: null,
        data: null
      });
    }
  };

  generateExcel = () => {
    let { block_uuid, ActivityMonth, TreeType } = this.state;
    fetchExcelApi(
      `/regular-reports/data-entry-seedling-growth?month=${ActivityMonth}&type=${
        TreeType ? TreeType : 1
      }&block.uuid=${block_uuid}`,
      "get"
    ).then();
  };

  render() {
    let {
      data,
      loading,
      blockNumber,
      blockNoList,
      ActivityMonth,
      TreeType
    } = this.state;
    const columns = [
      {
        title: "Date Of Planting",
        //dataIndex: "dt_planted",
        render: it =>
          it.dt_planted
            ? it.dt_planted.length > 10
              ? {
                  children: it.dt_planted,

                  props: {
                    colSpan: 6
                  }
                }
              : it.dt_planted == "Total"
              ? it.dt_planted
              : formatDate(it.dt_planted)
            : "-"
      },
      {
        title: "Total No. Of Trees",
        //dataIndex: "count"
        render: it =>
          it.dt_planted.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.count
            ? it.count
            : "-"
      },
      {
        title: "Dates Of Starting",
        //dataIndex: "start_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_planted.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_planted == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.start_date
            ? formatDate(it.start_date)
            : "-"
      },
      {
        title: "Date Of Ending",
        //dataIndex: "end_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_planted.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_planted == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.end_date
            ? formatDate(it.end_date)
            : "-"
      },
      {
        title: "Last Update Date",
        //dataIndex: "update_date",
        //render: it => (it ? formatDate(it) : "-")
        render: it =>
          it.dt_planted.length > 10
            ? {
                props: {
                  colSpan: 0
                }
              }
            : it.dt_planted == "Total"
            ? {
                props: {
                  colSpan: 1
                }
              }
            : it.update_date
            ? formatDate(it.update_date)
            : "-"
      }
    ];
    let JSX =
      data && data.blocks ? (
        <MultiTable columns={columns} dataSource={data} type={TreeType == "1" ? "Dwarf Seedling Growth" :TreeType == "2" ? "Tall Seedling Growth":"Dwarf Seedling Growth"} />
      ) : ActivityMonth ? (
        <Empty />
      ) : (
        <Empty description={"Select Activity Month"} />
      );
    const buttonList =
      data && data.blocks
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Data Entry Status Report Growth Tall/Dwarf"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Tree Type",
            listItems: ["Dwarf", "Tall"],
            hasInitial: true,
            value:
              TreeType == "1" ? "Dwarf" : TreeType == "2" ? "Tall" : "Dwarf",
            changeHandler: it => this.onTreeTypeChangeHandler(it)
          },
          {
            label: "Activity Month",
            type: "month",
            //value: ActivityMonth ? ActivityMonth : undefined,
            changeHandler: this.activityMonthHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DataEntryStatusReportGrowthTallDwarf;
