import { array } from "prop-types";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

/*=export const parseData = arr => {
  let size = 0;
  let n = 4;
  arr.blocks.map(({ areas }, index) =>
    areas.map(({ trees }, j) => {
      size = Math.ceil(trees.length / n);
      let i = 0;
      let k = 0;
      var treesarr = new Array();
      while (i < size * n) {
        treesarr[k] = trees.slice(i, (i += size));
        treesarr[k].push({
          tree_no_full: null,
          harvested_nuts: null,
          bunch_qty: null
        });
        k++;
        if (k == n) k = 0;
      }
      areas[j].trees = treesarr;
    })
  );
  return arr;
};*/

export const parseData = arr => {
  let size = 0;
  let n = 4;
  let parsedData = [];
  let array1 = [];
  let array2 = [];
  let array3 = [];
  let array4 = [];
  arr.blocks &&
    arr.blocks.map(({ areas }, index) =>
      areas.map(({ trees }, j) => {
        trees.map((it, j) => {
          size = trees.length;
          if (j % 4 == 0) array1.push(it);
          else if (j % 4 == 1) array2.push(it);
          else if (j % 4 == 2) array3.push(it);
          else if (j % 4 == 3) array4.push(it);
        });
        // array1.push({
        //   tree_no_full: null,
        //   harvested_nuts: null,
        //   bunch_qty: null
        // });
        // array2.push({
        //   tree_no_full: null,
        //   harvested_nuts: null,
        //   bunch_qty: null
        // });
        // array3.push({
        //   tree_no_full: null,
        //   harvested_nuts: null,
        //   bunch_qty: null
        // });
        // array4.push({
        //   tree_no_full: null,
        //   harvested_nuts: null,
        //   bunch_qty: null
        // });
        areas[j].trees = [];
        areas[j].trees.push(array1, array2, array3, array4);
        array1 = [];
        array2 = [];
        array3 = [];
        array4 = [];
      })
    );
  //console.log("arr", arr);
  return arr;
};
