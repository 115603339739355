import React, { Fragment } from "react";
import { Table, Statistic, Col, Row } from "antd";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 3
  },
  md: {
    span: 3
  }
};

export default ({
  columns = [],
  dataSource1 = [],
  dataSource2 = [],
  blockNo,
  areaNo
}) => {
  return (
    <div>
      <div className="multi-table-header">
        <Statistic title="Block No." value={blockNo} />
        <Statistic title="Area No." value={areaNo} />
      </div>
      <hr />
      <Row gutter={[16]}>
        <Col span={dataSource2.length > 0 ? 12 : 24}>
          <Table
            rowKey="uuid"
            className="multi-table2"
            columns={columns}
            dataSource={dataSource1}
            pagination={false}
          />
        </Col>
        <Col span={12}>
          {dataSource2.length > 0 ? (
            <Table
              rowKey="uuid"
              className="multi-table2"
              columns={columns}
              dataSource={dataSource2}
              pagination={false}
            />
          ) : (
            <Fragment />
          )}
        </Col>
      </Row>
      <br />
      <br />
    </div>
  );
};
