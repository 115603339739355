import moment from "moment";

let coloums = [
  {
    children: [
      {
        title: "Type Code",
        dataIndex: "code"
      },
      {
        title: "Tree No.",
        dataIndex: "tree_no_full"
      }
    ]
  }
];

export const relatedReportsColoums = val => {
  let startMonth = val[0].format("MM"),
    endMonth = val[1].format("MM"),
    startYear = val[0].format("YYYY"),
    nextYear = startYear + 1,
    endYear = val[1].format("YYYY"),
    yearDiff = endYear - startYear,
    startDate,
    endDate,
    result = [];

  // if (yearDiff > 0) {
  //   startDate = moment(`${startYear}-${startMonth}`)
  //   endDate = moment(`${nextYear}-${endMonth}`)
  // } else {
  startDate = moment(`${startYear}-${startMonth}`);
  endDate = moment(`${endYear}-${endMonth}`);
  //}

  while (startDate.isBefore(endDate) || !startDate.isAfter(endDate)) {
    result.push(startDate.format("MMMM - YYYY"));
    startDate.add(1, "month");
  }
  return RPColoums(result);
};

const RPColoums = months =>
  coloums.concat(
    months.map((it, i) => ({
      title: it,
      children: [
        {
          title: "No. of spathes",
          dataIndex: "no_of_spathes_" + i,
          render: it => (it ? it : "-")
        },
        {
          title: "No. of Buttons",
          dataIndex: "no_of_buttons_" + i,
          render: it => (it ? it : "-")
        },
        {
          title: "120 Days Count",
          dataIndex: "count_120_days_" + i,
          render: it => (it ? it : "-")
        },
        {
          title: "120 Days Setting %",
          dataIndex: "count_120_percent_" + i,
          render: it => (it ? it : "-")
        },
        {
          title: "Harvest qty",
          dataIndex: "harvested_nuts_" + i,
          render: it => (it ? it : "-")
        },
        {
          title: "Harvest %",
          dataIndex: "harvest_percent_" + i,
          render: it => (it ? it : "-")
        }
      ]
    }))
  );
