export const parserFunction = (types, totals) =>
  types.concat({ type: "Total", data: totals }).map(each => {
    let tempVar = { type: each.type ? each.type : "Total" };
    console.log(each);

    if (each.data.length > 0) {
      each.data.forEach(({ months, count }) => {
        if (months == "0-24") tempVar.zeroTwentyFour = count;
        else if (months == "25-48") tempVar.twentyFiveFourtyEight = count;
        else if (months == "49-60") tempVar.fourtyNineSixty = count;
        else if (months == "0-60") tempVar.zeroSixty = count;
        else if (months == "61-72") tempVar.sixtyOneSeventyTwo = count;
        else if (months == "73-96") tempVar.seventyThreeNinetySixty = count;
        else if (months == "97-144") tempVar.ninetySevenOneFourtyFour = count;
        else if (months == "145-192") tempVar.oneFourtyFiveOneNinetyTwo = count;
        else if (months == "193-240") tempVar.OneNinetyThreeTwoFourty = count;
        else if (months == "241-288")
          tempVar.TwoFourtyOneTwoEightyEight = count;
        else if (months == ">288") tempVar.greaterTwoEightyEight = count;
        else if (months == "73-288") tempVar.seventyThreeTwoEightyEight = count;
        else if (months == "0-288") tempVar.zeroTwoEightyEight = count;
        else tempVar.total = count;
      });
    }
    return tempVar;
  });

export const parseData = arr =>
  arr.map(({ block_no, data }) => ({
    block_no,
    summaryData: parserFunction(data.types, data.totals)
  }));
