import { sortFunction } from "../../../utility/sorter";
import { traverse } from "@babel/types";
import moment from "moment";

export const parseData = trees => {
  // let resultArray = [];

  // var Areas = [];
  var Area = [];
  var Block = [];
  var treeData = [];

  trees.map(({ stage_code, dt_planted, ...rest }, index) => {
    if (stage_code) {
      var code;
      if (stage_code === 1) {
        code = "Seedling";
      } else if (stage_code === 2) {
        code = "Plant";
      } else if (stage_code === 3) {
        code = "Tree";
      } else {
        code = stage_code;
      }
      trees[index].stage_code = code;
    }

    // if (dt_planted) {
    //   console.log("Date Planted", dt_planted);

    //   trees[index].dt_planted = moment(trees[index].dt_planted).format(
    //     "DD/MMM/YYYY"
    //   );
    // }
  });
  if (trees[trees.length - 1].code != null)
    trees.push({
      code: null,
      dt_died: null,
      dt_planted: null,
      dt_stage2: null,
      dt_stage3: null,
      stage2_user: null,
      suffix: null,
      transfer_mode: null,
      tree_id: null,
      tree_no: null,
      tree_no_full: null,
      tree_type_id: null
    });

  //console.log("Ttttt", trees);
  return trees;
};
