import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { adminRoutes, valuationRoutes, farmRoutes, reportsRoutes } from "./routes";
import { fetchApi } from "../../../services/api";
import * as JWT from "jwt-decode";

import {
  Layout,
  Menu,
  Icon,
  Dropdown,
  Tooltip,
  message,
  notification,
  Select
} from "antd";
import {
  COOKIE,
  createCookie,
  deleteCookie,
  getCookie
} from "../../../services/cookie";
import UserForm from "../../pages/Admin/UserForm";

import "./index.less";

const { Sider } = Layout,
  { Item } = Menu;
const { Option } = Select;

class SideBar extends Component {
  state = {
    tooltipToggle: false,
    name: null,
    farm: null,
    initialKey: [],
    routes: [],
    modal: false,
    data: [],
    btnLoad: false,
    user: null,
    isChangePass: false,
    user_uuid: null,
    loading: false,
    farmList: [],
    dynamicFarmList: [],
    url: null,
    selected: null
  };

  formatString = name => {
    let temp = "";
    name.split("_").forEach((it, i) => {
      if (it !== "null") temp = temp + " " + it;
    });
    return temp;
  };

  setInitialKeyHandler = (link = null) => {
    let routeItem = this.state.routes.menuRoutes.filter(item =>
      item.link.includes(`/${this.props.location.pathname.split("/")[1]}`)
    );
    if (routeItem) this.setState({ initialKey: [routeItem[0].key] });

    //if (link) this.props.history.push(link);
  };

  componentWillMount() {
    this.fetchFarmData();
    var token = getCookie(COOKIE.ID_TOKEN);
    var decoded = token ? JWT(token) : "";
    //console.log(decoded.roles[0]);
    //if (getCookie(COOKIE.ROLE) == "ROLE_ADMIN") {
    if (decoded && decoded.roles[0] == "ROLE_ADMIN") {
      this.setState({ routes: adminRoutes }, () => {
        console.log(this.state.routes);
        this.setInitialKeyHandler();
      });
    } else if (decoded && decoded.roles[0] == "ROLE_VALUATION") {
      console.log("valuationRoutes",valuationRoutes)
      this.setState({ routes: valuationRoutes }, () => {
        console.log(this.state.routes);
        this.setInitialKeyHandler();
      });
    } else if (decoded && decoded.roles[0] == "ROLE_FARM") {
      this.setState({ routes: farmRoutes }, () => {
        console.log(this.state.routes);
        this.setInitialKeyHandler();
      });
    } else if (decoded && decoded.roles[0] == "ROLE_REPORTS") {
      this.setState({ routes: reportsRoutes }, () => {
        console.log(this.state.routes);
        this.setInitialKeyHandler();
      });
    }

    let url = window.location.pathname;
    let activeTab = url.split("/");
    let routemenu = adminRoutes.menuRoutes;
    for (var i = 0; i < routemenu.length; i++) {
      var menulink = routemenu[i].link.split("/");
      if (activeTab[1] == menulink[1]) {
        //console.log(routemenu[i].key);
        localStorage.menuselect = routemenu[i].key;
      }
    }

    this.fetchPath();

    this.setState({
      user_uuid: atob(getCookie(COOKIE.UUID)),
      data: null
    });
  }

  fetchPath = () => {
    //console.log(window.location.pathname);
    let url = window.location.pathname;

    let activeTab = url.split("/");
    //console.log(activeTab[1]);
    this.setState({
      url: activeTab
    });
  };

  //#region sider menu header
  renderHeaderRoutes = routes =>
    routes.map(({ key, dropdown, iconType, theme, tooltip }, index) =>
      dropdown.length > 0 ? (
        <Dropdown
          key={key}
          overlay={this.generateDropDown(dropdown)}
          placement="bottomLeft"
          trigger={["click"]}
          onVisibleChange={this.handleTooltipVisiblity}
        >
          <Tooltip
            title={
              tooltip && !this.state.tooltipToggle
                ? tooltip
                : !this.state.tooltipToggle && this.state.name
            }
            overlayStyle={{ textTransform: "capitalize" }}
            key={key}
          >
            {" "}
            {/* username will be rendered here instead of null later */}
            <div>
              <Icon type={iconType} theme={theme} />
            </div>
          </Tooltip>
        </Dropdown>
      ) : (
        <Tooltip
          key={key}
          overlayStyle={{ textTransform: "capitalize" }}
          key={key}
          title={
            tooltip ? (tooltip == "Location" ? this.state.farm : tooltip) : null
          }
        >
          <div>
            {" "}
            <Icon type={iconType} theme={theme} />
          </div>
        </Tooltip>
      )
    );

  handleTooltipVisiblity = () =>
    this.setState({ tooltipToggle: !this.state.tooltipToggle });

  generateDropDown = dropdownList => (
    <Menu>
      {dropdownList.map(({ label, onClick }, i) =>
        label == "locationDropdown" ? (
          <Select
            placeholder="Select Farm"
            style={{ width: 150 }}
            onChange={this[onClick]}
            className="locationDropdown"
          >
            {//this.state.farmList.map(({ name, code }, i) => (
            this.state.dynamicFarmList.map(({ name, code }, i) => (
              <Option key={i} value={name}>
                {/* {name.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                  return g1.toUpperCase() + g2.toLowerCase();
                })} */}
                {name}
              </Option>
            ))}
          </Select>
        ) : (
          <Item onClick={this[onClick]} key={i}>
            {label}
          </Item>
        )
      )}
    </Menu>
  );

  profileClickHandler = () => {
    this.handleTooltipVisiblity();
    console.log("profile Clicked");
  };

  locationChangeHandler = value => {
    let code = null;
    for (let i = 0; i < this.state.farmList.length; i++) {
      if (this.state.farmList[i]["name"] == value) {
        code = this.state.farmList[i]["code"];
        break;
      }
    }
    console.log("value", value);
    console.log("farmList", this.state.farmList);
    this.handleTooltipVisiblity();
    //console.log(value);
    //deleteCookie(COOKIE.FARM);
    createCookie(COOKIE.FARM, value, null, "/");
    createCookie(COOKIE.CODE, code, null, "/");
    //setTimeout(createCookie(COOKIE.FARM, value), 3000);
    // this.props.history.push("/input-formats/120-days-count");
    //this.props.history.push("/");

    setTimeout(window.location.reload(), 5000);
    //window.location.reload();
  };

  modaltoggle = () => {
    let { user, modal, isChangePass } = this.state;
    if (modal) {
      this.setState({
        modal: false,
        user: null,
        isChangePass: false
      });
    } else {
      this.setState({ modal: !this.state.modal });
    }
  };

  changePasswordClickHandler = () => {
    let { user_uuid } = this.state;
    fetchApi(`/users/${this.state.user_uuid}`, "get").then(data =>
      this.setState(
        {
          data: data
        },
        () => {
          if (this.state.data.canChangePassword) {
            this.setState(
              {
                isChangePass: true,
                user: {
                  username: this.state.data.username
                }
              },
              () => this.setState({ modal: true })
            );
          } else {
            message.info(
              "You do not have Permission to change the Password. Please consult Admin"
            );
          }
        }
      )
    );
  };

  onSumbitHandler = data => {
    //to remove the previous instances of
    notification.destroy();
    this.setState({ btnLoad: true });
    let { user_uuid } = this.state;
    fetchApi(`/users/change-password/${user_uuid}`, "put", {
      oldPassword: data.oldpassword,
      newPassword: data.password,
      newConfirmPassword: data.confirmPassword
    }).then(res => {
      if (res) {
        if (res.violations) {
          notification.error({
            message: "Failed",
            description: res.violations[0].message
          });
        } else {
          notification.success({
            message: "Success",
            description:
              "Successfully updated User Password! Please Login Again"
          });
          if (localStorage) {
            localStorage.removeItem("pass");
          }
          setTimeout(this.logoutClickHandler, 3000);
          //this.logoutClickHandler();
        }
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to updated user password"
        });
        this.setState(
          {
            btnLoad: false,
            btnLoad: false
          },
          () => this.modaltoggle()
        );
      }
      // this.setState(
      //   {
      //     btnLoad: false
      //   },
      //   () => this.modaltoggle()
      // );
    });
  };

  logoutClickHandler = () => {
    this.setState(
      {
        btnLoad: false,
        btnLoad: false
      },
      () => this.modaltoggle()
    );
    this.handleTooltipVisiblity();
    deleteCookie(COOKIE.ID_TOKEN);
    deleteCookie(COOKIE.NAME);
    deleteCookie(COOKIE.FARM);
    deleteCookie(COOKIE.CODE);
    deleteCookie(COOKIE.ROLE);
    deleteCookie(COOKIE.UUID);
    this.props.history.push("/login");
  };
  //#endregion

  renderMenuRoutes = routes =>
    routes.map(it => (
      //console.log(it) &&
      <Item
        key={it.key}
        //onClick={() => this.props.history.push(it.link)}
      >
        {/* {() => this.setInitialKeyHandler} */}
        <Link
          to={{
            pathname: it.link
            //state: { selectedmenu: it.key }
          }}
          //onClick={() => this.setState({ selected: it.key })}
          onClick={() => (localStorage.menuselect = it.key)}
          className="link-no-style"
        >
          <Icon type={it.iconType} theme={it.theme} />
          <span className="nav-text">{it.title}</span>
        </Link>
      </Item>
    ));

  fetchFarmData = () => {
    let farmname = getCookie(COOKIE.FARM);
    let location = getCookie(COOKIE.FARM);
    farmname = farmname[0].toUpperCase() + farmname.substr(1);

    fetchApi("/farms", "get").then(data =>
      this.setState(
        {
          farmList: data,
          //dynamicFarmList: data.splice(data.indexOf(farmname), 1)
          dynamicFarmList: data.filter(function(item) {
            return item["name"] != location;
          })
        },
        () => console.log(this.state.farmList)
      )
    );
  };

  render() {
    let { headerRoute, menuRoutes } = this.state.routes;
    const { modal, btnLoad, isChangePass, user } = this.state;

    return (
      <Sider
        className="sider"
        breakpoint="lg"
        collapsedWidth="0"
        width="260"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="sider-header-menu">
          {this.renderHeaderRoutes(headerRoute)}
        </div>
        {console.log(
          "this.props.location.state.selectedmenu",
          this.props.location.state
        )}
        <Menu
          className="sider-menu"
          mode="inline"
          //onClick={() => this.setInitialKeyHandler}
          //defaultSelectedKeys={this.state.initialKey}
          defaultSelectedKeys={this.state.initialKey}
          selectedKeys={
            localStorage.menuselect
              ? localStorage.menuselect
              : this.props.location.state
              ? this.props.location.state.selectedmenu
              : "11"
          }
        >
          {this.renderMenuRoutes(menuRoutes)}
        </Menu>
        <UserForm
          title="Change Password"
          onSumbitHandler={this.onSumbitHandler}
          onClose={this.modaltoggle}
          btnLoad={btnLoad}
          modal={modal}
          isChangePass={isChangePass}
          user={user}
        />
      </Sider>
    );
  }
}

export default withRouter(SideBar);
