import React, { Component, Fragment } from "react";

import { message, Spin } from "antd";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { parseData4 } from "./parseData";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import { formatDate } from "../../../utility/date";
import { sortFunction, sortFunction2 } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

//Component import
import ReportLayout from "../../pageLayouts/Report";
import MultiTable2 from "./MultiTable2";
import Empty from "../../components/Custom/Empty";

class OneTwentyDaysCount extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    dateOfEmasculation: null
  };

  componentDidMount() {
    this.setState({ loading: false });
    this.fetchBlockNo();
  }

  fetchOneTwentyDays = () => {
    this.setState({ loading: true });
    let { block_uuid, area_uuid, dateOfEmasculation } = this.state,
      url = "/input-format/count-120-days?month=" + dateOfEmasculation;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    fetchApi(url, "get")
      .then(data => {
        data
          ? this.setState({
              data: sortFunction2(data, [
                "dtEmasculation",
                "treeNo",
                "areaNo",
                "blockNo"
              ]),
              dynamicData: parseData4(data)
            })
          : message.error("Internal Server Error!");
      })
      .then(() => this.setState({ loading: false }));
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList, dateOfEmasculation } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => (dateOfEmasculation ? this.fetchOneTwentyDays() : null)
    );
  };

  onAreaChangeHandler = it => {
    let {
      areaNoList,
      blockNoList,
      blockNumber,
      dateOfEmasculation
    } = this.state;
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null,
        code: null,

        codeList: [],
        tree_type_uuid2: null
      },
      () => (dateOfEmasculation ? this.fetchOneTwentyDays() : null)
    );
  };

  dateOfEmasculationHandler = (date, dateString) => {
    date
      ? this.setState({ 
          dateOfEmasculation: date.format("YYYY-MM"),
          data: [],
          dynamicData: []
    }, () =>
          this.fetchOneTwentyDays()
        )
      : this.setState({ 
          dateOfEmasculation: null,
          data: [],
          dynamicData: [] 
        }
        // , () =>
        //   this.fetchOneTwentyDays()
        );
  };

  // tableFilterHandler = () => {
  //   let { dateOfEmasculation, data, areaNumber, blockNumber } = this.state,
  //     filterJson = {};
  //   if (blockNumber) filterJson.blockNo = blockNumber;
  //   if (areaNumber) filterJson.areaNo = areaNumber;
  //   if (dateOfEmasculation)
  //     filterJson.dtEmasculation = moment(dateOfEmasculation).format(
  //       "DD/MM/YYYY"
  //     );

  //   this.setState({
  //     dynamicData: parseData3(data)
  //   });
  // };

  generateTables = tableData =>
    tableData.map(({ blockNo, areaNo, value1, value2 }, index) => (
      <Fragment>

        {
          index !=0 ?
          <div className="pagebreak"/>:""
        } 
        <MultiTable2
          key={index}
          blockNo={blockNo}
          areaNo={areaNo}
          columns={[
            {
              title: "Tree No.",
              dataIndex: "treeNo",
              key: "treeNo"
            },
            {
              title: "Spathe No.",
              dataIndex: "spatheNo",
              key: "spatheNo"
            },
            {
              title: "Date Of Emasculation",
              dataIndex: "dtEmasculation",
              key: "dtEmasculation",
              render: it => (it ? formatDate(it) : "-")
            },
            {
              title: "No. Of Buttons",
              dataIndex: "buttons",
              key: "buttons"
            },
            {
              title: "Actual Nuts Count Qty",
              dataIndex: "actualNuts",
              key: "actualNuts",
              render: it => (it ? it : "-")
            }
          ]}
          //dataSource1={value2}
          //dataSource2={value}
          dataSource1={value1}
          dataSource2={value2}
        />
      </Fragment>
    ));

  generateExcel = () => {
    let { block_uuid, area_uuid, dateOfEmasculation } = this.state,
      url = "/input-format/count-120-days?month=" + dateOfEmasculation;
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      dynamicData,
      loading,
      blockNoList,
      areaNoList,
      blockNumber,
      areaNumber,
      dateOfEmasculation
    } = this.state;
    let JSX = dateOfEmasculation ? (
      dynamicData.length > 0 ? (
        <Fragment>
      <h3>
        120 Days Count Input Format
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Farm : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    <h3>Emasculation Done In : {" "+moment(this.state.dateOfEmasculation).format("MMMM YYYY")}</h3>
    {this.generateTables(dynamicData)}
    </Fragment>   
      ) : (
        <Empty></Empty>
      )
    ) : (
      <Empty description={"Select Emasculation Done In"} />
    );
    const buttonList =
      dynamicData.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="120 Days Count"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Emasculation Done In",
            type: "month",
            changeHandler: this.dateOfEmasculationHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default OneTwentyDaysCount;
