import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Statistic,
  Descriptions,
  Spin,
  Col
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { formatDate } from "../../../../../utility/date";

import { parseData } from "../../parserFunctionsResearchCodeList";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class DwarfTreeResearchCodeList extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,

    blockNoList: [],
    block_uuid: null,
    blockNumber: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    code: null,
    codeList: [],
    research_uuid2: null,

    DateOfPeriod: null,

    farm_codes: null,
    farm_total: 0
  };

  componentDidMount() {
    this.fetchResearchCodeList();
    this.fetchCodelist();
    this.fetchBlockNo();
  }

  fetchResearchCodeList = () => {
    this.setState({ loading: true });
    //let { block_uuid, DateOfPeriod, blockNumber } = this.state;

    let { area_uuid, block_uuid, research_uuid2 } = this.state,
      url = "/api/dwarf_researches/get-codewise-list-dwarf";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    if (research_uuid2) {
      if (block_uuid) {
        url += `&research.uuid=${research_uuid2}`;
      } else {
        url += `?research.uuid=${research_uuid2}`;
      }
    }

    // if (block_uuid) {
    //   if (research_uuid2) {
    //     url += `&block.uuid=${block_uuid}`;
    //   } else {
    //     url += `?block.uuid=${block_uuid}`;
    //   }
    // }

    fetchApi(url, "get").then(res => {
      if (res)
        this.setState({
          data: res.data ? parseData(cloneDeep(res.data)) : null,
          farm_codes: res.farm_codes,
          farm_total: res.farm_total,
          loading: false
        });
      else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchCodelist = () => {
    fetchApi(`/researches?type=1`, "get").then(res =>
      this.setState({ codeList: res })
    );
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
        //research_uuid2: null,
        //code: null
      },
      () => this.fetchResearchCodeList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
        //code: null,
        //research_uuid2: null
      },
      () => this.fetchResearchCodeList()
    );
  };

  onCodeChangeHandler = it => {
    //console.log("It is", it);
    let { codeList } = this.state;
    this.setState(
      {
        code: it ? it : null,
        research_uuid2: it
          ? cloneDeep(codeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchResearchCodeList()
    );
  };

  generateTables = tableData => {
    // console.log("Table Data", tableData);
    return (
      <div>
        <h3>
          Research Dwarf Tree Transaction - Code Wise List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
        : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
        {tableData.map(
          ({ block_total, block_no, Block_codes, Areas }, index) => (
            <div key={index}>
              {Areas.map(({ block_data, area_count }, i) => (
                //console.log("Tree Nos", tree_nos)
                <div key={i}>
                  {block_data.map(({ tree_nos, total_count, ...rest }, j) => (
                    <div key={j} className={index == 0 && i == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
                      {tree_nos && (
                        <div>
                          <Col key={j} {...layout}>
                            <Statistic
                              title="Block No."
                              value={rest["block_no"]}
                            />
                          </Col>
                          <Col key={j} {...layout}>
                            <Statistic title="Area No." value={rest["area_no"]} />
                          </Col>
                          <Col key={j} {...layout}>
                            <Statistic title="Code" value={rest["code"]} />
                          </Col>
                        </div>
                      )}
                      {tree_nos && (
                        <Descriptions bordered className="p-0">
                          <Descriptions.Item label="Tree No." span={3}>
                            {tree_nos.map((it, i) => (
                              <Col>
                                <span key={i} style={{ margin: "0px 10px" }}>
                                  {it}
                                </span>
                              </Col>
                            ))}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="No. Of Dwarf Trees"
                            span={3}
                          >
                            {total_count}
                          </Descriptions.Item>
                          <Descriptions.Item span={6}></Descriptions.Item>
                        </Descriptions>
                      )}
                      {j == block_data.length - 1 ? (
                        <br />
                      ) : (
                          <Fragment><br /><br /></Fragment>
                        )}
                    </div>
                  ))}
                  {area_count > 0 && (
                    <Descriptions bordered>
                      <Descriptions.Item
                        label="No. Of Dwarf Trees Area Wise"
                        span={3}
                      >
                        {area_count}
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                  {i == Areas.length - 1 ? (
                    <br />
                  ) : (
                      <Fragment><br /><br /></Fragment>
                    )}
                </div>
              ))}
              {Block_codes && (
                <Descriptions bordered>
                  <Descriptions.Item
                    label={"Total Dwarf Trees in Block"}
                    span={3}
                  >
                    {block_no}
                  </Descriptions.Item>
                  {Block_codes.map(({ Code, block_count }, i) => (
                    <Descriptions.Item
                      key={i}
                      label={"Code " + Code}
                      span={3}
                    >
                      {block_count}
                    </Descriptions.Item>
                  ))}
                  <Descriptions.Item label={"All Codes"} span={3}>
                    {block_total}
                  </Descriptions.Item>
                </Descriptions>
              )}
              {index == tableData.length - 1 ? (
                <br />
              ) : (
                  <Fragment><br /><br /></Fragment>
                )}
            </div>
          )
        )}
        {this.state.farm_codes && (
          <Descriptions bordered>
            <Descriptions.Item
              label={"Total Dwarf Trees in Farm"}
              span={3}
            ></Descriptions.Item>
            {this.state.farm_codes &&
              this.state.farm_codes.map(({ code, farm_count }, i) => (
                <Descriptions.Item
                  key={i}
                  label={"Code " + code}
                  span={3}
                >
                  {farm_count}
                </Descriptions.Item>
              ))}
            <Descriptions.Item label={"All Codes"} span={3}>
              {this.state.farm_total}
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
    );
  };

  generateExcel = () => {
    let { area_uuid, block_uuid, research_uuid2 } = this.state,
      url = "/api/dwarf_researches/get-codewise-list-dwarf";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    if (research_uuid2) {
      if (block_uuid) {
        url += `&research.uuid=${research_uuid2}`;
      } else {
        url += `?research.uuid=${research_uuid2}`;
      }
    }

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      loading,
      DateOfPeriod,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNoList,
      code,
      codeList
    } = this.state;

    const buttonList = data
      ? [
        {
          label: "Excel",
          icon: "file-text",
          onClickHandler: () => this.generateExcel()
        },
        {
          label: "Print",
          icon: "diff",
          reference: this.componentRef
        }
      ]
      : null;
    let JSX = data ? this.generateTables(data) : null;
    const columns = [
      {
        title: "Total No. Of Entered spathes",
        dataIndex: "total_spathes"
      },
      {
        title: "Date Of Starting",
        dataIndex: "start_date",
        render: it => (it ? formatDate(it) : "-")
      },
      {
        title: "Date Of Ending",
        dataIndex: "end_date",
        render: it => (it ? formatDate(it) : "-")
      },
      {
        title: "Last Update Date",
        dataIndex: "last_update_date",
        render: it => (it ? formatDate(it) : "-")
      }
    ];

    return (
      <ReportLayout
        title="Research Dwarf Tree Transaction - Code Wise List"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Code",
            listItems: cloneDeep(codeList).map(it => it.code),
            //disabled: areaNumber ? false : true,
            value: code ? code : undefined,
            changeHandler: it => this.onCodeChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? <div>{JSX}</div> : <Empty></Empty>}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfTreeResearchCodeList;
