import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, Icon, message, notification, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction, sortFunctionDesc } from "../../../../utility/sorter";
import { cloneDeep } from "lodash";
import { fetchApi } from "../../../../services/api";
import moment from "moment";

var queryLimit = 20;
class DwarfTree120Dayscount extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicTableData: [],
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    rowNoList: [],
    rowNumber: null,
    row_uuid: null,
    treeNoList: [],
    treeNumber: null,
    tree_uuid: null,
    pagination: true,
    dateOfEmasculation: null,
    addModal: false,
    editData: null,
    btnLoad: false,
    isEdit: false,
    loading: false,
    dtDue: null,
    addFormModal: false,
    addData: null
  };

  componentDidMount() {
    this.dwarfTree120Dayscount();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Dwarf Tree 120 Days count</h1>";
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Dwarf-Tree-120-Days-Count");
  }

  dwarfTree120Dayscount = () => {
    this.setState({ loading: true, data: [], pagination: false });
    let {
      dtEmasculationBefore,
      dtEmasculationAfter,
      block_uuid,
      area_uuid,
      row_uuid,
      tree_uuid,
      dateOfEmasculation
    } = this.state,
      url = "/count120days";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (row_uuid) url += `&row.uuid=${row_uuid}`;
    if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
    if (dateOfEmasculation) {
      let date = moment(dateOfEmasculation).format("YYYY-MM");
      this.fetchCount120Days(date);
      dtEmasculationAfter = moment(date + "-" + "01").format("YYYY-MM-DD");
      dtEmasculationBefore = moment(dtEmasculationAfter)
        .endOf("month")
        .format("YYYY-MM-DD");
      if (block_uuid) {
        url += `&dtEmasculation[before]=${dtEmasculationBefore}`;
        url += `&dtEmasculation[after]=${dtEmasculationAfter}`;
      } else {
        url += `?dtEmasculation[before]=${dtEmasculationBefore}`;
        url += `&dtEmasculation[after]=${dtEmasculationAfter}`;
      }
    }

    fetchApi(url, "get")
      .then(data =>
        data
          ? //(this.setState({ data: sortFunction(data, "treeNo") }),
          //this.setState({ data: data }),
          this.setState(
            {
              data: sortFunctionDesc(
                sortFunction(data, "treeNo"),
                "dtEmasculation"
              ),
              pagination: true
              /*data.map(({ dtEmasculation, dtDue, ...it }) => {
                return {
                  dtEmasculation: dtEmasculation
                    ? moment(dtEmasculation).format("DD/MM/YYYY")
                    : null,
                  dtDue: dtDue ? moment(dtDue).format("DD/MM/YYYY") : null,
                  date: dtDue,
                  ...it
                };
              })*/
            } //,              () => this.tableFilterHandler()
          )
          : message.error("Internal Server Error")
      )
      .then(() => this.setState({ loading: false }));
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchCount120Days = dateOfEmasculation => {
    var { block_uuid } = this.state;
    fetchApi(
      `/input-format/count-120-days?month=${dateOfEmasculation}&itemsPerPage=${queryLimit}&block.uuid=${block_uuid}`,
      "get"
    ).then(data =>
      this.setState({ addData: data }, () => console.log(this.state.addData))
    );
  };

  clear = () => {
    this.setState(
      {
        data: [],
        dynamicTableData: [],
        blockNumber: null,
        block_uuid: null,
        areaNoList: [],
        areaNumber: null,
        area_uuid: null,
        rowNoList: [],
        rowNumber: null,
        row_uuid: null,
        treeNoList: [],
        treeNumber: null,
        tree_uuid: null,
        pagination: true,
        dateOfEmasculation: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        isEdit: false,
        loading: false,
        dtDue: null,
        addFormModal: false,
        addData: null
      },
      () => this.dwarfTree120Dayscount()
    );
  };

  addModalToggle = () => {
    let { addModal } = this.state;
    if (addModal) {
      this.setState({
        addModal: false,
        editData: null,
        isEdit: false
      });
    } else {
      //this.setState({ addModal: !this.state.addModal });
      this.setState({ addModal: !this.state.addModal });
    }
  };

  addFormModalToggle = () => {
    let {
      addFormModal,
      dateOfEmasculation,
      addData,
      loading,
      block_uuid
    } = this.state;
    if (addFormModal) {
      this.setState({
        addFormModal: false,
        editData: null,
        isEdit: false
      });
    } else {
      //this.setState({ addModal: !this.state.addModal });
      if (loading) {
        message.info("Loading Data Please wait");
      } else {
        if (dateOfEmasculation && block_uuid) {
          if (addData && addData.length > 0) {
            this.setState({ addFormModal: !this.state.addFormModal });
          } else {
            this.setState({ addFormModal: false });
            message.info("No Trees for the selected month");
          }
        } else {
          message.info("Select Block No. and Date of Emasculation");
          this.setState({ addFormModal: false });
        }
      }
    }
  };

  // addFormModelToggle = () => {
  //   console.log("Add");
  // };

  addFormHandler = ({ actualNuts, dtDue, ...val }) => {
    notification.destroy();
    let { editData, isEdit } = this.state;
    this.setState({ btnLoad: true });

    if (isEdit) {
      fetchApi(`/count120days/${editData.uuid}`, "put", {
        actualNuts: parseInt(actualNuts),
        dtDue: moment(dtDue).format("YYYY-MM-DD"),
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully Updated Dwarf Tree 120 Days Count"
          });
          this.dwarfTree120Dayscount();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to Update Dwarf Tree 120 Days Count"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      //console.log("ADDDDD");
      console.log(val);
      this.setState({ btnLoad: false }, () => this.addModalToggle());
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => String(item.blockNoFull) === String(it))[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => String(item.blockNoFull) === String(it))[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNoList: [],
            treeNumber: null,
            tree_uuid: null,
            formTreeNoList: [],
            spatheNo: null
          },
          () => this.dwarfTree120Dayscount()
        )
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null,
          areaNoList: [],
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          formTreeNoList: [],
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        },
        () => this.dwarfTree120Dayscount()
      );
    }
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNumber, blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${
        cloneDeep(areaNoList).filter(item => String(item.areaNo) === String(it))[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => String(item.blockNoFull) === String(blockNumber)
        )[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => String(item.areaNo) === String(it)
            )[0].uuid,
            rowNoList: sortFunction(res, "rowNoFull"),
            rowNumber: null,
            row_uuid: null,
            treeNoList: [],
            treeNumber: null,
            tree_uuid: null
          },
          () => this.dwarfTree120Dayscount()
        )
      );
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null
        },
        () => this.dwarfTree120Dayscount()
      );
    }
  };

  onRowChangeHandler = it => {
    let {
      rowNoList,
      blockNumber,
      areaNoList,
      areaNumber,
      blockNoList
    } = this.state;

    if (it) {
      fetchApi(
        `/dwarves?row.uuid=${
        cloneDeep(rowNoList).filter(item => String(item.rowNoFull) === String(it))[0].uuid
        }&area.uuid=${
        cloneDeep(areaNoList).filter(item => String(item.areaNo) === String(areaNumber))[0]
          .uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => String(item.blockNoFull) === String(blockNumber)
        )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState(
          {
            rowNumber: it,
            row_uuid: cloneDeep(rowNoList).filter(
              item => String(item.rowNoFull) === String(it)
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null,
            tree_uuid: null
          },
          () => this.dwarfTree120Dayscount()
        )
      );
    } else {
      this.setState(
        {
          rowNumber: null,
          row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null
        },
        () => this.dwarfTree120Dayscount()
      );
    }
  };

  onTreeChangeHandler = it => {
    let { treeNoList } = this.state;
    if (it)
      this.setState(
        {
          treeNumber: it,
          tree_uuid: cloneDeep(treeNoList).filter(
            item => String(item.treeNoFull) === String(it)
          )[0].uuid
        },
        () => this.dwarfTree120Dayscount()
      );
    else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null
        },
        () => this.dwarfTree120Dayscount()
      );
    }
  };

  dateOfEmasculationHandler = (date, dateString) => {
    if (date) {
      this.setState({ dateOfEmasculation: date }, () =>
        this.dwarfTree120Dayscount()
      );
    } else {
      this.setState({ dateOfEmasculation: null, addData: null }, () =>
        this.dwarfTree120Dayscount()
      );
    }
  };

  editHandler = ({ actualNuts, date, dtDue, uuid }) => {
    this.setState(
      {
        isEdit: true,
        editData: {
          actualNuts,
          //dtDue: moment(date, "YYYY-MM-DD"),
          dtDue: moment(dtDue, "YYYY-MM-DD"),
          uuid
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );
    console.log(this.state.dtDue);
  };

  handleOk = (values, limit = queryLimit) => {
    notification.destroy();
    limit = queryLimit;
    console.log("val", values);
    for (let i = 0; i < limit; i++) {
      //console.log("Values of " + i, values["" + i + "actualNuts"]);
      let uuid = values["" + i + "uuid"];
      if (values["" + i + "actualNuts"]) {
        if (values["" + i + "actualNuts"] > values["" + i + "buttons"]) {
          message.info("Actual count is greater than buttons count");
        }
        fetchApi(`/count120days/${uuid}`, "put", {
          actualNuts: parseInt(values["" + i + "actualNuts"]),
          dtDue: values["" + i + "dueDate"].format("YYYY-MM-DD")
        }).then(res => {
          notification.destroy();
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully Added Dwarf Tree 120 Days Count"
            });
            // this.setState(
            //   {
            //     dateOfEmasculation: null,
            //     areaNumber: null,
            //     area_uuid: null,
            //     rowNumber: null,
            //     row_uuid: null,
            //     treeNumber: null,
            //     tree_uuid: null
            //   },
            //   () => 
              this.dwarfTree120Dayscount()
            // );
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Update Dwarf Tree 120 Days Count"
            });
          }
          //this.setState({ btnLoad: false }, () => this.addModalToggle());
        });
      }
    }

    this.setState({
      addFormModal: false
    });
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState(
      {
        blockNumber: null,
        block_uuid: null,

        areaNumber: null,
        area_uuid: null,

        rowNumber: null,
        row_uuid: null,

        treeNumber: null,
        tree_uuid: null,

        dateOfEmasculation: null,
        addFormModal: false
      },
      () => this.dwarfTree120Dayscount()
    );
  };
  /*
  tableFilterHandler = () => {
    let {
        dateOfEmasculation,
        data
        // areaNumber,
        // blockNumber,
        // treeNumber,
        // rowNumber
      } = this.state,
      filterJson = {};
    // if (blockNumber) filterJson.blockNo = blockNumber;
    // if (areaNumber) filterJson.areaNo = areaNumber;
    // if (treeNumber) filterJson.treeNo = treeNumber;
    // if (rowNumber) filterJson.rowNo = rowNumber.toString();
    /*if (dateOfEmasculation)
      filterJson.dtEmasculation = dateOfEmasculation.format("MM/YYYY");

    console.log(filterJson, dateOfEmasculation);

    this.setState(
      {
        dynamicTableData: sortFunction(filter(data, filterJson), "treeN o")
      },
      () => console.log(this.state.dynamicTableData)
    );
    this.setState(
      {
        dynamicTableData: sortFunction(data, "treeN o")
      },
      () => console.log(this.state.dynamicTableData)
    );
    console.log(this.state.data);
  };*/

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      areaNumber,
      dateOfEmasculation,
      pagination,
      addModal,
      editData,
      btnLoad,
      rowNoList,
      isEdit,
      treeNumber,
      rowNumber,
      loading,
      data,
      addFormModal,
      addData,
    } = this.state;
    const columns = [
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Spathe No.",
        key: "spatheNo",
        dataIndex: "spatheNo"
      },
      {
        title: "Date of Emasculation",
        key: "dtEmasculation",
        dataIndex: "dtEmasculation",
        render: it => (it ? moment(it).format("DD/MM/YYYY") : null)
      },
      {
        title: "No. of Buttons",
        key: "buttons",
        dataIndex: "buttons"
      },
      {
        title: "Actual Nuts Count",
        children: [
          {
            title: "Due Date",
            key: "dtDue",
            dataIndex: "dtDue",
            className: "text-center",
            render: it => (it ? moment(it).format("DD/MM/YYYY") : null)
          },
          {
            title: "Quantity",
            key: "actualNuts",
            dataIndex: "actualNuts",
            className: "text-center"
          }
        ]
      },
      {
        title: "Actions",
        key: "actions",
        className: "action",
        render: it => (
          <Tooltip placement="top" title="Edit">
            <Icon onClick={() => this.editHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ];
    const buttonList =
      data.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfEmasculation
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addFormModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfEmasculation
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addFormModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Dwarf Tree 120 Days Count"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: blockNoList.map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Row No.",
            listItems: cloneDeep(rowNoList).map(it => it.rowNoFull),
            disabled: areaNumber ? false : true,
            value: rowNumber ? rowNumber : undefined,
            changeHandler: it => this.onRowChangeHandler(it)
          },
          {
            label: "Tree No.",
            listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
            disabled: rowNumber ? false : true,
            value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it)
          },
          {
            label: "Date of Emasculation",
            type: "month",
            value: dateOfEmasculation ? dateOfEmasculation : undefined,
            changeHandler: this.dateOfEmasculationHandler
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        editData={editData}
        btnLoad={btnLoad}
        isEdit={isEdit}
        addFormModal={addFormModal}
        handleOk={this.handleOk}
        handleCancel={this.handleCancel}
        addData={addData}
        formItems={[
          {
            name: "dtDue",
            placeholder: "Due Date",
            type: "date",
            message: "Input the Due Date!"
          },
          {
            name: "actualNuts",
            placeholder: "Actual Nuts",
            type: "number",
            message: "Input the Actual Nuts!"
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            <Table
              ref="table"
              rowKey="uuid"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
              //loading={loading}
              locale={{
                emptyText: <Empty />
              }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default DwarfTree120Dayscount;
