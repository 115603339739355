import React, { Component, Fragment } from "react";
import { Table, Col, Statistic, Descriptions, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

const stylebackground = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center",
  minWidth: "200px",
  background: "#fafafa"
};

const style = {
  border: "0.5px solid #e9e9e9",
  textAlign: "center",
  minWidth: "200px"
};

export default ({ tableData, columns, fromDate, toDate, pgheading }) => (
  <div>
    <h3>
      {pgheading} &nbsp;&nbsp; {moment(fromDate).format("DD/MM/YYYY")} - {moment(toDate).format("DD/MM/YYYY")}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
    {tableData.blocks.map(({ block_no_full, areas, block_total }, index) => (
      <div key={index}>
        {areas &&
          areas.map(({ area_no, trees, area_total }, j) => (
            <div className={index == 0 && j == 0 ? "" : "pagebreak"}>
              <div>
                <Col key={1} {...layout}>
                  <Statistic title="Block No." value={block_no_full} />
                </Col>
                <Col key={2} {...layout}>
                  <Statistic title="Area No." value={area_no} />
                </Col>
                <br></br>
                <br></br>
                <br></br>
                <Row>
                  {trees &&
                    trees.length > 1 &&
                    trees.map((it, k) =>
                      it[0].length > 0 ? (
                        <Col
                          span={
                            // trees[1][0].length == 0
                            //   ? 24
                            //   : trees[2][0].length == 0
                            //   ? 11
                            //   : trees[3][0].length == 0
                            //   ? 6
                            //   : 3
                            trees[1][0].length == 0
                              ? 23
                              : trees[2][0].length == 0
                                ? 11
                                : trees[3][0].length == 0
                                  ? 7
                                  : 5
                          }
                          offset={
                            // trees[1][0].length== 0 ||  k==0
                            // ? 0
                            // : trees[2][0].length == 0
                            // ? 2
                            // : trees[3][0].length == 0
                            // ? 3:
                            // 4
                            k == 0 ?
                              0 : 1
                          }
                        >
                          <Table
                            //className="block-wise-summary-dwarf-table"
                            //className="multi-table2"
                            //className="bordered-table-head"
                            className="table-align-center"
                            bordered
                            key={"table" + j}
                            columns={columns}
                            dataSource={it[0]}
                            size="large"
                            pagination={false}
                          />
                        </Col>
                      ) : null
                    )}
                </Row>
                <br></br>
                {area_total && (
                  <div>
                    <Row>
                      <Col span={9} style={stylebackground}>
                        <h3>{"Nuts Harvested in Area " + area_no}</h3>
                      </Col>
                      <Col span={7} style={stylebackground}>
                        <h3>Entries </h3>
                      </Col>
                      <Col span={7} style={stylebackground}>
                        <h3>Nuts</h3>
                      </Col>
                    </Row>
                    {area_total.map(({ nuts, code, entries }, i) => (
                      <Row key={i}>
                        <Col span={9} style={stylebackground}>
                          {code ? (
                            <h3>{"Type " + code}</h3>
                          ) : (
                              <h3>{"Area Wise Total Nuts Harvested"}</h3>
                            )}
                        </Col>
                        <Col span={7} style={style}>
                          <h3>{entries}</h3>
                        </Col>
                        <Col span={7} style={style}>
                          <h3>{nuts}</h3>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              </div>
              {j == areas.length - 1 ? (
                <br />
              ) : (
                  <Fragment><br /><br /></Fragment>
                )}
            </div>
          ))}

        {block_total && (
          <div>
            <Row>
              <Col span={9} style={stylebackground}>
                <h3>{"Nuts Harvested in Block " + block_no_full}</h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Entries </h3>
              </Col>
              <Col span={7} style={stylebackground}>
                <h3>Counts </h3>
              </Col>
            </Row>

            {block_total.map(({ nuts, code, entries }, i) => (
              <Row key={i}>
                <Col span={9} style={stylebackground}>
                  {code ? (
                    <h3>{"Type " + code}</h3>
                  ) : (
                      <h3>{"Block Wise Total Nuts Harvested"}</h3>
                    )}
                </Col>
                <Col span={7} style={style}>
                  <h3>{entries}</h3>
                </Col>
                <Col span={7} style={style}>
                  <h3>{nuts}</h3>
                </Col>
              </Row>
            ))}
          </div>
        )}
        {index == tableData.blocks.length - 1 ? (
          <br />
        ) : (
            <Fragment><br /><br /></Fragment>
          )}
      </div>
    ))}
    {tableData.farm_total && (
      <div>
        <Row>
          <Col span={9} style={stylebackground}>
            <h3>{"Nuts Harvested In Farm "}</h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Entries </h3>
          </Col>
          <Col span={7} style={stylebackground}>
            <h3>Nuts</h3>
          </Col>
        </Row>
        {tableData.farm_total.map(({ code, entries, nuts }, k) => (
          <Row key={k}>
            <Col span={9} style={stylebackground}>
              {code ? (
                <h3>{"Type " + code}</h3>
              ) : (
                  <h3>{"Farm Wise Total Nuts Harvested"}</h3>
                )}
            </Col>
            <Col span={7} style={style}>
              <h3>{entries}</h3>
            </Col>
            <Col span={7} style={style}>
              <h3>{nuts}</h3>
            </Col>
          </Row>
        ))}
      </div>
    )}
    <br></br>
    <br></br>
    <br></br>
  </div>
);
