import React, { Component } from "react";

import { Select } from "antd";

import "../Input/index.less";

const { Option } = Select;

class CustomSelect extends React.Component {
  static getDerivedStateFromProps(nextProps, prevSate) {
    if ("value" in nextProps) {
      return Object.assign(
        {},
        {
          value: nextProps.value || null
        },
        nextProps.value
          ? {
              fieldActive: nextProps.value ? true : false
            }
          : {}
      );
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || null,
      test: "",
      fieldActive: false
    };
  }

  triggerChange = changedValue => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  //Styling handler
  activateField = () => {
    this.setState({
      fieldActive: true
    });
  };
  // to deactivate input only if it's empty
  disableField = val => {
    if (val === null) {
      this.setState({
        fieldActive: false
      });
    }
  };

  render() {
    const {
      label,
      selectItems = [],
      required = false,
      hasInitial = false,
      value,
      ...rest
    } = this.props;
    const { state } = this;
    return (
      <div className="custom-input">
        <label
          className={
            this.state.fieldActive || hasInitial
              ? required
                ? "field-active custom-input-label require-field-active"
                : "field-active custom-input-label"
              : required
              ? "custom-input-label require-field-active"
              : "custom-input-label"
          }
        >
          {label}
        </label>
        <Select
          showSearch
          //value={state.value}
          value={
            state.value ? state.value : hasInitial ? selectItems[0] : undefined
          }
          onFocus={this.activateField}
          onBlur={this.disableField}
          //allowClear
          allowClear={hasInitial ? false : true}
          // defaultValue={
          //   hasInitial ? (selectItems ? selectItems[0] : undefined) : undefined
          // }
          {...rest}
        >
          {selectItems.map(it => (
            <Option value={it} key={it}>
              {it}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default CustomSelect;
