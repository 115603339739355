import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";

class PerformanceComparisonReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,

    blockNoList: [],
    blockNumber: null,
    block_uuid: null,

    areaNumber: null,
    area_uuid: null,
    areaNoList: [],

    isopen: false,
    period: [],
    periodVS: null,
    formType: null,
    title: "Age Of Flowering"
  };

  componentDidMount() {
    this.fetchBlockNo();
    //this.fetchResearchGroup();
  }

  fetchAgeOfFlowering = () => {
    let { block_uuid, area_uuid } = this.state,
      url = `/special-reports/age-of-flowering-dwarf-trees`;
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    this.setState({ loading: true });
    fetchApi(url, "get").then(res => {
      if (res) {
        this.setState({
          data: res.blocks
          //title: res.title
        });
      } else {
        message.error("Internal Server Error!");
        this.setState({
          data: null
        });
      }
      this.setState({ loading: false });
    });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null
        //areaNoList: null
      },
      () => this.fetchAgeOfFlowering()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList } = this.state;
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchAgeOfFlowering()
    );
  };

  generateExcel = () => {
    let { block_uuid, area_uuid } = this.state;
    let url = `/special-reports/age-of-flowering-dwarf-trees?block.uuid=${block_uuid}`;
    if (block_uuid) {
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      fetchExcelApi(url, "get").then();
    } else {
      message.error("Select Block No.!");
    }
  };

  //generateTables = tableData => <MultiTable dataSource={tableData} />;

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      areaNoList,
      areaNumber
    } = this.state;

    let JSX = data ? <MultiTable dataSource={data} /> : null;

    const buttonList = data
      ? data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null
      : null;

    return (
      <ReportLayout
        title={"Age Of Flowering For Dwarf Trees"}
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? (
              data.length > 0 ? (
                <div> {JSX}</div>
              ) : (
                <Empty description={"Select Block No."}></Empty>
              )
            ) : (
              <Empty></Empty>
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PerformanceComparisonReport;
