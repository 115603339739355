import React, { Fragment } from "react";
import { Table, Col, Statistic, Row } from "antd";
import { isEmpty } from "lodash";
import ScollBarView from "react-double-scrollbar";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

export default ({ dataSource, columns }) =>
  dataSource && (
    <div>
      {dataSource.map(({ block_no, areas }, i) => (
        <div key={i}>
          {areas.map(({ tableData, ...rest }, index) => (
            <div key={index}>
              <br />
              <Row gutter={5}>
                {Object.keys(rest).map((it, index) => (
                  <Fragment>
                    <Col key={"BlockNo" + index} {...layout}>
                      {<Statistic title="Block No." value={block_no} />}
                    </Col>
                    <Col key={"AreaNo" + index} {...layout}>
                      {<Statistic title="Area No." value={rest.area_no} />}
                    </Col>
                    {/* <Col key={index} {...layout}>
                      {
                        <Statistic
                          title={it
                            .split("_")
                            .map(it => `${it.toUpperCase()} `)}
                          value={rest[it]}
                        />
                      }
                    </Col> */}
                  </Fragment>
                ))}
              </Row>
              <ScollBarView>
                <Table
                  //className="block-wise-summary-dwarf-table"
                  //className="bordered-table-head"
                  bordered
                  columns={columns}
                  dataSource={tableData}
                  size="medium"
                  pagination={false}
                />
              </ScollBarView>
              <br/>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
