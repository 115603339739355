








const pad = n => {
    return n.toString().length == 1 ? '0' + n : n;
}

export const formatDate = (date) => {
    let d = new Date(date)
    return pad(d.getDate()) + '/' + pad(d.getMonth() + 1) + '/' + d.getFullYear()
}