import { mapKeys, groupBy } from "lodash";
import { sortFunction } from "../../../../../utility/sorter";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
  arr.blocks &&
    arr.blocks.map(
      (
        { total_tall_trees, total_dwarf_trees, total_trees_harvested, data },
        index
      ) => {
        var total = {
          harvest_dt: total_dwarf_trees
            ? "Total No. of Trees : " + total_dwarf_trees
            : "Total No. of Trees : " + total_tall_trees
        };
        data.unshift(total);
        data.map(
          (it, i) => {
            if (i == data.length - 1) {
              var obj = {};
              obj["harvest_dt"] = "Total";
              obj["count"] = total_trees_harvested;
              obj["start_date"] = null;
              obj["end_date"] = null;
              obj["last_update_date"] = null;
              data.push(obj);
            }
          }
          //var tree_total = { total_trees: total_trees };
        );
      }
    );
  console.log("array", arr);
  return arr;
};
