import React, { Component, Fragment } from "react";
import { Table, message, Spin } from "antd";
//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import ScollBarView from "react-double-scrollbar";
import Empty from "../../../components/Custom/Empty";
//Utility
import { cloneDeep } from "lodash";
import { parserFunction } from "./parserFunctions";
import moment from "moment";
import { Coloums } from "./columnsChecker";

class MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    DwarfTypeList: [],
    TallTypeList: [],
    type: null,
    type_uuid: null,
    activityDate: null,
    typeVisible: false,
    treeType: null
  };

  componentDidMount() {
    this.fetchDwarfType();
    this.fetchTallType();
  }

  fetchMonthlyMovementStatement = () => {
    let { activityDate, type_uuid, treeType } = this.state;
    if (activityDate) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/monthly-movement-statement?type=${treeType}&month=${activityDate}&type.uuid=${type_uuid}`,
        "get"
      ).then(res => {
        if (res)
          this.setState(
            {
              dynamicData: res,
              data: res
            },
            () => {
              let tempdata=cloneDeep(this.state.data)
              delete tempdata.blockList;
              this.setState(
                {
                  data: parserFunction(tempdata)
                },
                () => console.log("parsedata", this.state.data)
              );
            }
          );
        else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: []
      });
    }
  };

  fetchDwarfType = () => {
    fetchApi(`/tree_types?type=1`, "get").then(data =>
      this.setState({ DwarfTypeList: data })
    );
  };

  fetchTallType = () => {
    fetchApi(`/tree_types?type=2`, "get").then(data =>
      this.setState({ TallTypeList: data })
    );
  };

  onDropDownChangeHandler = (type, item) => {
    let { treeType, TallTypeList, DwarfTypeList } = this.state;
    switch (type) {
      case "treeType":
        if (item) {
          if (item === "DWARF TYPE") {
            this.setState(
              {
                typeVisible: true,
                treeType: 1,
                type: null,
                type_uuid: null
              },
              () => this.fetchMonthlyMovementStatement()
            );
          } else if (item === "TALL TYPE") {
            this.setState(
              {
                typeVisible: true,
                treeType: 2,
                type: null,
                type_uuid: null
              },
              () => this.fetchMonthlyMovementStatement()
            );
          } else if (item === "PITS") {
            this.setState(
              {
                typeVisible: false,
                treeType: 3,
                type: null,
                type_uuid: null
              },
              () => this.fetchMonthlyMovementStatement()
            );
          } else if (item === "GAPS") {
            this.setState(
              {
                typeVisible: false,
                treeType: 4,
                type: null,
                type_uuid: null
              },
              () => this.fetchMonthlyMovementStatement()
            );
          } else {
            this.setState(
              {
                typeVisible: false,
                treeType: null,
                type: null,
                type_uuid: null
              },
              () => this.fetchMonthlyMovementStatement()
            );
          }
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null,
              areaNoList: [],
              areaNumber: null,
              area_uuid: null
            },
            () => this.fetchMonthlyMovementStatement()
          );
        }
        break;

      case "type":
        this.setState(
          {
            type: item ? item : null,
            type_uuid: item
              ? cloneDeep(
                parseInt(treeType) === 1
                    ? DwarfTypeList
                    : parseInt(treeType) === 2
                    ? TallTypeList
                    : []
                ).filter(it => String(it.code) === String(item))[0].uuid
              : null
          },
          () => this.fetchMonthlyMovementStatement()
        );
        break;

      default:
        if (item) {
          this.setState({ activityDate: moment(item).format("YYYY-MM") }, () =>
            this.fetchMonthlyMovementStatement()
          );
        } else {
          this.setState({ activityDate: null }, () =>
            this.fetchMonthlyMovementStatement()
          );
        }
    }
  };

  generateExcel = () => {
    let { activityDate, type_uuid, treeType } = this.state;
    if (activityDate) {
      fetchExcelApi(
        `/regular-reports/monthly-movement-statement?type=${treeType}&month=${activityDate}&type.uuid=${type_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      dynamicData,
      loading,
      treeType,
      DwarfTypeList,
      TallTypeList,
      typeVisible,
      type,
      activityDate
    } = this.state;
    let JSX =
      dynamicData && dynamicData.blockList && dynamicData.blockList.length>0 && data && data.length > 0 ? (
        <Fragment>
          <h3>
            Monthly Movement Statement of Seedling/Plants/Trees/Pits & Gaps for{" "}
            {" " + moment(activityDate).format("MMM YYYY")}
          </h3>

          <ScollBarView>
            <Table
              bordered
              columns={Coloums(dynamicData.blockList)}
              dataSource={data}
              pagination={false}
            />
          </ScollBarView>
        </Fragment>
      ) : activityDate ? (
        <Empty description="No Data Found" />
      ) : (
        <Empty description={"Select Activity Date"} />
      );

    const buttonList =
      dynamicData && dynamicData.blockList && dynamicData.blockList.length>0 && data && data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Monthly Movement Statement of Seedling/Plants/Trees/Pits & Gaps"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Tree Type",
            listItems: ["ALL", "DWARF TYPE", "TALL TYPE", "PITS", "GAPS"],
            hasInitial: true,
            changeHandler: it => this.onDropDownChangeHandler("treeType", it)
          },
          {
            label: `${
              parseInt(treeType) === 1 ? "Dwarf" : parseInt(treeType) === 2 ? "Tall" : null
            } Type`,
            listItems:
              DwarfTypeList || TallTypeList
                ? cloneDeep(
                    parseInt(treeType) === 1
                      ? DwarfTypeList
                      : parseInt(treeType) === 2
                      ? TallTypeList
                      : []
                  ).map(it => it.code)
                : null,
            visible: typeVisible,
            value: type ? type : undefined,
            changeHandler: it => this.onDropDownChangeHandler("type", it)
          },
          {
            label: "Activity Date",
            type: "month",
            changeHandler: it =>
              this.onDropDownChangeHandler("activityDate", it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps;
