import { mapKeys, groupBy } from "lodash";
import { sortFunction } from "../../../../../utility/sorter";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
  arr.blocks &&
    arr.blocks.map(({ total_trees, total_spathes, data }, index) => {
      var total = {
        count_enrty: "Total No. of trees in the Block : " + total_trees
      };
      data.unshift(total);
      data.map(
        (it, i) => {
          //if First Row
          if (i == 1) {
            it["count_enrty"] = arr.month;
            it["control_spathes"] = total_spathes;
            var obj = {};
            obj["count_enrty"] = "Total";
            obj["control_spathes"] = total_spathes;
            obj["total_spathes"] = total_spathes;
            obj["start_date"] = null;
            obj["last_update_date"] = null;
            //if First and Last Row
            if (i == data.length - 1) {
              data.push(obj);
            }
          } //if Last Row
          else if (i == data.length - 1) {
            var obj = {};
            obj["count_enrty"] = "Total";
            obj["control_spathes"] = total_spathes;
            obj["total_spathes"] = total_spathes;
            obj["start_date"] = null;
            obj["last_update_date"] = null;
            data.push(obj);
          } else {
            if (i != 0) {
              it["count_enrty"] = null;
              it["control_spathes"] = null;
            }
          }
        }
        //var tree_total = { total_trees: total_trees };
      );
    });
  //console.log("array", arr);
  return arr;
};
