import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";

class AreaNumber extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    dynamicTableData: [],
    blockNumber: null,
    block_uuid: null,
    blockNoList: [],
    btnLoad: false,
    pagination: true,
    editData: null,
    isEdit: false,
    areaData: [],
    blockUuid: null,
    areaUuid: null,
    tableLoader: false,
    addPopulateData: null,
    isAdded: false
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchArea();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Area Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Area-Number");
  }

  fetchBlockNo = () => {
    let { blockNumber } = this.state,
      url = "/blocksuuid";
    if (blockNumber) url += `?blockNo=${blockNumber}`;
    fetchApi(url, "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchArea = () => {
    this.setState({ tableLoader: true, data: [], pagination: false });
    let { block_uuid } = this.state,
      url = "/areas";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;

    fetchApi(url, "get").then(data =>
      this.setState({
        tableLoader: false,
        data: sortFunction(data, "blockNo"),
        pagination: true
      })
    );
  };

  addModalToggle = () => {
    let {
      blockNumber,
      editData,
      addModal,
      isEdit,
      block_uuid,
      isAdded
    } = this.state;

    if (addModal) {
      this.setState({
        addModal: false,
        editData: null,
        blockNumber: isEdit || isAdded ? blockNumber : null,
        block_uuid: isEdit || isAdded ? block_uuid : null,
        addPopulateData: {
          blockNo: blockNumber
        },
        isEdit: false,
        isAdded: false
      });
      //this.fetchArea();
    } else {
      if (!blockNumber) {
        message.info("Select Block No.");
      } else {
        this.setState({ addModal: blockNumber ? !this.state.addModal : false });
      }
    }
  };

  clearFields = () => {
    this.setState({
      addPopulateData: {
        areaNo: null,
        areaName: null,
        startTree: null,
        endTree: null
      }
    });
  };

  addFormHandler = ({
    areaNo,
    startTree,
    startTreeSuffix,
    endTree,
    endTreeSuffix,
    ...val
  }) => {
    //to remove the previous instances of
    notification.destroy();

    let { block_uuid, isEdit, blockUuid, areaUuid } = this.state;
    this.setState({ btnLoad: true });
    if (!isEdit) {
      fetchApi("/areas", "post", {
        areaNo: parseInt(areaNo),
        startTree: parseInt(startTree),
        startTreeSuffix: startTreeSuffix == "--All--" ? null : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix: endTreeSuffix == "--All--" ? null : endTreeSuffix,
        block: "/blocks/" + block_uuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added an Area"
            });
            this.clearFields();
            this.setState({ isAdded: true });
            this.fetchArea();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add an Area"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    } else {
      fetchApi(`/areas/${areaUuid}`, "put", {
        areaNo: parseInt(areaNo),
        startTree: parseInt(startTree),
        startTreeSuffix:
          startTreeSuffix == "--All--" || startTreeSuffix == ""
            ? null
            : startTreeSuffix,
        endTree: parseInt(endTree),
        endTreeSuffix:
          endTreeSuffix == "--All--" || endTreeSuffix == ""
            ? null
            : endTreeSuffix,
        block: "/blocks/" + blockUuid,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully updated an Area"
            });
            this.fetchArea();
            this.addModalToggle()
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to update an Area"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        addPopulateData: {
          blockNo: it
        }
      },
      () => this.fetchArea()
    );
  };

  onEditClickHandler = item => {
    let { startTreeSuffix, endTreeSuffix, ...rest } = item;
    this.setState(
      {
        isEdit: true,
        areaData: item
      },
      () => {
        this.setState({
          blockUuid: item.blockUuid,
          areaUuid: item.uuid,
          addModal: true,
          editData: {
            startTreeSuffix: startTreeSuffix ? startTreeSuffix : "--All--",
            endTreeSuffix: endTreeSuffix ? endTreeSuffix : "--All--",
            ...rest
          }
        });
      }
    );
  };

  generateExcel = () => {
    let { block_uuid } = this.state,
      url = "/areas";
    if (block_uuid) url += `?block.uuid=${block_uuid}`;
    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      addModal,
      data,
      blockNumber,
      blockNoList,
      editData,
      pagination,
      tableLoader,
      btnLoad,
      isEdit,
      addPopulateData
    } = this.state;
    const columns = [
      {
        title: "Block No.",
        dataIndex: "blockNo"
      },
      {
        title: "Area No.",
        dataIndex: "areaNo"
      },
      {
        title: "Area Name",
        dataIndex: "areaName"
      },
      {
        title: "Start Tree No.",
        dataIndex: "startTreeNoFull"
      },
      {
        title: "End Tree No.",
        dataIndex: "endTreeNoFull"
      },
      {
        title: "Actions",
        className: "action",
        render: it => (
          <Tooltip placement="topRight" title={"Edit"}>
            <Icon onClick={() => this.onEditClickHandler(it)} type="edit" />
          </Tooltip>
        )
      }
    ],
      formItems = [
        {
          name: "areaName",
          placeholder: "Area Name",
          type: "string"
        },
        {
          name: "startTree",
          placeholder: "Start Tree No.",
          type: "number",
          postfix: true
        },
        {
          name: "endTree",
          placeholder: "End Tree No.",
          type: "number",
          postfix: true
        }
      ];
    const _formItems = isEdit
      ? [
        {
          name: "blockNo",
          placeholder: "Block No.",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number",
          disabled: true
        }
      ].concat(formItems)
      : [
        {
          name: "blockNo",
          placeholder: "Block No.",
          disabled: true
        },
        {
          name: "areaNo",
          placeholder: "Area No.",
          type: "number"
        }
      ].concat(formItems);

    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];

    return (
      <LocationPageBuilder
        title="Area Number Master"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: blockNoList.map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        addPopulateData={addPopulateData}
        btnLoad={btnLoad}
        editData={editData ? editData : null}
        formItems={_formItems}
        isEdit={isEdit}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Area Number Master - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
            : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              rowClassName="pagebreak"
              ref="table"
              columns={columns}
              dataSource={data}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default AreaNumber;
