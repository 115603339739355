import React, { Component, Fragment} from "react";

import { message, Spin } from "antd";

//Utility
import { fetchApi, fetchExcelApi } from "../../../services/api";
import { parseData } from "./parseData";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import { sortFunction, sortFunction2 } from "../../../utility/sorter";
import { COOKIE, getCookie } from "../../../services/cookie";

//Component import
import ReportLayout from "../../pageLayouts/Report";
import MultiTable from "./MultiTable3";
import Empty from "../../components/Custom/Empty";

class TallTreeHarvestData extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    harvestDate: null,
    harvest_dt: null
  };

  componentDidMount() {
    this.setState({ loading: false });
    //this.fetchData();
    this.fetchBlockNo();
  }

  fetchData = () => {
    let { area_uuid, block_uuid, harvest_dt } = this.state,
      url = "/inputformat/talltreeharvest";
    if (harvest_dt) {
      this.setState({ loading: true });

      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;

      let harvest_dtAfter = moment(harvest_dt + "-" + "01").format(
        "YYYY-MM-DD"
      );
      let harvest_dtBefore = moment(harvest_dtAfter)
        .endOf("month")
        .format("YYYY-MM-DD");
      if (block_uuid)
        url += `&harvestDt[before]=${harvest_dtBefore}&harvestDt[after]=${harvest_dtAfter}`;
      else
        url += `?harvestDt[before]=${harvest_dtBefore}&harvestDt[after]=${harvest_dtAfter}`;
      fetchApi(url, "get").then(data => {
        if (data) {
          this.setState({
            //data,
            //dynamicData: parseData(sortFunction(data, "blockNo"))
            data: sortFunction2(data, [
              "harvestedNuts",
              "bunchQty",
              "treeNo",
              "areaNo",
              "blockNo"
            ]),
            dynamicData: parseData(data)
          });
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        data: [],
        dynamicData: []
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchData()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState({ rowNoList: sortFunction(res, "rowNoFull") });
      });
    }
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchData()
    );
  };

  harvestDateHandler = it => {
    this.setState(
      {
        harvest_dt: it ? moment(it).format("YYYY-MM") : null
      },
      () => this.fetchData()
    );
  };

  // generateTables = tableData =>
  //   tableData.map(({ blockNo, areaNo, value }, index) => (
  //     <MultiTable
  //       key={index}
  //       blockNo={blockNo}
  //       areaNo={areaNo}
  //       columns={[
  //         {
  //           title: "Tree No.",
  //           dataIndex: "treeNo",
  //           key: "treeNo"
  //         },
  //         {
  //           title: "Bunch Qty",
  //           dataIndex: "bunchQty",
  //           key: "bunchQty"
  //         },
  //         {
  //           title: "Nuts Qty",
  //           dataIndex: "harvestedNuts",
  //           key: "harvestedNuts"
  //         }
  //       ]}
  //       dataSource={value}
  //     />
  //   ));

  generateTables = tableData => (
    <MultiTable
      tableData={tableData}
      columns={[
        {
          title: "Tree No.",
          dataIndex: "treeNo",
          key: "treeNo"
        },
        {
          title: "Bunch Qty",
          dataIndex: "bunchQty",
          key: "bunchQty"
        },
        {
          title: "Nuts Qty",
          dataIndex: "harvestedNuts",
          key: "harvestedNuts"
        }
      ]}
    />
  );

  generateExcel = () => {
    let { area_uuid, block_uuid, harvest_dt } = this.state,
      url = "/inputformat/talltreeharvest";
    // if (block_uuid) url += `?block.uuid=${block_uuid}`;
    // if (area_uuid) url += `&area.uuid=${area_uuid}`;
    // if (harvest_dt) {
    //   if (block_uuid) url += `&harvestDt[before]=${harvest_dt}`;
    //   else url += `?harvestDt[before]=${harvest_dt}`;
    // }
    if (harvest_dt) {
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;

      let harvest_dtAfter = moment(harvest_dt + "-" + "01").format(
        "YYYY-MM-DD"
      );
      let harvest_dtBefore = moment(harvest_dtAfter)
        .endOf("month")
        .format("YYYY-MM-DD");
      if (block_uuid)
        url += `&harvestDt[before]=${harvest_dtBefore}&harvestDt[after]=${harvest_dtAfter}`;
      else
        url += `?harvestDt[before]=${harvest_dtBefore}&harvestDt[after]=${harvest_dtAfter}`;
    }

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      dynamicData,
      loading,
      blockNoList,
      areaNoList,
      blockNumber,
      areaNumber,
      harvest_dt
    } = this.state;
    let JSX = harvest_dt ? (
      dynamicData.length > 0 ? (
        <Fragment>
          <h3>
            Tall Tree Harvest Data Input Format
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Farm : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
          <h3>Harvest Due In : {" "+moment(this.state.harvest_dt).format("MMMM YYYY")}</h3>
          {this.generateTables(dynamicData)}
        </Fragment>
      ) : (
        <Empty description={"No Data Found"} />
      )
    ) : (
      <Empty description={"Select Harvest Due In "} />
    );
    const buttonList =
      dynamicData.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        title="Tall Tree Harvest Data"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          // {
          //   label: "Harvest Due In",
          //   type: "date",
          //   changeHandler: it => this.harvestDateHandler(it)
          // }
          {
            label: "Harvest Due In",
            type: "month",
            changeHandler: this.harvestDateHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          {/* <h1>Tall Tree Harvest Data</h1> */}
            <Spin spinning={this.state.loading} size="large">
              {JSX}
            </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallTreeHarvestData;
