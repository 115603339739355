const DeathReasonList = {
    deathReasonList: ["RB - Rhinoceros Beetle",
    "RP - Red Palm Weevil",
    "OT - OFFTYPE",
    "WK - WEAK",
    "BR - Bud Rot",
    "BI - Bud Rot(Incidence)",
    "LT - Lightning",
    "WE - Wrong Entry",
    "OR - Other Reason"]
};
export default DeathReasonList;