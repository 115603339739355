import React from "react";
import MonthlyFarmProductionActivitiesReportAllBlocks from "./MonthlyFarmProductionActivitiesReportAllBlocks";
import MonthwiseFarmProductionActivitiesReportPeriodGroup from "./MonthwiseFarmProductionActivitiesReportPeriodGroup";
import WeeklySpatheEmasculationDataSummaryAllBlocks from "./WeeklySpatheEmasculationDataSummaryAllBlocks";
import DwarfTreesPopulationMonthlyAverageHarvestedNuts from "./DwarfTreesPopulationMonthlyAverageHarvestedNuts";
import TallTreesPopulationMonthlyAverageHarvestedNuts from "./TallTreesPopulationMonthlyAverageHarvestedNuts";
import DataEntryStatusReport120Days from "./DataEntryStatusReportHO/DataEntryStatusReport120Days";
import DataEntryStatusReportEmasculation from "./DataEntryStatusReportHO/DataEntryStatusReportEmasculation";
import DataEntryStatusReportGrowthTallDwarf from "./DataEntryStatusReportHO/DataEntryStatusReportGrowthTallDwarf";
import DataEntryStatusReportHarvestTallDwarf from "./DataEntryStatusReportHO/DataEntryStatusReportHarvestTallDwarf";
import DataEntryStatusReportTallMalePollenCollection from "./DataEntryStatusReportHO/DataEntryStatusReportTallMalePollenCollection";
import TreeWisePerformanceComparison3yearsPeriod from "./TreeWisePerformanceComparison3yearsPeriod";
import MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps from "./MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps";
import MonthwiseFarmPerformanceReportPeriodGroup from "./MonthwiseFarmPerformanceReportPeriodGroup";
import DwarfTallTypeDeletionReport from "./DwarfTallTypeDeletionReport";
import DwarfTreeEvaluationReport from "./DwarfTreeEvaluationReport";
import TallTreeEvaluationReport from "./TallTreeEvaluationReport";
import DwarfTallTypePopulationReport from "./DwarfTallTypePopulationReport";
import SeedlingWiseDwarfTallTypeReport from "./SeedlingWiseDwarfTallTypeReport";
import ValuationReport from "./ValuationReport";
import ValuationReportNew from "./ValuationReportNew";
import ValuationOfNewAdditions from "./ValuationOfNewAdditions";
import ValueOfDeletedTrees from "./ValueOfDeletedTrees";

export default [
  {
    label: "Monthly Farm Production Activities Report - All Blocks",
    key: "1",
    link:
      "/regular-reports/monthly-farm-production-activities-report-all-blocks",
    render: () => <MonthlyFarmProductionActivitiesReportAllBlocks />
  },
  {
    label:
      "Monthwise Farm Production Activities Report - for a Selected Period & Selected Group",
    key: "2",
    link:
      "/regular-reports/monthwise-farm-production-activities-report-selected-period-group",
    render: () => <MonthwiseFarmProductionActivitiesReportPeriodGroup />
  },
  {
    label:
      "Monthwise Farm Performance Activities Report - for a Selected Period & Selected Group",
    key: "3",
    link:
      "/regular-reports/monthwise-farm-performance-activities-report-selected-period-group",
    render: () => <MonthwiseFarmPerformanceReportPeriodGroup />
  },
  {
    label:
      "Tree Wise Performance Comparison between any 3 years & for a Selected Period",
    key: "4",
    link: "/regular-reports/tree-wise-performance-comparison-3-years-period",
    render: () => <TreeWisePerformanceComparison3yearsPeriod />
  },
  {
    label: "Monthly Movement Statement of Seedlings/Plants/Trees/Pits & Gaps",
    key: "5",
    link:
      "/regular-reports/monthly-movement-statement-seedlings-plants-trees-pits-gaps",
    render: () => <MonthlyMovementStatementSeedlingsPlantsTreesPitsGaps />
  },
  /*{
    label: "Dwarf Trees population spread Typewise based on Monthly Average Harvested Nuts",
    key: "6",
    link: "/regular-reports/dwarf-trees-population-monthly-average-harvested-nuts",
    render: () => <DwarfTreesPopulationMonthlyAverageHarvestedNuts />
  },
  {
    label: "Tall Trees population spread Typewise based on Monthly Average Harvested Nuts",
    key: "7",
    link: "/regular-reports/tall-trees-population-monthly-average-harvested-nuts",
    render: () => <TallTreesPopulationMonthlyAverageHarvestedNuts />
  },*/
  {
    label: "Weekly Spathe Emasculation Data Summary - All Blocks",
    //key: "8",
    key: "6",
    link: "/regular-reports/weekly-spathe-emasculation-data-summary-all-blocks",
    render: () => <WeeklySpatheEmasculationDataSummaryAllBlocks />
  },
  {
    label: "Dwarf/Tall Type Population Report",
    key: "7",
    link: "/regular-reports/dwarf-tall-type-population-report",
    render: () => <DwarfTallTypePopulationReport />
  },
  {
    //label: "Seedling Wise Dwarf/Tall Type Report",
    label: "New Addition Dwarf/Tall Type Report",
    key: "8",
    //link: "/regular-reports/seedling-wise-dwarf-tall-type-report",
    link: "/regular-reports/new-addition-dwarf-tall-type-report",
    render: () => <SeedlingWiseDwarfTallTypeReport />
  },
  {
    label: "Dwarf/Tall Type deletion Report",
    key: "9",
    //link: "/regular-reports/dwarf-tree-wise-deletion-report",
    link: "/regular-reports/dwarf-tall-type-deletion-report",
    render: () => <DwarfTallTypeDeletionReport />
  },
  // {
  //   label: "Valuation Report",
  //   key: "11",
  //   link: "/regular-reports/valuation-report",
  //   render: () => <ValuationReport />
  // },
  {
    label: "Valuation Report",
    key: "10",
    link: "/regular-reports/valuation-report",
    render: () => <ValuationReportNew />
  },
  {
    label: "Data Entry Status Report for H.O.",
    key: "11",
    render: null,
    link: [
      "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-120-days",
      "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-emasculation",
      "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-growth-tall-dwarf",
      "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-harvest-tall-dwarf",
      "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-tall-male-pollen-collection"
    ],
    children: [
      {
        label: "Data Entry Status Report-120 Days",
        key: "1",
        link:
          "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-120-days",
        render: () => <DataEntryStatusReport120Days />
      },
      {
        label: "Data Entry Status Report-Emasculation",
        key: "2",
        link:
          "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-emasculation",
        render: () => <DataEntryStatusReportEmasculation />
      },
      {
        label: "Data Entry Status Report-Growth-Tall/Dwarf",
        key: "3",
        link:
          "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-growth-tall-dwarf",
        render: () => <DataEntryStatusReportGrowthTallDwarf />
      },
      {
        label: "Data Entry Status Report-Harvest-Tall/Dwarf",
        key: "4",
        link:
          "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-harvest-tall-dwarf",
        render: () => <DataEntryStatusReportHarvestTallDwarf />
      },
      {
        label: "Data Entry Status Report-Tall Male Pollen Collection",
        key: "5",
        link:
          "/regular-reports/data-entry-status-report-for-H-O/data-entry-status-report-tall-male-pollen-collection",
        render: () => <DataEntryStatusReportTallMalePollenCollection />
      }
    ]
  },
  /*,
  {
    label: "Valuation Of New Additions",
    key: "12",
    link: "/regular-reports/valuation-of-new-additions",
    render: () => <ValuationOfNewAdditions />
  },
  {
    label: "Value Of Deleted Trees",
    key: "13",
    link: "/regular-reports/value-of-deleted-trees",
    render: () => <ValueOfDeletedTrees />
  }*/
  /*{
    label: "Dwarf Tree Evaluation Report",
    key: "14",
    link: "/regular-reports/dwarf-tree-evaluation-report",
    render: () => <DwarfTreeEvaluationReport />
  },
  {
    label: "Tall Tree Evaluation Report",
    key: "15",
    link: "/regular-reports/tall-tree-evaluation-report",
    render: () => <TallTreeEvaluationReport />
  }
  */
];
