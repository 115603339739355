import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";

//import dummyData from "./dwarftree_emasculation_list.json";

class PerformanceComparisonReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    farm_total: null,
    coloums: [],
    loading: false,

    blockNoList: [],
    blockNumber: null,
    block_uuid: null,

    areaNumber: null,
    area_uuid: null,
    areaNoList: [],

    treeNumber: null,
    tree_uuid: null,
    treeNoList: [],

    isopen: false,
    period: [],
    periodVS: null,
    formType: null,
    title: "Age Of Flowering",

    to_date: null,
    from_date: null,
    treeType: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    //this.fetchEmasculationList();
  }

  fetchEmasculationList = () => {
    let { block_uuid, area_uuid, from_date, to_date, tree_uuid } = this.state;
    this.setState({ data: [] });
    if (from_date && to_date) {
      let url = `/emasculation-list?dtEmasculationFrom=${from_date}&dtEmasculationTo=${to_date}`;
      if (block_uuid) url += `&block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState(
            {
              //data: res.blocks,
              //farm_total: res.farm_total
              //title: res.title
              data: res
            }
            // ,
            // () => console.log("Block details", res.blocks)
          );
        } else {
          message.error("Internal Server Error!");
          this.setState({
            data: null
          });
        }
        this.setState({ loading: false });
      });
    }

    // this.setState(
    //   { data: dummyData.blocks, farm_total: dummyData.farm_total },
    //   () => console.log("Dummy Data", this.state.data)
    // );
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
          cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
              .uuid
          : null,
        areaNumber: null,
        area_uuid: null
        //areaNoList: null
      },
      () => this.fetchEmasculationList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    if (it) {
      fetchApi(
        `/dwarves?area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null,
            tree_uuid: null
            //spatheNo: null
          },
          () => this.fetchEmasculationList()
        )
      );
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          // rowNumber: null,
          // row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null
          //spatheNo: null
        },
        () => this.fetchEmasculationList()
      );
    }
    // this.setState(
    //   {
    //     areaNumber: it ? it : null,
    //     area_uuid: it
    //       ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
    //       : null
    //   },
    //   () => this.fetchEmasculationList()
    // );
  };

  onTreeChangeHandler = it => {
    let { treeNoList } = this.state;

    if (it) {
      this.setState({ treeNumber: it });
      fetchApi(
        `/dwarves/${
          cloneDeep(treeNoList).filter(item => item.treeNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            treeNumber: it,
            tree_uuid: cloneDeep(treeNoList).filter(
              item => item.treeNoFull == it
            )[0].uuid,
            spatheNo: res.nextSpatheNo
          },
          () => this.fetchEmasculationList()
        )
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        },
        () => this.fetchEmasculationList()
      );
    }
  };

  // fromDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         from_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchEmasculationList()
  //     );
  //   } else {
  //     this.setState(
  //       {
  //         from_date: null
  //       },
  //       () => this.fetchEmasculationList()
  //     );
  //   }
  // };

  // toDateHandler = date => {
  //   if (date) {
  //     this.setState(
  //       {
  //         to_date: date.format("YYYY-MM-DD")
  //       },
  //       () => this.fetchEmasculationList()
  //     );
  //   } else {
  //     this.setState(
  //       {
  //         to_date: null
  //       },
  //       () => this.fetchEmasculationList()
  //     );
  //   }
  // };

  onRangeChangeHandler = date => {
    if (date.length > 0) {
      this.setState(
        {
          from_date: date[0].format("YYYY-MM-DD"),
          to_date: date[1].format("YYYY-MM-DD")
        },
        () => this.fetchEmasculationList()
      );
    } else {
      this.setState(
        {
          from_date: null,
          to_date: null,
          blockNumber: null,
          block_uuid: null,
          data: [],
          farm_count_auto: null,
          farm_count_manual: null
        },
        () => this.fetchEmasculationList()
      );
    }
  };

  // generateExcel = () => {
  //   let { block_uuid, area_uuid } = this.state;
  //   let url = `/special-reports/age-of-flowering-dwarf-trees?block.uuid=${block_uuid}`;
  //   if (block_uuid) {
  //     if (area_uuid) url += `&area.uuid=${area_uuid}`;
  //     fetchExcelApi(url, "get").then();
  //   } else {
  //     message.error("Please Select A Block No.!");
  //   }
  // };

  //generateTables = tableData => <MultiTable dataSource={tableData} />;

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      areaNoList,
      areaNumber,
      treeType,
      farm_total,
      treeNoList,
      treeNumber,
      from_date,
      to_date
    } = this.state;

    let JSX = data ? (
      <MultiTable dataSource={data} farmTotal={farm_total} fromdate={from_date} todate={to_date}/>
    ) : null;

    const buttonList =
      data && data.blocks
        ? [
            // {
            //   label: "Excel",
            //   icon: "file-text",
            //   onClickHandler: () => this.generateExcel()
            // },
            {
              label: "Print",
              icon: "diff",
              reference: this.componentRef
            }
          ]
        : null;

    return (
      <ReportLayout
        title={"Dwarf Tree Emasculation - List"}
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          // {
          //   label: "Tree No",
          //   listItems: ["Dwarf", "Tall"],
          //   //disabled: blockNumber ? false : true,
          //   value: treeNo ? treeNo : undefined
          //   //changeHandler: it => this.onTreeTypeChangeHandler(it)
          // },
          {
            label: "Tree No.",
            listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
            disabled: areaNumber ? false : true,
            value: treeNumber ? treeNumber : undefined,
            changeHandler: it => this.onTreeChangeHandler(it)
          },
          // {
          //   label: "From",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.fromDateHandler
          // },
          // {
          //   label: "To",
          //   type: "date",
          //   hasInitial: false,
          //   changeHandler: this.toDateHandler
          // }
          {
            label: "Emasculation Date:",
            type: "range",
            //mode: ["date", "date"],
            format: "DD/MM/YYYY",
            changeHandler: (date, string) => this.onRangeChangeHandler(date)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {/*data ? (
              data.length > 0 ? (
                <div> {JSX}</div>
              ) : (
                <Empty description={"Select Emasculation Date"}></Empty>
              )
            ) : (
              <Empty></Empty>
            )*/}
            {data && data.blocks ? (
              <div>{JSX}</div>
            ) : to_date && from_date ? (
              <Empty description="No Data Found" />
            ) : (
              <Empty description={"Select Emasculation Date"} />
            )}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PerformanceComparisonReport;
