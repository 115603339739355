import React, { Component, Fragment } from "react";
import { Table, message, Spin, Statistic } from "antd";

//Component import
import ReportLayout from "../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import MultiTable from "./MultiTableWeeklySpathe";
import Empty from "../../../components/Custom/Empty";

//Utility
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import { parserFunction } from "./parserFunctions";
import { sortFunction } from "../../../../utility/sorter";
import moment from "moment";
import { columns, columns_farm } from "./columns";

class WeeklySpatheEmasculationDataSummaryAllBlocks extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    farm: [],
    month: null,
    loading: false,
    tempData: [],
    blockNoList: [],
    block_uuid: null,
    activityPeriod: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchWeeklySpatheEmasculation = () => {
    let { block_uuid, activityPeriod } = this.state;
    if (activityPeriod) {
      this.setState({ loading: true });
      fetchApi(
        `/regular-reports/weekly-spathes-emasculated?month=${activityPeriod}&block.uuid=${block_uuid}`,
        "get"
      ).then(res => {
        if (res) {
          res.blocks
            ? this.setState(
              {
                tempData: parserFunction(res.blocks),
                data: parserFunction(res.blocks),
                farm: res.farm ? parserFunction([res.farm]) : [],
                month: res.month
              },
              // () => {
              //   this.state.tempData.push(...this.state.farm);
              //   this.setState({ data: this.state.tempData });
              // }
            )
            : this.setState(
              {
                data: [],
                tempData: [],
                farm: [],
                month: null
              },
              // () => {
              //   this.state.tempData.push(...this.state.farm);
              //   this.setState({ data: this.state.tempData });
              // }
            );
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({
        tempData: [],
        data: [],
        farm: null
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    if (it) {
      this.setState(
        {
          blockNumber: it ? it : null,
          block_uuid: it
            ? cloneDeep(this.state.blockNoList).filter(
              each => each.blockNoFull === it
            )[0].uuid
            : null
        },
        () => this.fetchWeeklySpatheEmasculation()
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null
        },
        () => this.fetchWeeklySpatheEmasculation()
      );
    }
  };

  activityPeriodHandler = date => {
    if (date) {
      this.setState(
        {
          activityPeriod: moment(date).format("YYYY-MM")
        },
        () => this.fetchWeeklySpatheEmasculation()
      );
    } else {
      this.setState(
        {
          activityPeriod: null,
          data: []
        },
        () => this.fetchWeeklySpatheEmasculation()
      );
    }
  };

  generateExcel = () => {
    let { block_uuid, activityPeriod } = this.state;
    if (activityPeriod) {
      fetchExcelApi(
        `/regular-reports/weekly-spathes-emasculated?month=${activityPeriod}&block.uuid=${block_uuid}`,
        "get"
      ).then();
    }
  };

  render() {
    let {
      data,
      loading,
      blockNoList,
      blockNumber,
      activityPeriod,
      month,
      farm
    } = this.state;
    let JSX =
      data.length > 0 ? (
        <Fragment>
          <MultiTable columns={columns} dataSource={data} month={month} />
          <MultiTable columns={columns_farm} dataSource={farm} month={month} />
        </Fragment>
      ) : activityPeriod ? (
        <Empty description="No Data Found" />
      ) : (
            <Empty description={"Select Activity Period"} />
          );
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null;
    return (
      <ReportLayout
        title="Weekly Spathe Emasculation Data Summary - All Blocks"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Activities Period",
            type: "month",
            //value: activityPeriod ? activityPeriod : undefined,
            changeHandler: this.activityPeriodHandler
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default WeeklySpatheEmasculationDataSummaryAllBlocks;
