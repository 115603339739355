import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import moment from "moment";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

let columns = [
  {
    title: "Block No.",
    dataIndex: "block_no_full"
    //render: it => (it ? it : "-")
  },
  {
    title: "Area No.",
    dataIndex: "area_no"
    //render: it => (it ? it : "-")
  },
  {
    title: "Tree No.",
    dataIndex: "tree_no_full"
    //render: it => (it ? it : "-")
  },
  {
    title: "Date Planted",
    dataIndex: "dt_planted",
    render: it => moment(it).format("DD/MM/YYYY")
  },
  {
    title: "Date Flowered",
    dataIndex: "dt_flowered",
    render: it => moment(it).format("DD/MM/YYYY")
  },
  {
    title: "Age In Days",
    dataIndex: "days"
    //render: it => (it ? it : "-")
  },
  {
    title: "Age In Months",
    dataIndex: "months"
    //render: it => (it ? it : "-")
  },
  {
    title: "Age In Years",
    dataIndex: "years"
    //render: it => (it ? it : "-")
  }
];

export default ({ dataSource }) =>
<div>
<h3>Age Of Flowering For Dwarf Trees &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  {dataSource.map(({ block_no, trees }, index) => (
    <div key={index}>
      {/* <Col span={12}>
        <Statistic title="" value={"Age Of Flowering For Dwarf Trees"} />
      </Col>
      <Col span={12}>
        <Statistic title="" value={"Farm : " + "gg"} />
      </Col> */}

      <Table
        //className="block-wise-summary-dwarf-table"
        //key={j}
        className="bordered-table-head"
        bordered
        size="small"
        columns={columns}
        dataSource={trees}
        pagination={false}
      />
    </div>
  ))}
  </div>;
