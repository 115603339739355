import React from "react";

export const farmColumns = (count_120_month, harvest_ems_month) => [
  {
    title: "BLOCKS",
    className: "reports-right-border",
    children: [
      // {
      //   title: "Block No.",
      //   render: it =>
      //     it.block_no
      //       ? it.block_no.length > 7
      //         ? {
      //             children: it.block_no,
      //             props: {
      //               colSpan: 2
      //             }
      //           }
      //         : it.block_no
      //       : {
      //           children: it.total,
      //           props: {
      //             colSpan: 2
      //           }
      //         }
      // },
      {
        title: "Dwarf Type",
        dataIndex: "tree_code"
        // render: it =>
        //   it.tree_code
        //     ? it.block_no
        //       ? it.block_no.length > 7
        //         ? {
        //             props: {
        //               colSpan: 0
        //             }
        //           }
        //         : it.tree_code
        //       : {
        //           props: {
        //             colSpan: 0
        //           }
        //         }
        //     : {
        //         props: {
        //           colSpan: 0
        //         }
        //       }
      },
      {
        title: "No. of Trees",
        dataIndex: "no_of_tree"
      },
      {
        title: "No. of Plants",
        dataIndex: "no_of_plants"
      },
      {
        title: "Total Population",
        className: "reports-right-border",
        dataIndex: "total_population"
      }
    ]
  },
  {
    title: "EMASCULATION DATA",
    className: "reports-right-border",
    children: [
      {
        title: "Trees Emasc",
        dataIndex: "trees_emasc"
      },
      {
        title: "P/M Spathes",
        dataIndex: "pm_spathe"
      },
      {
        title: "Good Spathes",
        dataIndex: "good_spathes"
      },
      {
        title: "Spathes/ TreeEm",
        dataIndex: "spathes_treesemasc"
      },
      {
        title: "Spathes/ TotalPP",
        dataIndex: "spathes_treepop"
      },
      {
        title: "Total Buttons",
        dataIndex: "buttons_pol"
      },
      {
        title: "Button/ Spathe",
        className: "reports-right-border",
        dataIndex: "button_spathes"
      }
    ]
  },
  {
    title: "120 DAYS NUTS COUNT",
    className: "reports-right-border",
    children: [
      {
        title: count_120_month + " Spathes",
        dataIndex: "sep_spathes"
      },
      {
        title: count_120_month + " Buttons",
        dataIndex: "sep_buttons"
      },
      {
        title: "120 Days Count",
        dataIndex: "nuts"
      },
      {
        title: "Nuts/ Spathe",
        dataIndex: "nuts_spathe"
      },
      {
        title: "120 Days Setting %",
        className: "reports-right-border",
        dataIndex: "days_setting"
      }
    ]
  },
  {
    title: "HARVEST DATA ANALYSIS",
    className: "reports-right-border",
    children: [
      {
        title: harvest_ems_month + "-Total Trees",
        dataIndex: "jan_total_trees"
      },
      {
        title: harvest_ems_month + " Buttons",
        dataIndex: "jan_buttons"
      },
      {
        title: "120 days Count",
        dataIndex: "nuts_counts"
      },
      {
        title: "Harvested Nuts",
        dataIndex: "harvested_nuts"
      },
      {
        title: "Trees Harvested",
        dataIndex: "trees_harvested"
      },
      {
        title: "Nuts/ Harv. Tree",
        dataIndex: "nuts_by_trees"
      },
      {
        title: "Nuts/ Tot. Trees",
        dataIndex: "nuts_by_tot_trees"
      },
      {
        title: "Good Nuts",
        dataIndex: "good_nuts"
      },
      {
        title: "Rejected Nuts",
        dataIndex: "rejected_nuts"
      },
      {
        title: "% of Good Nuts",
        dataIndex: "percent_good_nuts"
      },
      {
        title: "% of Rejected Nuts",
        dataIndex: "percent_rejected_nuts"
      },
      {
        title: "% of Yield to",
        className: "reports-right-border",
        children: [
          {
            title: "Buttons",
            dataIndex: "percent_yield_buttons"
          },
          {
            title: "120 days",
            className: "reports-right-border",
            dataIndex: "percent_yield_days_count"
          }
        ]
      }
    ]
  }
];
