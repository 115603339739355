import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import { Skeleton, Col, Statistic, Row, Descriptions, Badge, Spin } from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
//import MultiTable from "./MultiTableDataEntryStatus";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { cloneDeep, filter, mapKeys } from "lodash";
import moment from "moment";

//Import json object
import dummyData from "./dummy.json";

import { parseData } from "../../parserFunctionsTypeList";
import { sortFunction } from "../../../../../utility/sorter";
import Empty from "../../../../components/Custom/Empty";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class DwarfSeedlingTypeList extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    dynamicData: [],
    loading: false,

    blockNoList: [],
    block_uuid: null,
    blockNumber: null,

    areaNoList: [],
    areaNumber: null,
    area_uuid: null,

    code: null,
    codeList: [],
    tree_type_uuid2: null,

    stage: null,

    TallType: null,
    TallTypeList: [],
    TallType_uuid: null,

    DateOfSummary: null,
    farm_codes: null,
    farm_total: 0
  };

  componentDidMount() {
    this.fetchBlockNo();
    //this.fetchTallType();
    //this.fetchTallAgeTypeWiseList();
    this.fetchTypeList();
    this.fetchCodeList();
  }

  fetchTypeList = () => {
    this.setState({ loading: true });
    let { area_uuid, block_uuid, tree_type_uuid2 } = this.state,
      url = "/api/dwarves/get-type-list-dwarf?stage_code=1";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (tree_type_uuid2) url += `&tree_type.uuid=${tree_type_uuid2}`;
    // if (tree_type_uuid2) {
    //   console.log("Area Uuid", area_uuid);
    //   console.log("Block uuid", block_uuid);
    //   console.log("Tree Type uuid", tree_type_uuid2);
    // }
    fetchApi(url, "get")
      .then(res => {
        console.log("The api response", res.data);
        this.setState(
          {
            dynamicData: parseData(cloneDeep(res.data)),
            farm_codes: res.farm_codes,
            farm_total: res.farm_total,
            loading: false
          },
          () => {
            console.log(this.state.dynamicData);
          }
        );
      })
      .catch(err => {
        console.log("The Error", err);
      });

    // this.setState({
    //   dynamicData: parseData(cloneDeep(dummyData.data)),
    //   farm_codes: res.farm_codes,
    //   farm_total: res.farm_total,
    //   loading: false
    // });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  // fetchTallType = () =>
  //   fetchApi("/tree_types?type=2", "get").then(data =>
  //     this.setState({ TallTypeList: data })
  //   );

  fetchCodeList = () => {
    fetchApi(`/tree_types?type=1`, "get").then(res =>
      this.setState({ codeList: res })
    );
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
        //tree_type_uuid2: null
      },
      () => this.fetchTypeList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;
    if (it) {
    }
    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
        //code: null,

        //codeList: [],
        //tree_type_uuid2: null
      },
      () => this.fetchTypeList()
    );
  };

  onCodeChangeHandler = it => {
    //console.log("It is", it);
    let { codeList } = this.state;
    this.setState(
      {
        code: it ? it : null,
        tree_type_uuid2: it
          ? cloneDeep(codeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchTypeList()
    );
  };

  onTreeTypeChangeHandler = it => {
    this.setState(
      {
        stage:
          it == "Seedling" ? 1 : it == "Plant" ? 2 : it == "Tree" ? 3 : null
      },
      () => this.fetchTallAgeTypeWiseList()
    );
  };
  onTallTypeChangeHandler = it => {
    let { TallTypeList } = this.state;
    this.setState(
      {
        TallType: it ? it : null,
        TallType_uuid: it
          ? cloneDeep(TallTypeList).filter(item => item.code == it)[0].uuid
          : null
      },
      () => this.fetchTallAgeTypeWiseList()
    );
  };
  dateOfSummaryOnHandler = date => {
    if (date) {
      this.setState(
        {
          DateOfSummary: date.format("YYYY-MM-DD")
        },
        () => this.fetchTallAgeTypeWiseList()
      );
    }
  };

  fetchTallAgeTypeWiseList = () => {
    let { block_uuid, stage, TallType_uuid, DateOfSummary } = this.state,
      today = moment().format("YYYY-MM-DD");
    //console.log(block_uuid, stage, TallType_uuid, DateOfSummary);
    //console.log(today);
    fetchApi(
      `/tree-age/block-wise-list-tall?date=${
      DateOfSummary ? DateOfSummary : today
      }&stage_code=${
      stage ? stage : 1
      }&block.uuid=${block_uuid}&tree_type.uuid=${TallType_uuid}`,
      "get"
    ).then(data =>
      this.setState({ tableLoader: false, data, dynamicData: data.data })
    );
  };

  generateTables = tableData => (
    <div>
      <h3>
        Dwarf Seedling Master - Type Wise List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      {tableData.map(
        ({ Blocks, block_total, block_no, Block_codes, Areas }, index) => (
          <div key={index}>
            {Areas.map(({ block_data, area_count }, i) => (
              //console.log("Tree Nos", tree_nos)
              <div key={i}>
                {block_data.map(({ tree_nos, total_count, ...rest }, j) => (
                  <div key={j} className={index == 0 && i == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
                    {tree_nos && (
                      <div>
                        <Col key={j} {...layout}>
                          <Statistic
                            title="Block No."
                            value={rest["block_no"]}
                          />
                        </Col>
                        <Col key={j} {...layout}>
                          <Statistic title="Area No." value={rest["area_no"]} />
                        </Col>
                        <Col key={j} {...layout}>
                          <Statistic title="Type" value={rest["code"]} />
                        </Col>
                      </div>
                    )}
                    {tree_nos && (
                      <Descriptions bordered className="p-0">
                        {/* {console.log("areaaa no", rest["area_no"])} */}
                        <Descriptions.Item label="Tree No." span={3}>
                          {tree_nos.map((it, i) => (
                            <Col>
                              <span key={i} style={{ margin: "0px 10px" }}>
                                {it}
                              </span>
                            </Col>
                          ))}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="No. of Dwarf Seedling"
                          span={3}
                        >
                          {total_count}
                        </Descriptions.Item>
                        <Descriptions.Item span={6}></Descriptions.Item>
                      </Descriptions>
                    )}
                    {j == block_data.length - 1 ? (
                      <br />
                    ) : (
                        <Fragment><br /><br /></Fragment>
                      )}
                  </div>
                ))}
                {area_count > 0 && (
                  <Descriptions bordered>
                    <Descriptions.Item
                      label="No. of Dwarf Seedling Area Wise"
                      span={3}
                    >
                      {area_count}
                    </Descriptions.Item>
                  </Descriptions>
                )}
                {i == Areas.length - 1 ? (
                  <br />
                ) : (
                    <Fragment><br /><br /></Fragment>
                  )}
              </div>
            ))}
            {Block_codes && (
              <Descriptions bordered>
                <Descriptions.Item
                  label={"Total Dwarf Seedling in Block"}
                  span={3}
                >
                  {block_no}
                </Descriptions.Item>
                {Block_codes.map(({ Code, Area_count }, i) => (
                  <Descriptions.Item
                    key={i}
                    label={"Type " + Code}
                    span={3}
                  >
                    {Area_count}
                  </Descriptions.Item>
                ))}
                <Descriptions.Item label={"All Types"} span={3}>
                  {block_total}
                </Descriptions.Item>
              </Descriptions>
            )}
            {index == tableData.length - 1 ? (
              <br />
            ) : (
                <Fragment><br /><br /></Fragment>
              )}
          </div>
        )
      )}
      {this.state.farm_codes && (
        <Descriptions bordered>
          <Descriptions.Item
            label={"Total Dwarf Seedling in Farm"}
            span={3}
          >
          </Descriptions.Item>
          {this.state.farm_codes &&
            this.state.farm_codes.map(({ code, farm_count }, i) => (
              <Descriptions.Item key={i} label={"Type " + code} span={3}>
                {farm_count}
              </Descriptions.Item>
            ))}
          <Descriptions.Item label={"All Types"} span={3}>
            {this.state.farm_total}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );

  generateExcel = () => {
    let { area_uuid, block_uuid, tree_type_uuid2 } = this.state,
      url = "/api/dwarves/get-type-list-dwarf?stage_code=1";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;
    if (tree_type_uuid2) url += `&tree_type.uuid=${tree_type_uuid2}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      dynamicData,
      loading,
      TallTypeList,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNoList,
      code,
      codeList
    } = this.state;
    const buttonList = dynamicData
      ? dynamicData.length > 0
        ? [
          {
            label: "Excel",
            icon: "file-text",
            onClickHandler: () => this.generateExcel()
          },
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          }
        ]
        : null
      : null;
    let JSX = dynamicData ? this.generateTables(dynamicData) : null;
    const columns = [
      {
        title: "Area Count",
        dataIndex: "area_count"
      },
      {
        title: "Total Count",
        dataIndex: "total_count",
        render: it => (it ? it : 0)
      }
    ];
    //console.log("Dynamic data", dynamicData);
    return (
      <ReportLayout
        title="Dwarf Seedling Master - Type Wise List"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          },
          {
            label: "Type",
            listItems: cloneDeep(codeList).map(it => it.code),

            value: code ? code : undefined,
            changeHandler: it => this.onCodeChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {dynamicData ? <div>{JSX}</div> : <Empty />}
            {/* <MultiTable dataSource={dynamicData} columns={columns}></MultiTable> */}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default DwarfSeedlingTypeList;
