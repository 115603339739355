import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { cloneDeep } from "lodash";
import moment from "moment";

class PerformanceComparisonReport extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    isopen: false,
    isopenVS: false,
    period: [],
    periodVS: null,
    formType: null,
    researchType: null,
    researchTypeList: [],
    researchType_uuid: null,
    researchTypeVS: null,
    researchTypeVS_uuid: null,
    years: 0,
    research: 0,
    title: "Performance Summary",
    valueVS: null,
    value: null
  };

  componentDidMount() {
    this.fetchBlockNo();
    this.fetchResearchGroup();
  }

  fetchPerformanceComparisonReport = () => {
    this.setState({
      data: [],
      research: 0,
      years: 0
    })
    let {
        block_uuid,
        researchType_uuid,
        researchTypeVS_uuid,
        period,
        periodVS,
        formType
      } = this.state,
      flag = 0,
      url = `/special-reports/get-performance-comparison-report`;
    if (formType == "Research Group") {
      if (researchTypeVS_uuid && periodVS) {
        url += `?research.uuid=${researchType_uuid}&research.uuid2=${researchTypeVS_uuid}&from_year=${periodVS}`;
        flag = 1;
      }
    } else if (formType == "Period") {
      if (period.length > 0 && researchType_uuid) {
        url += `?research.uuid=${researchType_uuid}&from_year=${period[0]}&to_year=${period[1]}`;
        flag = 1;
      }
    }
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (flag) {
      this.setState({ loading: true });
      fetchApi(url, "get").then(res => {
        if (res) {
          this.setState({
            data: res.blocks,
            title: res.title
          });
          this.state.data.map(({ ...record }) =>
            record.years
              ? this.setState({
                  years: 1
                })
              : record.research
              ? this.setState({
                  research: 1
                })
              : null
          );
        } else {
          message.error("Internal Server Error!");
          this.setState({
            data: []
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  fetchResearchGroup = () =>
    fetchApi(
      "/researches?properties[]=uuid&properties[]=code&type=1",
      "get"
    ).then(data => this.setState({ researchTypeList: data }));

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  handleOpenChangeVS = status => {
    if (status) {
      this.setState({ isopenVS: true });
    } else {
      this.setState({ isopenVS: false });
    }
  };

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        if (item) {
          this.setState(
            {
              blockNumber: item ? item : null,
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                    each => each.blockNoFull === item
                  )[0].uuid
                : null
            },
            () => this.fetchPerformanceComparisonReport()
          );
        } else {
          this.setState(
            {
              blockNumber: null,
              block_uuid: null
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
        break;

      case "formType":
        if (item) {
          this.setState(
            {
              formType: item,
              researchType: null,
              researchType_uuid: null,
              researchTypeVS: null,
              researchTypeVS_uuid: null,
              period: [],
              periodVS: null,
              value: null,
              valueVS: null,
              title: "Performance Summary",
              years: 0,
              research: 0
            },
            () => this.fetchPerformanceComparisonReport()
          );
        } else {
          this.setState(
            {
              formType: null,
              researchType: null,
              researchType_uuid: null,
              researchTypeVS: null,
              researchTypeVS_uuid: null,
              period: [],
              periodVS: null,
              value: null,
              valueVS: null,
              title: "Performance Summary",
              years: 0,
              research: 0
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
        break;
      case "researchType":
        if (item) {
          this.setState(
            {
              researchType: item ? item : null,
              researchType_uuid: item
                ? cloneDeep(this.state.researchTypeList).filter(
                    each => each.code === item
                  )[0].uuid
                : null
            },
            () => this.fetchPerformanceComparisonReport()
          );
        } else {
          this.setState(
            {
              researchType: null,
              researchType_uuid: null,
              title: "Performance Summary"
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
        break;
      case "researchTypeVS":
        if (item) {
          this.setState(
            {
              researchTypeVS: item ? item : null,
              researchTypeVS_uuid: item
                ? cloneDeep(this.state.researchTypeList).filter(
                    each => each.code === item
                  )[0].uuid
                : null
            },
            () => this.fetchPerformanceComparisonReport()
          );
        } else {
          this.setState(
            {
              researchTypeVS: null,
              researchTypeVS_uuid: null
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
        break;

      //default:
      case "period":
        if (item.length > 0) {
          item[0] > item[1]
            ? this.setState( 
                {
                  //value: item,
                  value: [item[1], item[0]],
                  period: [
                    moment(item[1]).format("YYYY"),
                    moment(item[0]).format("YYYY")
                  ],
                  isopen: false
                },
                () => this.fetchPerformanceComparisonReport()
              )
            : this.setState(
                {
                  value: item,
                  period: [
                    moment(item[0]).format("YYYY"),
                    moment(item[1]).format("YYYY")
                  ],
                  isopen: false
                },
                () => this.fetchPerformanceComparisonReport()
              );
          // this.setState(
          //   {
          //     value: item,
          //     period: [
          //       moment(item[0]).format("YYYY"),
          //       moment(item[1]).format("YYYY")
          //     ],
          //     isopen: false
          //   },
          //   () => this.fetchPerformanceComparisonReport()
          // );
        } else {
          this.setState(
            {
              value: null,
              period: [],
              isopen: false,
              data: []
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
        break;
      case "periodVS":
        if (item) {
          this.setState(
            {
              valueVS: item,
              periodVS: moment(item).format("YYYY"),
              isopenVS: false
            },
            () => this.fetchPerformanceComparisonReport()
          );
        } else {
          this.setState(
            {
              valueVS: null,
              periodVS: null,
              isopenVS: false,
              data: []
            },
            () => this.fetchPerformanceComparisonReport()
          );
        }
    }
  };

  generateExcel = () => {
    let {
        block_uuid,
        researchType_uuid,
        researchTypeVS_uuid,
        period,
        periodVS,
        formType
      } = this.state,
      flag = 0,
      url = `/special-reports/get-performance-comparison-report`;
    if (formType == "Research Group") {
      if (researchTypeVS_uuid && periodVS) {
        url += `?research.uuid=${researchType_uuid}&research.uuid2=${researchTypeVS_uuid}&from_year=${periodVS}`;
        flag = 1;
      }
    } else if (formType == "Period") {
      if (period.length > 0 && researchType_uuid) {
        url += `?research.uuid=${researchType_uuid}&from_year=${period[0]}&to_year=${period[1]}`;
        flag = 1;
      }
    }
    if (block_uuid) url += `&block.uuid=${block_uuid}`;

    if (flag) {
      fetchExcelApi(url, "get").then();
    }
  };
  generateTables =( tableData,title )=> <MultiTable dataSource={tableData} title={title}/>;

  render() {
    let {
      data,
      blockNoList,
      blockNumber,
      loading,
      isopen,
      isopenVS,
      value,
      valueVS,
      researchTypeList,
      researchType,
      formType,
      researchType_uuid,
      researchTypeVS,
      researchTypeVS_uuid,
      period,
      periodVS,
      title,
      years,
      research
    } = this.state;
    let JSX =
      (data.length > 0 && years) || (data.length > 0 && research) ? (
        console.log("tableData",data),
        this.generateTables(data,title)
      ) : formType == "Research Group" ? (
        researchType_uuid && researchTypeVS_uuid && periodVS ? (
          <Empty />
        ) : (
          <Empty
            description={"Select Research Type, V/S Research Type and Period"}
          />
        )
      ) : formType == "Period" ? (
        researchType_uuid && period.length > 0 ? (
          <Empty />
        ) : (
          <Empty description={"Select Form Type, Research Type and Period"} />
        )
      ) : (
        <Empty description={"Select Form Type, Research Type and Period"} />
      );
    const buttonList =
      (data.length > 0 && years) || (data.length > 0 && research)
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;

    return (
      <ReportLayout
        title={"Performance Summary"
          //title
        }
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Form Type",
            listItems: ["Period", "Research Group"],
            value: formType ? formType : undefined,
            changeHandler: it => this.onDropDownChangeHandler("formType", it)
          },
          {
            label: "Research Type",
            disabled: formType ? false : true,
            listItems: cloneDeep(researchTypeList).map(it => it.code),
            value: researchType ? researchType : undefined,
            changeHandler: it =>
              this.onDropDownChangeHandler("researchType", it)
          },
          {
            label: "V/S Research Type",
            listItems: cloneDeep(researchTypeList).map(it => it.code),
            value: researchTypeVS ? researchTypeVS : undefined,
            visible: formType == "Research Group" ? true : false,
            changeHandler: it =>
              this.onDropDownChangeHandler("researchTypeVS", it)
          },
          formType == "Research Group"
            ? {
                label: "Period",
                mode: "year",
                type: "date",
                value: valueVS,
                open: isopenVS,
                format: "YYYY",
                showTime: true,
                onOpenChange: this.handleOpenChangeVS,
                onPanelChange: val =>
                  this.setState({ valueVS: val }, () =>
                    this.onDropDownChangeHandler("periodVS", val)
                  ),
                changeHandler: () =>
                  //this.onDropDownChangeHandler("research: 0", [])
                  this.onDropDownChangeHandler("periodVS", null)
              }
            : {
                label: "Period",
                mode: ["year", "year"],
                value: value,
                type: "range",
                format: "YYYY",
                open: isopen,
                showTime: true,
                disabled: formType == "Period" ? false : true,
                onOk: date => this.onDropDownChangeHandler("period", date),
                onOpenChange: this.handleOpenChange,
                onPanelChange: val => this.setState({ value: val }),
                changeHandler: () => this.onDropDownChangeHandler("period", [])
              }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default PerformanceComparisonReport;
