import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import {render} from '@testing-library/react';


import { Table, Icon, message, notification, Tooltip, Spin, Modal, Row, Col, Button} from "antd";


//Component import
import LocationPageBuilder from "../../MasterFiles/PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { cloneDeep, filter, values } from "lodash";
import { fetchApi } from "../../../../services/api";
import { formatDate } from "../../../../utility/date";
import moment from "moment";

class DwarfTreeEmasculation extends Component {
  componentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      treeTypeData:[],
      blockNoList: [],
    data: [],
    blockNumber: null,
    areaNoList: [],
    areaNumber: null,
    areaNumberForm: null,
    pagination: true,
    dateOfEmasculation: null,
    EmasculationDate: null,
    addModal: false,
    visibleConfirm: false,
    editData: null,
    btnLoad: false,
    rowNumber: null,
    rowNoList: [],
    iEdit: false,
    addPopulateData: null,
    isPrematureSpathe: false,
    spatheNo: null,
    stageCode: null,
    confirmedId:null,
    dtPlanted: null,
    loading: false,
    area_uuid: null,
    block_uuid: null,
    row_uuid: null,
    treeNoList: [],
    formTreeNoList: [],
    treeNumber: null,
    treeNumberAreaRowUuid: null,
    tree_uuid: null,
    dwarfData: null,
    isDelete: false,
    treeTypeCode:null,
    visible:false,
    record:null,
    treeTypeId:null,
    isConfirm:false,
    storageId:null,
    storageCode:null,
    recordId:null,
    recordCode:null,
    treeTypeStatus:false,
    spatheNumber:null,
    addFormModal:false,
    visibleAdd:false,
    change:false,
    };
  }

  componentDidMount() {
    this.dwarfTreeEmasculation();
    this.fetchBlockNo();
   this.fetchTreeType();
   
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0),
      cell = row.insertCell(0);
    row.innerHTML = "<h1>Dwarf Tree Emasculation</h1>";
    table.setAttribute("id", "table-to-xls");
    //table.setAttribute("class", "Dwarf-Tree-Emasculation");
  }

  fetchTreeType = ()=>{
    let {treeTypeData} = this.state;
    
    fetchApi(
      "/tree_types?type=1","get").then((response) =>{
      this.setState({treeTypeData:response});
      console.log(this.state.treeTypeData);
      return treeTypeData;
     });
      
     }

  dwarfTreeEmasculation = (reload = true) => {
    if (reload) {
      this.setState({ loading: true, data: [], pagination: false });
      let {
        block_uuid,
        area_uuid,
        row_uuid,
        tree_uuid,
        formTreeNoList,
        dateOfEmasculation,
        treeTypeCode,
        EmasculationDate,
        treeType_Id,
        record
      } = this.state,
        url = "/emasculation";
       
        console.log("tree id",tree_uuid);
        console.log("block idd",block_uuid);
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (area_uuid) url += `&area.uuid=${area_uuid}`;
      //if (row_uuid) url += `&row.uuid=${row_uuid}`;
       if (tree_uuid) url += `&tree.uuid=${tree_uuid}`;
      console.log("block id",block_uuid);
      //  if (dateOfEmasculation)
      //    if (block_uuid) {
      //      url += `&dtEmasculation%5Bbefore%5D=${moment(
      //        dateOfEmasculation
      //      ).format("YYYY/MM/DD")}`;
      //      url += `&dtEmasculation%5Bafter%5D=${moment(
      //        dateOfEmasculation
      //      ).format("YYYY/MM/DD")}`;
      //    } else {
      //      url += `?dtEmasculation%5Bbefore%5D=${moment(
      //        dateOfEmasculation
      //     ).format("YYYY/MM/DD")}`;
      //      url += `&dtEmasculation%5Bafter%5D=${moment(
      //       dateOfEmasculation
      //      ).format("YYYY/MM/DD")}`;
      //    }

       if (EmasculationDate){
          if (block_uuid) {
           url += `&dtEmasculation%5Bbefore%5D=${moment(EmasculationDate).format(
             "YYYY/MM/DD"
           )}`;
           url += `&dtEmasculation%5Bafter%5D=${moment(EmasculationDate).format(
             "YYYY/MM/DD"
           )}`;
           console.log('text');
          } else {
            console.log('TEST');
            url += `&dtEmasculation%5Bbefore%5D=${moment(EmasculationDate).format(
              "YYYY/MM/DD"
            )}`;
             url += `&dtEmasculation%5Bafter%5D=${moment(EmasculationDate).format(
               "YYYY/MM/DD"
            )}`;
          }
        }
      
      //console.log("From Tree List", formTreeNoList);

      if (block_uuid && formTreeNoList.length < 1) {
        // console.log("inside the loop");
        this.fetchFormTreeList();
      }
console.log(url);
      fetchApi(url, "get")
        .then(data =>{
                data
               ? this.setState({
                  data: data,
                 pagination: true
               })
           
                : message.error("Internal Server Error")
              // this.setState({data:data});
         console.log(data)

              }
        )
        .then(() => this.setState({ loading: false }));
    }
  };

 

  fetchBlockNo = () => {
    let { blockNumber } = this.state;
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchAreaRowUuid = (callbackFun = false) => {
   
    this.setState({ loading: true });
    var { block_uuid, treeNumber, treeNumberAreaRowUuid,treeTypeCode,uuid,treeTypeId} = this.state;
   
    //console.log("treeNumber inside fetchAreaRowUUId", treeNumber);
    fetchApi(
      `/dwarves?block.uuid=${block_uuid}&treeNo=${treeNumberAreaRowUuid}`,
      "get"
    ).then(res =>
       this.setState(
         {
           areaNumber: res[0].areaNo,
           areaNumberForm: res[0].areaNo,
           area_uuid: res[0].areaUuid,
           row_uuid: res[0].rowUuid,
          tree_uuid: res[0].uuid,
           loading: false,
           treeTypeCode:res[0].treeType,
           uuid:res[0].uuid,
           treeTypeId:res[0].treeTypeId,
         },
        
         () => {
        //  this.setState({treeTypeCode:res[0].treeType},function(){console.log(this.state.treeTypeCode)});
            console.log(this.state.treeTypeCode,res);
            console.log(this.state.uuid);
            localStorage.setItem('rowId',this.state.uuid);
            console.log(res)
         }
       )

      

    );

   
  };

  fetchFormTreeList = () => {
    let { blockNoList, blockNumber, block_uuid,treeTypeCode } = this.state;
    fetchApi(
      `/dwarves?block.uuid=${block_uuid}&properties[]=uuid&properties[]=treeNoFull`,
      "get"
    ).then(res =>
      
      this.setState(
        {
          formTreeNoList: sortFunction(res, "treeNoFull"),
          treeNoList: sortFunction(res, "treeNoFull"),
          treeNumber: null,
          treeNumberAreaRowUuid: null,
          tree_uuid: null,
          spatheNo: null,
          stageCode: null,
          dtPlanted: null
        },
         () => this.dwarfTreeEmasculation()
        // function(){
        //   console.log(res);
        // }
      )
    );
  };

  isTenDaysAgo = () => {
    {
      let {
        block_uuid,
        area_uuid,
        tree_uuid,
        dateOfEmasculation,
        editData,
        isEdit,
        spatheNo,
        stageCode,
        dtPlanted
      } = this.state;
      let url;
      let n = 0;
      let uuid = "";
      let monthDiff = dateOfEmasculation.diff(moment(dtPlanted), "months");
     // console.log("monthDiff", monthDiff);

      if ((tree_uuid && dateOfEmasculation) || (isEdit && editData.block)) {
        if (editData && editData.block) {
          var blockstring = editData.block.lastIndexOf("/");
          var treestring = editData.treeuuid.lastIndexOf("/");
          var block = editData.block.substring(blockstring + 1);
          var tree = editData.treeuuid.substring(treestring + 1);
          uuid = editData.uuid;
          url = `/emasculation?block.uuid=${block}&tree.uuid=${tree}`;
          //n = 1;
        } else {
          url = `/emasculation?block.uuid=${block_uuid}&tree.uuid=${tree_uuid}`;
        }
        if (dateOfEmasculation) {
          notification.destroy();
          fetchApi(url, "get").then(data => {
            if (data) {
              let length = data.length;
              let i = length - 1;
              for (; i >= n; i--) {
                if (
                  isEdit &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) < 10 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) > -1
                ) {
                  if (editData) if (data[i].uuid == uuid) continue;
                  notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 15,
                    //duration: 0,
                    description:
                      "Emasculation details were added on " +
                      moment(data[i].dtEmasculation).format("DD/MM/YYYY") +
                      ", Difference is " +
                      dateOfEmasculation.diff(
                        moment(
                          moment(data[i].dtEmasculation).format("YYYY-MM-DD")
                        ),
                        "days"
                      ) +
                      " days"
                  });
                } else if (
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) < 10 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) > -1 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD"))
                  ) > 0
                ) {
                  if (editData) if (data[i].uuid == uuid) continue;
                  notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 15,
                    //duration: 0,
                    description:
                      "Emasculation details were added on " +
                      moment(data[i].dtEmasculation).format("DD/MM/YYYY") +
                      ", Difference is " +
                      dateOfEmasculation.diff(
                        moment(
                          moment(data[i].dtEmasculation).format("YYYY-MM-DD")
                        ),
                        "days"
                      ) +
                      " days"
                  });
                }
              }
            } else {
              message.error("Internal Server Error");
            }
          });
        }
        if (!isEdit) {
      //    console.log("stageCode", stageCode);
          if (spatheNo == 1) {
            if ((monthDiff <= 30 && monthDiff >= 0) && dateOfEmasculation > moment(dtPlanted)) {
              notification.destroy();
              notification.info({
                message: "Alert",
                duration: 5,
                description:
                  "Please Note, the tree has completed " + monthDiff + " months"
              });
              // setTimeout(() => {
              //   notification.destroy();
              //   notification.info({
              //     message: "Alert",
              //     duration: 5,
              //     description:
              //       "Date Of Planting: " +
              //       moment(dtPlanted).format("DD/MM/YYYY")
              //   });
              // }, 5000);
              // if (stageCode == 1) {
              //   setTimeout(() => {
              //     notification.destroy();
              //     notification.info({
              //       message: "Alert",
              //       duration: 5,
              //       description: "Seedling will be transferred to Plant"
              //     });
              //   }, 10000);
              // }
            } 
            //else {
              // if (spatheNo == 1) {
              //   notification.destroy();
              //   notification.info({
              //     message: "Alert",
              //     duration: 5,
              //     description:
              //       "Date Of Planting: " +
              //       moment(dtPlanted).format("DD/MM/YYYY")
              //   });
                // if (stageCode == 1) {
                //   setTimeout(() => {
                //     notification.destroy();
                //     notification.info({
                //       message: "Alert",
                //       duration: 5,
                //       description: "Seedling will be transferred to Plant"
                //     });
                //   }, 5000);
                // }
              //}
            //}
          } else {
            if (!isEdit) {
              if (spatheNo == 1) {
                if ((monthDiff <= 30 && monthDiff >= 0) && dateOfEmasculation > moment(dtPlanted)) {
                  console.log("stageCode", stageCode);
                  //notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 5,
                    description:
                      "Please Note, the tree has completed " +
                      monthDiff +
                      " months"
                  });
                  // setTimeout(() => {
                  //   notification.destroy();
                  //   notification.info({
                  //     message: "Alert",
                  //     duration: 5,
                  //     description:
                  //       "Date Of Planting: " +
                  //       moment(dtPlanted).format("DD/MM/YYYY")
                  //   });
                  // }, 5000);
                  // if (stageCode == 1) {
                  //   setTimeout(() => {
                  //     notification.destroy();
                  //     notification.info({
                  //       message: "Alert",
                  //       duration: 5,
                  //       description: "Seedling will be transferred to Plant"
                  //     });
                  //   }, 10000);
                  // }
                } 
                //else {
                  // notification.destroy();
                  // notification.info({
                  //   message: "Alert",
                  //   duration: 5,
                  //   description:
                  //     "Date Of Planting: " +
                  //     moment(dtPlanted).format("DD/MM/YYYY")
                  // });
                  // if (stageCode == 1) {
                  //   setTimeout(() => {
                  //     notification.destroy();
                  //     notification.info({
                  //       message: "Alert",
                  //       duration: 5,
                  //       description: "Seedling will be transferred to Plant"
                  //     });
                  //   }, 5000);
                  // }
                //}
              }
            }
          }
          if(spatheNo == 1 && stageCode == 1)
          this.setState({
            visibleConfirm: true ,
            addModal: false,
            //btnLoad: false
          })
        }
      }
    }
  };

  isTenDaysAgo2 = () => {
    {
      let {
        block_uuid,
        area_uuid,
        tree_uuid,
        dateOfEmasculation,
        editData,
        isEdit,
        spatheNo,
        stageCode,
        dtPlanted,
        treeTypeCode
      } = this.state;
      let url;
      let n = 0;
      let uuid = "";
      
      console.log(this.state.dtPlanted,this.state.tree_uuid);
      console.log(this.state.dateOfEmasculation);
      let monthDiff = dateOfEmasculation.diff(moment(dtPlanted), "months");
      
      console.log("monthDiff", monthDiff);

      if ((tree_uuid && dateOfEmasculation) || (isEdit && editData.block)) {
        if (editData && editData.block) {
          var blockstring = editData.block.lastIndexOf("/");
          var treestring = editData.treeuuid.lastIndexOf("/");
          var block = editData.block.substring(blockstring + 1);
          var tree = editData.treeuuid.substring(treestring + 1);
          console.log(tree,this.state.tree_uuid);
          uuid = editData.uuid;
          url = `/emasculation?block.uuid=${block}&tree.uuid=${tree}`;
          //n = 1;
        } else {
          url = `/emasculation?block.uuid=${block_uuid}&tree.uuid=${tree_uuid}`;
        }
        if (dateOfEmasculation) {
          notification.destroy();
          fetchApi(url, "get").then(data => {
            if (data) {
              let length = data.length;
              let i = length - 1;
              for (; i >= n; i--) {
                if (
                  isEdit &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) < 10 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) > -1
                ) {
                  if (editData) if (data[i].uuid == uuid) continue;
                  notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 15,
                    //duration: 0,
                    description:
                      "Emasculation details were added on " +
                      moment(data[i].dtEmasculation).format("DD/MM/YYYY") +
                      ", Difference is " +
                      dateOfEmasculation.diff(
                        moment(
                          moment(data[i].dtEmasculation).format("YYYY-MM-DD")
                        ),
                        "days"
                      ) +
                      " days"
                  });
                } else if (
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) < 10 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD")),
                    "days"
                  ) > -1 &&
                  dateOfEmasculation.diff(
                    moment(moment(data[i].dtEmasculation).format("YYYY-MM-DD"))
                  ) > 0
                ) {
                  if (editData) if (data[i].uuid == uuid) continue;
                  notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 15,
                    //duration: 0,
                    description:
                      "Emasculation details were added on " +
                      moment(data[i].dtEmasculation).format("DD/MM/YYYY") +
                      ", Difference is " +
                      dateOfEmasculation.diff(
                        moment(
                          moment(data[i].dtEmasculation).format("YYYY-MM-DD")
                        ),
                        "days"
                      ) +
                      " days"
                  });
                }
              }
            } else {
              message.error("Internal Server Error");
            }
          });
        }
        if (!isEdit) {
          console.log("stageCode", stageCode);
          if (spatheNo == 1) {
            if ((monthDiff <= 30 && monthDiff >= 0) && dateOfEmasculation > moment(dtPlanted)) {
              notification.destroy();
              notification.info({
                message: "Alert",
                duration: 5,
                description:
                  "Please Note, the tree has completed " + monthDiff + " months"
              });
            } 
          } else {
            if (!isEdit) {
              if (spatheNo == 1) {
                if ((monthDiff <= 30 && monthDiff >= 0) && dateOfEmasculation > moment(dtPlanted)) {
                  console.log("stageCode", stageCode);
                  //notification.destroy();
                  notification.info({
                    message: "Alert",
                    duration: 5,
                    description:
                      "Please Note, the tree has completed " +
                      monthDiff +
                      " months"
                  });
                } 
              }
            }
          }
        }
      }
    }
  };


  clear = () => {
    this.setState(
      {
        data: [],
        blockNumber: null,
        areaNoList: [],
        areaNumber: null,
        areaNumberForm: null,
        pagination: true,
        dateOfEmasculation: null,
        EmasculationDate: null,
        addModal: false,
        editData: null,
        btnLoad: false,
        rowNumber: null,
        rowNoList: [],
        iEdit: false,
        addPopulateData: null,
        //EmasculationDate: null,
        isPrematureSpathe: false,
        spatheNo: null,
        stageCode: null,
        dtPlanted: null,
        loading: false,
        area_uuid: null,
        block_uuid: null,
        row_uuid: null,
        treeNoList: [],
        formTreeNoList: [],
        treeNumber: null,
        treeNumberAreaRowUuid: null,
        tree_uuid: null,
        dwarfData: null,
        isDelete: false,
        addPopulateData: null
      },
      () => this.dwarfTreeEmasculation()
    );
  };

  addModalToggle = () => {
    message.destroy();
    let {
      loading,
      isAdded,
      dateOfEmasculation,
      EmasculationDate,
      treeNumber,
      treeNumberAreaRowUuid,
      tree_uuid,
      blockNumber,
      block_uuid,
      areaNumber,
      areaNumberForm,
      area_uuid,
      rowNumber,
      row_uuid,
      addModal,
      isEdit,
      isDelete,
      spatheNo,
      stageCode,
      treeTypeCode
    } = this.state;
    if (!isEdit && !isAdded && !isDelete && loading) {
      message.info("Loading Data Please wait");
    } else {
      if (addModal) {
        this.setState(
          {
            addModal: false,
            editData: null,
            // areaNumber: isEdit ? areaNumber : null,
            // area_uuid: isEdit ? area_uuid : null,
            // treeNumber: isEdit ? treeNumber : null,
            // tree_uuid: isEdit ? tree_uuid : null,
            // rowNumber: isEdit ? rowNumber : null,
            // row_uuid: isEdit ? row_uuid : null,
            rowNumber: isEdit || isAdded || isDelete ? rowNumber : null,
            row_uuid: isEdit || isAdded || isDelete ? row_uuid : null,
            blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
            block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
            areaNumber: isEdit || isDelete ? areaNumber : null,
            areaNumberForm: isEdit || isDelete ? areaNumberForm : null,
            area_uuid: isEdit || isDelete ? area_uuid : null,
            treeNumber: isEdit || isDelete ? treeNumber : null,
            treeNumberAreaRowUuid:
            isEdit || isDelete ? treeNumberAreaRowUuid : null,
            tree_uuid: isEdit || isDelete ? tree_uuid : null,
            treeTypeCode:isEdit || isDelete ? treeTypeCode: null,
            dateOfEmasculation:
              isEdit || isDelete
                ? dateOfEmasculation
                  ? dateOfEmasculation
                  : null
                : null,
            EmasculationDate:
              isEdit || isDelete
                ? EmasculationDate
                  ? EmasculationDate
                  : null
                : null,
            // areaNumber: null,
            // area_uuid: null,
            // treeNumber: null,
            // tree_uuid: null,

            isEdit: false,
            isDelete: false,
            isPrematureSpathe: false,
            addPopulateData: null
            //EmasculationDate: null
          }
          //() => (isAdded ? this.dwarfTreeEmasculation() : null)
        );
      } else {
        if (!blockNumber || !dateOfEmasculation) {
          message.info("Select Block No. and Date of Emasculation");
        } else {
          if (treeNumber && !isEdit && !isAdded && !isDelete)
            this.isTenDaysAgo();

          if (
            cloneDeep(dateOfEmasculation)
            //cloneDeep(treeNumber) ||
            //cloneDeep(spatheNo)
          ) {
            this.setState(
              {
                editData: Object.assign(
                  {},
                  cloneDeep(dateOfEmasculation)
                    ? { dtEmasculation: cloneDeep(dateOfEmasculation) }
                    : {},
                  cloneDeep(dateOfEmasculation)
                    ? {
                      dtAppearance: cloneDeep(dateOfEmasculation).subtract(
                        40,
                        "days"
                      )
                    }
                    : {},
                  cloneDeep(dateOfEmasculation)
                    ? {
                      pollinationFrom: cloneDeep(dateOfEmasculation).add(
                        4,
                        "days"
                      )
                    }
                    : {},
                  cloneDeep(dateOfEmasculation)
                    ? {
                      pollinationTo: cloneDeep(dateOfEmasculation).add(
                        8,
                        "days"
                      )
                    }
                    : {},
                  cloneDeep(spatheNo) ? { spatheNo: spatheNo } : {},
                  cloneDeep(treeNumber) ? { tree: cloneDeep(treeNumber) } : {},
                  cloneDeep(this.state.editData)
                )
              },
              ()=>{
                this.state.addModal==true?
                this.setState({addModal: false})
                : spatheNo == 1 && stageCode == 1 ?
                this.setState({visibleConfirm:  true })
                : this.setState({addModal: true, btnLoad: false})
              }
              //() => this.setState({ addModal: !this.state.addModal },()=>this.setState({visibleConfirm: spatheNo == 1 ? true : false}))
            );
          }
        }
      }
    }
  };

  yesConfirm =()=>{
    if(this.state.addModal==false)
      this.setState({ 
        addModal: true,
        visibleConfirm: false,
        btnLoad: false
      })
    else 
    this.setState({ 
      visibleConfirm: false,
      btnLoad: false 
    })
  }
  closeConfirm =() =>{
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      formTreeNoList,
      areaNumber,
      dateOfEmasculation,
      pagination,
      addModal,
      editData,
      btnLoad,
      rowNoList,
      isEdit,
      isDelete,
      addPopulateData,
      treeNumber,
      rowNumber,
      loading,
      EmasculationDate,
      isPrematureSpathe,
      dtPlanted,
      stageCode,
      isAdded,
      row_uuid,
      block_uuid,
      area_uuid,
      tree_uuid,
      areaNumberForm,
      treeNumberAreaRowUuid,
      treeTypeCode,
      data
    } = this.state;
    this.setState({
      visibleConfirm: false,
      btnLoad: false,
      addModal: false,
      editData: null,
      // areaNumber: isEdit ? areaNumber : null,
      // area_uuid: isEdit ? area_uuid : null,
      // treeNumber: isEdit ? treeNumber : null,
      // tree_uuid: isEdit ? tree_uuid : null,
      // rowNumber: isEdit ? rowNumber : null,
      // row_uuid: isEdit ? row_uuid : null,
      rowNumber: isEdit || isAdded || isDelete ? rowNumber : null,
      row_uuid: isEdit || isAdded || isDelete ? row_uuid : null,
      blockNumber: isEdit || isAdded || isDelete ? blockNumber : null,
      block_uuid: isEdit || isAdded || isDelete ? block_uuid : null,
      areaNumber: isEdit || isDelete ? areaNumber : null,
      areaNumberForm: isEdit || isDelete ? areaNumberForm : null,
      area_uuid: isEdit || isDelete ? area_uuid : null,
      treeNumber: isEdit || isDelete ? treeNumber : null,
      treeTypeCode:isEdit || isDelete ?  treeTypeCode : null,
      treeNumberAreaRowUuid:
        isEdit || isDelete ? treeNumberAreaRowUuid : null,
      tree_uuid: isEdit || isDelete ? tree_uuid : null,
      dateOfEmasculation:
        isEdit || isDelete
          ? dateOfEmasculation
            ? dateOfEmasculation
            : null
          : null,
      EmasculationDate:
        isEdit || isDelete
          ? EmasculationDate
            ? EmasculationDate
            : null
          : null,
      // areaNumber: null,
      // area_uuid: null,
      // treeNumber: null,
      // tree_uuid: null,
      isEdit: false,
      isDelete: false,
      isPrematureSpathe: false,
      addPopulateData: null
      //EmasculationDate: null
    })
  }

  clearFields = () => {
    let { isPrematureSpathe, dateOfEmasculation } = this.state;
    this.setState({
      isPrematureSpathe: false,
      spatheNo: null,
      stageCode: null,
      dtPlanted: null,
      addPopulateData: {
        tree: null,
        spatheNo: null,
        //dtEmasculation: null,
        //prematureSpathe: null,
        //dtAppearance: null,
        buttons: null,
        //pollinationFrom: null,
        //pollinationTo: null,
        pollinationFrom: isPrematureSpathe
          ? null
          : cloneDeep(dateOfEmasculation).add(4, "days"),
        pollinationTo: isPrematureSpathe
          ? null
          : cloneDeep(dateOfEmasculation).add(8, "days"),
        prematureSpathe: isPrematureSpathe ? "Yes" : "No"
      }
    });
  };

  addFormHandler = ({
    tree,
    spikesQty,
    flowerWt,
    pollenWt,
    viability,
    spatheNo,
    prematureSpathe,
    buttons,
    dtEmasculation,
    dtAppearance,
    pollinationFrom,
    dtDeleted,
    pollinationTo,
    ...values
  }) => {
    notification.destroy();
    console.log(tree);
    let {
      treeNoList,
      tree_uuid,
      areaNoList,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNumberForm,
      area_uuid,
      rowNoList,
      rowNumber,
      row_uuid,
      recordId,
      treeTypeId,
      isEdit,
      isDelete,
      editData,
      treeTypeCode,
      dtPlanted,
      storageId,
      change,
      confirmedId,
      loading
    } = this.state;

    
    if (loading) {
      message.info("Loading Data Please wait");
    } else {
      this.setState({ btnLoad: true });
      if (isDelete) {
        fetchApi(`/emasculation/${editData.uuid}`, "put", {
          isDeleted: true,
         // dtDeleted: moment().format("YYYY-MM-DD"),
        }).then(res => {
          //console.log("response ", res);
          console.log(dtDeleted);
          if (res) {
            notification.success({
              message: "Success",
              description: "Successfully Deleted Dwarf Tree Emasculation"
            });
            this.dwarfTreeEmasculation();
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Delete Dwarf Tree Emasculation"
            });
          }
          this.setState({ btnLoad: false }, () => {
            //this.onFormTreeChangeHandler(tree);
          //  this.getNextSpatheNo(tree);
            this.addModalToggle();
          });
        });
      } else if (isEdit) {
     
        fetchApi(`/emasculation/${editData.uuid}`, "put", {
      
        // tree:editData.tree_uuid,
          treeTypeCode:editData.treeTypeCode,
          area: editData.area,
          row: editData.row,
          dtPlanted:editData.dtPlanted,
          block: editData.block,
          spatheNo: parseInt(spatheNo),
          buttons: parseInt(buttons),
          prematureSpathe: prematureSpathe == "Yes" ? true : false,
          dtEmasculation: moment(dtEmasculation).format("YYYY-MM-DD"),
          dtAppearance: moment(dtAppearance).format("YYYY-MM-DD"),
          pollinationFrom: pollinationFrom
            ? moment(pollinationFrom).format("YYYY-MM-DD")
            : null,
          pollinationTo: pollinationTo
            ? moment(pollinationTo).format("YYYY-MM-DD")
            : null,
          ...values
        }).then(res => {
          if (res) {
            console.log(res);
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            } else {
              console.log('test');
              notification.success({
                message: "Success",
                description: "Successfully Updated Dwarf Tree Emasculation"
              });
            this.dwarfTreeEmasculation();
              //  fetchApi("/emasculation", "get")
              //  .then(data =>{
              //       data
              //        ? this.setState({
              //           data: data,
              //          pagination: true
              //          })
                
              //         : message.error("Internal Server Error")
              // console.log(data)
     
              //        }
              //  )
              this.addModalToggle()
            
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to Update Dwarf Tree Emasculation"
            });
          }
          this.setState({ btnLoad: false }
            //, () => this.addModalToggle()
          );
        });
      } else {
        fetchApi("/emasculation", "post", {
          tree: "/dwarves/" + tree_uuid,
          area: "/areas/" + area_uuid,
          row: "/rows/" + row_uuid,
          block:
            "/blocks/" +
            filter(blockNoList, { blockNoFull: blockNumber })[0].uuid,
          spatheNo: parseInt(spatheNo),
          buttons: parseInt(buttons),
          prematureSpathe: prematureSpathe == "Yes" ? true : false,
          dtEmasculation: moment(dtEmasculation).format("YYYY-MM-DD"),
          dtAppearance: moment(dtAppearance).format("YYYY-MM-DD"),
          treeTypeCode:treeTypeCode,
          pollinationFrom: pollinationFrom
            ? moment(pollinationFrom).format("YYYY-MM-DD")
            : null,
          pollinationTo: pollinationTo
            ? moment(pollinationTo).format("YYYY-MM-DD")
            : null,
            treeTypeId: confirmedId && confirmedId == true ? Number(recordId):confirmedId == false ?Number(storageId):treeTypeId,
          ...values
        }).then(res => {
          if (res) {
           
            if (res.violations) {
              notification.error({
                message: "Failed",
                description: res.violations[0].message
              });
            this.setState({addModal: false});
            } else {
              notification.success({
                message: "Success",
                description: "Successfully added Dwarf Tree Emasculation"
              });
            //  this.setState({addModal: false});
              this.setState(
                {
                  isAdded: true,
                  //dateOfEmasculation: null,
                  EmasculationDate: null,
                  isPrematureSpathe: false,
                  areaNumber: null,
                  areaNumberForm: null,
                  area_uuid: null,
                  treeNumber: null,
                  treeNumberAreaRowUuid: null,
                  tree_uuid: null,
                },
                () => this.dwarfTreeEmasculation()
              );

              this.clearFields();
            }
          } else {
            notification.error({
              message: "Failed",
              description: "Failed to add Dwarf Tree Emasculation"
            });
          }
          this.setState({ btnLoad: false, addPopulateData: null });
        });
      }
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res =>
        this.setState(
          {
            blockNumber: it ? it : null,
            block_uuid: it
              ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
                .uuid
              : null,
            areaNoList: res,
            areaNumber: null,
            areaNumberForm: null,
            area_uuid: null,
            rowNoList: [],
            rowNumber: null,
            row_uuid: null,
            treeNoList: [],
            treeNumber: null,
            treeNumberAreaRowUuid: null,
            tree_uuid: null,
            formTreeNoList: [],
            spatheNo: null,
            stageCode: null,
            dtPlanted: null
          },
          () => this.dwarfTreeEmasculation()
        )
      );
    } else {
      this.setState(
        {
          blockNumber: null,
          block_uuid: null,
          areaNoList: [],
          areaNumber: null,
          areaNumberForm: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          formTreeNoList: [],
          treeNoList: [],
          treeNumber: null,
          treeNumberAreaRowUuid: null,
          tree_uuid: null,
          spatheNo: null,
          stageCode: null,
          dtPlanted: null
        },
        () => this.dwarfTreeEmasculation()
      );
    }
  };

  onAreaChangeHandler = it => {
    let { areaNoList, areaNumber, blockNumber, blockNoList,treeTypeCode } = this.state;

    /*if (it) {
      fetchApi(
        `/rowsuuid?area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }`,
        "get"
      ).then(res => {
        this.setState(
          {
            areaNumber: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            rowNoList: sortFunction(res, "rowNoFull"),
            rowNumber: null,
            row_uuid: null,
            treeNoList: [],
            treeNumber: null,
            tree_uuid: null,
            spatheNo: null
          },
          () => this.dwarfTreeEmasculation()
        );
      });
    } else {
      this.setState(
        {
          areaNumber: null,
          area_uuid: null,
          rowNoList: [],
          rowNumber: null,
          row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        },
        () => this.dwarfTreeEmasculation()
      );
    }*/

    if (it) {
      fetchApi(
        `/dwarves?area.uuid=${
        cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
        }&block.uuid=${
        cloneDeep(blockNoList).filter(
          item => item.blockNoFull == blockNumber
        )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState(
          {
            areaNumber: it,
            areaNumberForm: it,
            area_uuid: cloneDeep(areaNoList).filter(
              item => item.areaNo == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            formTreeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null,
            treeNumberAreaRowUuid: null,
            tree_uuid: null,
            spatheNo: null,
            stageCode: null,
            dtPlanted: null,
          },
          () => this.dwarfTreeEmasculation()
        )
      );
    } else {
      this.setState(
        {
          areaNumber: null,
          areaNumberForm: null,
          area_uuid: null,
          rowNumber: null,
          row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          treeNumberAreaRowUuid: null,
          tree_uuid: null,
          spatheNo: null,
          
          stageCode: null,
          dtPlanted: null
        },
        () => this.dwarfTreeEmasculation()
      );
    }
  };

  /*onRowChangeHandler = it => {
    let {
      rowNoList,
      blockNumber,
      areaNumber,
      areaNoList,
      blockNoList
    } = this.state;

    if (it) {
      fetchApi(
        `/dwarves?row.uuid=${
          cloneDeep(rowNoList).filter(item => item.rowNoFull == it)[0].uuid
        }&area.uuid=${
          cloneDeep(areaNoList).filter(item => item.areaNo == areaNumber)[0]
            .uuid
        }&block.uuid=${
          cloneDeep(blockNoList).filter(
            item => item.blockNoFull == blockNumber
          )[0].uuid
        }&properties[]=uuid&properties[]=treeNoFull`,
        "get"
      ).then(res =>
        this.setState(
          {
            rowNumber: it,
            row_uuid: cloneDeep(rowNoList).filter(
              item => item.rowNoFull == it
            )[0].uuid,
            treeNoList: sortFunction(res, "treeNoFull"),
            treeNumber: null,
            tree_uuid: null,
            spatheNo: null
          },
          () => this.dwarfTreeEmasculation()
        )
      );
    } else {
      this.setState(
        {
          rowNumber: null,
          row_uuid: null,
          treeNoList: [],
          treeNumber: null,
          tree_uuid: null,
          spatheNo: null
        },
        () => this.dwarfTreeEmasculation()
      );
    }
  };*/

  onTreeChangeHandler = it => {
    let { treeNoList,treeTypeCode,confirmedId,recordId,storageId,treeTypeId } = this.state;
    
    if (it) {
     console.log(it);
      this.setState({ treeNumber: it, loading: true});
      console.log(this.state.treeNumber);
      fetchApi(
        `/dwarves/${
        cloneDeep(treeNoList).filter(item => item.treeNoFull == it)[0].uuid
        }?groups[]=getNextSpatheNo`,
        "get"
      ).then(res =>
       // console.log(treeTypeCode);
      
         this.setState(
           {
             treeNumber: it,
             treeNumberAreaRowUuid: it,
            tree_uuid: cloneDeep(treeNoList).filter(
              item => item.treeNoFull == it
             )[0].uuid,
             spatheNo: res.nextSpatheNo,
             stageCode: res.stageCode,
             dtPlanted: res.dtPlanted,
            treeTypeId:res.treeTypeId,
             //treeTypeId:confirmedId && confirmedId == true ? Number(recordId):confirmedId == false ?Number(storageId):res.treeTypeId,
            treeTypeCode:res.treeTypeCode,
          },
          () =>{ this.fetchAreaRowUuid(this.dwarfTreeEmasculation)
            console.log(treeTypeCode);
          }
         )
       
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          treeNumberAreaRowUuid: null,
          tree_uuid: null,
          spatheNo: null,
          stageCode: null,
          dtPlanted: null
        },
        () => this.dwarfTreeEmasculation()
      );
    }
  };

  treeChangeHandler = (it)=>{
    let {treeTypeData,isEdit,uuid,treeTypeId,editData,treeTypeCode,data, treeTypeUuid, spatheNo,
      prematureSpathe,
      buttons,
      dtEmasculation,
      dtAppearance,
      pollinationFrom,
      spatheNumber,
      isConfirm,
      storageId,
      storageCode,
      recordId,
      visible,
      visibleAdd,
      treeTypeStatus,
      change,
      recordCode,
      pollinationTo,...values} = this.state;

      let previousCode = localStorage.getItem('oldValueCode');
      let previousId = localStorage.getItem('oldValueId');
      console.log(previousCode,previousId);

       this.setState({storageId:previousId,storageCode:previousCode},function(){console.log(this.state.storageId,this.state.storageCode)});
     
         if(it){
           this.setState({change:true});
           console.log(it);
          const filtered = treeTypeData.find(el => {return (el.code === it)});
          let treeId = filtered.id;
          let treeCode = filtered.code;
          console.log(treeCode);
          this.setState({recordId:treeId},function(){console.log(this.state.recordId)});
          this.setState({recordCode:treeCode},function(){console.log(this.state.recordCode)});
          if(treeCode === previousCode){
            this.setState({visibleAdd:false});
          }else if(treeCode !== previousCode){
            this.setState({visibleAdd:true,addPopulateData:{
              treeTypeCode:it
            }});
          }
    }else{
      this.setState({change:false});
     // this.setState({treeTypeId:storageId},function(){console.log(this.state.treeTypeId)});
     // this.setState({storageId:previousId,storageCode:previousCode},function(){console.log(this.state.storageId,this.state.storageCode)});
     
    }
       
    }


    handleOk = ()=>{
      this.setState({visibleAdd:false});
    }
  
    handleCancelAdd = ()=>{
      let {treeTypeId,storageId,storageCode,recordId,confirmedId,recordCode,isConfirm,pollinationTo,visible,editData,visibleAdd,uuid,tree_uuid,area_uuid,row_uuid,dtAppearance,spatheNo,  dtEmasculation, pollinationFrom, blockNoList,
      blockNumber,prematureSpathe,...values}= this.state;
      this.setState({confirmedId:true});
      this.setState({visibleAdd:false,isConfirm:true});
      this.setState({treeTypeId:recordId});
    }
  
    handleCancelCloseAdd = ()=>{
    
      let addRowUuid =   localStorage.getItem('rowId');
      let {treeTypeId,storageId,storageCode,visibleAdd,blockNoList,spatheNo,confirmedId,dtAppearance,buttons, blockNumber,recordId,recordCode,tree_uuid,area_uuid, pollinationFrom,pollinationTo, row_uuid, dtEmasculation, prematureSpathe,isConfirm,uuid,visible,editData,treeTypeCode,treeTypeData}= this.state;
      this.setState({confirmedId:false});
      this.setState({visibleAdd:false,isConfirm:false});
      this.setState({treeTypeId:storageId,treeTypeCode:storageCode,uuid:addRowUuid});
      
      console.log(this.state.storageCode);
          this.setState({addPopulateData: {
            treeTypeCode: storageCode
          },
         })
    }
  
  treeTypeChangeHandler = (it)=>{
  let {treeTypeData,isEdit,uuid,treeTypeId,editData,treeTypeCode,data, treeTypeUuid, spatheNo,
    prematureSpathe,
    buttons,
    dtEmasculation,
    dtAppearance,
    pollinationFrom,
    spatheNumber,
    isConfirm,
    storageId,
    storageCode,
    recordId,
    visible,
    treeTypeStatus,
    recordCode,
    pollinationTo,...values} = this.state;
    let treetypeIdentifier = localStorage.getItem('treeID');
    let treetypeCodeIdentifier = localStorage.getItem('treeCo');
     this.setState({storageId:treetypeIdentifier,storageCode:treetypeCodeIdentifier});
       if(it){
        this.setState({isEdit: true, addPopulateData: {
          treeTypeCode: it
        },
       })
        const filtered = treeTypeData.find(el => {
          
          console.log(el);
          return (el.code === it)});
        console.log(filtered);
        let treeId = filtered.id;
        let treeCode = filtered.code;
        console.log(treeId);
        console.log(treeCode);
        this.setState({recordId:treeId,recordCode:treeCode});
        if(treeCode === treetypeCodeIdentifier){
          this.setState({visible:false});
        }else if(treeCode !== treetypeCodeIdentifier){
          this.setState({visible:true});
          console.log(it);
        }else if(it !== treetypeCodeIdentifier && !isConfirm){
          this.setState({visible:true,treeTypeCode:it});
          console.log(this.state.treeTypeCode);
        }
  }
  }



  handleOk = ()=>{
    this.setState({visible:false});
  }

  handleCancel = ()=>{
    let {treeTypeId,storageId,storageCode,recordId,recordCode,isConfirm,visible,editData}= this.state;
    this.setState({visible:false,isConfirm:true});
    this.setState({treeTypeId:recordId},()=>{console.log(treeTypeId)});
   
    fetchApi(`/emasculation/${editData.uuid}`,"put",{
           treeTypeId:Number(recordId),
         })
  }

  handleCancelClose = ()=>{
    let {treeTypeId,storageId,storageCode,recordId,recordCode,isConfirm,visible,editData,treeTypeCode,treeTypeData}= this.state;
    this.setState({visible:false,isConfirm:false});
    this.setState({treeTypeId:storageId,treeTypeCode:storageCode});
    console.log(this.state.storageCode);
     // console.log(treeCode);
        fetchApi(`/emasculation/${editData.uuid}`,"put",{
          treeTypeId:Number(storageId),
        })
        this.setState({isEdit: true, addPopulateData: {
          treeTypeCode: storageCode
        },
       })
  }

  dateOfEmasculationHandler = (date, dateString) => {
    let { isPrematureSpathe } = this.state;
    if (date) {
      this.setState(
        {
          dateOfEmasculation: date,
          EmasculationDate: date,
          addPopulateData: {
            dtEmasculation: date
            //prematureSpathe: prematureSpathe ? "Yes" : "No",
          }
        },
        () => {
          this.dwarfTreeEmasculation();
          //this.isTenDaysAgo();
        }
      );
    } else {
      this.setState(
        {
          dateOfEmasculation: null,
          EmasculationDate: null,
          addPopulateData: {
            dtEmasculation: null
            //prematureSpathe: prematureSpathe ? "Yes" : "No",
          }
        },
        () => {
          this.dwarfTreeEmasculation();
          //this.isTenDaysAgo();
        }
      );
    }
  };

  formDateOfEmasculationHandler = (date, dateString) => {
    let { isPrematureSpathe, editData,dtPlanted ,tree_uuid,treeTypeUuid} = this.state;
  
    let trytest = localStorage.getItem('treeuid');
   this.setState({tree_uuid:trytest},()=>{console.log(this.state.tree_uuid)});
    let plantedDate = localStorage.getItem('planted');
    console.log(plantedDate);
    if (date){
      this.setState(
        {
          dateOfEmasculation: date,
          dtPlanted:plantedDate,
          tree_uuid:tree_uuid,
           addPopulateData: {
            dtEmasculation: date,
            dtAppearance: cloneDeep(date).subtract(40, "days"),
             pollinationFrom: cloneDeep(date).add(4, "days"),
            pollinationTo: cloneDeep(date).add(8, "days")
             //prematureSpathe: prematureSpathe ? "Yes" : "No",
           },
          editData: {
            dtEmasculation: date,
            dtAppearance: cloneDeep(date).subtract(40, "days"),
            pollinationFrom: cloneDeep(date).add(4, "days"),
            pollinationTo: cloneDeep(date).add(8, "days"),
            tree:editData.tree,
            treeuuid:tree_uuid,
            uuid: editData.uuid,
            spatheNo: editData.spatheNo,
            buttons: editData.buttons,
            block: editData.block,
            area: editData.area,
            row: editData.row,
            treeNo: editData.treeNo,
            treeTypeCode:editData.treeTypeCode,
            dtPlanted:editData.dtPlanted
          }
        },
        () => {
          //this.dwarfTreeEmasculation();
          //this.isTenDaysAgo();
          this.isTenDaysAgo2();
          console.log(this.state.dtPlanted);
         // console.log(tree);
        }
      );
    } else {
      this.setState(
        {
          dateOfEmasculation: null,
          dtPlanted:null,
          addPopulateData: {
            dtEmasculation: null
            //prematureSpathe: prematureSpathe ? "Yes" : "No",
          },
          editData: {
            dtEmasculation: null,
            tree: editData.tree,
            //treeuuid: editData.treeTypeUuid,
            treeuuid:tree_uuid,
            uuid: editData.uuid,
            spatheNo: editData.spatheNo,
            buttons: editData.buttons,
            block: editData.block,
            area: editData.area,
            row: editData.row,
            treeNo: editData.treeNo,
            treeTypeCode:editData.treeTypeCode,
          }
        },
        () => {
          //this.dwarfTreeEmasculation();
          //this.isTenDaysAgo();
          this.isTenDaysAgo2();
        }
      );
    }
  };

  onFormTreeChangeHandler = it => {
    let { formTreeNoList, isPrematureSpathe,confirmedId,recordId,storageId,treeTypeCode,treeTypeId,treeNumber,tree,block_uuid, tree_uuid, treeNumberAreaRowUuid,uuid } = this.state;
    if (it) {
      this.setState({ loading: true, btnLoad: true });
      //this.setState({ treeNumber: it }, () => this.dwarfTreeEmasculation());
      fetchApi(
        `/dwarves/${
        cloneDeep(formTreeNoList).filter(item => item.treeNoFull == it)[0]
          .uuid
        }?groups[]=getNextSpatheNo`,
        "get"
      ).then(res =>
        this.setState(
          {
            treeNumber: it,
            treeNumberAreaRowUuid: it,
            tree_uuid: cloneDeep(formTreeNoList).filter(
              item => item.treeNoFull == it
            )[0].uuid,
            spatheNo: res.nextSpatheNo,
            stageCode: res.stageCode,
            dtPlanted: res.dtPlanted,
            treeTypeCode:res.treeTypeCode,
           treeTypeId:res.treeTypeId,
           // treeTypeId: confirmedId && confirmedId == true ? Number(recordId):confirmedId == false ?Number(storageId):res.treeTypeId,
            addPopulateData: {
              tree: it ? it : null,
              //treeTypeCode:treeTypeCode,
            }
          },
          () => {
            console.log(res);
            localStorage.setItem('oldValueCode',this.state.treeTypeCode);
            console.log(this.state.treeTypeCode);
            localStorage.setItem('oldValueId',this.state.treeTypeId);
          
            this.setState(
              {
                btnLoad:false,
                addPopulateData: {
                  spatheNo: cloneDeep(this.state.spatheNo),
                  treeTypeCode:this.state.treeTypeCode,
                  tree: it ? it : null,
                  //prematureSpathe: prematureSpathe ? "Yes" : "No",
                }
              },
              () => {
                this.fetchAreaRowUuid();
                this.isTenDaysAgo();
              }
            );
          }
        )
      );
    } else {
      this.setState(
        {
          treeNumber: null,
          treeNumberAreaRowUuid: null,
          tree_uuid:null,
          spatheNo: null,
          stageCode: null,
          dtPlanted: null,
          area_uuid: null,
          row_uuid: null,
          addPopulateData: {
            tree: it ? it : null,
          }
        },
        () => {
          this.setState({
            btnLoad:false,
            addPopulateData: {
              spatheNo: null,
              tree: it ? it : null,
              //prematureSpathe: prematureSpathe ? "Yes" : "No",
            }
          });
        }
      );
    }
  };

  // dateOfEmasculationHandler = (date, dateString) => {
  //   if (date) {
  //     this.setState({ dateOfEmasculation: date }, () =>
  //       this.dwarfTreeEmasculation()
  //     );
  //   } else {
  //     this.setState({ dateOfEmasculation: null }, () =>
  //       this.dwarfTreeEmasculation()
  //     );
  //   }
  // };

  onPrematureSpatheHandler = val => {
    let { dateOfEmasculation, isPrematureSpathe, editData } = this.state;
    if (val === "No") {
      this.setState(
        {
          isPrematureSpathe: false,
          editData: {
            prematureSpathe: "No",
            dtEmasculation: editData.dtEmasculation,
            dtAppearance: editData.dtAppearance,
            pollinationFrom: editData.pollinationFrom,
            pollinationFrom: editData.pollinationTo,
            tree: editData.treeuuid,
            treeuuid: editData.treeTypeUuid,
            treeNo: editData.tree,
            uuid: editData.uuid,
            spatheNo: editData.spatheNo,
            block: editData.block,
            area: editData.area,
            row: editData.row
          },
          addPopulateData: {
            dtEmasculation:
              editData && editData.dtEmasculation
                ? editData.dtEmasculation
                : dateOfEmasculation,
            dtAppearance:
              editData && editData.dtAppearance
                ? editData.dtAppearance
                : cloneDeep(dateOfEmasculation)
                  ? cloneDeep(dateOfEmasculation).subtract(40, "days")
                  : null,
            pollinationFrom:
              editData && cloneDeep(editData.dtEmasculation)
                ? cloneDeep(editData.dtEmasculation).add(4, "days")
                : cloneDeep(dateOfEmasculation)
                  ? cloneDeep(dateOfEmasculation).add(4, "days")
                  : null,
            pollinationTo:
              editData && cloneDeep(editData.dtEmasculation)
                ? cloneDeep(editData.dtEmasculation).add(8, "days")
                : cloneDeep(dateOfEmasculation)
                  ? cloneDeep(dateOfEmasculation).add(8, "days")
                  : null

            //prematureSpathe: prematureSpathe ? "Yes" : "No",
          }
        },
        () => {
          this.setState({
            addPopulateData: {
              // dtAppearance: editData.dtAppearance
              //   ? editData.dtAppearance
              //   : cloneDeep(dateOfEmasculation)
              //   ? cloneDeep(dateOfEmasculation).subtract(40, "days")
              //   : null,
              // pollinationFrom: cloneDeep(editData.dtEmasculation)
              //   ? cloneDeep(editData.dtEmasculation).add(4, "days")
              //   : cloneDeep(dateOfEmasculation)
              //   ? cloneDeep(dateOfEmasculation).add(4, "days")
              //   : null,
              // pollinationTo: cloneDeep(editData.dtEmasculation)
              //   ? cloneDeep(editData.dtEmasculation).add(8, "days")
              //   : cloneDeep(dateOfEmasculation)
              //   ? cloneDeep(dateOfEmasculation).add(8, "days")
              //   : null,
              // dtEmasculation: editData.dtEmasculation
              //   ? editData.dtEmasculation
              //   : dateOfEmasculation
              //prematureSpathe: prematureSpathe ? "Yes" : "No",
            }
          });
        }
      );
    } else {
      this.setState(
        {
          isPrematureSpathe: true,
          editData: {
            prematureSpathe: "Yes",
            dtEmasculation: editData.dtEmasculation,
            treeTypeCode:editData.treeTypeCode,
            dtAppearance: editData.dtAppearance,
            pollinationFrom: editData.pollinationFrom,
            pollinationFrom: editData.pollinationTo,
            tree: editData.treeuuid,
            treeuuid: editData.treeTypeUuid,
            treeNo: editData.tree,
            uuid: editData.uuid,
            spatheNo: editData.spatheNo,
            block: editData.block,
            area: editData.area,
            row: editData.row
          },
          addPopulateData: {
            dtEmasculation:
              editData && editData.dtEmasculation
                ? editData.dtEmasculation
                : dateOfEmasculation,
            dtAppearance:
              editData && editData.dtAppearance
                ? editData.dtAppearance
                : cloneDeep(dateOfEmasculation)
                  ? cloneDeep(dateOfEmasculation).subtract(40, "days")
                  : null,
            buttons: null,
            pollinationFrom: null,
            pollinationTo: null
            //prematureSpathe: prematureSpathe ? "Yes" : "No",
          }
        }
        // ,
        // () => this.setState({ addPopulateData: null })
      );
    }
  };

  onEmasculationDateHandler = (date, string) => {
    let { blockNumber, isPrematureSpathe } = this.state;
    this.setState(
      {
        //EmasculationDate: date,
        dateOfEmasculation: date,
        EmasculationDate: date,
        addPopulateData: {
          dtAppearance: cloneDeep(date)
            ? cloneDeep(date).subtract(40, "days")
            : null,
          pollinationFrom: !cloneDeep(isPrematureSpathe)
            ? cloneDeep(date)
              ? cloneDeep(date).add(4, "days")
              : null
            : null,
          pollinationTo: !cloneDeep(isPrematureSpathe)
            ? cloneDeep(date)
              ? cloneDeep(date).add(8, "days")
              : null
            : null,
          dtEmasculation: date
          //prematureSpathe: prematureSpathe ? "Yes" : "No",
        }
      },
      () => this.isTenDaysAgo()
    );
  };

  editHandler = ({
    dtAppearance,
    dtEmasculation,
    pollinationFrom,
    pollinationTo,
    dtDueDate,
    //prematureSpatheData,
    prematureSpathe,
   uuid,
    spatheNo,
    treeTypeCode,
    buttons,
    block,
    area,
    row,
    tree,
  treeTypeId,
  dtPlanted,
    treeNo,
  treeTypeUuid,
  spatheNumber,
    }) => {
    // const {
    //   // dtAppearanceDate,
    //   // dtEmasculationDate,
    //   // pollinationFromDate,
    //   // pollinationToDate,
    //   dtAppearance,
    //   dtEmasculation,
    //   pollinationFrom,
    //   pollinationTo,
    //   dtDueDate,
    //   //prematureSpatheData,
    //   prematureSpathe,
    //  uuid,
    //   spatheNo,
    //   treeTypeCode,
    //   buttons,
    //   block,
    //   area,
    //   row,
    //   tree,
    // treeTypeId,
    //   treeNo,
    // treeTypeUuid,
    // } = this.state;
    let{tree_uuid,block_uuid}= this.state;
    this.setState(
      {
        //dateOfEmasculation: moment(dtEmasculation, "YYYY-MM-DD"),
        isEdit: true,
        addPopulateData: {
          dtEmasculation: moment(dtEmasculation, "YYYY-MM-DD")
        },
        editData: {
          dtAppearance: moment(dtAppearance, "YYYY-MM-DD"),
          dtEmasculation: moment(dtEmasculation, "YYYY-MM-DD"),
          pollinationFrom:pollinationFrom
            ? moment(pollinationFrom, "YYYY-MM-DD")
            : "",
          pollinationTo:pollinationTo
            ? moment(pollinationTo, "YYYY-MM-DD")
            : "",
          dtDue: moment(dtDueDate, "YYYY-MM-DD"),
          prematureSpathe:prematureSpathe ? "Yes" : "No",
          tree:treeNo,
          treeTypeId,
          treeTypeUuid,
          treeTypeCode,
          uuid,
          spatheNo,
          buttons,
          block,
          area,
          dtPlanted,
          row,
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );
    console.log(treeTypeId,tree,dtPlanted);

    localStorage.setItem('treeID',treeTypeId);
    localStorage.setItem('treeCo',treeTypeCode);
    localStorage.setItem('planted',dtPlanted);
   
    this.setState({spatheNumber:spatheNo},function(){console.log(this.state.spatheNumber)});
     


    console.log(tree);
    console.log(block);
    let test = tree;
    let bloc = block;
    let test2 = test.substring(9);
    let test7 = bloc.substring(8);

    console.log(test2);
    console.log(test7);
    localStorage.setItem('treeuid',test2);
    localStorage.setItem('tr',test);
   this.setState({tree_uuid:test2});
   this.setState({block_uuid:test7},()=>{console.log(this.state.block_uuid)});
  
   // localStorage.setItem('spathe',spatheNumber);

   // let spatheValue =  localStorage.getItem('spathe');
  // this.setState({spatheNumber:spatheValue},function(){console.log(this.state.spatheNumber)});
  // spatheNumber == 1 ? this.setState({treeTypeStatus:true}):this.setState({treeTypeStatus:false});
  }

  getNextSpatheNo = it => {
    let { treeNoList,treeTypeCode,treeTypeId,formTreeNoList,tree_uuid,confirmedId,recordId,storageId} = this.state;
    if (it) {
      this.setState({ loading: true });
      console.log(this.state.treeNoList);
      fetchApi(
        `/dwarves/${
        cloneDeep(treeNoList).filter(item => item.treeNoFull == it)[0].uuid
        }?groups[]=getNextSpatheNo`,
        "get"
      ).then(res =>
        this.setState(
          {
            spatheNo: res.nextSpatheNo,
            treeNumberAreaRowUuid: it,
            stageCode: res.stageCode,
            dtPlanted: res.dtPlanted,
            treeTypeCode:res.treeTypeCode,
            treeTypeId:res.treeTypeId,
          }
           ,
           () => {
             this.fetchAreaRowUuid();
           }
        )
      );
    } else {
      this.setState({
        spatheNo: null,
        stageCode: null,
        dtPlanted: null
      });
    }
  };

  deleteHandler = ({
    // dtAppearanceDate,
    // dtEmasculationDate,
    // pollinationFromDate,
    // pollinationToDate,
    dtAppearance,
    dtEmasculation,
    pollinationFrom,
    pollinationTo,
    dtDueDate,
    //prematureSpatheData,
    prematureSpathe,
    uuid,
    spatheNo,
    treeTypeCode,
    treeTypeId,
    buttons,
    block,
    area,
    row,
    tree,
    treeNo
  }) => {
    this.setState(
      {
        //dateOfEmasculation: moment(dtEmasculation, "YYYY-MM-DD"),
        isDelete: true,
        addPopulateData: {
          dtEmasculation: moment(dtEmasculation, "YYYY-MM-DD")
        },
        editData: {
          dtAppearance: moment(dtAppearance, "YYYY-MM-DD"),
          dtEmasculation: moment(dtEmasculation, "YYYY-MM-DD"),
          pollinationFrom: pollinationFrom
            ? moment(pollinationFrom, "YYYY-MM-DD")
            : "",
          pollinationTo: pollinationTo
            ? moment(pollinationTo, "YYYY-MM-DD")
            : "",
          dtDue: moment(dtDueDate, "YYYY-MM-DD"),
          prematureSpathe: prematureSpathe ? "Yes" : "No",
          tree: treeNo,
          treeuuid: tree,
          uuid,
          treeTypeId,
          spatheNo,
          treeTypeCode,
          buttons,
          block,
          area,
          row
        }
      },
      () =>
        this.setState({
          addModal: true
        })
    );

    console.log(uuid);
  };

  render() {
    let {
      blockNoList,
      blockNumber,
      areaNoList,
      treeNoList,
      formTreeNoList,
      areaNumber,
      dateOfEmasculation,
      treeTypeStatus,
      pagination,
      addModal,
      editData,
      btnLoad,
      rowNoList,
      isEdit,
      isDelete,
      addPopulateData,
      treeNumber,
      rowNumber,
      loading,
      EmasculationDate,
      isPrematureSpathe,
      dtPlanted,
      stageCode,
      treeTypeData,
      treeTypeCode,
      isAdded,
      row_uuid,
      block_uuid,
      area_uuid,
      tree_uuid,
      areaNumberForm,
      treeNumberAreaRowUuid,
      changed_treeUid,
      spatheNumber,
      data
    } = this.state;
    const columns = [
      {
        title: "Tree No.",
        key: "treeNo",
        dataIndex: "treeNo"
      },
      {
        title: "Spathe No.",
        key: "spatheNo",
        dataIndex: "spatheNo"
      },
      {
        title: "Premature Spathe",
        key: "prematureSpathe",
        dataIndex: "prematureSpathe",
        render: it => (it ? "Yes" : "No")
      },
      {
        title: "Tree Types",
        key: "treeTypeCode",
        dataIndex: "treeTypeCode",
      },
      {
        title: "Date of Emasculation",
        key: "dtEmasculation",
        dataIndex: "dtEmasculation",
        render: it => (it ? formatDate(it) : "-")
      },
      {
        title: "Date of Appearance",
        key: "dtAppearance",
        dataIndex: "dtAppearance",
        render: it => (it ? formatDate(it) : "-")
      },

      {
        title: "Buttons",
        key: "buttons",
        dataIndex: "buttons",
        render: it => (it ? it : "-")
      },
      {
        title: "Date of Pollination",
        children: [
          {
            title: "From",
            key: "pollinationFrom",
            dataIndex: "pollinationFrom",
            className: "text-center",
            render: it => (it ? formatDate(it) : "-")
          },
          {
            title: "To",
            key: "pollinationTo",
            dataIndex: "pollinationTo",
            className: "text-center",
            render: it => (it ? formatDate(it) : "-")
          }
        ]
      },
      {
        title: "Actions",
        key: "actions",
        className: "action",
        render: (record) => (
          <Fragment>
            <Tooltip placement="top" title="Edit">
              <Icon
                className="table-action-btn"
                type="edit"
            onClick={() => {this.editHandler(record)
              console.log(record);
            }
            }
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.deleteHandler(record)}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];
    const buttonList =
      data.length > 0
        ? [
          blockNumber || areaNumber || treeNumber || dateOfEmasculation
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          // {
          //   label: "Excel",
          //   icon: "file-text"
          // },
          // {
          //   label: "Print",
          //   icon: "diff",
          //   reference: this.componentRef
          // },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          blockNumber || areaNumber || treeNumber || dateOfEmasculation
            ? {
              label: "Clear",
              icon: "delete",
              onClickHandler: this.clear
            }
            : "",
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "tree",
            placeholder: "Tree No.",
            //type: "alphanumeric",
            listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
            readonly: treeNoList.length > 0 ? false : true,
            message: "Input the tree no.!",
            disabled: this.state.btnLoad,
            changeHandler: it => this.onFormTreeChangeHandler(it)
          },
          {
            name: "spatheNo",
            placeholder: "Spathe No.",
            disabled: true,
            type: "number",
            value: this.state.spatheNo,
            message: "Input the Spath Number!",
            className: this.state.spatheNo == 1 ? "spatheHighlight" : ""
          },
          {
            name: "dtEmasculation",
            placeholder: "Date Of Emasculation",
            type: "date",
            message: "Input the Date Of Emasculation!",
            //changeHandler: this.onEmasculationDateHandler
            changeHandler: this.formDateOfEmasculationHandler
          },
          {
            name: "prematureSpathe",
            placeholder: "Premature Spathe",
            listData: ["No", "Yes"],
            hasInitial: true,
            message: "Input the number of Premature Spathe!",
            //value: isPrematureSpathe ? true : false,
            value: isPrematureSpathe ? "Yes" : "No",
            required: false,
            changeHandler: this.onPrematureSpatheHandler
          },
          {
            name: "treeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            required: true,
          //  changeHandler: this.onPrematureSpatheHandler
         disabled: this.state.spatheNo == 1  ? false : true,
            value: this.state.treeTypeCode,
            listData: cloneDeep(treeTypeData).map(it => it.code),
          //  disabled:spatheNumber === 1 ? false : true,
           changeHandler: it=> this.treeChangeHandler(it)
          },
          {
            name: "dtAppearance",
            placeholder: "Date Of Appearance",
            type: "date",
            disabled: true,
            message: "Input the Date Of Appearance!",
            required: false
          },
          {
            name: "buttons",
            placeholder: "Buttons",
            disabled: isPrematureSpathe == true ? true : false,
            type: "number",
            required: isPrematureSpathe == true ? false : true,
            message: "Input the number of Buttons!"
          },
          {
            name: "pollinationFrom",
            placeholder: "Pollination From Date",
            type: "date",
            disabled: true,
            required: false,
            message: "Input Pollination From!"
          },
          {
            name: "pollinationTo",
            placeholder: "Pollination To Date",
            type: "date",
            disabled: true,
            required: false,
            message: "Input Pollination To!"
          }
        ]
        : [
          {
            name: "tree",
            placeholder: "Tree No.",
            //type: "alphanumeric",
            disabled: true,
            required: false
          },
          {
            name: "spatheNo",
            placeholder: "Spathe No.",
            disabled: true,
            type: "number",
            value: this.state.spatheNo,
            className: this.state.spatheNo == 1 ? "spatheHighlight" : "",
            required: false
          },
          {
            name: "dtEmasculation",
            placeholder: "Date Of Emasculation",
            disabled: isDelete ? true : false,
            type: "date",
            message: "Input the Date Of Emasculation!",
            changeHandler: this.formDateOfEmasculationHandler
          },
          {
            name: "prematureSpathe",
            placeholder: "Premature Spathe",
            listData: ["No", "Yes"],
            hasInitial: true,
            disabled: isDelete ? true : false,
            message: "Input the number of Premature Spathe!",
            //value: isPrematureSpathe ? true : false,
            value: isPrematureSpathe ? "Yes" : "No",
            required: false,
            changeHandler: this.onPrematureSpatheHandler
          },
          {
            name: "treeTypeCode",
            placeholder: "Tree Type",
            message: "Input the Tree Type!",
            listData: cloneDeep(treeTypeData).map(it => it.code),
            required: true,
            value: this.state.treeTypeCode,
            disabled:spatheNumber === 1 ? false : true,
            changeHandler:it=>this.treeTypeChangeHandler(it)
          },
          {
            name: "dtAppearance",
            placeholder: "Date Of Appearance",
            type: "date",
            disabled: true,
            message: "Input the Date Of Appearance!",
            required: false
          },
          {
            name: "buttons",
            placeholder: "Buttons",
            disabled:
              isDelete == true ||
                (editData && editData.prematureSpathe == "Yes") ||
                isPrematureSpathe == true
                ? true
                : false,
            type: "number",
            required:
              (editData && editData.prematureSpathe == "Yes") ||
                isPrematureSpathe == true
                ? false
                : true,
            message: "Input the number of Buttons!"
          },
          {
            name: "pollinationFrom",
            placeholder: "Pollination From Date",
            type: "date",
            disabled: true,
            required: false,
            message: "Input Pollination From!"
          },
          {
            name: "pollinationTo",
            placeholder: "Pollination To Date",
            type: "date",
            disabled: true,
            required: false,
            message: "Input Pollination To!"
          }
        ];

  
    return (
      <Fragment>
        <Modal
          className="page-builder-add-modal emasculation-modal"
          //className="admin-container-modal"
          width={400}
          footer={false}
          closable={false}
          destroyOnClose={true}
          title="Confirm"
          visible={this.state.visibleConfirm}
          // onOk={()=>this.yesConfirm()}
          // onCancel={()=>this.closeConfirm()}
        >
          <p className="emasculation-p ">{"Date Of Planting: "+ moment(dtPlanted).format("DD/MM/YYYY")}</p>
          {stageCode == 1 ?<p>Seedling will be transferred to Plant</p>:null }
          <p>Do you want to Proceed further?</p>
          <Row type="flex" justify="end" gutter={5}>
                <Col>
                  <Button
                    onClick={()=>this.closeConfirm()} //loading={btnLoad}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col>
                  <Button
                     onClick={()=>this.yesConfirm()}
                     >
                       OK
                  </Button>
                </Col>
              </Row>
          </Modal>
        <LocationPageBuilder
          title="Dwarf Tree Emasculation"
          buttonList={buttonList}
          dropDownList={[
            {
              label: "Block",
              listItems: blockNoList.map(it => it.blockNoFull),
              value: blockNumber ? blockNumber : undefined,
              changeHandler: it => this.onBlockChangeHandler(it)
            },
            {
              label: "Area",
              listItems: cloneDeep(areaNoList).map(it => it.areaNo),
              disabled: blockNumber ? false : true,
              value: areaNumber ? areaNumber : undefined,
              changeHandler: it => this.onAreaChangeHandler(it)
            },
            /*{
              label: "Row No.",
              listItems: cloneDeep(rowNoList).map(it => it.rowNoFull),
              disabled: areaNumber ? false : true,
              value: rowNumber ? rowNumber : undefined,
              changeHandler: it => this.onRowChangeHandler(it)
            },*/
            {
              label: "Tree No.",
              listItems: cloneDeep(treeNoList).map(it => it.treeNoFull),
             disabled: areaNumber ? false : true,
              value: treeNumber ? treeNumber : undefined,
              changeHandler: it => this.onTreeChangeHandler(it)
            },
            {
              label: "Date of Emasculation",
              type: "date",
              //value: dateOfEmasculation ? dateOfEmasculation : undefined,
              value: EmasculationDate ? EmasculationDate : undefined,
              changeHandler: this.dateOfEmasculationHandler
            },
          ]}
          addModal={addModal}
          addModalToggle={this.addModalToggle}
          addFormHandler={this.addFormHandler}
          editData={editData}
          btnLoad={btnLoad}
          isEdit={isEdit}
          isDelete={isDelete}
          addPopulateData={addPopulateData}
          formItems={formItems}
        // formItems={[
        //   {
        //     name: "tree",
        //     placeholder: "Tree No.",
        //     //type: "alphanumeric",
        //     listData: cloneDeep(formTreeNoList).map(it => it.treeNoFull),
        //     disabled: isEdit || isDelete ? true : false,
        //     readonly: treeNoList.length > 0 ? false : true,
        //     message: "Input the tree no.!",
        //     changeHandler: it => this.onFormTreeChangeHandler(it)
        //   },
        //   {
        //     name: "spatheNo",
        //     placeholder: "Spathe No.",
        //     disabled: true,
        //     type: "number",
        //     value: this.state.spatheNo,
        //     message: "Input the Spath Number!",
        //     className: this.state.spatheNo == 1 ? "spatheHighlight" : ""
        //   },
        //   {
        //     name: "dtEmasculation",
        //     placeholder: "Date Of Emasculation",
        //     disabled: isDelete ? true : false,
        //     type: "date",
        //     message: "Input the Date Of Emasculation!",
        //     changeHandler: this.onEmasculationDateHandler
        //   },
        //   {
        //     name: "prematureSpathe",
        //     placeholder: "Premature Spathe",
        //     listData: ["No", "Yes"],
        //     hasInitial: true,
        //     disabled: isDelete ? true : false,
        //     message: "Input the number of Premature Spathe!",
        //     //value: isPrematureSpathe ? true : false,
        //     value: isPrematureSpathe ? "Yes" : "No",
        //     required: false,
        //     changeHandler: this.onPrematureSpatheHandler
        //   },
        //   {
        //     name: "dtAppearance",
        //     placeholder: "Date Of Appearance",
        //     type: "date",
        //     disabled: true,
        //     message: "Input the Date Of Appearance!"
        //   },
        //   {
        //     name: "buttons",
        //     placeholder: "Buttons",
        //     disabled:
        //       isDelete == true ||
        //       (editData && editData.prematureSpathe == "Yes") ||
        //       isPrematureSpathe == true
        //         ? true
        //         : false,
        //     type: "number",
        //     required:
        //       (editData && editData.prematureSpathe == "Yes") ||
        //       isPrematureSpathe == true
        //         ? false
        //         : true,
        //     message: "Input the number of Buttons!"
        //   },
        //   {
        //     name: "pollinationFrom",
        //     placeholder: "Pollination From Date",
        //     type: "date",
        //     disabled: true,
        //     required: false,
        //     message: "Input Pollination From!"
        //   },
        //   {
        //     name: "pollinationTo",
        //     placeholder: "Pollination To Date",
        //     type: "date",
        //     disabled: true,
        //     required: false,
        //     message: "Input Pollination To!"
        //   }
        // ]}
        >
          <div ref={this.componentRef}>
            <Spin spinning={loading} size="large">
              <Table
                ref="table"
                rowKey="uuid"
                columns={columns}
                dataSource={data}
                pagination={!pagination ? pagination : { pageSize: 15 }}
                //loading={loading}
                locale={{
                  emptyText: <Empty />
                }}
              />
            </Spin>
          </div>
        </LocationPageBuilder>
        <Modal 
        title="Confirm"
        visible={this.state.visible}
        className="confirmModal"
        onCancel={this.handleCancel}
        footer={[
          <Button key="submit" onClick={this.handleCancel}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelClose}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>

        <Modal 
        title="Confirm"
        visible={this.state.visibleAdd}
        className="confirmModal"
        onCancel={this.handleCancelAdd}
        footer={[
          <Button key="submit" onClick={this.handleCancelAdd}>
            Yes
          </Button>,
          <Button key="back" onClick={this.handleCancelCloseAdd}>
            No
          </Button>
        ]}
        >
          <div className="confirmationBody">Are you sure you want to change the Tree Type?</div>
        </Modal>
      </Fragment>
    );
  }
}

export default DwarfTreeEmasculation;
