import React, { Component } from "react";

//Component
import { message, Spin } from "antd";
import ReportLayout from "../../../pageLayouts/Report";
import MultiTable from "./MultiTable";
import Empty from "../../../components/Custom/Empty";
import { dwartfColoums } from "./coloumChecker";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { fetchApi, fetchExcelApi } from "../../../../services/api";
import { parserFunctionDwarf } from "./parserFunction";
import { cloneDeep } from "lodash";
import moment from "moment";

class SelectedListYieldingDwarfTrees extends Component {
  componentRef = React.createRef();
  state = {
    data: [],
    coloums: [],
    loading: false,
    blockNoList: [],
    blockNumber: null,
    block_uuid: null,
    areaNumber: null,
    areaNoList: [],
    area_uuid: null,
    year: null,
    harvestQuantity: null,
    isopen: false,
    yieldType: 0,
    value: null
  };

  componentDidMount() {
    this.fetchBlockNo();
  }

  fetchDwarfYielding = () => {
    this.setState({data:[]})
    let {
      block_uuid,
      area_uuid,
      yieldType,
      harvestQuantity,
      year
    } = this.state;
    if (block_uuid && harvestQuantity && year) {
      this.setState({ loading: true });
      fetchApi(
        `/special-reports/get-high-low-dwarf-list?yield_type=${yieldType}&year=${year}&harvest_quantity=${harvestQuantity}&block.uuid=${block_uuid}&area.uuid=${area_uuid}`,
        "get"
      ).then(res => {
        if (res) {
          let { blocks, year } = res;
          blocks
            ? this.setState(
                {
                  coloums: dwartfColoums(this.state.year)
                },
                () => this.setState({ data: parserFunctionDwarf(blocks) })
              )
            : this.setState(
                {
                  coloums: []
                },
                () => this.setState({ data: [] })
              );
        } else message.error("Internal Server Error!");
        this.setState({ loading: false });
      });
    } else {
      this.setState({ data: [] });
    }
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  //year functions
  handleOpenChange = status => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  clearValue = () => {
    this.setState(
      {
        value: null,
        year: null
      },
      () => this.fetchDwarfYielding()
    );
  };

  onDropDownChangeHandler = (type, item) => {
    switch (type) {
      case "block":
        if (item) {
          this.setState(
            {
              block_uuid: item
                ? cloneDeep(this.state.blockNoList).filter(
                    each => each.blockNoFull === item
                  )[0].uuid
                : null,
              blockNumber: item ? item : null
            },
            () => {
              fetchApi(
                `/areasuuid?block.uuid=${this.state.block_uuid}`,
                "get"
              ).then(res =>
                this.setState({ areaNoList: res }, () =>
                  this.fetchDwarfYielding()
                )
              );
            }
          );
        } else {
          this.setState(
            {
              block_uuid: null,
              blockNumber: null,
              areaNoList: [],
              areaNumber: null
            },
            () => () => this.fetchDwarfYielding()
          );
        }

        break;
      case "area":
        this.setState(
          {
            areaNumber: item ? item : null,
            area_uuid: item
              ? cloneDeep(this.state.areaNoList).filter(
                  it => it.areaNo == item
                )[0].uuid
              : null
          },
          () => this.fetchDwarfYielding()
        );
        break;

      case "harvest-quantity":
        this.setState({ harvestQuantity: item ? item : null }, () =>
          this.fetchDwarfYielding()
        );
        break;

      case "yield":
        this.setState({ yieldType: item == "High" ? 1 : 0 }, () =>
          this.fetchDwarfYielding()
        );
        break;

      default:
        this.setState(
          {
            value: item,
            year: moment(item).format("YYYY"),
            isopen: false
          },
          () => this.fetchDwarfYielding()
        );
    }
  };

  generateExcel = () => {
    let {
      block_uuid,
      area_uuid,
      yieldType,
      harvestQuantity,
      year
    } = this.state;
    let url = `/special-reports/get-high-low-dwarf-list?yield_type=${yieldType}&year=${year}&harvest_quantity=${harvestQuantity}&block.uuid=${block_uuid}&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  generateTables = (tableData,title )=> (
    <MultiTable dataSource={tableData} columns={this.state.coloums} title={title}/>
  );

  render() {
    let {
      data,
      areaNumber,
      blockNoList,
      areaNoList,
      loading,
      blockNumber,
      harvestQuantity,
      block_uuid,
      year,
      value,
      isopen,
      yieldType
    } = this.state;
    let sign = "";
    let title = "";
    if (
      this.state.harvestQuantity != null &&
      this.state.year != null &&
      blockNumber != null
    ) {
      if (yieldType == 1) {
        sign = ">=";
      } else {
        sign = "<=";
      }
      title =
      "Selected List Of Low/High Yielding (Performance) Dwarf Trees " +
        sign +
        " " +
        this.state.harvestQuantity +
        " During THE YEAR " +
        this.state.year;
    }
    let JSX =
      data.length > 0 ? (
        this.generateTables(data,title)
      ) : harvestQuantity && block_uuid && year ? (
        <Empty />
      ) : (
        <Empty
          description={"Select the Block No., Harvest Quantity and Year"}
        />
      );
    const buttonList =
      data.length > 0
        ? [
            {
              label: "Excel",
              icon: "file-text",
              onClickHandler: () => this.generateExcel()
            },
            {
              label: "Print",
              icon: "diff",
              //toolTip: "Suggestion: Print in Landscape",
              reference: this.componentRef
            }
          ]
        : null;
    return (
      <ReportLayout
        // title={
        //   "Selected List Of Low/High Yielding (Performance) Dwarf Trees " +
        //   title
        // }
        title={
          "Selected List Of Low/High Yielding (Performance) Dwarf Trees "
        }
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onDropDownChangeHandler("block", it)
          },
          {
            label: "Area",
            disabled: blockNumber ? false : true,
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onDropDownChangeHandler("area", it)
          },
          {
            label: "Yield",
            hasInitial: true,
            listItems: ["Low", "High"],
            changeHandler: it => this.onDropDownChangeHandler("yield", it)
          },
          {
            label:
              yieldType == 1 ? "Harvest Quantity >=" : "Harvest Quantity <=",
            type: "number",
            changeHandler: it =>
              this.onDropDownChangeHandler("harvest-quantity", it)
          },
          {
            label: "Year",
            type: "date",
            mode: "year",
            value: value != null ? value : undefined,
            open: isopen,
            format: "YYYY",
            onOpenChange: this.handleOpenChange,
            onPanelChange: val => this.onDropDownChangeHandler("year", val),
            changeHandler: this.clearValue
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {JSX}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default SelectedListYieldingDwarfTrees;
