import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

import {
  Table,
  message,
  Skeleton,
  Spin,
  Statistic,
  Descriptions,
  Col
} from "antd";

//Component import
import ReportLayout from "../../../../pageLayouts/Report";
import { fetchApi, fetchExcelApi } from "../../../../../services/api";
import MultiTable from "../MultiTableDataEntryStatus";
import Empty from "../../../../components/Custom/Empty";

//Utility
import { COOKIE, getCookie } from "../../../../../services/cookie";
import { sortFunction } from "../../../../../utility/sorter";
import { mapKeys, isEmpty, cloneDeep } from "lodash";
import moment from "moment";

//Parse Function
import { parseData } from "../../parserFunctionsAreaList";
//mock
//import mock from "./mocData";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

class TallPlantAreaList extends Component {
  componentRef = React.createRef();
  state = {
    data: null,
    loading: false,
    farmTotal: [],
    blockNoList: [],
    block_uuid: null,
    blockNumber: null,
    areaNoList: [],
    areaNumber: null,
    area_uuid: null,
    farm_codes: null,
    farm_total: 0
  };

  componentDidMount() {
    //this.fetchDataEntryTall();
    this.fetchBlockNo();
    this.fetchAreaList();
  }

  fetchAreaList = () => {
    this.setState({ loading: true });
    // let { block_uuid, ActivityMonth } = this.state,
    //   today = moment().format("YYYY-MM");
    let { area_uuid, block_uuid } = this.state,
      url = "/api/talls/get-areawise-list-tall?stage_code=2";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchApi(url, "get").then(res => {
      if (res) {
        this.setState(
          {
            data: res.data ? parseData(cloneDeep(res.data)) : null,
            farm_codes: res.farm_codes ? res.farm_codes : null,
            farm_total: res.farm_total ? res.farm_total : null,
            loading: false
          },
          () => console.log(this.state.data)
        );
      } else message.error("Internal Server Error!");
      this.setState({ loading: false });
    });

    // this.setState({
    //   data: mock
    // });
    // fetchApi(
    //     `/regular-reports/farm-production-activities-all-blocks?period=${
    //     DateOfSummary ? DateOfSummary : today
    //     }&block.uuid=${block_uuid}`,
    //     "get"
    // ).then(res => {
    //     if (res)
    //         this.setState({
    //             data: parserFunction(res.blocks).flat(1)
    //         })
    //     else message.error("Internal Server Error!");
    //     this.setState({ loading: false });
    // });
  };

  fetchBlockNo = () =>
    fetchApi("/blocksuuid", "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;
    if (it) {
      fetchApi(
        `/areasuuid?block.uuid=${
        cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0].uuid
        }`,
        "get"
      ).then(res => this.setState({ areaNoList: res }));
    }
    this.setState(
      {
        blockNumber: it ? it : null,
        block_uuid: it
          ? cloneDeep(blockNoList).filter(item => item.blockNoFull == it)[0]
            .uuid
          : null,
        areaNumber: null,
        area_uuid: null,
        areaNoList: []
      },
      () => this.fetchAreaList()
    );
  };

  onAreaChangeHandler = it => {
    let { areaNoList, blockNoList, blockNumber } = this.state;

    this.setState(
      {
        areaNumber: it ? it : null,
        area_uuid: it
          ? cloneDeep(areaNoList).filter(item => item.areaNo == it)[0].uuid
          : null
      },
      () => this.fetchAreaList()
    );
  };

  activityMonthHandler = date => {
    if (date) {
      this.setState(
        {
          ActivityMonth: date.format("YYYY-MM")
        },
        () => this.fetchAreaList()
      );
    } else {
      this.setState({
        ActivityMonth: null,
        blockNumber: null,
        block_uuid: null,
        TreeType: null,
        data: null
      });
    }
  };

  generateTables = tableData => (
    <div>
      <h3>
        Tall Plant Master - Area Wise List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
      : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
      {tableData.map(({ Area, block_no, block_codes, block_total }, index) => (
        <div key={index}>
          {Area.map(({ area_count, area_codes, tree_nos, ...rest }, j) =>
            tree_nos ? (
              <div key={j} className={index == 0 && j == 0 ? "dwarf-list-report" : "pagebreak dwarf-list-report"}>
                {tree_nos && (
                  <div>
                    <Col key={j} {...layout}>
                      <Statistic
                        title="Block No."
                        value={rest["block_no"]}
                      />
                    </Col>
                    <Col key={j} {...layout}>
                      <Statistic title="Area No." value={rest["area_no"]} />
                    </Col>
                  </div>
                )}
                {tree_nos && (
                  <Descriptions bordered className="p-0">
                    <Descriptions.Item label="Tree No." span={3}>
                      {tree_nos &&
                        tree_nos.map((it, i) => (
                          <Col>
                            <span key={i} style={{ margin: "0px 10px" }}>
                              {it}
                            </span>
                          </Col>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item span={6}></Descriptions.Item>
                  </Descriptions>
                )}

                {tree_nos && (
                  <Descriptions bordered>
                    <Descriptions.Item
                      label={"Total Tall Trees In Area"}
                      span={3}
                    >
                      {rest["area_no"]}
                    </Descriptions.Item>
                    {area_codes &&
                      area_codes.map(({ code, total_count }, k) => (
                        <Descriptions.Item
                          key={k}
                          label={"Type " + code}
                          span={3}
                        >
                          {total_count}
                        </Descriptions.Item>
                      ))}
                    <Descriptions.Item label={"All Types"} span={3}>
                      {area_count}
                    </Descriptions.Item>
                    <Descriptions.Item span={6}></Descriptions.Item>
                  </Descriptions>
                )}
                {j == Area.length - 1 ? (
                  <br />
                ) : (
                    <Fragment><br /><br /></Fragment>
                  )}
              </div>
            ) : null
          )}
          {block_codes && (
            <Descriptions bordered>
              <Descriptions.Item label={"Total Tall Trees in Block"} span={3}>
                {block_no}
              </Descriptions.Item>
              {block_codes &&
                block_codes.map(({ code, area_count }, i) => (
                  <Descriptions.Item
                    key={i}
                    label={"Type " + code}
                    span={3}
                  >
                    {area_count}
                  </Descriptions.Item>
                ))}
              <Descriptions.Item label={"All Types"} span={3}>
                {block_total}
              </Descriptions.Item>
            </Descriptions>
          )}
          <br />
          <br />
        </div>
      ))}
      {this.state.farm_codes && (
        <Descriptions bordered>
          <Descriptions.Item
            label={"Total Tall Trees in Farm"}
            span={3}
          ></Descriptions.Item>
          {this.state.farm_codes &&
            this.state.farm_codes.map(({ code, farm_count }, i) => (
              <Descriptions.Item key={i} label={"Type " + code} span={3}>
                {farm_count}
              </Descriptions.Item>
            ))}
          <Descriptions.Item label={"All Types"} span={3}>
            {this.state.farm_total}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );

  generateExcel = () => {
    let { area_uuid, block_uuid } = this.state,
      url = "/api/talls/get-areawise-list-tall?stage_code=2";
    if (block_uuid) url += `&block.uuid=${block_uuid}`;
    if (area_uuid) url += `&area.uuid=${area_uuid}`;

    fetchExcelApi(url, "get").then();
  };

  render() {
    let {
      data,
      loading,
      farmTotal,
      blockNoList,
      blockNumber,
      areaNumber,
      areaNoList
    } = this.state;

    const buttonList = data
      ? [
        {
          label: "Excel",
          icon: "file-text",
          onClickHandler: () => this.generateExcel()
        },
        {
          label: "Print",
          icon: "diff",
          reference: this.componentRef
        }
      ]
      : null;
    let JSX = data ? this.generateTables(data) : null;
    const columns = [
      {
        title: "Date Of Pollen Collected",
        dataIndex: "dt_pollen_collected"
      },
      {
        title: "Total No. Of Trees",
        dataIndex: "count"
      },
      {
        title: "Dates Of Starting",
        dataIndex: "start_date"
      },
      {
        title: "Date Of Ending",
        dataIndex: "end_date"
      },
      {
        title: "Last Update Date",
        dataIndex: "update_date"
      }
    ];
    return (
      <ReportLayout
        title="Tall Plant Master - Area Wise List"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Area",
            listItems: cloneDeep(areaNoList).map(it => it.areaNo),
            disabled: blockNumber ? false : true,
            value: areaNumber ? areaNumber : undefined,
            changeHandler: it => this.onAreaChangeHandler(it)
          }
        ]}
      >
        <div ref={this.componentRef}>
          <Spin spinning={loading} size="large">
            {data ? <div>{JSX}</div> : <Empty></Empty>}
          </Spin>
        </div>
      </ReportLayout>
    );
  }
}

export default TallPlantAreaList;
