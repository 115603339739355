import React from "react";
import { Table, Col, Statistic, Row } from "antd";
import ScollBarView from "react-double-scrollbar";
import { COOKIE, getCookie } from "../../../../services/cookie";

const layout = {
  xs: {
    span: 6
  },
  sm: {
    span: 6
  },
  lg: {
    span: 4
  },
  md: {
    span: 4
  }
};

let columns = [
  {
    title: "No. of spathes",
    dataIndex: "no_of_spathes",
    render: it => (it ? it : "-")
  },
  {
    title: "No. of Buttons",
    dataIndex: "no_of_buttons",
    render: it => (it ? it : "-")
  },
  {
    title: "120 Days Count",
    dataIndex: "count_120_days",
    render: it => (it ? it : "-")
  },
  {
    title: "Proj %",
    dataIndex: "proj",
    render: it => (it ? it : "-")
  },
  {
    title: "Harvest Nuts",
    dataIndex: "harvested_nuts",
    render: it => (it ? it : "-")
  },
  {
    title: "Setg %",
    dataIndex: "setg",
    render: it => (it ? it : "-")
  }
];
let count=0;
export default ({ dataSource, title }) =>
<div>
  <h3>{title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : {getCookie(COOKIE.FARM).charAt(0).toUpperCase() + getCookie(COOKIE.FARM).slice(1)}{" "} </h3>
  {dataSource.map(({ years, research, ...rest }, index) =>
    years || research ? (

      <div key={index} 
      //className={index==0?"":"pagebreak"}
      >
        <Row gutter={5}>
          {/* {Object.keys(rest).map((it, index) => (
            <Col key={index} {...layout}>
              {
                <Statistic
                  title={it.split("_").map(it => `${it.toUpperCase()} `)}
                  value={rest[it]}
                />
              }
            </Col>
          ))} */}
          <Col key={rest.block_no} {...layout}>
          <Statistic title={"Block No."} value={rest.block_no} />
          </Col>
        </Row>
            {years
              ?               
              years.length>2?
              <ScollBarView>
              <div style={{ display: "flex" }}>
              {years &&
                years.map(({ year, months }, i) => (
                  <Table
                    //className="block-wise-summary-dwarf-table"
                    className="bordered-table-head"
                    bordered
                    columns={[
                      {
                        title: year,
                        children:
                          i === 0
                            ? [{ title: "Month", dataIndex: "month" }].concat(
                                columns
                              )
                            : columns
                      }
                    ]}
                    dataSource={months}
                    size="small"
                    pagination={false}
                  />
                ))}
                </div>
        </ScollBarView>:
        <div style={{ display: "flex" }}>
              {years &&
                years.map(({ year, months }, i) => (
                  <Table
                    //className="block-wise-summary-dwarf-table"
                    className="bordered-table-head"
                    bordered
                    columns={[
                      {
                        title: year,
                        children:
                          i === 0
                            ? [{ title: "Month", dataIndex: "month" }].concat(
                                columns
                              )
                            : columns
                      }
                    ]}
                    dataSource={months}
                    size="small"
                    pagination={false}
                  />
                ))}
                </div>

              :
              <div style={{ display: "flex" }}> 
              {research &&
                research.map(({ no_of_trees, name, months }, i) => (
                  
                  <Table
                    //className="block-wise-summary-dwarf-table"
                    className="bordered-table-head"
                    bordered
                    columns={[
                      {
                        title: name + " AREA-No. OF TREES: " + no_of_trees,
                        children:
                          i === 0
                            ? [{ title: "Month", dataIndex: "month" }].concat(
                                columns
                              )
                            : columns
                      }
                    ]}
                    dataSource={months}
                    size="small"
                    pagination={false}
                  />
                ))}</div>
                }
                <br/>
          <div className={index-1<dataSource.length?"":"pagebreak"}/>
      </div>
    ) : null
  )}
  </div>;
