import { mapKeys, groupBy } from "lodash";
import { sortFunction } from "../../../utility/sorter";

/**
 * @name parseData
 * @description parses the array supplied for rendering in the table
 * @param {Array} arr
 */

export const parseData = arr => {
  let parsedData = [];
  mapKeys(groupBy(arr, "blockNo"), (item, blockNo) =>
    mapKeys(groupBy(item, "areaNo"), (value, areaNo) =>
      parsedData.push({ blockNo, areaNo, value })
    )
  );
  //console.log("Parse Data", parsedData);
  return sortFunction(parsedData, "blockNo");
};
export const parseData2 = arr => {
  let parsedData = [];
  arr &&
    arr.map(({ areas, block_no }) =>
      areas.map(({ area_no, trees }) =>
        parsedData.push({
          block_no,
          area_no,
          trees
        })
      )
    );
  return parsedData;
};
export const parseData3 = arr => {
  let parsedData = [];
  let half_length, value2;
  mapKeys(groupBy(arr, "blockNo"), (item, blockNo) =>
    mapKeys(groupBy(item, "areaNo"), (value, areaNo) => {
      half_length = Math.ceil(value.length / 2);
      value2 = value.splice(0, half_length);
      return parsedData.push({ blockNo, areaNo, value, value2 });
    })
  );
  return sortFunction(parsedData, "blockNo");
};

export const parseData4 = arr => {
  let size = 0;
  let n = 4;
  let parsedData = [];
  let value1 = [];
  let value2 = [];
  mapKeys(groupBy(arr, "blockNo"), (item, blockNo) =>
    mapKeys(groupBy(item, "areaNo"), (value, areaNo) => {
      value.map((it, j) => {
        size = value.length;
        if (j % 2 == 0) value1.push(it);
        else if (j % 2 == 1) value2.push(it);
      });
      parsedData.push({ blockNo, areaNo, value1, value2 });
      value1 = [];
      value2 = [];
      return parsedData;
    })
  );
  console.log("parsed data", parseData);
  return sortFunction(parsedData, "blockNo");
};
